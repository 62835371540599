import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'
import AddressCopyToClipBoard from 'components/MarketplaceV2/utils/AddressCopyToClipBoard'
import CancelDirectListing from '../buttons/CancelDirectListing'
import CloseAuctionButton from '../buttons/CloseAuctionButton'

// Constants
import { CBY_ADDRESS } from 'constants/BlockchainConstants'
import ClaimButton from '../buttons/ClaimButton'
import moment from 'moment'

function TradeStationMain({ handleBuyNowModal, handleMakeOfferModal, handleUpdateListingModal }) {
  const { address: walletAddress } = useAccount()
  const { listedNft } = useSelector(state => state.listings)
  const [listingStarted, setListingStarted] = useState(false)
  const [listingHasOffer, setListingHasOffer] = useState(false)

  const isListingExpired = end_time => {
    const currentUnixTimestamp = Math.floor(Date.now() / 1000)
    if (end_time < currentUnixTimestamp) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const startMoment = moment.unix(listedNft.startTime)
    const now = moment()
    if (now.isSameOrAfter(startMoment)) {
      setListingStarted(true)
    }

    if (listedNft?.bidder != '0x') setListingHasOffer(true)
  }, [])

  return (
    <div className="w-full">
      <div className="flex flex-col lg:flex-row justify-between">
        <p className="text-base mb-2.5">{listedNft?.listingType === 0 ? 'Buyout price' : 'Minimum bid price'}</p>
        <p className="text-base mb-2.5 flex">
          Owned by:
          <span className="text-secondary ml-3">{<AddressCopyToClipBoard address={listedNft?.lister} />}</span>
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="flex items-center">
          <div>
            <img
              className="h-8 w-8 mt-0.5 mr-2"
              src={
                listedNft?.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                  ? `/assets/images/carbify-logo-marketplace.png`
                  : `/assets/images/carbify-blue-dollar.png`
              }
              alt=""
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">
              {listedNft?.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                ? (listedNft?.listingType === 0
                    ? listedNft?.buyoutPricePerToken / 10 ** 18
                    : listedNft?.reservePricePerToken / 10 ** 18
                  ).toLocaleString()
                : (listedNft?.listingType === 0
                    ? listedNft?.buyoutPricePerToken / 10 ** 18
                    : listedNft?.reservePricePerToken / 10 ** 18
                  ).toLocaleString()}{' '}
              {listedNft?.currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? `CBY` : `USDC`}
            </p>
          </div>
        </div>
        {listedNft?.listingType === 0 ? (
          listedNft?.lister.toLowerCase() !== walletAddress.toLowerCase() ? (
            <div className="flex items-center mt-4 lg:mt-0">
              <button onClick={() => handleBuyNowModal(true)} className="bg-white py-1 px-3 rounded-lg mr-2.5">
                <span className="text-base font-bold text-bt">Buy now</span>
              </button>
              <button onClick={() => handleMakeOfferModal(true)} className="border py-1 px-3 rounded-lg">
                Make offer
              </button>
            </div>
          ) : (
            <div className="flex items-center mt-4 lg:mt-0">
              <CancelDirectListing listingId={listedNft?.listingId} />
              {!isListingExpired(listedNft?.endTime) && (
                <button onClick={() => handleUpdateListingModal(true)} className="border py-1 px-3 rounded-lg">
                  Update listing
                </button>
              )}
            </div>
          )
        ) : listedNft?.listingType === 1 ? (
          <div className="flex items-center mt-4 lg:mt-0">
            {!isListingExpired(listedNft?.endTime) ? (
              <>
                {listedNft?.lister.toLowerCase() !== walletAddress.toLowerCase() ? (
                  <>
                    <button
                      onClick={() => handleMakeOfferModal(true)}
                      className="bg-secondary py-1 px-3 rounded-lg mr-2.5"
                    >
                      <span className="text-base font-bold text-bt">Place a bid</span>
                    </button>
                  </>
                ) : (
                  <>
                    {!listingHasOffer && (
                      <CloseAuctionButton listingId={listedNft?.listingId} closeFor={listedNft?.lister} />
                    )}
                    {!listingStarted && (
                      <button onClick={() => handleUpdateListingModal(true)} className="border py-1 px-3 rounded-lg">
                        Update listing
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {listedNft &&
                  (listedNft.bidder.toLowerCase() === walletAddress.toLowerCase() ? (
                    <>
                      <ClaimButton listingId={listedNft?.listingId} closeFor={listedNft?.bidder} />
                    </>
                  ) : listedNft.lister.toLowerCase() === walletAddress.toLowerCase() ? (
                    <>
                      <ClaimButton listingId={listedNft?.listingId} closeFor={listedNft?.lister} />
                    </>
                  ) : (
                    ''
                  ))}
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default TradeStationMain
