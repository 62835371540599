import React from 'react'

const Price = ({ logo, price, unit }) => {
  return (
    <div className="flex space-x-2 justify-start items-center">
      <img className="w-8 h-8" src={`/assets/images/${logo}`} />
      <div className="text-xl tracking-wider font-bold">
        {price} {unit}
      </div>
    </div>
  )
}

export default Price
