import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import ClaimAco2Card from 'components/cards/ClaimAco2Card'

// Actions'
import { getAllTreeBalances } from 'features/dashboard/getAllTreeBalances'
import { getAllTreeData } from 'features/dashboard/getAllTreeData'
import CarbonCertificateCard from 'components/cards/CarbonCertificateCard'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance.js'
import CertificateManagement from 'components/dashboard/CertificateManagement '

import Certificates from './Certificates'
import { getDetailsAndOverviewUser } from 'features/dashboard/getDetailsAndOverviewUser'
import OffsetMap from 'components/certificates/map/OffsetMap'
import MapAndCardsContainer from 'components/certificates/map/MapAndCardsContainer'
import OverviewCardsContainer from 'components/certificates/map/OverviewCardsContainer'
import Spinner from 'components/utils/Spinner'
import RequestedCerificates from './RequestedCerificates'
import { getUserCertificateRequests } from 'features/certificates/getUserCertificateRequests'

function CertificatesPage() {
  const { address } = useAccount();

  const [balance, setBalance] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [userCertificates, setUserCertificates] = useState(null);
  const [userCertificatesRequests, setUserCertificatesRequests] = useState(null);

  // certificate generation state
  const [certificateGenerationData, setCertificateGenerationData] = useState(null);

  useEffect(() => {
    getUserCertificateRequests().then((data) => {
      if (data) {
        setUserCertificatesRequests(data);
      }
    });
  }, [address]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.matchMedia("(max-width: 540px)").matches);
    };

    // Only run on client-side
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    setInitLoading(false); // Hide loading after screen size is determined

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (initLoading) {
    return <Spinner />;
  }

  return (
    <Layout title="Carbify | Certificates" content="Carbify Certificates">
      <PageHeading>Certificates</PageHeading>
      <CertificateManagement />
      <CarbonCertificateCard
        balance={balance}
        setBalance={setBalance}
        setUserCertificates={setUserCertificates}
        setUserCertificatesRequests={setUserCertificatesRequests}
        certificateGenerationData={certificateGenerationData}
        setCertificateGenerationData={setCertificateGenerationData}
      />
      <Certificates 
        userCertificates={userCertificates}
      />
      <OffsetMap 
        firstComponent={MapAndCardsContainer}
        secondaryComponent={OverviewCardsContainer}
        isSmallScreen={isSmallScreen}
        userCertificates={userCertificates}
        setUserCertificates={setUserCertificates}
      />
      <RequestedCerificates 
        userCertificatesRequests={userCertificatesRequests}
        setCertificateGenerationData={setCertificateGenerationData}
      />
    </Layout>
  )
}

export default CertificatesPage
