import React, { useState } from 'react'

//icons
import { AiOutlineCheck } from 'react-icons/ai'
import ChevronDownIcon from 'icons/ChevronDownIcon'
import ChevronUpIcon from 'icons/ChevronUpIcon'

const SortFilter = ({ sortValue, setSortValue }) => {
  const [openFilters, setOpenFilters] = useState(false)
  const sortingCategories = [
    'Price low to high',
    'Price high to low',
    'Recently listed',
    'Oldest',
    'Best Offer',
    'My Listings',
    'Expired',
    'All',
    'Active',
    // 'Sold',
  ]

  const handleFilters = () => {
    setOpenFilters(!openFilters)
  }

  const handleSortFilter = value => {
    setSortValue(value)
    setOpenFilters(false)
  }

  console.log('Value', sortValue)

  return (
    <div className="relative lg:w-60 sm:w-full z-10">
      <div
        className={`flex justify-between items-center cursor-pointer px-3 py-2 ${
          openFilters ? 'bg-[#232323] border-b-0 rounded-b-none' : ''
        } border rounded-lg`}
        onClick={handleFilters}
        style={{ height: '42px' }}
      >
        <span className="font-medium text-base tracking-wider">{sortValue}</span>
        <div className="flex items-center">
          {openFilters ? <ChevronUpIcon width={16} height={16} /> : <ChevronDownIcon width={16} height={16} />}
        </div>
      </div>
      {openFilters && (
        <div
          className={`absolute z-20 w-full bg-[#232323] rounded-b-lg px-3 py-2 border border-carbifyOrange border-t-0`}
        >
          <ul className="flex flex-col space-y-1">
            {sortingCategories.map(category => (
              <li
                key={category}
                onClick={() => handleSortFilter(category)}
                className={`flex justify-between items-center text-sm px-3 py-1 cursor-pointer ${
                  category === sortValue ? 'text-carbifyOrange' : 'hover:bg-opacity-50'
                }`}
                style={{ userSelect: 'none' }}
              >
                <span>{category}</span>
                {category === sortValue && <AiOutlineCheck />}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SortFilter
