/* global BigInt */
import React, { useState, useEffect } from 'react'
import { useReadContract, useAccount } from 'wagmi'
import { toast } from 'react-toastify'
import NftreeCard from './NftreeCard'
import NftreeBuyNowModal from '../modals/NftreeBuyNowModal'

// Constants
import {
  MARKETPLACE_ADDRESS,
  MARKETPLACE_ABI,
  NFTREE_BATCH_7_ADDRESS,
  NFTREE_BATCH_8_ADDRESS,
  USDC_ADDRESS,
  USDCE_ADDRESS,
  USDC_ABI,
  USDT_ADDRESS,
  USDT_ABI,
  STANDARD_PLOT_ADDRESS,
} from 'constants/BlockchainConstants'

// Icons
import { BiInfinite } from 'react-icons/bi'

function StandardPlotCard() {
  const [showBuyNowModal, setShowBuyNowModal] = useState(false)
  const [currentSupply, setCurrentSupply] = useState('')
  const [supplyLimit, setSupplyLimit] = useState()
  const [pricePerToken, setPricePerToken] = useState('')

  const { address: walletAddress } = useAccount()
  const [usdcBalance, setUSDCBalance] = useState('')
  const [usdceBalance, setUSDCEBalance] = useState('')
  const [usdtBalance, setUSDTBalance] = useState('')
  const [currency, setCurrency] = useState('')
  const [tokenOwner, setTokenOwner] = useState('')
  const [tokenBalance, setTokenBalance] = useState('')
  const [isUSDC, setIsUSDC] = useState(false);

  const {
    data: NFTInfo,
    error: NFTInfoError,
  } = useReadContract({
    address: MARKETPLACE_ADDRESS,
    abi: MARKETPLACE_ABI,
    functionName: 'NFTInfo',
    args: [STANDARD_PLOT_ADDRESS],
  })
  useEffect(() => {
    if (NFTInfo) {
      setCurrentSupply(Number(NFTInfo[0]))
      setSupplyLimit(Number(NFTInfo[1]))
      setPricePerToken(Number(NFTInfo[2]))
      setTokenOwner(NFTInfo[4])
    } else if (NFTInfoError) {
      // toast.error('Error fetching NFT Info:', NFTInfoError.cause)
    }
  }, [NFTInfo, NFTInfoError])

  const {
    data: tokenOwnerDataBatch7,
    error: tokenOwnerDataBatch7Error,
  } = useReadContract({
    address: NFTREE_BATCH_7_ADDRESS,
    abi: {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    functionName: 'balanceOf',
    args: [tokenOwner],
  })
  useEffect(() => {
    if (tokenOwnerDataBatch7) {
      setTokenBalance(_ => _ + Number(tokenOwnerDataBatch7))
    } else if (tokenOwnerDataBatch7Error) {
      // toast.error('Error fetching NFT Info:', tokenOwnerDataBatch7Error.cause)
    }
  }, [tokenOwnerDataBatch7, tokenOwnerDataBatch7Error])

  const {
    data: tokenOwnerDataBatch8,
    error: tokenOwnerDataBatch8Error,
  } = useReadContract({
    address: NFTREE_BATCH_8_ADDRESS,
    abi: {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    functionName: 'balanceOf',
    args: [tokenOwner],
  })
  useEffect(() => {
    if (tokenOwnerDataBatch8) {
      setTokenBalance(_ => _ + Number(tokenOwnerDataBatch8))
    } else if (tokenOwnerDataBatch8Error) {
      // toast.error('Error fetching NFT Info:', tokenOwnerDataBatch8Error.cause)
    }
  }, [tokenOwnerDataBatch8, tokenOwnerDataBatch8Error])

  const {
    data: usdcBalanceData,
    error: usdcBalanceError,
  } = useReadContract({
    address: USDC_ADDRESS,
    abi: USDC_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (usdcBalanceData) {
      setUSDCBalance(Number(usdcBalanceData))
    } else if (usdcBalanceError) {
      toast.error('Error fetching USDC balance:', usdcBalanceError.cause)
    }
  }, [usdcBalanceData, usdcBalanceError])

  const {
    data: usdceBalanceData,
    error: usdceBalanceError,
  } = useReadContract({
    address: USDCE_ADDRESS,
    abi: USDC_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (usdceBalanceData) {
      setUSDCEBalance(Number(usdceBalanceData))
    } else if (usdceBalanceError) {
      toast.error('Error fetching USDCE balance:', usdceBalanceError.cause)
    }
  }, [usdceBalanceData, usdceBalanceError])

  const {
    data: usdtBalanceData,
    error: usdtBalanceError,
  } = useReadContract({
    address: USDT_ADDRESS,
    abi: USDT_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (usdtBalanceData) {
      setUSDTBalance(Number(usdtBalanceData))
    } else if (usdtBalanceError) {
      toast.error('Error fetching USDT balance:', usdtBalanceError.cause)
    }
  }, [usdtBalanceData, usdtBalanceError])

  useEffect(() => {
    if (usdcBalance >= usdceBalance) {
      setIsUSDC(true)
      if (usdcBalance >= usdtBalance) {
        setCurrency(USDC_ADDRESS)
      } else setCurrency(USDT_ADDRESS)
    } else {
      setIsUSDC(false)
      if (usdceBalance >= usdtBalance) {
        setCurrency(USDCE_ADDRESS)
      } else setCurrency(USDT_ADDRESS)
    }
  }, [usdcBalance, usdtBalance, usdceBalance, showBuyNowModal])

  return (
    <>
      <NftreeCard
        title={'Standard Landplot'}
        image={'/assets/images/Standard_Plot_NFT_Card.png'}
        quantity={<BiInfinite size={28} />}
        price={pricePerToken ? pricePerToken / 10 ** 6 : ''}
        currency={currency}
        setShowBuyNowModal={setShowBuyNowModal}
      />
      {
        <NftreeBuyNowModal
          showModal={showBuyNowModal}
          setShowModal={setShowBuyNowModal}
          title={'Standard Landplot'}
          image={'/assets/images/Standard_Plot_NFT_Card.png'}
          pricePerToken={pricePerToken ? pricePerToken / 10 ** 6 : ''}
          currency={currency}
          isUSDC={isUSDC}
          setCurrency={setCurrency}
          asset_contract={STANDARD_PLOT_ADDRESS}
        />
      }
    </>
  )
}

export default StandardPlotCard
