"use client"
import React from 'react';
import { SmallOverviewCard, DualOverviewCard } from './OverviewCards';

const OverviewCardsContainer = ({ overviewData }) => {

    const areaReforested = overviewData == null 
    ? undefined 
    : (overviewData.total_trees * 12.5) + ' m²';
    const foodProvided = overviewData == null 
    ? undefined 
    : (overviewData.total_trees * 50) + ' kg';
    const workProvided = overviewData == null 
    ? undefined 
    : (overviewData.total_trees / 1200).toFixed(4) + ' FTE';
    const communitiesImpacted = overviewData == null 
    ? undefined 
    : `${overviewData.total_trees / 2500}`;
    // Should be a rounded number
    const burnedInCertificates = overviewData == null
    ? undefined
    : overviewData.total_aco2_burned_in_certificates.toFixed(0) + ' kg';
    
    return (
        <div className='w-full'>
            <div className="grid grid-cols-2 gap-4 w-full">
                <SmallOverviewCard 
                    title="Total agroforestry systems" 
                    value={overviewData === undefined ? undefined : overviewData?.total_trees}
                    tooltipText="The number of agroforestry systems that have contributed to the selected carbon reduction certificates, or the agroforestry systems that are owned. An agroforestry system is a biodiverse system consisting of multiple trees."
                />
                <SmallOverviewCard 
                    title="CO2 compensated" 
                    value={burnedInCertificates === undefined ? undefined : burnedInCertificates}
                    tooltipText="The total amount of CO2 used for the selected carbon reduction certificates. This number shows the exact number of carbon debits utilized for these certificates."
                />
                <SmallOverviewCard 
                    title="Food provided" 
                    value={foodProvided === undefined ? undefined : foodProvided}
                    tooltipText="This number represents the total amount of food produced by the agroforestry systems responsible for the selected carbon reduction certificates. It reflects the food output of the entire system(s)."
                />
                <SmallOverviewCard 
                    title="Work provided" 
                    value={workProvided === undefined ? undefined : workProvided}
                    tooltipText="This number represents the total amount of work provided by Carbify, resulting from the planting, maintenance, and harvesting of fruits and nuts from the agroforestry systems responsible for the selected carbon reduction certificates. It reflects the labor involved in the entire system(s)."
                />
                <SmallOverviewCard 
                    title="Area afforested" 
                    value={areaReforested === undefined ? undefined : areaReforested}
                    tooltipText="This number represents the total afforestation area in square meters (m²) established by the agroforestry systems responsible for the selected carbon reduction certificates. This metric tracks Carbify's efforts in environmental restoration."
                />
                <SmallOverviewCard 
                    title="Communities impacted" 
                    value={communitiesImpacted === undefined ? undefined : communitiesImpacted}
                    tooltipText="Communities impacted by Carbify through tree planting and food provision based on the selected carbon reduction certificates. The impact is shown for the entire agroforestry systems involved."
                />
            </div>
            <div className="mt-4 w-full hidden">
                <DualOverviewCard 
                    title="Total CO2 inside the compensation pool"
                    value1={overviewData === undefined ? undefined : overviewData?.total_aco2_generated.toFixed(2)}
                    value2={overviewData === undefined ? undefined : overviewData?.total_aco2_burned_in_certificates.toFixed(2)}
                    tooltipText="The total amount of CO2 absorbed by the trees planted by Carbify. This metric measures the carbon sequestration and as we call them Carbon Debits. / The total amount of CO2 used for carbon offsets. This number shows the carbon debits utilized for issuing carbon reduction certificates."
                />
            </div>
        </div>
    )
};

export default OverviewCardsContainer;
