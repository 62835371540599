import React from 'react'

// icons
import { AiOutlineSearch } from 'react-icons/ai'

const SearchByName = ({ search, setSearch }) => {

  const onChange = e => {
    setSearch(e.target.value)
  }

  return (
    <form className="flex rounded-lg border  grow">
      <div className="bg-black bg-opacity-25 rounded-l-lg flex items-center justify-center pl-3">
        <AiOutlineSearch className="w-6 h-6 text-white text-opacity-50" />
      </div>
      <input
        type="text"
        name="name"
        id="name"
        className="w-full px-3 py-2 bg-black bg-opacity-25 focus:outline-none focus:border-carbifyOrange rounded-r-lg"
        placeholder="Search by title"
        value={search}
        onChange={onChange}
      />
    </form>
  )
}

export default SearchByName
