import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarbonUnitInput from 'components/utils/CarbonUnitInput'
import TotalFootprint from './TotalFootprint'
import Decimal from 'decimal.js'

//reducers
import { setCarbonFootprint, setHouseFootprint } from 'features/footprint/footprintSlice'

//icons
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'

const House = ({ onBack, onNext, setPage }) => {
  const CO2_EMISSION_FACTORS = {
    electricity: 0.4521, // 1 kWh 0.4521
    naturalGas: 0.05, // 1 m3 of natural gas 0.05
    heatingOil: 2.54, // Heating Oil = 2540 kg CO2 per 1000 litres of oil.
    coal: 2.88, // 2880 kg CO2 per 1000kg COAL
    lpg: 1.56, // 1560kg co2 per 1000 liter lpg
    propane: 1.54, // 1540 kg CO2 per 1000 litres of propane
    wood: 70, // 1 m3 of wood 70
  }

  const [formData, setFormData] = useState({
    people: 1,
    electricity: 0,
    gas: 0,
    heatingOil: 0,
    coal: 0,
    lpg: 0,
    propane: 0,
    wood: 0,
    factor: CO2_EMISSION_FACTORS.electricity,
  })

  const { people, electricity, gas, heatingOil, coal, lpg, propane, wood, factor } = formData
  const electricityUnits = ['kWh']
  const gasUnits = ['m³', 'kWh', 'kg', 'therms']
  const propaneUnits = ['liters', 'US gallons']
  const coalUnits = ['kg', 'kWh', 'metric tons', 'x 10kg bags', 'x 20kg bags', 'x 25kg bags', 'x 50kg bags']
  const lpgUnits = ['litres', 'kWh', 'kg', 'therms', 'US gallons']
  const heatingOilUnits = ['litres', 'kWh', 'kg', 'metric tons', 'US gallons']

  const { carbonFootprint, houseFootprint } = useSelector(state => state.footprint)
  const [electricityUnit, setElectricityUnit] = useState(electricityUnits[0])
  const [gasUnit, setGasUnit] = useState(gasUnits[0])
  const [heatingOilUnit, setHeatingOilUnit] = useState('litres')
  const [coalUnit, setCoalUnit] = useState(coalUnits[0])
  const [lpgUnit, setLpgUnit] = useState(lpgUnits[0])
  const [propaneUnit, setPropaneUnit] = useState(propaneUnits[0])
  const [woodUnit, setWoodUnit] = useState('metric tons')
  const [isFirstRendering, setIsFirstRendering] = useState(true)
  const [showTooltip, setShowTooltip] = useState(false)

  const [electricityCalculation, setElectricityCalculation] = useState(0)
  const [gasCalculation, setGasCalculation] = useState(0)
  const [heatingOilCalculation, setHeatingOilCalculation] = useState(0)
  const [coalCalculation, setCoalCalculation] = useState(0)
  const [lpgCalculation, setLpgCalculation] = useState(0)
  const [propaneCalculation, setPropaneCalculation] = useState(0)
  const [woodCalculation, setWoodCalculation] = useState(0)
  const [peopleCalculation, setPeopleCalculation] = useState(1)
  const [sum, setSum] = useState(0)

  const dispatch = useDispatch()
  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleTooltip = () => {
    setShowTooltip(true)
  }

  const handleCloseTooltip = () => {
    setShowTooltip(false)
  }

  const handleNavigation = type => {
    setPage(false)
    if (type === 'Next') {
      onNext(true)
    } else {
      onBack(true)
    }
  }

  const calculateGasFootprint = (gu, value) => {
    if (gu === gasUnits[1]) {
      return new Decimal(value / 10.39).times(CO2_EMISSION_FACTORS.naturalGas) // for Kwh
    } else if (gu === gasUnits[0]) {
      return new Decimal(value).times(CO2_EMISSION_FACTORS.naturalGas) // m3
    } else if (gu === gasUnits[2]) {
      return new Decimal(value / 0.72).times(CO2_EMISSION_FACTORS.naturalGas) // for kg
    } else {
      return new Decimal(value * 2.851).times(CO2_EMISSION_FACTORS.naturalGas) // for therms
    }
  }

  const calculateLpgFootprint = (lpgu, value) => {
    if (lpgu === lpgUnits[1]) {
      return new Decimal(value / 6.9).times(CO2_EMISSION_FACTORS.lpg) //for kWh
    } else if (lpgu === lpgUnits[0]) {
      return new Decimal(value).times(CO2_EMISSION_FACTORS.lpg) //for liters
    } else if (lpgu === lpgUnits[2]) {
      return new Decimal(value / 0.51).times(CO2_EMISSION_FACTORS.lpg) // for kg
    } else if (lpgu === lpgUnits[3]) {
      return new Decimal(value * 4.197).times(CO2_EMISSION_FACTORS.lpg) // for therms
    } else {
      return new Decimal(value * 994402.55).times(CO2_EMISSION_FACTORS.lpg) // for US gallons
    }
  }

  const calculateHeatingOilFootprint = (hou, value) => {
    if (hou === heatingOilUnits[1]) {
      return new Decimal(value / 10).times(CO2_EMISSION_FACTORS.heatingOil) //for KWh
    } else if (hou === heatingOilUnits[0]) {
      return new Decimal(value).times(CO2_EMISSION_FACTORS.heatingOil) //for liters
    } else if (hou === heatingOilUnits[2]) {
      return new Decimal(value / 0.85).times(CO2_EMISSION_FACTORS.heatingOil) //for kg
    } else if (hou === heatingOilUnits[3]) {
      return new Decimal((value * 1000) / 0.85).times(CO2_EMISSION_FACTORS.heatingOil) //for metric ton
    } else {
      return new Decimal(value * 3.7854).times(CO2_EMISSION_FACTORS.heatingOil) // for US gallons
    }
  }

  const calculateCoalFootprint = (cu, value) => {
    if (cu === coalUnits[1]) {
      return new Decimal(value / 8.141).times(CO2_EMISSION_FACTORS.coal) //for kWh
    } else if (cu === coalUnits[0]) {
      return new Decimal(value).times(CO2_EMISSION_FACTORS.coal) //for kg
    } else if (cu === coalUnits[2]) {
      return new Decimal(value * 1000).times(CO2_EMISSION_FACTORS.coal) //for metric tons
    } else if (cu === coalUnits[3]) {
      return new Decimal(value * 10).times(CO2_EMISSION_FACTORS.coal) //for x 10kg bags
    } else if (cu === coalUnits[4]) {
      return new Decimal(value * 20).times(CO2_EMISSION_FACTORS.coal) //for x 20kg bags
    } else if (cu === coalUnits[5]) {
      return new Decimal(value * 25).times(CO2_EMISSION_FACTORS.coal) //for x 25kg bags
    } else {
      return new Decimal(value * 50).times(CO2_EMISSION_FACTORS.coal) //for x 50kg bags
    }
  }

  const calculatePropaneFootprint = (pu, value) => {
    if (pu === propaneUnits[0]) {
      return new Decimal(propane).times(CO2_EMISSION_FACTORS.propane) // for liters
    } else {
      return new Decimal(value * 3.78541).times(CO2_EMISSION_FACTORS.propane) // for US gallons
    }
  }

  const handleCalculation = e => {
    e.preventDefault()
    const electricityCarbon = new Decimal(electricity).times(factor)
    setElectricityCalculation(electricityCarbon)
    const gasCarbon = calculateGasFootprint(gasUnit, gas).valueOf()
    setGasCalculation(gasCarbon)
    const lpgCarbon = calculateLpgFootprint(lpgUnit, lpg).valueOf()
    setLpgCalculation(lpgCarbon)
    const heatingOilCarbon = calculateHeatingOilFootprint(heatingOilUnit, heatingOil).valueOf()
    setHeatingOilCalculation(heatingOilCarbon)
    const coalCarbon = calculateCoalFootprint(coalUnit, coal).valueOf()
    setCoalCalculation(coalCarbon)
    const propaneCarbon = calculatePropaneFootprint(propaneUnit, propane).valueOf()
    setPropaneCalculation(propaneCarbon)
    const woodCarbon = new Decimal((wood * 1000) / 700).times(CO2_EMISSION_FACTORS.wood).valueOf()
    setWoodCalculation(woodCarbon)
    setPeopleCalculation(people)
  }

  const offsetHouseFootprint = () => {
    dispatch(setHouseFootprint(0))
    setFormData({
      people: 1,
      electricity: 0,
      gas: 0,
      heatingOil: 0,
      coal: 0,
      lpg: 0,
      propane: 0,
      wood: 0,
      factor: 0.4521,
    })
    window.open('https://carbondebits.io/shop/', '_blank')
  }

  const handleCancelElement = element => {
    let result = new Decimal(houseFootprint).times(1000).times(people).valueOf()
    if (element === 'electricity') {
      result = new Decimal(result).minus(electricityCalculation).valueOf()
      setFormData({ ...formData, electricity: 0 })
      setElectricityCalculation(0)
    } else if (element === 'gas') {
      result = new Decimal(result).minus(gasCalculation).valueOf()
      setFormData({ ...formData, gas: 0 })
      setGasCalculation(0)
    } else if (element === 'heatingOil') {
      result = new Decimal(result).minus(heatingOilCalculation).valueOf()
      setFormData({ ...formData, heatingOil: 0 })
      setHeatingOilCalculation(0)
    } else if (element === 'coal') {
      result = new Decimal(result).minus(coalCalculation).valueOf()
      setFormData({ ...formData, coal: 0 })
      setCoalCalculation(0)
    } else if (element === 'lpg') {
      result = new Decimal(result).minus(lpgCalculation).valueOf()
      setFormData({ ...formData, lpg: 0 })
      setLpgCalculation(0)
    } else if (element === 'propane') {
      result = new Decimal(result).minus(propaneCalculation).valueOf()
      setFormData({ ...formData, propane: 0 })
      setPropaneCalculation(0)
    } else if (element === 'wood') {
      result = new Decimal(result).minus(woodCalculation).valueOf()
      setFormData({ ...formData, wood: 0 })
      setWoodCalculation(0)
    }
    const result2 = new Decimal(result).dividedBy(peopleCalculation).valueOf()
    const finalResult = new Decimal(result2).dividedBy(1000).valueOf()
    dispatch(setHouseFootprint(parseFloat(finalResult)))
  }

  useEffect(() => {
    if (!isFirstRendering) {
      const newSum = Decimal.sum(
        electricityCalculation,
        gasCalculation,
        lpgCalculation,
        heatingOilCalculation,
        coalCalculation,
        propaneCalculation,
        woodCalculation,
      ).valueOf()
      const finalSum = new Decimal(newSum).dividedBy(people).valueOf()
      const carbon = new Decimal(finalSum).dividedBy(1000).valueOf()
      setSum(carbon)
    } else {
      setIsFirstRendering(false)
    }
  }, [
    electricityCalculation,
    gasCalculation,
    lpgCalculation,
    heatingOilCalculation,
    coalCalculation,
    propaneCalculation,
    woodCalculation,
    peopleCalculation,
  ])

  useEffect(() => {
    if (!isFirstRendering) {
      dispatch(setHouseFootprint(parseFloat(sum)))
    } else {
      setIsFirstRendering(false)
    }
  }, [sum])

  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [houseFootprint])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      <div className="flex flex-col xl:w-[800px] 2xl:max-3xl:w-[650px] md:h-[724px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
          <div className="font-semibold text-xl xxxs:max-md:text-lg">How much do you use in your home?</div>
          <p className="text-base font-normal text-white text-opacity-80 tracking-wider">
            Your individual footprint is calculated by dividing the amount of energy by the
            number of people in your house. Enter your consumption of each type of energy, and press the Calculate button.
            To calculate your full household footprint, select "1".{' '}
          </p>
        </div>

        <div className="flex flex-col justify-between h-full py-4 tracking-wider">
          <form className="flex flex-col space-y-3" onSubmit={handleCalculation}>
            <div className="flex flex-col space-y-2 text-base font-normal text-white text-opacity-80 border-b border-copyToClipboard pb-12 md:h-[477px]">
              <div className="flex flex-col space-y-1 font-medium xxxs:max-xs:w-[190px]">
                <label for="people">How many people are in your household?</label>
                <input
                  type="number"
                  name="people"
                  id="people"
                  min="1"
                  className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px] h-10 focus:outline-none focus:border-carbifyOrange p-2.5"
                  placeholder={`${people}`}
                  required="true"
                  value={people}
                  onChange={onChange}
                />
              </div>
              <div className="xxxs:max-md:flex-col flex md:space-x-8">
                <CarbonUnitInput
                  label={'Electricity'}
                  name={'electricity'}
                  value={electricity}
                  dropDownOptions={electricityUnits}
                  onChange={onChange}
                  onUnitChange={setElectricityUnit}
                />
                <div className="md:w-2/4 mt-7 md:max-3xl:mt-6">
                  <p className="flex md:justify-start items-center space-x-2 xxxs:max-sm:text-sm xxxs:max-xxs:space-x-1 xl:max-3xl:space-x-1">
                    <span>at a factor</span>
                    <input
                      type="text"
                      name="factor"
                      id="factor"
                      className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-28 xxxs:max-sm:w-[72px] h-10 focus:outline-none focus:border-carbifyOrange p-2.5"
                      placeholder={`${factor}`}
                      required="true"
                      value={factor}
                      onChange={onChange}
                      disabled
                    />
                    <p className="relative flex xxxs:max-xxs:flex-col xxs:space-x-2 xxxs:max-xxs:text-sm">
                      <span>kgCO2e/kWh </span>
                      <div onMouseEnter={handleTooltip} onMouseLeave={handleCloseTooltip}>
                        <IoIosInformationCircleOutline className="w-5 h-6" />
                        {showTooltip ? (
                          <div className="absolute z-50 object-cover right-0 -top-48 isolate rounded bg-copyToClipboard p-2 text-base text-bt hover:scale-300 md:w-96 xxxs:max-md:w-60 xxxs:max-md:text-sm xxs:max-md:-top-56">
                            The carbon footprint of electricity is calculated using a factor that represents the average
                            emissions associated with its generation. Think of the factor as a multiplier that helps
                            estimate the amount of carbon dioxide (CO2) released into the atmosphere for each unit of
                            electricity consumed.
                          </div>
                        ) : null}
                      </div>
                    </p>
                  </p>
                </div>
              </div>
              <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                <CarbonUnitInput
                  label={'Gas'}
                  name={'gas'}
                  value={gas}
                  dropDownOptions={gasUnits}
                  onChange={onChange}
                  onUnitChange={setGasUnit}
                />
                <CarbonUnitInput
                  label={'Heating Oil'}
                  name={'heatingOil'}
                  value={heatingOil}
                  dropDownOptions={heatingOilUnits}
                  onChange={onChange}
                  onUnitChange={setHeatingOilUnit}
                />
              </div>
              <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                <CarbonUnitInput
                  label={'Coal'}
                  name={'coal'}
                  value={coal}
                  dropDownOptions={coalUnits}
                  onChange={onChange}
                  onUnitChange={setCoalUnit}
                />
                <CarbonUnitInput
                  label={'LPG'}
                  name={'lpg'}
                  value={lpg}
                  dropDownOptions={lpgUnits}
                  onChange={onChange}
                  onUnitChange={setLpgUnit}
                />
              </div>
              <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                <CarbonUnitInput
                  label={'Propane'}
                  name={'propane'}
                  value={propane}
                  dropDownOptions={propaneUnits}
                  onChange={onChange}
                  onUnitChange={setPropaneUnit}
                  inputUnit={propaneUnit}
                />
                <CarbonUnitInput
                  label={'Wooden pellets'}
                  name={'wood'}
                  value={wood}
                  onChange={onChange}
                  onUnitChange={setWoodUnit}
                  inputUnit={woodUnit}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="bg-carbifyOrange text-bt font-bold text-base text-center w-full h-12 rounded-lg hover:bg-[#024728] hover:text-white"
              >
                Calculate & Add To Footprint
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <TotalFootprint
            title={'Household'}
            individualFootprint={houseFootprint}
            totalFootprint={carbonFootprint}
            offsetFootprint={offsetHouseFootprint}
          />
          <div className="flex flex-col p-3">
            {electricity > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  {' '}
                  <b>
                    {electricity} {electricityUnit}
                  </b>{' '}
                  of electricity at {CO2_EMISSION_FACTORS.electricity} kgCO2e/kWh
                </p>
                <button
                  onClick={() => handleCancelElement('electricity')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {gas > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {gas} {gasUnit}{' '}
                  </b>
                  of natural gas
                </p>
                <button
                  onClick={() => handleCancelElement('gas')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {heatingOil > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {heatingOil} {heatingOilUnit}
                  </b>{' '}
                  of Heating Oil
                </p>
                <button
                  onClick={() => handleCancelElement('heatingOil')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {coal > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {coal} {coalUnit}
                  </b>{' '}
                  of coal
                </p>
                <button
                  onClick={() => handleCancelElement('coal')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {lpg > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {lpg} {lpgUnit}
                  </b>{' '}
                  of lpg
                </p>
                <button
                  onClick={() => handleCancelElement('lpg')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {propane > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {propane} {propaneUnit}
                  </b>{' '}
                  of propane
                </p>
                <button
                  onClick={() => handleCancelElement('propane')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {wood > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {wood} {woodUnit}
                  </b>{' '}
                  of wooden pellets
                </p>
                <button
                  onClick={() => handleCancelElement('wood')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between xxxs:max-md:mt-3 mt-4">
          <div className="flex items-center justify-center pr-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-cardGreen hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-cardGreen hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default House
