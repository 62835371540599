import React from 'react'

const Tooltip = props => {
  return (
    <div className="absolute z-10 object-cover left-0 top-10 w-80 isolate rounded bg-copyToClipboard p-2 text-base text-bt hover:scale-300">
      {props.text}
    </div>
  )
}

export const TooltipProper = ({ text }) => {
  return (
    <div className="absolute z-10 w-80 rounded bg-copyToClipboard p-2 text-base text-bt"
         style={{ top: '100%', right: '0', transform: 'translateX(0)', marginTop: '0.5rem' }}>
      {text}
    </div>
  );
};

export default Tooltip
