import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarbonDropdownInput from 'components/utils/CarbonDropdownInput'
import TotalFootprint from './TotalFootprint'

//reducer
import { getFlightEmission, resetFlightErrors, resetFlightEmission } from 'features/footprint/flightSlice'
import { setCarbonFootprint, setAirFootprint } from 'features/footprint/footprintSlice'

//icons
// import { IoIosInformationCircleOutline } from "react-icons/io"
// import CheckCircle from 'icons/CheckCircle'
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'
import { toast } from 'react-toastify'

const AirTravel = ({ onBack, onNext, setPage }) => {
  const travelClassTypes = ['economy', 'business']
  const [formData, setFormData] = useState({
    tripType: 'oneWay',
    from: '',
    to: '',
    via: '',
    travelClass: travelClassTypes[0],
    passenger: 1,
  })

  const { tripType, from, to, via, travelClass, passenger } = formData
  const { carbonFootprint, airFootprint } = useSelector(state => state.footprint)
  const { flightEmission, errors } = useSelector(state => state.flight)

  const dispatch = useDispatch()
  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleNavigation = type => {
    setPage(false)
    if (type === 'Next') {
      onNext(true)
    } else {
      onBack(true)
    }
  }

  const calculateRoute = (tripT, departure, destination, stop, classT) => {
    let legs = []
    if (tripT === 'oneWay') {
      if (stop.length > 0) {
        return (legs = [
          { departure_airport: departure, destination_airport: stop, cabin_class: classT },
          { departure_airport: stop, destination_airport: destination, cabin_class: classT },
        ])
      } else {
        return (legs = [{ departure_airport: departure, destination_airport: destination, cabin_class: classT }])
      }
    } else {
      if (stop.length > 0) {
        return (legs = [
          { departure_airport: departure, destination_airport: stop, cabin_class: classT },
          { departure_airport: stop, destination_airport: destination, cabin_class: classT },
          { departure_airport: destination, destination_airport: stop, cabin_class: classT },
          { departure_airport: stop, destination_airport: departure, cabin_class: classT },
        ])
      } else {
        return (legs = [
          { departure_airport: departure, destination_airport: destination, cabin_class: classT },
          { departure_airport: destination, destination_airport: departure, cabin_class: classT },
        ])
      }
    }
  }
  const handleCalculation = e => {
    e.preventDefault()
    const type = 'flight'
    const passengers = passenger
    const tClass = travelClass === travelClassTypes[1] ? 'premium' : travelClass
    const legs = calculateRoute(tripType, from, to, via, tClass)
    dispatch(getFlightEmission({ type, passengers, legs }))
  }

  const offsetAirFootprint = offsetFlag => {
    dispatch(setAirFootprint(0))
    dispatch(resetFlightEmission())
    setFormData({
      tripType: 'oneWay',
      from: '',
      to: '',
      via: '',
      travelClass: travelClassTypes[0],
      passenger: 1,
    })
    if (offsetFlag) {
      window.open('https://carbondebits.io/shop/', '_blank')
    }
  }

  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [airFootprint])

  useEffect(() => {
    if (flightEmission) {
      dispatch(setAirFootprint(flightEmission.data.attributes.carbon_mt))
    }
  }, [flightEmission])

  useEffect(() => {
    if (errors) {
      toast.error(errors.message)
    }
    return () => {
      // Cleanup function
      dispatch(resetFlightErrors())
    }
  }, [errors])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      <div className="flex flex-col xl:w-[800px] 2xl:max-3xl:w-[650px] md:h-[724px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
          <div className="font-semibold text-xl">How do you get around?</div>
          <p className="text-base font-normal text-white text-opacity-80 tracking-wider">
            You can enter details for up to 3 flight itineraries.
          </p>
        </div>
  
        <div className="flex flex-col justify-between h-full py-4 tracking-wider">
          <form className="flex flex-col space-y-3" onSubmit={handleCalculation}>
            <div className="flex flex-col space-y-3 text-base font-normal text-white text-opacity-80 border-b border-copyToClipboard pb-12 md:h-[525px]">
              <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <div className="relative space-y-1 3xl:w-[360px] font-medium text-base xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]">
                    <label htmlFor="from">From</label>
                    <input
                      type="text"
                      name="from"
                      id="from"
                      className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] focus:outline-none focus:border-carbifyOrange p-2 xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]"
                      placeholder="Enter IATA code"
                      required
                      value={from}
                      onChange={onChange}
                    />
                  </div>
                  <div className="relative space-y-1 3xl:w-[360px] font-medium text-base xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]">
                    <label htmlFor="to">To</label>
                    <input
                      type="text"
                      name="to"
                      id="to"
                      className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] h-10 focus:outline-none focus:border-carbifyOrange p-2 xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]"
                      placeholder="Enter IATA code"
                      required
                      value={to}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <div className="relative space-y-1 3xl:w-[360px] font-medium text-base xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]">
                    <label htmlFor="via">Via (optional)</label>
                    <input
                      type="text"
                      name="via"
                      id="via"
                      className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] h-10 focus:outline-none focus:border-carbifyOrange p-2 xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]"
                      placeholder="Enter IATA code"
                      value={via}
                      onChange={onChange}
                    />
                  </div>
                  <CarbonDropdownInput
                    label="Class"
                    name="travelClass"
                    value={travelClass}
                    dropDownOptions={travelClassTypes}
                    travelClass
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-1 font-medium">
                <label htmlFor="passenger">Passengers</label>
                <input
                  type="number"
                  name="passenger"
                  id="passenger"
                  min="1"
                  className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] h-10 focus:outline-none focus:border-carbifyOrange p-2.5 xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px]"
                  placeholder={`${passenger}`}
                  required
                  value={passenger}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="mt-auto">
              <button
                type="submit"
                className="bg-carbifyOrange text-bt font-bold text-base text-center w-full h-12 rounded-lg hover:bg-[#024728] hover:text-white"
              >
                Calculate & Add To Footprint
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <TotalFootprint
            title="Air Travel"
            individualFootprint={airFootprint}
            totalFootprint={carbonFootprint}
            offsetFootprint={offsetAirFootprint}
          />
          <div className="flex flex-col p-3">
            {from ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  Air travel from <b>{from}</b> to <b>{to}</b> in {travelClass} via {via}
                </p>
                <button
                  onClick={() => offsetAirFootprint(false)}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
  
        <div className="flex justify-between xxxs:max-md:mt-3 mt-4">
          <div className="flex items-center justify-center pr-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default AirTravel
