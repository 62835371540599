import React, { useEffect, useState } from 'react'
import moment, { unix } from 'moment'
import DatePicker from 'react-datepicker'

// Icons
import { BsCalendar } from 'react-icons/bs'

function UnixDatePicker({ label, setDate, currDate }) {
  const [selectedDate, setSelectedDate] = useState(currDate)
  const [minDate, setMinDate] = useState(moment().toDate())
  
  const handleDateChange = date => {
    setSelectedDate(date)
    setDate(date)
  }

  return (
    <div className="flex-1 space-y-1">
      <label className="block text-xl font-bold">{label}</label>
      <div className="relative flex-1 mb-2">
        <DatePicker
          minDate={minDate}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          className="tracking-wider font-medium bg-white border border-white border-opacity-50 bg-opacity-10 text-white text-opacity-50 rounded-lg focus:outline-0 focus:ring-blue-500 focus:border-carbifyOrange block w-full p-2.5"
        />
        <BsCalendar className="absolute right-3 bottom-2.5 text-white text-opacity-50 w-5 h-5" />
      </div>
    </div>
  )
}

export default UnixDatePicker
