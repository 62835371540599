import Web3 from 'web3';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import Multicall from '@dopex-io/web3-multicall';
import { Config } from '../../config.js';

const STANDARD_ADDRESS = `${Config().contract_addresses.standard_address}`.toLowerCase();
const RARE_ADDRESS = `${Config().contract_addresses.rare_address}`.toLowerCase();
const GENESIS_ADDRESS = `${Config().contract_addresses.genesis_address}`.toLowerCase();
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();
const MAX_BATCH_SIZE = Config().max_calls_per_multicall_batch || 20;  // Define max batch size or set a sensible default

export const getLockedCBYPerPlotType = async (erc721Data) => {
    try {
        let standardBalance = 0;
        let rareBalance = 0;
        let genesisBalance = 0;
        let totalBalance = 0;

        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({
            multicallAddress: Config().contract_addresses.multi_call,
            provider
        });
        const stakingContract = new web3.eth.Contract(STAKING_ABI, STAKING_ADDRESS);

        const fetchStakeInfoInBatches = async (tokens) => {
            let results = [];
            for (let i = 0; i < tokens.length; i += MAX_BATCH_SIZE) {
                const batchTokens = tokens.slice(i, Math.min(i + MAX_BATCH_SIZE, tokens.length));
                const batchResult = await multicall.aggregate(
                    batchTokens.map(token => stakingContract.methods.getStakeInfo(token.token_id, token.contract_address.toLowerCase()))
                );
                results = results.concat(batchResult);
            }
            return results;
        };

        if (erc721Data) {
            const lockedTokens = erc721Data.filter(token => token.is_locked);
            const result = await fetchStakeInfoInBatches(lockedTokens);

            for (const treeStakeData of result) {
                const formattedCBYAmount = Number(treeStakeData[5]) / 10 ** 18;
                if (treeStakeData[10].toLowerCase() === STANDARD_ADDRESS) {
                    standardBalance += formattedCBYAmount;
                    totalBalance += formattedCBYAmount;
                } else if (treeStakeData[10].toLowerCase() === RARE_ADDRESS) {
                    rareBalance += formattedCBYAmount;
                    totalBalance += formattedCBYAmount;
                } else if (treeStakeData[10].toLowerCase() === GENESIS_ADDRESS) {
                    genesisBalance += formattedCBYAmount;
                    totalBalance += formattedCBYAmount;
                }
            }
        }

        return { 
            standardLockedCBY: standardBalance, 
            rareLockedCBY: rareBalance, 
            genesisLockedCBY: genesisBalance,
            totalLockedCBY: totalBalance
        };
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message, standardLockedCBY: 0, rareLockedCBY: 0, genesisLockedCBY: 0, totalLockedCBY: 0 };
    }
}
