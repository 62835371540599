import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'
import Card from './Card'

// API
import { getERC721Data } from 'features/dashboard/getERC721Data'

function DashboardCards({ cbyPrice, totalTrees }) {
  const { address } = useAccount()
  const { CBYBalance } = useSelector(state => state.wallet)

  const [totalStakedNftrees, setTotalStakedNftrees] = useState(null)
  const [stakedCBYs, setStakedCBYs] = useState(null)
  const [totalCBYs, setTotalCBYs] = useState(null)

  useEffect(() => {
    getERC721Data(address, undefined).then(data => {
      const formatData = data.token_data
      let count = 0
      let lockedCby = 0
      for (let i = 0; i < formatData?.length; i++) {
        if (formatData[i].is_locked) {
          const cbyAmountFormatted = Number(formatData[i].locked_cby) / 10 ** 18
          lockedCby += cbyAmountFormatted
        }
        if (formatData[i].is_staked) {
          count++
        }
      }
      setTotalStakedNftrees(count)
      setStakedCBYs(lockedCby)
    })
  }, [address])

  useEffect(() => {
    if (CBYBalance?.balance && setStakedCBYs) {
      setTotalCBYs(CBYBalance?.balance + stakedCBYs)
    } else if (CBYBalance?.balance) {
      setTotalCBYs(CBYBalance?.balance)
    } else if (stakedCBYs) {
      setTotalCBYs(stakedCBYs)
    } else {
      setTotalCBYs(0)
    }
  }, [CBYBalance?.balance, stakedCBYs])

  const perCBYInDollars = cbyPrice
  const totalCBYsInDollars = (totalCBYs * perCBYInDollars).toFixed(2)
  const stakedCBYsInDollars = (stakedCBYs * perCBYInDollars).toFixed(2)
  const availableCBYs = CBYBalance?.balance?.toFixed(2)
  const availableCBYsInDollars = (availableCBYs * perCBYInDollars).toFixed(2)

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-3 mb-6">
      <Card
        cardHeading={'TOTAL OWNED'}
        loading={totalStakedNftrees === null || stakedCBYs === null || totalTrees === null || totalCBYs === null}
        amount={`${totalCBYs === 0 ? '0' : totalCBYs?.toFixed(2)} $${CBYBalance?.symbol}`}
        CBYsInDollars={`$${parseFloat(totalCBYsInDollars).toString()}`}
        tooltipInfo={
          'The amount of $CBY tokens you own is listed here. We try to show a rough value based on exchange or decentralised exchange prices'
        }
      />
      <Card
        cardHeading={'LOCKED IN STAKING'}
        loading={totalStakedNftrees === null || stakedCBYs === null || totalTrees === null || totalCBYs === null}
        amount={`${stakedCBYs === null ? '0' : stakedCBYs.toFixed(2)} $${CBYBalance?.symbol}`}
        CBYsInDollars={`$${parseFloat(stakedCBYsInDollars).toString()}`}
        tooltipInfo={
          'The amount of $CBY tokens locked in the staking program. You cannot use these tokens as they are locked. You can withdraw the tokens any time you want (but staking rewards will be halted)'
        }
      />
      <Card
        cardHeading={'AVAILABLE'}
        loading={totalStakedNftrees === null || stakedCBYs === null || totalTrees === null || totalCBYs === null}
        amount={`${availableCBYs} $${CBYBalance?.symbol}`}
        CBYsInDollars={`$${parseFloat(availableCBYsInDollars).toString()}`}
        tooltipInfo={
          'Available $CBY tokens not used for staking or locked for other purposes. If this balance is low or zero, you need to acquire tokens to participate in the staking program'
        }
      />
      <Card
        cardHeading={'STAKED - OWNED NFTrees'}
        loading={totalStakedNftrees === null || stakedCBYs === null || totalTrees === null || totalCBYs === null}
        amount={`${totalStakedNftrees === null ? '0' : totalStakedNftrees} / ${totalTrees === null ? '0' : totalTrees}`}
        tooltipInfo={
          'The number of trees you own is listed here, as well as the number of trees locked in the staking program. For example, it may show 12/50. This means 12 trees are locked in the staking program while you own 50 trees in total'
        }
      />
    </div>
  )
}

export default DashboardCards
