import React from 'react'

const GraphContainer = ({ children }) => {
  return (
    <div className="flex flex-col relative sm:mb-3 xl:mb-0 items-start h-graphCardHeight border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 text-white font-montserrat">
      {children}
    </div>
  )
}

export default GraphContainer
