import React, { useState } from 'react';
import Information from 'icons/Information';  // Make sure you have this icon or import it correctly
import { TooltipProper } from 'components/utils/Tooltip';  // Assuming you have a Tooltip component

const StatCard = ({ title, value, unit, tooltip, loading }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex flex-col pb-6 justify-between w-full border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
      <div>
        <div className="pl-6 pt-3 flex justify-between items-center pr-4">
          <p className="text-white font-medium text-lg font-montserrat">{title}</p>
          <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} className='relative cursor-pointer'>
            <Information className="text-white" />
            {showTooltip && <TooltipProper text={tooltip} />}
          </div>
        </div>
        {loading ? (
          <div className="pl-6">
            <div className="w-20 animate-spin z-51">
              <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
            </div>
          </div>
        ) : (
          <div>
            <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">{value} {unit}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export const StatCardOverview = ({ title, value, unit, tooltip, loading }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex flex-col pb-6 justify-between w-full border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
      <div>
        <div className="pl-6 pt-3 flex justify-between items-center pr-4">
          <p className="text-white font-medium text-lg font-montserrat">{title}</p>
          <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} className='relative cursor-pointer'>
            <Information className="text-white" />
            {showTooltip && <TooltipProper text={tooltip} />}
          </div>
        </div>
        {loading ? (
          <div className="pl-6">
            <div className="w-20 animate-spin z-51">
              <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
            </div>
          </div>
        ) : (
          <div>
            <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">{value} {unit}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatCard;
