import React from 'react'

function NftMediaCard({ imageURL }) {
  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="w-full flex items-center justify-center py-6">
        <img src={imageURL} alt="NFT Thumbnail" className="h-[450px] w-[300px] rounded-xl" />
      </div>
    </div>
  )
}

export default NftMediaCard
