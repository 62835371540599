import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRCode from 'qrcode'
import 'react-toastify/dist/ReactToastify.css'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import FlashMessage from 'components/utils/FlashMessage'
import { countries } from 'json/countries'
import SelectCountry from 'components/utils/SelectCountry'
import PasswordField from 'components/utils/PasswordField'
import { resetProfileErrors, resetIsUpdated } from 'features/user/userProfileSlice'

// Icons
import { BsPencilSquare, BsTrash } from 'react-icons/bs'
import { CiFloppyDisk } from 'react-icons/ci'

import CheckCircle from 'icons/CheckCircle'

// Reducers
import { updateUserProfile, deleteUserProfile } from 'features/user/userProfileSlice'
import { totpCreate, totpActivate, totpDelete } from 'features/authentication/otpSlice'
import Spinner from 'components/utils/Spinner'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function ProfilePage() {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo, is2faAuthenticated, loading } = useSelector(state => state.authentication)
  const is2faEnabled = useSelector(state => state.authentication.userInfo?.isTwoFactorEnabled)
  const { errors, isUpdated } = useSelector(state => state.userProfile)
  const { qrcodeUrl } = useSelector(state => state.otp)

  const [generalInfoData, setGeneralInfoData] = useState({
    username: userInfo ? userInfo.username : '',
    linked_wallet: userInfo ? userInfo.linked_wallet : '',
    fullName: userInfo ? userInfo.fullName : '',
    email: userInfo ? userInfo.email : '',
    country: userInfo ? userInfo.country : '',
    twoFactorAuth: userInfo ? userInfo.twoFactorAuth : false,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    confirmDeletePassword: '',
  })

  const [otpToken, setOtpToken] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [qrcode, setqrCode] = useState('')
  const [isChecked, setIsChecked] = useState(is2faEnabled && is2faAuthenticated)

  const { username, fullName, email, country, currentPassword, newPassword, confirmPassword, confirmDeletePassword, twoFactorAuth } =
    generalInfoData

  const onChange = e => {
    setGeneralInfoData({ ...generalInfoData, [e.target.name]: e.target.value })
    setErrorMessage('')
  }

  const handleSelectCountry = e => {
    setGeneralInfoData({ ...generalInfoData, country: e.target.value })
  }

  const handleTwoFactorAuthToggle = () => {
    setGeneralInfoData(prevData => ({
      ...prevData,
      twoFactorAuth: !prevData.twoFactorAuth
    }));
  }

  const handleUpdateProfileInformation = e => {
    e.preventDefault()

    if (newPassword && newPassword !== confirmPassword) {
      toast.error('The passwords confirmation does not match!')
    } else if (newPassword && newPassword.length < 8) {
      toast.error('The password must be at least 8 characters!')
    } else {
      dispatch(
        updateUserProfile({
          fullName: fullName,
          ...(currentPassword && { currentPassword: currentPassword }),
          ...(newPassword && { newPassword: newPassword }),
          ...(confirmPassword && { confirmPassword: confirmPassword }),
          username,
          email: email.toLowerCase(),
          country,
          twoFactorAuth: twoFactorAuth,
        }),
      )

      toast.success('Profile information updated successfully, now redirecting to the dashboard!')

      if (currentPassword) {
        setGeneralInfoData({
          ...generalInfoData,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        })
      }

      setTimeout(() => {
        navigate('/dashboard')
      }, 2500)
    }
  }

  const handleUpdateProfilePicture = e => {
    const file = e.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append('profile_picture', file)

    dispatch(updateUserProfile(formData))

    toast.success('Profile picture updated successfully!')
  }

  const handleDeleteAccount = e => {
    dispatch(deleteUserProfile(confirmDeletePassword))
    setShowPopup(false)
    if (confirmDeletePassword) {
      setGeneralInfoData({
        ...generalInfoData,
        confirmDeletePassword: '',
      })
    }
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    if (confirmDeletePassword) {
      setGeneralInfoData({
        ...generalInfoData,
        confirmDeletePassword: '',
      })
    }
  }

  const handleClose2fa = () => {
    setIsChecked(!isChecked)
  }

  const checkHandler = () => {
    // setIsChecked(!isChecked)
  }

  const handleOtpVerify = e => {
    e.preventDefault()
    dispatch(totpActivate({ token: otpToken }))
  }

  const handleDisable2fa = () => {
    console.log('handleDisable2fa');
    dispatch(totpDelete())
  }
  const verifyKYC = () => {
    console.log('verifyKYC');
  }
  const connectDiscord = () => {
    console.log('connectDiscord');
  }
  const connectTG = () => {
    console.log('connectTG');
  }

  useEffect(() => {
    try{
      if (isChecked) {
      dispatch(totpCreate())
      QRCode.toDataURL(qrcodeUrl).then(setqrCode)
    }
  }catch(error){
    console.error("Error:", error)
    toast.error(error)
  }
  }, [isChecked, dispatch, qrcodeUrl])

  const styles = {
    heading3: `text-xl font-semibold text-gray-900 p-4 border-b`,
    heading4: `text-base text-ct-blue-600 font-medium border-b mb-2`,
    modalOverlay: `overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`,
    orderedList: `space-y-1 text-sm list-decimal`,
    buttonGroup: `flex items-center rounded-b`,
    buttonBlue: `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`,
    buttonGrey: `text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`,
    inputField: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5`,
  }

  return (
    <Layout title="Carbify | Profile" content="Update your Profile">
      {loading && <Spinner />}
      {/* {errorMessage && <FlashMessage type={'error'}>{errorMessage}</FlashMessage>}
      {errors && <FlashMessage type={'error'} errors={errors} action={resetProfileErrors}></FlashMessage>}
      {isUpdated && (
        <FlashMessage setSuccessMessage={setSuccessMessage} action={resetIsUpdated}>
          {' '}
          {successMessage}{' '}
        </FlashMessage>
      )} */}
  
      <PageHeading>My Profile</PageHeading>
  
      <div className="flex flex-col xl:flex-row xl:space-x-8 w-full mt-8">
        <div className="w-full xl:w-1/3 flex flex-col space-y-8">
          <div className="w-full bg-darkGreen bg-opacity-70 rounded-lg p-6 flex flex-col items-start space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
              <div className="relative w-20 h-20">
                <img
                  className="w-full h-full rounded-full object-cover cursor-pointer"
                  src={userInfo && userInfo.photoUrl ? userInfo.photoUrl : '/assets/images/avatar.png'}
                  alt="User Avatar"
                  onClick={() => document.getElementById('dropzone-file').click()}
                />
                <div
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 hover:opacity-100 transition-opacity duration-300"
                  onClick={() => document.getElementById('dropzone-file').click()}
                >
                  <BsPencilSquare className="text-white text-xl cursor-pointer" />
                </div>
              </div>
              <div>
                <h3 className="text-2xl font-bold text-white">{userInfo ? userInfo.username : 'Alex Dolen'}</h3>
                <p className="text-base font-normal text-white break-all">{userInfo ? userInfo.email : 'alexdolen@gmail.com'}</p>
              </div>
            </div>
            <div className="text-white text-sm mt-4">
              <p>
                Please enter your bio text here by clicking on the edit icon below. This text should include information
                about your background, education, work experience, and any other relevant information that you would like
                to share.
              </p>
            </div>
            <input onChange={handleUpdateProfilePicture} id="dropzone-file" type="file" className="hidden" />
          </div>
  
          <div className="w-full bg-darkGreen bg-opacity-70 rounded-lg p-6 space-y-4 mb-6">
            <h4 className="text-xl font-semibold">Verify your identity</h4>
            <div className="flex items-center space-x-2">
              <p className="text-sm">KYC is needed in order to participate in the Private Sale</p>
              <button
                className="bg-white text-darkGreen font-bold py-2 px-4 rounded-lg shadow-md hover:bg-carbifyOrange hover:text-white"
                onClick={() => verifyKYC()}
              >
                Verify
              </button>
            </div>
            <h4 className="text-xl font-semibold">2FA</h4>
            <div className="flex items-center space-x-2">
              <p className="text-sm">Add an Authenticator App to activate the 2 Factor Authenticator</p>
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" onChange={handleTwoFactorAuthToggle} checked={generalInfoData.twoFactorAuth} />
                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-carbifyOrange"></div>
              </label>
            </div>
            <h4 className="text-xl font-semibold">Connect Socials</h4>
            <button
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-md w-full flex items-center justify-center"
              onClick={connectDiscord}
            >
              Connect your Discord
            </button>
            <button
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-md w-full flex items-center justify-center"
              onClick={connectTG}
            >
              Connect your Telegram
            </button>
            <button
              className="bg-red text-white font-bold py-2 px-4 rounded-lg shadow-md w-full flex items-center justify-center"
              onClick={() => setShowPopup(true)}
            >
              Delete your account
            </button>
          </div>
        </div>
        <div className="w-full xl:w-2/3 bg-darkGreen bg-opacity-70 rounded-lg p-6 space-y-8 mt-8 xl:mt-0">
          <h1 className="text-2xl font-semibold">General Information</h1>
          <form onSubmit={handleUpdateProfileInformation} className="space-y-6">
            <div className="space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="username" className="text-base font-medium">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg p-2.5"
                  placeholder="Username"
                  required
                  value={username}
                  onChange={onChange}
                />
              </div>
              <div className="flex flex-col md:flex-row md:space-x-3 space-y-2 md:space-y-0 ">
                <div className="flex flex-col space-y-2 w-full md:w-1/2">
                  <label htmlFor="fullName" className="text-base font-medium">
                    Full Name (optional)
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg p-2.5"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={onChange}
                  />
                </div>
                <div className="flex flex-col space-y-2 w-full md:w-1/2">
                  <label htmlFor="email" className="text-base font-medium">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg p-2.5"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="country" className="text-base font-medium">
                  Country
                </label>
                <SelectCountry handleSelectCountry={handleSelectCountry} userCountry={country} countries={countries} />
              </div>
              <h1 className="text-2xl font-semibold">Password</h1>
              <PasswordField
                label="Current Password"
                onChange={onChange}
                value={currentPassword}
                name="currentPassword"
                required={false}
              />
              <div className="flex flex-col md:flex-row md:space-x-3 space-y-2 md:space-y-0">
                <PasswordField
                  label="New Password"
                  onChange={onChange}
                  value={newPassword}
                  name="newPassword"
                  required={currentPassword ? true : false}
                />
                <PasswordField
                  label="Confirm New Password"
                  onChange={onChange}
                  value={confirmPassword}
                  name="confirmPassword"
                  required={currentPassword ? true : false}
                />
              </div>
              <div className="flex flex-col space-y-2 font-normal text-sm">
                <p className="flex items-center space-x-2">
                  <CheckCircle />
                  <span>At least 8 characters (and up to 100 characters)</span>
                </p>
                <p className="flex items-center space-x-2">
                  <CheckCircle />
                  <span>Upper & lowercase letters</span>
                </p>
                <p className="flex items-center space-x-2">
                  <CheckCircle />
                  <span>At least one number</span>
                </p>
                <p className="flex items-center space-x-2">
                  <CheckCircle />
                  <span>At least one symbol</span>
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="flex items-center justify-center w-full h-12 bg-white hover:bg-carbifyOrange text-bt font-bold rounded-lg text-base px-5 py-2.5 space-x-2"
            >
              <CiFloppyDisk className="w-5 h-5 stroke-1" />
              <span>Save changes</span>
            </button>
          </form>
        </div>
      </div>
  
      {showPopup && (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed flex justify-center items-center bg-black bg-opacity-80 z-50 w-full p-4 overflow-x-hidden overflow-y-auto xxxs:inset-0 h-modal xxxs:h-full"
        >
          <div className="relative w-full h-full max-w-md md:h-auto xxxs:max-md:items-center xxxs:max-md:justify-center xxxs:max-md:flex">
            {/* Modal content */}
            <div className="relative bg-light_green bg-opacity-70 p-6 border rounded-lg drop-shadow-2xl ease-in-out duration-500">
              <button
                onClick={handleClosePopup}
                type="button"
                className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="flex items-center justify-center pb-2 space-x-3 border-b border-white">
                <BsTrash className="w-10 h-10 fill-carbifyOrange" />
                <div className="text-xl font-semibold">Delete Account</div>
              </div>
              <div className="p-3 font-semibold text-lg text-center">Are you sure you want to Delete your account?</div>
  
              <div>
                <form className="space-y-4 font-montserrat" onSubmit={handleDeleteAccount}>
                  <PasswordField
                    label={'Enter account password'}
                    onChange={onChange}
                    value={confirmDeletePassword}
                    name={'confirmDeletePassword'}
                  />
                  <div className="flex space-x-3 justify-center items-center">
                    <button
                      onClick={handleClosePopup}
                      className="flex items-center justify-center h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base px-5 py-2.5 space-x-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteAccount}
                      type="submit"
                      className="flex items-center justify-center h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base px-5 py-2.5 space-x-2 hover:bg-red hover:text-white"
                    >
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
  
      {isChecked && !is2faEnabled && !is2faAuthenticated && (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed flex md:justify-center md:items-center bg-black bg-opacity-80 z-50 w-full p-4 overflow-x-hidden overflow-y-auto xxxs:inset-0 h-modal xxxs:h-full"
        >
          <div className="relative w-full h-full max-w-md md:h-auto">
            <div className="relative bg-light_green bg-opacity-80 p-6 border rounded-lg drop-shadow-2xl hover:-translate-y-0.5 ease-in-out duration-500 xxxs:max-xs:py-2">
              <button
                onClick={handleClose2fa}
                type="button"
                className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="flex items-center justify-center pb-2 space-x-3 border-b border-white">
                <div className="text-xl font-semibold">Two-Factor Authentication (2FA)</div>
              </div>
              <div className="p-6 space-y-4 xs:max-lg:px-6 xs:max-lg:py-2 xxxs:max-xs:px-2 xxxs:max-xs:py-1">
                <h4 className="text-base font-semibold">Configuring Google Authenticator or Authy</h4>
                <div className="list-decimal ml-5">
                  <li>Install Google Authenticator (IOS - Android) or Authy (IOS - Android).</li>
                  <li>In the authenticator app, select "+" icon.</li>
                  <li>Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.</li>
                </div>
                <div>
                  <h4 className="text-base font-medium border-b mb-4">Scan QR Code</h4>
                  <div className="flex justify-center">
                    <img
                      className="block w-64 h-64 object-contain xs:max-lg:w-52 xs:max-lg:h-52 xxxs:max-xs:w-40 xxxs:max-xs:h-40"
                      src={qrcode}
                      alt="qrcode url"
                    />
                  </div>
                </div>
                <div>
                  <h4 className="text-base font-medium border-b mb-2">Verify Code</h4>
                  <p className="text-sm">For changing the setting, please verify the authentication code:</p>
                </div>
                <form onSubmit={handleOtpVerify}>
                  <input
                    type="text"
                    name="otpToken"
                    id="otpToken"
                    className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                    placeholder="Authentication Code"
                    required
                    value={otpToken}
                    onChange={e => setOtpToken(e.target.value)}
                  />
                  <p className="mt-2 text-xs text-red-600">{/* {errors.token ? errors.token.message : null} */}</p>
  
                  <div className="flex justify-center mt-4">
                    <button
                      type="submit"
                      className="flex items-center justify-center w-full h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base px-5 py-2.5 space-x-2"
                    >
                      Verify & Activate
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
  
}

export default ProfilePage