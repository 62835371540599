import { getERC721Balance } from './getERC721Balance'
import { ALL_NFTREE_ADDRESSES } from "constants/BlockchainConstants"

export const getAllTreeBalances = async (walletAddress) => {
    let treeData = 0 
    for (const erc721Address of ALL_NFTREE_ADDRESSES) {
        if (erc721Address === undefined) continue;
        const erc721Data = await getERC721Balance(walletAddress, erc721Address)
        if (erc721Data && erc721Data.token_count) {
            treeData += erc721Data.token_count
        }
    }
    return treeData
}