import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from 'layouts/Layout'
import Spinner from 'components/utils/Spinner'
import PageHeading from 'components/PageHeading'
import NftMediaCard from 'components/MarketplaceV2/listingDetails/atoms/NftMediaCard'
import NftDetails from 'components/MarketplaceV2/listingDetails/atoms/NftDetails'
import NftDescription from 'components/MarketplaceV2/listingDetails/atoms/NftDescription'
import TradeStation from 'components/MarketplaceV2/listingDetails/atoms/TradeStation'
import OwnedNftrees from 'components/MarketplaceV2/listingDetails/atoms/OwnedNftrees'
import Offers from 'components/MarketplaceV2/listingDetails/atoms/Offers'
import Attributes from 'components/MarketplaceV2/listingDetails/atoms/Attributes'
import ItemActivity from 'components/MarketplaceV2/listingDetails/ItemActivity'
import BuyNowModal from 'components/MarketplaceV2/listingDetails/modals/BuyNowModal'
import MakeOfferModal from 'components/MarketplaceV2/listingDetails/modals/MakeOfferModal'
import UpdateListingModal from 'components/MarketplaceV2/listingDetails/modals/UpdateListingModal'

// Reducers
import { getListedNft } from 'features/marketplace/listingSlice'
import { resetListedNft } from 'features/marketplace/listingSlice'
import { getListedNftsOffers } from 'features/nfts/offersSlice'
import { resetNftOffers } from 'features/nfts/offersSlice'
import { useAccount, useReadContract } from 'wagmi'
import { NFTREE_ABI } from 'constants/BlockchainConstants'
import { toast } from 'react-toastify'
// import { getNfts } from 'features/nfts/ownedNftSlice'

function ListingDetails() {
  const [showBuyNowModal, setShowBuyNowModal] = useState(false)
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false)
  const [showUpdateListingModal, setShowUpdateListingModal] = useState(false)

  const dispatch = useDispatch()
  const { listing_id } = useParams()

  const { listedNft, listedNftLoading } = useSelector(state => state.listings)
  const { listedNftsOffers } = useSelector(state => state.listedNftsOffers)
  // const { ownedNfts, ownedNftsLoading } = useSelector(state => state.ownedNfts)

  useEffect(() => {
    dispatch(resetListedNft())
    dispatch(resetNftOffers())
    dispatch(getListedNft(listing_id))
    dispatch(getListedNftsOffers(listing_id))
    // dispatch(getNfts())
  }, [listing_id])

  const handleBuyNowModal = showModal => {
    setShowBuyNowModal(showModal)
  }

  const handleMakeOfferModal = showModal => {
    setShowMakeOfferModal(showModal)
  }

  const handleUpdateListingModal = showModal => {
    setShowUpdateListingModal(showModal)
  }

  if (!listedNft || (!listedNftsOffers && listedNftsOffers != null && listedNftsOffers == undefined)) {
    return <Spinner />
  }

  return (
    <>
      <Layout title={`${listedNft?.metadata?.name || listedNft?.metadata?.batch_name} | Carbify NFTs`} content="Carbify NFTs">
        <PageHeading>{listedNft?.metadata?.name || listedNft?.metadata?.batch_name}</PageHeading>
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-x-6 mb-6">
          <div className="grid gap-y-6 mb-6">
            <NftMediaCard imageURL={listedNft?.metadata?.image} />
            <NftDetails
              contractAddress={listedNft?.assetContract}
              tokenId={listedNft?.tokenId}
              tokenStandard={listedNft?.tokenType === 0 ? 'ERC-1155' : 'ERC-721'}
            />
            <NftDescription description={listedNft ? listedNft.metadata.description : ''} />
          </div>
          <div className="lg:col-span-2">
            <div className="grid gap-y-6">
              <TradeStation
                handleBuyNowModal={handleBuyNowModal}
                handleMakeOfferModal={handleMakeOfferModal}
                handleUpdateListingModal={handleUpdateListingModal}
              />
              {listedNft && !listedNftLoading && (
                <OwnedNftrees contractAddress={listedNft?.assetContract} />
              )}
              {listedNftsOffers && (
                <Offers listingType={listedNft?.listingType} offersList={listedNftsOffers} />
              )}
              <Attributes />
            </div>
          </div>
        </section>
        {listedNft && !listedNftLoading && (
          <BuyNowModal showModal={showBuyNowModal} setShowModal={setShowBuyNowModal} listedNft={listedNft} />
        )}
        {listedNft && !listedNftLoading && (
          <MakeOfferModal showModal={showMakeOfferModal} setShowModal={setShowMakeOfferModal} listedNft={listedNft} />
        )}
        {listedNft && !listedNftLoading && (
          <UpdateListingModal
            showModal={showUpdateListingModal}
            setShowModal={setShowUpdateListingModal}
            listedNft={listedNft}
          />
        )}
      </Layout>
    </>
  );
}

export default ListingDetails;