import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'

const initialState = {
  ownedNfts: null,
  ownedNftsLoading: false,
  error: null,
}

export const getNfts = createAsyncThunk('nfts/getNfts', async ({ pageKey, amount }, thunkAPI) => {
  let account = getAccount(config)
  try {
    const carbifyNFTAddress = process.env.REACT_APP_CARBIFYNFT_ADDRESS
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL4}/api/erc1155/wallet?wallet_address=${
        account.address
      }&erc1155_address=${carbifyNFTAddress}&pageKey=${0}&amount=${30}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    console.log('GET NFTS, ', res.data)

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const ownedNftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNfts.pending, (state, action) => {
        state.ownedNftsLoading = true
        state.error = null
      })
      .addCase(getNfts.fulfilled, (state, action) => {
        state.ownedNftsLoading = false
        state.ownedNfts = { token_data: action.payload.token_data, pageKey: action.payload.pageKey }
      })
      .addCase(getNfts.rejected, (state, action) => {
        state.ownedNftsLoading = false
        state.error = action.payload
      })
  },
})

export const {} = ownedNftSlice.actions
export default ownedNftSlice.reducer
