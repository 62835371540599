import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import ClaimAco2Card from 'components/cards/ClaimAco2Card'

// Actions'
import { getAllTreeBalances } from 'features/dashboard/getAllTreeBalances'
import { getAllTreeData } from 'features/dashboard/getAllTreeData'
import CarbonCertificateCard from 'components/cards/CarbonCertificateCard'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance.js'
import { DashboardHero } from 'components/dashboard/CertificateManagement '

import Certificates from './Certificates'
import DashboardStats from 'components/dashboard/DashboardStats'
import { getDetailsAndOverviewUser } from 'features/dashboard/getDetailsAndOverviewUser'
import TotalTreesCard from 'components/cards/TotalTreesCard'
import { getAllLandplotBalances } from 'features/dashboard/getAllLandplotBalances'
import { getStakingRewardsMultiple } from 'features/rewards/getStakingRewardsMultiple'
import { getCBYPrice } from 'features/dashboard/getCBYPrice'
import TotalPlotsCard from 'components/cards/TotalPlotsCard'
import LineChartGraph from 'components/cards/LineChartGraphCard'
import DashboardCards from 'components/dashboard/DashboardCards'
import WarningModal from 'components/popups/warningModal'

function Dashboard() {
  const { address, connector: activeConnector } = useAccount();

  const { userInfo } = useSelector(state => state.authentication);
  const { loadingCbyLivePrice } = useSelector(state => state.cbyLivePrice);

  const [totalTrees, setTotalTrees] = useState(null);
  const [totalPlots, setTotalPlots] = useState(null);
  const [balance, setBalance] = useState(null);
  const [ACO2TotalBalance, setACO2TotalBalance] = useState(null);
  const [overviewAndDetails, setOverviewAndDetails] = useState(null);
  const [totalGenesisPlots, setTotalGenesisPlots] = useState(null);
  const [totalPremiumPlots, setTotalPremiumPlots] = useState(null);
  const [totalStandardPlots, setTotalStandardPlots] = useState(null);
  const [stakingRewards, setStakingRewards] = useState(null);
  const [cbyLivePrice, setCbyLivePrice] = useState(null);

  const [openTrustWalletModal, setOpenTrustWalletModal] = useState(false);
  const [openLedgerModal, setOpenLedgerModal] = useState(false);

  // Log the wallet provider whenever a connection is made
  useEffect(() => {
    if (activeConnector) {
      console.log('activeConnector', activeConnector);
      // target trust wallet after that target ledger live
      if (activeConnector.name === 'Trust Wallet') {
        // console.log('Connected with Trust Wallet');
        setOpenTrustWalletModal(true);  
      } else if (activeConnector.name === 'Ledger') {
        // console.log('Connected with Ledger Live');
        setOpenLedgerModal(true);
      }
    }
  }, [activeConnector]);

  useEffect(() => {
    getAllTreeBalances(address).then(data => {
      setTotalTrees(data)
    })
    getAllTreeData(address).then(treeData => {
      if (treeData.length > 0) {
        getDetailsAndOverviewUser(treeData).then(overviewData => {
          if (overviewData?.details_data && overviewData?.overview_data) {
            setOverviewAndDetails(overviewData)
          }
        })
        getStakingRewardsMultiple(treeData).then(data => {
          setStakingRewards(data.stakingReward)
        })
      } else {
        const overviewData = {
          overview_data: {
            total_trees: 0,
            total_batches: 0,
            total_aco2_generated: 0,
            total_aco2_burned_in_certificates: 0
          },
          details_data: []
        }
        setOverviewAndDetails(overviewData)
        setStakingRewards(0)
      }
    })
    getACO2TotalBalance(address).then(data => {
      setACO2TotalBalance(Number(data.totalBalanceFormatted.toFixed(2)))
    })
    getAllLandplotBalances(address).then(data => {
      setTotalPlots(data.total_plot_count)
      setTotalGenesisPlots(data.detailed_plot_count.genesis)
      setTotalPremiumPlots(data.detailed_plot_count.rare)
      setTotalStandardPlots(data.detailed_plot_count.standard)
    })
    getCBYPrice().then(data => {
      setCbyLivePrice(data)
    })
  }, [address]);

  return (
    <Layout title="Carbify | Dashboard" content="Carbify Dashboard">
      <PageHeading>Hello, {userInfo && userInfo.username}</PageHeading>
      <DashboardHero />

      <div className="grid grid-cols-1 2xl:grid-cols-3 gap-3 mb-6">
        <TotalTreesCard
          totalTrees={totalTrees}
          stakingRewards={stakingRewards}
        />
        <TotalPlotsCard
          totalPlots={totalPlots}
          totalGenesisPlots={totalGenesisPlots}
          totalPremiumPlots={totalPremiumPlots}
          totalStandardPlots={totalStandardPlots}
        />
        <LineChartGraph
          cbyLivePrice={cbyLivePrice}
          loadingCbyLivePrice={loadingCbyLivePrice}
        />
      </div>
      <DashboardCards
        cbyPrice={cbyLivePrice?.cby_price ? cbyLivePrice?.cby_price : 0} 
        totalTrees={totalTrees}
      />
      <DashboardStats 
        ACO2TotalBalance={ACO2TotalBalance} 
        totalTrees={totalTrees}
        overviewAndDetails={overviewAndDetails}
      />
      <ClaimAco2Card
        balance={balance}
        setBalance={setBalance}
        setACO2TotalBalance={setACO2TotalBalance}
        totalTrees={totalTrees}
      />
      <WarningModal 
        open={openTrustWalletModal}
        title="Trust Wallet Detected"
        message="We highly recommend using a different wallet provider for the best experience. Trust Wallet is known to have issues with some features on Carbify. To avoid any issues, please consider using MetaMask or WalletConnect."
        onClose={() => setOpenTrustWalletModal(false)}
      />
      <WarningModal 
        open={openLedgerModal}
        title="Ledger Live Detected"
        message="We strongly recommend turning off the 'debug data' setting in your Ledger Live application. Leaving this feature on will cause transactions with $aCO2 to become much more complex and greatly increase the number of required interactions, leading to an unusable situation due to the overwhelming number of transactions. To disable this setting, go to 'Settings' > 'Experimental features' > 'Debug data' and turn it off."
        onClose={() => setOpenLedgerModal(false)}
      />
    </Layout>
  )
}

export default Dashboard
