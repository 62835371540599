import React, { useEffect, useState } from 'react'
import QuantityField from 'components/nfts/inputFields/QuantityField'
import UnixDatePicker from 'components/nfts/inputFields/UnixDatePicker'
import AmountField from 'components/nfts/inputFields/AmountField'
import SelectCurrency from 'components/nfts/inputFields/SelectCurrency'
import ListingTypeSelector from 'components/nfts/inputFields/ListingTypeSelector'
import PeriodSelector from 'components/nfts/inputFields/PeriodSelector'
import CreatorEarning from 'components/nfts/inputFields/CreatorEarning'
import TotalPrice from 'components/nfts/utils/TotalPrice'
import TotalProjectedEarning from 'components/nfts/utils/TotalProjectedEarning'
import ModalsWrapper from 'components/utils/ModalsWrapper'
import moment from 'moment'

// Constants
import { CBY_ADDRESS } from 'constants/BlockchainConstants'

// Icons
import { BsInfoCircle } from 'react-icons/bs'
import UpdateListing from 'components/writeOnContract/marketplace/UpdateListing'

function UpdateListingModal({ showModal, setShowModal, listedNft }) {
  const [assetContract, setAssetContract] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [listingId, setListingId] = useState('')
  const [startDate, setStartDate] = useState(moment().get(listedNft.startTime)._d)
  const [endDate, setEndDate] = useState(moment().add(1, 'days')._d)
  const [quantity, setQuantity] = useState('')
  const [currency, setCurrency] = useState(CBY_ADDRESS.toLowerCase())
  const [buyoutPricePerToken, setBuyoutPricePerToken] = useState('')
  const [reservePricePerToken, setReservePricePerToken] = useState('')
  const [listingType, setListingType] = useState(0) // 0- Direct Listing 1- Auction
  const [commission, setCommission] = useState(0)

  useEffect(() => {
    setAssetContract(listedNft.assetContract)
    console.log(listedNft)
    setTokenId(listedNft.tokenId)
    setListingId(listedNft.listingId)
  }, [listedNft, assetContract, tokenId])

  useEffect(() => {
    setListingType(listedNft.listingType)
    setQuantity(listedNft.quantity)
    setCurrency(listedNft.currency)
    setBuyoutPricePerToken(
      listedNft.buyoutPricePerToken /
        (listedNft.currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? 10 ** 18 : 10 ** 6),
    )
    setReservePricePerToken(
      listedNft.reservePricePerToken /
        (listedNft.currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? 10 ** 18 : 10 ** 6),
    )
  }, [showModal])

  const handleListingType = type => {
    setListingType(type)
  }

  return (
    <ModalsWrapper showModal={showModal} setShowModal={setShowModal} title={'Update Listing'}>
      {/* ... modal content ... */}
      <div className="overflow-y-auto max-h-[80vh]">
        {/* Nft image & title */}
        <div className="flex w-full space-x-6 pb-4 mb-3 border-b">
          <div className="w-16 h-24">
            <img className="rounded-[5px]" src={listedNft?.metadata.image} />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-xl font-bold">{listedNft?.title}</h3>
            <p className="text-base font-semibold">Carbify NFTs</p>
          </div>
        </div>
        {/* Amount & start Date */}
        <div className="flex flex-col">
          <div className="flex jusitify-center space-x-2 mb-1">
            {/* <QuantityField
              label={'Number of items'}
              max={listedNft.quantity}
              quantity={quantity}
              setQuantity={setQuantity}
            /> */}
            <UnixDatePicker label={'Date of listing'} setDate={setStartDate} currDate={startDate} />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div></div>
            <div className="flex items-center space-x-2">
              <BsInfoCircle />
              <p className="text-sm font-normal">Useful for scheduling the listing</p>
            </div>
          </div>
        </div>
        {/* Amount and currency */}
        {listedNft.listingType === 0 && (
          <div className="grid grid-cols-8 items-end gap-1 mb-3">
            <div className="col-span-6">
              <AmountField
                label={'Buy Now Price'}
                listingType={listingType}
                price={buyoutPricePerToken}
                setPrice={setBuyoutPricePerToken}
              />
            </div>
            <div className="col-span-2">
              <SelectCurrency disabled={true} currency={currency} setCurrency={setCurrency} />
            </div>
          </div>
        )}
        {/*  */}
        {/* <div className="w-full mb-4">
          <ListingTypeSelector handleListingType={handleListingType} />
        </div> */}
        {/* Auction */}
        {listingType === 1 && (
          <>
            {/*  */}
            <div className="grid grid-cols-8 items-end gap-1 mb-3">
              <div className="col-span-6">
                <AmountField label={'Buyout Price'} setPrice={setBuyoutPricePerToken} />
              </div>
              <div className="col-span-2">
                <SelectCurrency disabled={true} currency={currency} setCurrency={setCurrency} />
              </div>
            </div>
            {/*  */}
            <div className="grid grid-cols-8 items-end gap-1 mb-3">
              <div className="col-span-6">
                <AmountField label={'Minimum Bid Price'} setPrice={setReservePricePerToken} />
              </div>
              <div className="col-span-2">
                <SelectCurrency disabled={true} currency={currency} setCurrency={setCurrency} />
              </div>
            </div>
          </>
        )}
        {/*  */}
        <div className="grid grid-cols-8 items-end gap-1 mb-3">
          {/* <div className="col-span-2">
            <PeriodSelector />
          </div> */}
          <div className="col-span-6">
            <UnixDatePicker label={'Duration'} setDate={setEndDate} currDate={endDate} />
          </div>
        </div>
        {/* Line */}
        <div className="border-b mb-3"></div>
        {/* Total price and projected earnings */}
        <div>
          <TotalPrice totalPrice={buyoutPricePerToken} selectedCurrency={currency} />
          {/*  */}
          <div className="w-full flex justify-between mb-2.5">
            <p className="text-[22px] font-semibold">Commission</p>
            <CreatorEarning setCommission={setCommission} asset={assetContract} />
          </div>
          {/* Total Projected Earnings */}
          <TotalProjectedEarning totalPrice={buyoutPricePerToken} commission={commission} selectedCurrency={currency} />
        </div>
        {/*  */}
        <div>
          <UpdateListing
            listingId={listingId}
            assetContract={assetContract}
            token_id={parseInt(tokenId)}
            startTime={moment(startDate).utc().unix()}
            secondsUntilEndTime={moment(endDate).utc().unix() - moment(startDate).utc().unix()}
            quantityToList={quantity}
            currencyToAccept={currency}
            reservePricePerToken={listingType === 0 ? buyoutPricePerToken : reservePricePerToken}
            buyoutPricePerToken={buyoutPricePerToken}
            listingType={listingType}
            decimals={currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? 18 : 6}
          />
        </div>
      </div>
    </ModalsWrapper>
  )
}

export default UpdateListingModal
