import React, { useState, useEffect } from 'react'
import moment from 'moment'

function TradeStationHeaderCountdown({ startTime, endTime }) {
  const targetDate = moment.unix(endTime).utc()
  const currentDate = moment.utc()
  const startDate = moment.unix(startTime).utc()
  const [timeDifference, setTimeDifference] = useState(targetDate.diff(currentDate))

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeDifference = targetDate.diff(moment.utc())
      setTimeDifference(newTimeDifference)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [targetDate])

  const timeEnd = moment.duration(timeDifference)
  const timeStart = moment.duration(startDate.diff(currentDate))
  const countdown = duration => {
    return { days: duration.days(), hours: duration.hours(), minutes: duration.minutes(), seconds: duration.seconds() }
  }

  const isStarting = startDate.diff(currentDate) > 0
  // Check if countdown has expired
  const isExpired = timeDifference <= 0

  return (
    <div className="grid grid-rows-1 m-3">
      {!isExpired ? (
        isStarting ? (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2">
              <div className="col-span-2 sm:col-span-1">
                <p className="text-3xl font-semibold">Starts in </p>
              </div>
              <div className="col-span-1">
                <div>
                  <p className="text-3xl font-semibold">{countdown(timeStart).days}</p>
                </div>
                <div>
                  <p className="text-base font-medium">Days</p>
                </div>
              </div>
              <div className="col-span-1">
                <div>
                  <p className="text-3xl font-semibold">{countdown(timeStart).hours}</p>
                </div>
                <div>
                  <p className="text-base font-medium">Hours</p>
                </div>
              </div>
              <div className="col-span-1">
                <div>
                  <p className="text-3xl font-semibold">{countdown(timeStart).minutes}</p>
                </div>
                <div>
                  <p className="text-base font-medium">Minutes</p>
                </div>
              </div>
              <div className="col-span-1">
                <div>
                  <p className="text-3xl font-semibold">{countdown(timeStart).seconds}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-base font-medium">Seconds</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-4 sm:grid-cols-10">
              <div>
                <p className="text-3xl font-semibold">{countdown(timeEnd).days}</p>
              </div>
              <div>
                <p className="text-3xl font-semibold">{countdown(timeEnd).hours}</p>
              </div>
              <div>
                <p className="text-3xl font-semibold">{countdown(timeEnd).minutes}</p>
              </div>
              <div>
                <p className="text-3xl font-semibold">{countdown(timeEnd).seconds}</p>
              </div>
            </div>
            <div className="grid grid-cols-4 sm:grid-cols-10">
              <div className="flex items-center">
                <p className="text-base font-medium">Days</p>
              </div>
              <div className="flex items-center">
                <p className="text-base font-medium">Hours</p>
              </div>
              <div className="flex items-center">
                <p className="text-base font-medium">Minutes</p>
              </div>
              <div className="flex items-center">
                <p className="text-base font-medium">Seconds</p>
              </div>
            </div>
          </>
        )
      ) : (
        <div>
          <p className="text-3xl font-semibold">Expired!</p>
        </div>
      )}
    </div>
  )
}

export default TradeStationHeaderCountdown
