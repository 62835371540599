import React, { useEffect, useState } from 'react'
import StatCard from './StatCard';  // You need to create this component as well
import { useDispatch, useSelector } from 'react-redux'
import { fetchOverviewData } from 'features/tree-map/treeSlice';

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(Math.round(number));
};

const formatNumberDecimal = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(number.toFixed(3));
};


const DashboardStats = ({ ACO2TotalBalance, totalTrees, overviewAndDetails }) => {
  const dispatch = useDispatch();

  const [areaReforested, setAreaReforested] = useState(null);
  const [foodProvided, setFoodProvided] = useState(null);
  const [workProvided, setWorkProvided] = useState(null);
  const [communitiesImpacted, setCommunitiesImpacted] = useState(null);

  const { overviewData } = useSelector((state) => state.tree);

  useEffect(() => {

    if (overviewData === null) {
      dispatch(fetchOverviewData());
    }

  }, [overviewData, dispatch]);

  const totalAgroforestrySystems = overviewData === null ? undefined : formatNumber(overviewData?.total_trees);
  const totalBatches = overviewData === null ? undefined : formatNumber(overviewData?.total_batches);
  // const areaReforested = overviewAndDetails?.overview_data === null ? undefined : formatNumber(overviewAndDetails?.overview_data.total_trees * 12.5);
  // const foodProvided = overviewAndDetails?.overview_data === null ? undefined : formatNumber(overviewAndDetails?.overview_data.total_trees * 50);
  // const workProvided = overviewAndDetails?.overview_data === null ? undefined : formatNumberDecimal(overviewAndDetails?.overview_data.total_trees / 1200);
  // const communitiesImpacted = overviewAndDetails?.overview_data === null ? undefined : formatNumberDecimal(overviewAndDetails?.overview_data.total_trees / 2500);

  const statsData = [
    { title: 'TOTAL TREES', value: totalTrees, unit: '', tooltip: 'Total number of trees you own.', loading:totalTrees===null},
    { title: 'CO2 FOR CARBON OFFSET', value: ACO2TotalBalance?ACO2TotalBalance:0, unit: 'KG', tooltip: 'Available CO2 for carbon offsets based on your current balance. Please visit the certificates page to create your certificates.', loading:ACO2TotalBalance===null },
    { title: 'FOOD PROVIDED', value: foodProvided, unit: 'KG', tooltip: 'This value represents the amount of food Carbify has been able to provide to local farmers and communities based on the trees you own.', loading:foodProvided===null },
    { title: 'WORK PROVIDED', value: workProvided, unit: 'FTE', tooltip: 'This value represents the number of full-time jobs Carbify has been able to create based on the trees you own. This is calculated based on a global average.', loading:workProvided===null },
    { title: 'AREA AFFORESTED', value: areaReforested, unit: 'm²', tooltip: 'Based on the trees you have purchased, an area has been aforested. This value is displayed here in square meters (m²). Carbify only plants trees in areas where no trees have grown for at least 5 years and where deforestation previously occurred.', loading:areaReforested===null },
    { title: 'COMMUNITIES IMPACTED', value: communitiesImpacted, unit: 'communities', tooltip: 'Based on the number of trees you own, local communities have been supported. This impact is displayed here.', loading:communitiesImpacted===null },
  ];

  useEffect(() => {
    if (overviewAndDetails !== null) {
      setAreaReforested(formatNumber(overviewAndDetails?.overview_data.total_trees * 12.5));
      setFoodProvided(formatNumber(overviewAndDetails?.overview_data.total_trees * 50));
      setWorkProvided(formatNumberDecimal(overviewAndDetails?.overview_data.total_trees / 1200));
      setCommunitiesImpacted(formatNumberDecimal(overviewAndDetails?.overview_data.total_trees / 2500));
    }
  }, [overviewAndDetails]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
      {statsData.map((stat, index) => (
        <StatCard key={index} title={stat.title} value={stat.value} unit={stat.unit} tooltip={stat.tooltip} loading={stat.loading} />
      ))}
    </div>
  );
};

export default DashboardStats;
