import { aCO2Pool_ABI } from "constants/BlockchainConstants";
import { ethers } from "ethers";
import { getAllPoolData } from "features/aco2-pools/getAllPoolData";
import { getAllPoolLogos } from "features/aco2-pools/getAllPoolLogos";
import { getUserPoolData } from "features/aco2-pools/getUserPoolData";
import { withdrawACO2FromPool } from "features/aco2-pools/withdrawaCO2";
import { getERC721Data } from "features/dashboard/getERC721Data";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Config } from "../../config";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

const PoolsMap = ({
  aco2Pools,
  poolLogos,
  isTeamMember,
  setShowSellAco2Popup,
  setSelectedPool,
  userPoolData,
  aco2TotalBalance,
  loading,
  setLoading,
  setAco2Pools,
  setPoolLogos,
  setUserPoolData,
  setErc721Data,
}) => {
  const { address } = useAccount();

  const [withdrawACO2ParamsQueue, setWithdrawACO2ParamsQueue] = useState([]);
  const [withdrawACO2Params, setWithdrawACO2Params] = useState([]);

  // Withdraw aCO2 from pool
  const {
    data: withdrawHash,
    writeContract: withdrawWrite,
    isPending: withdrawLoading,
    isError: withdrawWriteError,
  } = useWriteContract();
  const { isSuccess: isWithdrawingSuccess, isError: isWithdrawingError } =
    useWaitForTransactionReceipt({ hash: withdrawHash });

  const handleWithdraw = async (pool) => {
    try {
      setLoading(true);
      console.log("handleWithdraw - pool", pool);
      withdrawACO2FromPool(pool.id, pool.tokenIds.length).then((result) => {
        if (!result.error) {
          toast.success("Successfully submitted withdraw request");
          setWithdrawACO2ParamsQueue(result);
        } else {
          toast.error("Transaction failed");
          getAllPoolData().then((data) => {
            if (data === null) {
              console.error("Error:", data);
            } else {
              setAco2Pools(data.pool_data);
            }
          });
          getAllPoolLogos().then((data) => {
            setPoolLogos(data);
          });
          getUserPoolData(address).then((data) => {
            setUserPoolData(data.user_pool_data);
          });
          getERC721Data(address).then((data) => {
            setErc721Data(data.token_data);
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Transaction failed");
      getAllPoolData().then((data) => {
        if (data === null) {
          console.error("Error:", data);
        } else {
          setAco2Pools(data.pool_data);
        }
      });
      getAllPoolLogos().then((data) => {
        setPoolLogos(data);
      });
      getUserPoolData(address).then((data) => {
        setUserPoolData(data.user_pool_data);
      });
      getERC721Data(address).then((data) => {
        setErc721Data(data.token_data);
      });
      setLoading(false);
      return { error: error.message };
    }
  };

  useEffect(() => {
    if (withdrawACO2ParamsQueue.length > 0) {
      console.log("withdrawACO2ParamsQueue:", withdrawACO2ParamsQueue);
      setWithdrawACO2Params(withdrawACO2ParamsQueue[0]);
    } else if (withdrawACO2ParamsQueue.length === 0 && loading === true) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setTimeout(() => {
        getAllPoolData().then((data) => {
          if (data === null) {
            console.error("Error:", data);
          } else {
            setAco2Pools(data.pool_data);
          }
        });
        getAllPoolLogos().then((data) => {
          setPoolLogos(data);
        });
        getUserPoolData(address).then((data) => {
          setUserPoolData(data.user_pool_data);
        });
        getERC721Data(address).then((data) => {
          setErc721Data(data.token_data);
        });
        setLoading(false);
      }, 7000);
    }
  }, [withdrawACO2ParamsQueue]);
  useEffect(() => {
    if (withdrawACO2Params?.args?.length > 0) {
      console.log("withdrawACO2Params:", withdrawACO2Params);
      withdrawWrite({
        args: withdrawACO2Params.args,
        abi: aCO2Pool_ABI,
        address: Config().contract_addresses.aco2_pool_address,
        functionName: "withdrawaCO2",
      });
    }
  }, [withdrawACO2Params]);
  useEffect(() => {
    if (isWithdrawingSuccess) {
      toast.success("Withdraw successful");
      setWithdrawACO2ParamsQueue((prev) => prev.slice(1));
    } else if (isWithdrawingError || withdrawWriteError) {
      toast.error("Withdraw failed");
      getAllPoolData().then((data) => {
        if (data === null) {
          console.error("Error:", data);
        } else {
          setAco2Pools(data.pool_data);
        }
      });
      getAllPoolLogos().then((data) => {
        setPoolLogos(data);
      });
      getUserPoolData(address).then((data) => {
        setUserPoolData(data.user_pool_data);
      });
      getERC721Data(address).then((data) => {
        setErc721Data(data.token_data);
      });
      setLoading(false);
    }
  }, [
    isWithdrawingSuccess,
    isWithdrawingError,
    withdrawLoading,
    withdrawWriteError,
  ]);

  return (
    <div className="flex flex-col tracking-wider space-y-8">
      {aco2Pools?.map((pool) => {
        const aco2_total_amount =
          Number(
            ethers.utils.formatUnits(
              pool.maxExchangeCurrencyAmount,
              pool.exchangeCurrencyDecimals,
            ),
          ) / Number(pool.exchangeRate);
        const aco2_pledged_amount = Number(
          ethers.utils.formatUnits(pool.aco2Amount, 18),
        );
        const fillPercentage = ((aco2_pledged_amount / aco2_total_amount) * 100).toFixed(2);
        return (
          <div key={pool.id} className="flex flex-col p-6 bg-darkGreen bg-opacity-70 border rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10">
                  <img
                    src={
                      poolLogos?.find(
                        (logo) => logo.poolId === pool.id.toString(),
                      )?.logoURL || "defaultLogoURL"
                    }
                    alt={`${pool.id} Logo`}
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div className="text-white">
                  <p className="text-xl font-semibold">{pool.buyer}</p>
                  <p className="text-sm">{`${aco2_pledged_amount.toFixed(2)} / ${aco2_total_amount.toFixed(2)} $aCO2`}</p>
                </div>
              </div>
              <div className="text-green-400">{fillPercentage}% Filled</div>
            </div>
            <div className="relative w-full h-4 bg-green border rounded-lg overflow-hidden">
              <div
                className="absolute top-0 left-0 h-full rounded-sm bg-white"
                style={{
                  width: `${fillPercentage}%`,
                }}
              ></div>
            </div>
            <div className="flex justify-between">
              <div className="text-white space-y-2 w-1/2">
                <div className="flex items-center space-x-2">
                  <span className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full text-sm">💎</span>
                  <p className="flex-grow">Price:</p>
                  <p className="text-right">{`${pool.exchangeRate} ${pool.exchangeCurrencySymbol} per aCO2`}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full text-sm">⏱️</span>
                  <p className="flex-grow">Ending:</p>
                  <p className="text-right">{moment.unix(pool.expireDate).fromNow()}</p>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="flex items-center justify-center w-4 h-4 bg-gray-300 rounded-full text-sm">🏷️</span>
                  <p className="flex-grow">Max sellable per user:</p>
                  <p className="text-right">
                    {(
                      Number(
                        ethers.utils.formatUnits(
                          pool.userClaimsCap,
                          pool.exchangeCurrencyDecimals,
                        ),
                      ) / Number(pool.exchangeRate)
                    ).toFixed(2)}{" "}
                    $aCO2
                  </p>
                </div>
              </div>
              <div className="flex justify-end items-end w-1/2">
                <button
                  onClick={() => {
                    setShowSellAco2Popup(true);
                    setSelectedPool(pool);
                  }}
                  disabled={
                    aco2Pools === null ||
                    userPoolData === null ||
                    aco2TotalBalance === null ||
                    pool.isFilled ||
                    pool.isClosed ||
                    (pool.expireDate < moment().unix() && !isTeamMember)
                  }
                  className="bg-white hover:bg-carbifyOrange text-green hover:text-white font-bold rounded-lg py-2 px-4 md:py-3 md:px-6 disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  Sell $aCO2 to this pool
                </button>
                {isTeamMember && pool.expireDate < moment().unix() && aco2_pledged_amount > 0 ? (
                  <button
                    onClick={() => handleWithdraw(pool)}
                    disabled={
                      aco2Pools === null ||
                      userPoolData === null ||
                      aco2TotalBalance === null ||
                      pool.isClosed
                    }
                    className="ml-4 bg-red-600 hover:bg-red-700 text-white font-bold rounded-lg py-2 px-4 md:py-3 md:px-6 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    Withdraw $aCO2 from this pool
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  
  
};

export default PoolsMap;
