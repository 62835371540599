import axios from "axios";

const api_url = process.env.REACT_APP_ACO2_DATA_API;

export const getExternalCertficates = async (certificateId) => {
    try {
        // if certificateId is a number call response from below, otherwise call response from the else block
        if (typeof certificateId === "number") {
            const response = await axios.get(`${api_url}/api/certificates/id?certificate_id=${certificateId}`);
            return response.data;
        } else {
            const response = await axios.get(`${api_url}/api/certificates/id?sub_certificate_id=${certificateId}`);
            return response.data;
        }
    } catch (error) {
        console.error("Error fetching user certificates: ", error);
        return null;
    }
};

export const getUserCertificates = async (companyId, certificateIds) => {
    try {
        const body = {
            certificate_ids: certificateIds,
        }

        const response = await axios.get(`${api_url}/api/certificates/company?company_id=${companyId}`, body);

        return response.data;
    } catch (error) {
        console.error("Error fetching user certificates: ", error);
        return null;
    }
};
