import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAccount, useReadContract } from 'wagmi'
import { toast } from 'react-toastify'

// Constants
import { CarbifyNFTABI } from 'constants/BlockchainConstants'

const NftsCard = ({ asset_contract, image, title }) => {
  const [totalCount, setTotalCount] = useState(Number(0))
  const { address: walletAddress } = useAccount()

  const {
    data: balanceOfData,
    error: balanceOfError,
  } = useReadContract({
    address: asset_contract,
    abi: CarbifyNFTABI,
    functionName: 'tokenBalance',
    args: [walletAddress],
  })
  useEffect(() => {
    if (balanceOfData) {
      setTotalCount(Number(balanceOfData))
    } else if (balanceOfError) {
      toast.error('Error fetching balance of:', balanceOfError.cause)
    }
  }, [balanceOfData, balanceOfError])

  // Conditionally render nothing if totalCount is zero
  if (totalCount === 0 || totalCount === '') {
    return null
  }
  return (
    <div className="relative overflow-hidden bg-darkGreen bg-opacity-70 hover:bg-light_green border rounded-lg p-4 flex flex-col space-y-4 justify-start group">
      <Link to={`/carbifyNfts/${asset_contract}`}>
        <div className="flex items-center justify-center mb-3">
          <img className="w-auto h-[426px] rounded-[10px]" src={image} />
        </div>
        <div className="flex flex-col mb-3">
          <div className="text-xl tracking-wider font-bold text-center">{title}</div>
          <div className="h-12"></div>
        </div>
        <div className="absolute bottom-0 right-0 p-2 transition-transform transform translate-x-full group-hover:translate-x-0">
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </div>
      </Link>
      <div className="absolute -top-4 rounded-br-lg left-0 px-2 z-10 bg-white text-bt text-lg font-bold text-center">
        x{totalCount}
      </div>
    </div>
  )
}

export default NftsCard
