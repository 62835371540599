export const Config = () => ({
  web3_provider: process.env.REACT_APP_HTTP_PROVIDER,
  chain_id: process.env.REACT_APP_CHAIN_ID,
  aco2_data_api: process.env.REACT_APP_ACO2_DATA_API,
  max_trees_per_tx: parseInt(process.env.REACT_APP_MAX_TREES_PER_TX),
  max_calls_per_multicall_batch: parseInt(process.env.REACT_APP_MAX_CALLS_PER_MULTICALL_BATCH),
  crc_api: process.env.REACT_APP_CRC_API,
  auth_api: process.env.REACT_APP_API_URL_NODE_AUTH,
  contract_addresses: {
    batch1_address: process.env.REACT_APP_NFTREE_BATCH_1_ADDRESS,
    batch2_address: process.env.REACT_APP_NFTREE_BATCH_2_ADDRESS,
    batch3_address: process.env.REACT_APP_NFTREE_BATCH_3_ADDRESS,
    batch4_address: process.env.REACT_APP_NFTREE_BATCH_4_ADDRESS,
    batch5_address: process.env.REACT_APP_NFTREE_BATCH_5_ADDRESS,
    batch6_address: process.env.REACT_APP_NFTREE_BATCH_6_ADDRESS,
    batch7_address: process.env.REACT_APP_NFTREE_BATCH_7_ADDRESS,
    batch8_address: process.env.REACT_APP_NFTREE_BATCH_8_ADDRESS,
    batch9_address: process.env.REACT_APP_NFTREE_BATCH_9_ADDRESS,
    genesis_address: process.env.REACT_APP_GENESIS_PLOT_ADDRESS,
    rare_address: process.env.REACT_APP_RARE_PLOT_ADDRESS,
    standard_address: process.env.REACT_APP_STANDARD_PLOT_ADDRESS,
    cby_address: process.env.REACT_APP_CBY_ADDRESS,
    aco2_address: process.env.REACT_APP_ACO2_TOKEN_ADDRESS,
    aco2_pool_address: process.env.REACT_APP_ACO2_POOL_ADDRESS,
    staking_address: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
    staking_pool_address: process.env.REACT_APP_STAKINGPOOL_CONTRACT_ADDRESS,
    carbify_nft_address: process.env.REACT_APP_CARBIFYNFT_ADDRESS,
    multi_call: process.env.REACT_APP_MULTICALL_ADDRESS,
  },
  all_tree_addresses: [
    process.env.REACT_APP_NFTREE_BATCH_1_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_2_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_3_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_4_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_5_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_6_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_7_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_8_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_9_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_10_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_11_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_12_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_13_ADDRESS,
    process.env.REACT_APP_NFTREE_BATCH_15_ADDRESS,
  ],
  all_plot_addresses: [
    process.env.REACT_APP_GENESIS_PLOT_ADDRESS,
    process.env.REACT_APP_RARE_PLOT_ADDRESS,
    process.env.REACT_APP_STANDARD_PLOT_ADDRESS,
  ],
});
