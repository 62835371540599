import React from 'react'

function Wallet05({ width = 24, height = 24, active }) {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${active ? 'stroke-carbifyOrange' : 'stroke-white group-hover:stroke-carbifyOrange ease-in-out duration-500'} `}
    >
      <path
        d="M19.0001 10L5.00009 10M19.0001 10L20.8642 13.1069C21.282 13.8032 21.4909 14.1514 21.5994 14.5262C21.6955 14.8582 21.7335 15.2045 21.7117 15.5495C21.6871 15.9389 21.5587 16.324 21.302 17.0944L21.0941 17.7179C20.7007 18.8981 20.504 19.4882 20.1392 19.9245C19.817 20.3098 19.4032 20.608 18.9358 20.7918C18.4066 21 17.7845 21 16.5404 21L7.45973 21C6.21566 21 5.59362 21 5.06435 20.7918C4.59696 20.608 4.18321 20.3098 3.86102 19.9245C3.49617 19.4882 3.29946 18.8981 2.90605 17.7179L2.69822 17.0944C2.44144 16.324 2.31304 15.9389 2.28844 15.5495C2.26664 15.2044 2.30464 14.8582 2.40078 14.5261C2.50926 14.1514 2.71815 13.8032 3.13593 13.1069L5.00009 10M19.0001 10L19.4174 8.53955C19.6152 7.84717 19.7141 7.50097 19.6364 7.22708C19.5683 6.98715 19.4131 6.78132 19.2011 6.64996C18.9591 6.5 18.599 6.5 17.8789 6.5L6.12126 6.5C5.40116 6.5 5.04112 6.5 4.79911 6.64996C4.58711 6.78132 4.43185 6.98715 4.36378 7.22708C4.28608 7.50097 4.38499 7.84717 4.58282 8.53955L5.00009 10M12.0001 6.5H8.46437C7.94341 6.5 7.44379 6.28929 7.07541 5.91421C6.70704 5.53914 6.50009 5.03043 6.50009 4.5C6.50009 3.96957 6.70704 3.46086 7.07541 3.08579C7.44379 2.71071 7.94341 2.5 8.46437 2.5C11.2144 2.5 12.0001 6.5 12.0001 6.5ZM12.0001 6.5H15.5358C16.0568 6.5 16.5564 6.28929 16.9248 5.91421C17.2931 5.53914 17.5001 5.03043 17.5001 4.5C17.5001 3.96957 17.2931 3.46086 16.9248 3.08579C16.5564 2.71071 16.0568 2.5 15.5358 2.5C12.7858 2.5 12.0001 6.5 12.0001 6.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Wallet05
