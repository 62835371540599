import React from 'react'
import Price from './cardComponents/Price'

// Constants
import { CBY_ADDRESS, USDT_ADDRESS } from 'constants/BlockchainConstants'

// Icons
import { AiOutlineShoppingCart } from 'react-icons/ai'
import PolygonLogo from 'icons/PolygonLogo'

const NftreeCard = ({ title, image, quantity, currency, price, setShowBuyNowModal }) => {
  return (
    <div className="relative overflow-hidden bg-darkGreen bg-opacity-70 hover:bg-light_green border border rounded-lg p-4 flex flex-col space-y-4 justify-start group">
      <div className="flex items-center justify-center">
        <img className="w-full h-full rounded-[10px]" src={image} />
      </div>
      <div className="flex flex-col mb-3">
        <div className="text-xl text-center tracking-wider font-bold mb-3">{title}</div>
        <div className="flex justify-center items-center">
          <Price
            logo={
              currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                ? `carbify-logo-marketplace.png`
                : currency.toLowerCase() === USDT_ADDRESS.toLowerCase()
                ? `tether.png`
                : `carbify-blue-dollar.png`
            }
            price={price}
            unit={
              currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                ? `CBY`
                : currency.toLowerCase() === USDT_ADDRESS.toLowerCase()
                ? `USDT`
                : `USDC`
            }
          />
          <div className="ml-2">
            {/* <PolygonLogo /> */}
          </div>
        </div>
      </div>
      <div className="absolute -top-4 rounded-br-lg left-0 px-2 z-9 bg-white text-bt text-lg font-bold text-center">
      {quantity ? 
      
      ( quantity>0 ? 'x'+quantity : quantity) 
      : 0}
      </div>
      <div className="absolute -top-4 right-0 z-8">
        {/* {<img className="block w-12 object-contain h-14" src="/Carbify_favicon.png" />} */}
      </div>
      <div className="absolute bottom-0 left-0 transition-all transform ease-in translate-y-3 opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:duration-300 w-full px-4">
        <button
          onClick={() => setShowBuyNowModal(true)}
          className="flex space-x-3 items-center justify-center bg-white text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
        >
          <AiOutlineShoppingCart className="text-xl" />
          <span>Buy Now</span>
        </button>
      </div>
    </div>
  )
}

export default NftreeCard
