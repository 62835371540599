import axios from 'axios';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getAllPoolData = async () => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/aco2-pools/fetch`);

        if (response.status === 200) {
            const { data } = response;
            return data;
        } else {
            console.error("Response Error:", response.status);
            return 'null';
        }
    } catch (err) {
        console.error("Error:", err.message);
        return null;
    }
};
