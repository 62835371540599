import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import Spinner from 'components/utils/Spinner'
import NftCard from 'components/nfts/cards/NftCard'
import ListNftsModal from 'components/nfts/modals/ListNftsModal'

import { useAccount, useReadContract } from 'wagmi'
import { toast } from 'react-toastify'

// Constants
import { CarbifyNFTABI } from 'constants/BlockchainConstants'

// Actions
import { getNfts } from 'features/nfts/ownedNftSlice'

function NftsPage() {
  const { asset_contract } = useParams()
  const dispatch = useDispatch()
  const { address: walletAddress } = useAccount()

  const [showModal, setShowModal] = useState(false)
  const [nft, setNft] = useState({})
  const [pageKey, setPageKey] = useState(0)
  const [previousPageKeys, setPreviousPageKeys] = useState([])
  const { ownedNfts, ownedNftsLoading } = useSelector(state => state.ownedNfts)
  const [totalCount, setTotalCount] = useState(Number(0))
  const [amount, setAmount] = useState(30)

  const {
    data: totalNftdata,
    error: totalNftError,
  } = useReadContract({
    address: asset_contract,
    abi: CarbifyNFTABI,
    functionName: 'tokenBalance',
    args: [walletAddress],
    watch: true,
    onSettled(data, error) {
      if (error) {
        toast.error(error.reason)
      } else {
        setTotalCount(Number(data))
      }
    },
  })
  useEffect(() => {
    if (totalNftdata) {
      setTotalCount(Number(totalNftdata))
    } else if (totalNftError) {
      toast.error('Error fetching balance of NFTs:', totalNftError.cause)
    }
  }, [totalNftdata, totalNftError])

  useEffect(() => {
    dispatch(getNfts({ asset_contract, pageKey, amount: amount }))
  }, [walletAddress, pageKey, asset_contract])

  const handleNextPage = () => {
    setPreviousPageKeys(prevKeys => [...prevKeys, pageKey])
    setPageKey(ownedNfts?.pageKey)
  }

  const handlePreviousPage = () => {
    if (previousPageKeys.length > 0) {
      const previousPageKey = previousPageKeys.pop()
      setPageKey(previousPageKey)
    }
  }

  const handleListNftModal = (nft, showModal) => {
    setNft(nft)
    setShowModal(showModal)
  }

  if (!ownedNfts || ownedNftsLoading) return <Spinner />
  //   else if (totalCount === 0 || totalCount === '') return null

  console.log('MY NFTs: ', ownedNfts !== undefined ? ownedNfts : 0)

  return (
    <Layout title="Carbify | NFTs" content="Your NFTs">
      {ownedNftsLoading && <Spinner />}
      <PageHeading>NFTs</PageHeading>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-6 gap-y-6 mb-6">
        {ownedNfts?.token_data?.map((nft, index) => (
          <NftCard
            key={index}
            nft={nft}
            name={nft.metadata.name}
            image={nft.metadata.image}
            handleListNftModal={handleListNftModal}
          />
        ))}
      </div>
      <div className="flex justify-center">
        {previousPageKeys.length > 0 && (
          <button onClick={handlePreviousPage} className="bg-secondary py-2 px-6 rounded-lg mr-4">
            Previous
          </button>
        )}
        {ownedNfts?.pageKey !== pageKey && totalCount > amount && (
          <button onClick={handleNextPage} className="bg-secondary py-2 px-10 rounded-lg">
            Next
          </button>
        )}
      </div>
      {nft && (
        <ListNftsModal
          nft={nft}
          name={nft?.metadata?.name}
          image={nft?.metadata?.image}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </Layout>
  )
}

export default NftsPage
