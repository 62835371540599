import React from 'react'

function ChevronUpIcon({ color = 'white', width = 20, height = 20 }) {
  return (
    <div>
      <svg width={`${width}`} height={`${height}`} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 7L7 1L1 7" stroke={`${color}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

export default ChevronUpIcon
