import React from 'react'

const BestOffer = ({ offer, unit }) => {
  return (
    <div className="font-light text-sm flex items-center">
      {offer ? `Highest Offer ${offer} ${unit}` : `No offers yet`}
    </div>
  )
}

export default BestOffer
