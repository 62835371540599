import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { ethers } from 'ethers';
import { aCO2_ABI } from '../../constants/BlockchainConstants';
import { Config } from '../../config.js';

const ERC1155_ABI = aCO2_ABI;
const ERC1155_ADDRESS = `${Config().contract_addresses.aco2_address}`;
const MAX_CALLS_PER_MULTICALL_BATCH = Config().max_calls_per_multicall_batch;

export const getACO2TotalPendingReward = async (erc721Data) => {
    try {
        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({ multicallAddress: Config().contract_addresses.multi_call, provider });
        const aCO2Token = new web3.eth.Contract(ERC1155_ABI, ERC1155_ADDRESS);

        // Processing addresses to lowercase
        let allTreeAddresses = Config().all_tree_addresses.map(address => address?.toLowerCase());

        // if an address in allTreeAddresses is undefined, remove it (needed for development environment)
        allTreeAddresses = allTreeAddresses.filter(address => address !== undefined);

        let totalPendingReward = ethers.BigNumber.from(0);
        let detailedPendingRewards = [];

        if (!erc721Data || erc721Data.length === 0) {
            return { totalPendingRewardFormatted: "0", detailedPendingRewards: [] };
        }

        // Process calls in batches
        for (let i = 0; i < erc721Data.length; i++) {
            const { contract_address, token_id } = erc721Data[i];
            if (!allTreeAddresses.includes(contract_address.toLowerCase()) || token_id === null) {
                continue;
            }
            let calls = erc721Data.slice(i, i + MAX_CALLS_PER_MULTICALL_BATCH).map(data =>
                aCO2Token.methods.getPendingTokenAmount(data.contract_address, data.token_id)
            );
            
            const results = await multicall.aggregate(calls);
            results.forEach((pendingReward, index) => {
                const formattedReward = ethers.utils.formatUnits(pendingReward, 18);
                totalPendingReward = totalPendingReward.add(pendingReward);
                detailedPendingRewards.push({
                    nftree_id: erc721Data[i + index].token_id,
                    batch_address: erc721Data[i + index].contract_address,
                    pending_reward_formatted: formattedReward
                });
            });

            i += MAX_CALLS_PER_MULTICALL_BATCH - 1; // Adjust the loop index to skip processed items
        }

        const totalPendingRewardFormatted = ethers.utils.formatUnits(totalPendingReward, 18);
        return { totalPendingRewardFormatted, detailedPendingRewards };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
