import React from 'react'

function Spinner() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[100] overflow-hidden flex flex-col items-center justify-center">
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-51 overflow-hidden bg-gray-700 opacity-90 flex flex-col items-center justify-center"></div>
      <div className="w-60 animate-spin z-51">
        <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
      </div>
    </div>
  )
}

export default Spinner
