import React from 'react'

const Calculator = ({ width = 24, height = 24, active }) => {
  return (
    <div>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${active ? 'stroke-carbifyOrange' : 'stroke-white group-hover:stroke-carbifyOrange ease-in-out duration-500'}`}
      >
        <path
          d="M19.875 6.125L6.125 19.875M8.625 11.125V6.125M6.125 8.625H11.125M14.875 17.375H19.875M7.75 24.25H18.25C20.3502 24.25 21.4003 24.25 22.2025 23.8413C22.9081 23.4817 23.4817 22.9081 23.8413 22.2025C24.25 21.4003 24.25 20.3502 24.25 18.25V7.75C24.25 5.6498 24.25 4.5997 23.8413 3.79754C23.4817 3.09193 22.9081 2.51825 22.2025 2.15873C21.4003 1.75 20.3502 1.75 18.25 1.75H7.75C5.6498 1.75 4.5997 1.75 3.79754 2.15873C3.09193 2.51825 2.51825 3.09193 2.15873 3.79754C1.75 4.5997 1.75 5.6498 1.75 7.75V18.25C1.75 20.3502 1.75 21.4003 2.15873 22.2025C2.51825 22.9081 3.09193 23.4817 3.79754 23.8413C4.5997 24.25 5.6498 24.25 7.75 24.25Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default Calculator
