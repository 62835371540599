import { ethers } from 'ethers';
import { Config } from '../../config.js';
import { STAKING_ABI } from 'constants/BlockchainConstants';

const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();

export const estimateStakingGasCost = async (methodName, ...args) => {
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const stakingContract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, signer);

        // Estimate the gas for the method
        const estimatedGas = await stakingContract.estimateGas[methodName](...args);

        // Get the current gas price
        const gasPrice = await provider.getGasPrice();

        // Calculate the cost (estimatedGas * gasPrice)
        const cost = estimatedGas.mul(gasPrice);

        // Convert cost to Ether and format it
        const formattedCost = ethers.utils.formatEther(cost);

        return { formattedCost };
    } catch (error) {
        console.error("Error in estimating gas cost:", error);
        return { error: error.message, formattedCost: null };
    }
}
