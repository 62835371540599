"use client";

import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedFilters } from '../../features/tree-map/treeSlice';

// Custom styles for the Checkbox
const customCheckboxStyles = {
  color: "rgba(255, 150, 46, 1)",
  '&.Mui-checked': {
    color: "rgba(255, 150, 46, 1)",
  },
  '&:hover': {
    backgroundColor: "rgba(255, 150, 46, 0.08)",
  },
};

// Custom styles for the FormControlLabel
const customLabelStyles = {
  fontSize: '1rem',
  color: "#fff",
};

export const CustomCheckbox = ({ label, selectedFilters }) => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const newFilters = event.target.checked 
      ? [...selectedFilters, label] 
      : selectedFilters.filter((item) => item !== label);
    dispatch(setSelectedFilters(newFilters));
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={customCheckboxStyles}
          onChange={handleChange}
          checked={selectedFilters.includes(label)}
        />
      }
      label={label}
      sx={customLabelStyles}
    />
  );
};
