import { STAKING_ABI } from 'constants/BlockchainConstants';
import { ethers } from 'ethers';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();

export const calculateFiveDollarsWorthCBY = async () => {
    try {
        const provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        const stakingContract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, provider);
        const cbyPrice = await stakingContract.calculateFiveDollarsWorthCBY();
        if (!cbyPrice) return 0;
        const formattedCBYPrice = Number(cbyPrice) / 10 ** 18;
        return { formattedCBYPrice: formattedCBYPrice.toFixed(2) };
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message, formattedCBYPrice: 0.00 };
    }
}
