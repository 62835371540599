import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import SmallAco2Loader from 'components/utils/SmallAco2Loader'
import Spinner from 'components/utils/Spinner'
import { Config } from '../../config.js'
import { toast } from 'react-toastify'
import { IoMdInformationCircleOutline } from 'react-icons/io'

// API
import { getERC721Data } from 'features/dashboard/getERC721Data'
import { getAllStakedMerkleData } from 'features/staking/getAllStakedMerkleData'
import { getStakingACO2TotalPendingReward } from 'features/staking/getStakingACO2TotalPendingReward'
import { claimStakingACO2Rewards } from 'features/staking/claimStakingACO2'
import { claimStakingMerkle } from 'features/staking/claimStakingMerkle'
import { isClaimedStakingMerkle } from 'features/staking/isClaimedStakedMerkle'
import { STAKING_ABI } from 'constants/BlockchainConstants'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance.js'
import { checkRemainingMerkleBalance } from 'features/staking/checkRemainingMerkleBalance.js'

const ClaimStakingAco2Card = () => {
  const { address } = useAccount()

  const { loadingClaimAco2Rewards } = useSelector(state => state.aco2)

  const [erc721Data, setErc721Data] = useState(null)
  const [balance, setBalance] = useState(null)
  const [pendingRewards, setPendingRewards] = useState(null)
  const [merkleProofs, setMerkleProofs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [claimMerkle, setClaimMerkle] = useState(false)
  const [claimMerkleAmount, setClaimMerkleAmount] = useState(null)

  const [claimStakingParamsQueue, setClaimStakingParamsQueue] = useState([])
  const [claimStakingParams, setClaimStakingParams] = useState([])

  const [claimMerkleStakingParamsQueue, setClaimMerkleStakingParamsQueue] = useState([])
  const [claimMerkleStakingParams, setClaimMerkleStakingParams] = useState([])

  // claim staking
  const {
    data: claimStakingHash,
    writeContract: claimStakingWrite,
    isPending: isClaimStakingWriting,
    isError: isClaimStakingError,
    error: claimStakingErrorMessage,
  } = useWriteContract();
  const {
    isLoading: claimingStaking,
    isSuccess: claimStakingSuccess,
    isError: claimStakingError,
  } = useWaitForTransactionReceipt({ hash: claimStakingHash });

  // claim merkle staking
  const {
    data: claimMerkleStakingHash,
    writeContract: claimMerkleStakingWrite,
    isPending: isClaimMerkleStakingWriting,
    isError: isClaimMerkleStakingError,
    error: claimMerkleStakingErrorMessage,
  } = useWriteContract();
  const {
    isLoading: claimingMerkleStaking,
    isSuccess: claimMerkleStakingSuccess,
    isError: claimMerkleStakingError,
  } = useWaitForTransactionReceipt({ hash: claimMerkleStakingHash });

  const claimAco2 = async () => {
    setLoading(true);
    try {
      claimStakingACO2Rewards(erc721Data.token_data).then(data => {
        if (data.success) {
          toast.success('Successfully submitted staking $aCO2 claim request')
          let amountOfTrees = 0;
          let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
          amountOfTrees = data.allTokenIds.length;
          let query = [];
          const allTokenIds = data.allTokenIds;
          const allTokenAddresses = data.allTokenAddresses;
          if (amountOfTrees <= MAX_TREES_PER_TX) {
            MAX_TREES_PER_TX = amountOfTrees;
          }
          for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
            const tokenIdsChunk = allTokenIds.slice(i, i + MAX_TREES_PER_TX);
            const tokenAddressesChunk = allTokenAddresses.slice(i, i + MAX_TREES_PER_TX);
            query.push({ args: [tokenIdsChunk, tokenAddressesChunk] });
          }
          console.log('claimAco2 - query:', query);
          setClaimStakingParamsQueue(query);
          setBalance(null);
          setPendingRewards(null);
        } else {
          toast.error('Error submitting staking $aCO2 claim request')
          setLoading(false);
        }
      })
    } catch (error) {
      console.error("Error:", error);
      toast.error('Error submitting staking $aCO2 claim request')
      setLoading(false);
      // Handle any errors here
    }
  }

  const claimMerkleAco2 = async () => {
    setLoading(true);
    try {
      claimStakingMerkle(merkleProofs, address).then(data => {
        if (data.success) {
          toast.success('Successfully submitted staking merkle $aCO2 claim request')
          let amountOfTrees = 0;
          let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
          amountOfTrees = data.amounts.length;
          let query = [];
          let amounts = data.amounts;
          // transform amount from hex to big number string
          amounts = amounts.map(amount => ethers.BigNumber.from(amount).toString());
          const proofs = data.proofs;

          const chunks = Math.ceil(amountOfTrees / MAX_TREES_PER_TX);

          for (let i = 0; i < chunks; i++) {
            let start = i * MAX_TREES_PER_TX;
            let end = start + MAX_TREES_PER_TX;
            const amountChunk = amounts.slice(start, end);
            const proofChunk = proofs.slice(start, end);
            const args = [amountChunk, proofChunk];
            query.push({ args });
          }
          console.log('claimMerkleAco2 - query:', query);
          setClaimMerkleStakingParamsQueue(query);
          setBalance(null);
          setClaimMerkleAmount(null);
          setPendingRewards(null);
        } else {
          toast.error('Error submitting staking merkle $aCO2 claim request')
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error('Error submitting staking merkle $aCO2 claim request')
      setLoading(false);
      // Handle any errors here
    }
  }

  // claimStakingWrite();
  useEffect(() => {
    if (claimStakingParamsQueue.length > 0) {
      setClaimStakingParams(claimStakingParamsQueue[0])
    } else if (claimStakingParamsQueue.length === 0 && loading === true) {
      setLoading(false);
      setBalance(null);
      setPendingRewards(null);
      getERC721Data(address, undefined).then(data => {
        setErc721Data(data)
      })
    }
  }, [claimStakingParamsQueue])
  useEffect(() => {
    if (claimStakingParams?.args?.length > 0) {
      claimStakingWrite({
        args: claimStakingParams.args,
        abi: STAKING_ABI,
        address: (Config().contract_addresses.staking_address).toLowerCase(),
        functionName: 'claimMultiple',
      });
    }
  }, [claimStakingParams])
  useEffect(() => {
    if (claimStakingSuccess) {
      toast.success('Successfully claimed staking $aCO2 rewards')
      setClaimStakingParamsQueue(prev => prev.slice(1))
    } else if (isClaimStakingError || claimStakingError) {
      if (claimStakingErrorMessage?.shortMessage.includes('NFTree has no pending reward')) {
        toast.error('You can only claim rewards once per day. Please try again tomorrow.')
      } else {
        toast.error('Error claiming staking $aCO2 rewards')
      }
      setLoading(false);
      setBalance(null);
      setPendingRewards(null);
      getERC721Data(address, undefined).then(data => {
        setErc721Data(data)
      })
    }
  }, [claimStakingSuccess, claimStakingError, isClaimStakingError])

  // claimMerkleStakingWrite();
  useEffect(() => {
    if (claimMerkleStakingParamsQueue.length > 0) {
      setClaimMerkleStakingParams(claimMerkleStakingParamsQueue[0])
    } else if (claimMerkleStakingParamsQueue.length === 0 && loading === true) {
      setLoading(false);
      setBalance(null);
      setClaimMerkleAmount(null);
      setPendingRewards(null);
      isClaimedStakingMerkle(merkleProofs, address, false).then(data => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isClaimed === false) {
            setClaimMerkle(true)
            checkRemainingMerkleBalance(merkleProofs, address).then(data => {
              console.log('checkRemainingMerkleBalance - data:', data);
              setClaimMerkleAmount(data);
            })
          } else {
            setClaimMerkle(false)
          }
          getACO2TotalBalance(address).then(result => {
            if (!result.error) {
              setBalance(result);
            }
          });
        }
      });
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      });
    }
  }, [claimMerkleStakingParamsQueue])
  useEffect(() => {
    if (claimMerkleStakingParams?.args?.length > 0) {
      claimMerkleStakingWrite({
        args: claimMerkleStakingParams.args,
        abi: STAKING_ABI,
        address: (Config().contract_addresses.staking_address).toLowerCase(),
        functionName: 'claimMultipleStakingMerkleRewards',
      });
    }
  }, [claimMerkleStakingParams])
  useEffect(() => {
    if (claimMerkleStakingSuccess) {
      toast.success('Successfully claimed staking merkle $aCO2 rewards')
      setClaimMerkleStakingParamsQueue(prev => prev.slice(1))
    } else if (isClaimMerkleStakingError || claimMerkleStakingError) {
      toast.error('Error claiming staking merkle $aCO2 rewards')
      setLoading(false);
      setBalance(null);
      setPendingRewards(null);
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      })
      checkRemainingMerkleBalance(merkleProofs, address).then(data => {
        console.log('checkRemainingMerkleBalance - data:', data);
        setClaimMerkleAmount(data);
      })
    }
  }, [claimMerkleStakingSuccess, claimMerkleStakingError, isClaimMerkleStakingError])

  useEffect(() => {
    getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
      setErc721Data(data)
    })
    getAllStakedMerkleData(address).then(data => {
      setMerkleProofs(data.merkle_proofs)
      const localProofs = data.merkle_proofs;
      isClaimedStakingMerkle(data.merkle_proofs, address, false).then(data => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].isClaimed === false) {
            setClaimMerkle(true)
            checkRemainingMerkleBalance(localProofs, address).then(data => {
              console.log('checkRemainingMerkleBalance - data:', data);
              setClaimMerkleAmount(data);
            })
          } else {
            setClaimMerkle(false)
          }
        }
      })
    })
  }, [address]);

  useEffect(() => {
    if (address && erc721Data) {
      getACO2TotalBalance(address).then(result => { 
        if (!result.error) {
          setBalance(result);
        }
      });
      getStakingACO2TotalPendingReward(erc721Data.token_data).then(result => {
        if (!result.error) {
          setPendingRewards(result);
          let totalAmount = 0;
          isClaimedStakingMerkle(merkleProofs, address, false).then(data => {
            for (let i = 0; i < data.length; i++) {
              if (data[i].isClaimed === false) {
                const hexString = data[i].amount.hex;
                if (hexString) {
                  const amount = ethers.BigNumber.from(hexString);
                  const formattedAmount = ethers.utils.formatEther(amount);
                  totalAmount += Number(formattedAmount);
                }
                const addedAmount = Number(result.totalPendingRewardFormatted) + Number(totalAmount);
                setPendingRewards({ ...result, totalPendingReward: addedAmount, totalPendingRewardFormatted: addedAmount.toFixed(2) });
              }
            }
          })
        }
      });
    }
  }, [erc721Data, merkleProofs, address]);

  return (
    <>
      { loadingClaimAco2Rewards || loading || claimingStaking || claimingMerkleStaking || isClaimMerkleStakingWriting || isClaimStakingWriting ? <Spinner /> : null }
      <div className="items-stretch h-full bg-darkGreen bg-opacity-70 border rounded-lg mt-5">
        <div className="border-b-2 p-3 border-green w-full mb-6 text-center">
          <p className="font-semibold text-2xl">Claim your staking $aC02</p>
        </div>
        <div className="p-4 space-y-8">
          <div className="flex flex-col items-center w-full rounded border-2 border-green">
            <div className="flex flex-row p-3 w-full border-b-2 border-green gap-2.5 items-center justify-between">
              <p className="font-semibold text-lg">Wallet balance</p>
              <div className="flex flex-row justify-end h-9">
                {balance === null ? ( 
                  <SmallAco2Loader />
                ) : (
                  <div className="flex items-center">
                    <img className="h-6 w-6 mt-0.5 mr-2" src="/assets/images/aco2_logo.png" alt="" />
                    <span className="flex font-normal h-full justify-center items-center">
                      {balance && Number(balance.totalBalanceFormatted).toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row p-3 w-full gap-2.5 items-center justify-between">
              <p className="font-semibold text-lg">{claimMerkle === false ? `Available to claim from staking` : `Available staking Merkle rewards`}</p>
              <div className="flex flex-row justify-end h-9">
              {claimMerkle === false && (
                pendingRewards === null ? (
                  <SmallAco2Loader />
                ) : (
                  <div className="flex items-center">
                    <img className="h-6 w-6 mt-0.5 mr-2" src="/assets/images/aco2_logo.png" alt="" />
                    <span className="flex font-normal h-full justify-center items-center">
                      {pendingRewards && Number(pendingRewards.totalPendingRewardFormatted).toFixed(2)}
                    </span>
                  </div>
                )
              )}
              {claimMerkle === true && (
                claimMerkleAmount === null ? (
                  <SmallAco2Loader />
                ) : (
                  <div className="flex items-center">
                    <img className="h-6 w-6 mt-0.5 mr-2" src="/assets/images/aco2_logo.png" alt="" />
                    <span className="flex font-normal h-full justify-center items-center">
                      {claimMerkleAmount && Number(claimMerkleAmount).toFixed(2)}
                    </span>
                  </div>
                )
              )}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={() => {
                if (claimMerkle === true) {
                  claimMerkleAco2();
                } else {
                  claimAco2();
                }
              }}
              // onClick={claimAco2}
              disabled={ balance === null || pendingRewards === null || Number(pendingRewards.totalPendingRewardFormatted).toFixed(2) < 0.01 }
              className="bg-carbifyOrange disabled:bg-gray-400 w-full space-x-2 text-green font-bold text-base font-montserrat rounded-lg py-3 items-center justify-center flex flex-row hover:bg-green hover:text-white disabled:hover:text-green disabled:cursor-not-allowed"
            >
              Claim available $aCO2 from staking
            </button>
            <div className="justify-center mt-2 text-white font-light text-center inline-flex items-center">
              <span>You can claim your staking rewards once per day</span> 
              <IoMdInformationCircleOutline className="ml-1" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClaimStakingAco2Card
