import React, { useState } from 'react'

// Icons
import EyeIcon from 'icons/EyeIcon'
import EyeOffIcon from 'icons/EyeOffIcon'

const PasswordField = ({ label, value, onChange, name, required = true }) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleShowHidePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="flex-1 space-y-1">
      <label htmlFor={name} className="block text-base font-medium">
        {label}
      </label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          name={name}
          id={name}
          className="tracking-wide text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
          placeholder="●●●●●●●●●"
          required={required}
          value={value}
          onChange={onChange}
        />
        <div
          onClick={handleShowHidePassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
        >
          {!showPassword ? <EyeIcon /> : <EyeOffIcon />}
        </div>
      </div>
    </div>
  )
}

export default PasswordField
