import React, { useState } from 'react'

//icons
import { FiChevronDown } from 'react-icons/fi'
import { FiChevronUp } from 'react-icons/fi'

const CarbonUnitInput = ({
  label,
  name,
  value,
  dropDownOptions = null,
  onChange,
  onUnitChange,
  inputUnit = null,
  disabled = false,
}) => {
  const [showOption, setShowOption] = useState(false)
  const [unit, setUnit] = useState(dropDownOptions ? dropDownOptions[0] : inputUnit)

  const handleDropdown = () => {
    setShowOption(!showOption)
  }

  const handleUnit = unit => {
    setUnit(unit)
    onUnitChange(unit)
    setShowOption(!showOption)
  }

  const validateNumber = e => {
    const allowedKeys = [46, 8, 9, 27, 13, 110, 190]
    const input = e.target

    if (e.keyCode === 37) {
      // left arrow
      if (input.selectionStart > 0) {
        input.selectionStart--
      }
    } else if (e.keyCode === 39) {
      // right arrow
      if (input.selectionEnd < input.value.length) {
        input.selectionEnd++
      }
    } else if (
      allowedKeys.includes(e.keyCode) ||
      (e.keyCode >= 48 && e.keyCode <= 57) || // number keys 0-9
      (e.keyCode >= 96 && e.keyCode <= 105) // numpad keys 0-9
    ) {
      // do nothing
    } else {
      e.preventDefault()
    }
  }

  const handleClick = e => {
    if (e.target.value == 0) {
      e.target.value = ''
    } else {
      e.target.value = value
    }
  }

  return (
    <div className="relative space-y-1 3xl:w-[360px] xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px] font-medium text-base">
      <label
        className="xxxs:max-md:truncate-ellipsis md:max-xl:truncate 2xl:max-3xl:text-overflow 2xl:max-3xl:whitespace-nowrap"
        for={name}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type="text"
        name={name}
        id={name}
        className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg 3xl:w-[360px] xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px] h-10 focus:outline-none focus:border-carbifyOrange p-2 pr-36"
        onChange={onChange}
        onKeyDown={validateNumber}
        value={value}
        onClick={e => handleClick(e)}
        onBlur={e => (e.target.value = value)}
      />
      <p className="absolute bottom-2 right-3 flex space-x-1">
        <span>{unit}</span>
        {showOption ? (
          <FiChevronUp className="w-6 h-6" onClick={handleDropdown} />
        ) : (
          <FiChevronDown className="w-6 h-6" onClick={handleDropdown} />
        )}{' '}
      </p>
      {showOption ? (
        <div className="absolute z-50 right-0 rounded-lg flex flex-col w-32 bg-white text-darkGreen">
          {dropDownOptions ? (
            <>
              {dropDownOptions.map((item, index) => {
                if (index === 0) {
                  return (
                    <p
                      className="flex justify-center items-center p-1 border-b border-darkGreen hover:bg-[#024728] hover:rounded-t-lg hover:text-white"
                      onClick={() => handleUnit(item)}
                    >
                      {item}
                    </p>
                  )
                } else if (index === dropDownOptions.length - 1) {
                  return (
                    <p
                      className="flex justify-center items-center p-1 border-b border-darkGreen hover:bg-[#024728] hover:rounded-b-lg hover:text-white"
                      onClick={() => handleUnit(item)}
                    >
                      {item}
                    </p>
                  )
                } else {
                  return (
                    <p
                      className="flex justify-center items-center p-1 border-b border-darkGreen hover:bg-[#024728] hover:text-white"
                      onClick={() => handleUnit(item)}
                    >
                      {item}
                    </p>
                  )
                }
              })}
            </>
          ) : (
            <p
              className="flex justify-center items-center p-1 border-b border-darkGreen hover:bg-[#024728] hover:rounded-lg hover:text-white"
              onClick={() => handleUnit(inputUnit)}
            >
              {inputUnit}
            </p>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default CarbonUnitInput
