import { ethers } from 'ethers';
import { Config } from '../../config.js';

export const burnACO2Rewards = async (tokenData, amount) => {
    try {
        // Filter out tokens with zero amounts and sort by amount in descending order
        tokenData = 
            tokenData.filter(token => parseFloat(token.erc_1155_amount) > 0)
                .sort((a, b) => parseFloat(b.erc_1155_amount) - parseFloat(a.erc_1155_amount))
                .map(token => {
                    return {
                        contract_address: token.contract_address,
                        decimals: token.decimals,
                        erc_1155_token_id: token.erc_1155_token_id,
                        erc_1155_amount: ethers.utils.formatUnits(token.erc_1155_amount, 18).substring(0, 8)
                    };
                });

        let remainingAmountBN = ethers.utils.parseUnits(amount.toString(), 18);

        let tokenIds = [];
        let amounts = [];

        // Iterate over the sorted token data until the remaining amount is depleted
        for (let token of tokenData) {
            if (remainingAmountBN.isZero()) break; // Exit early if no amount left to fulfill

            let amountBN = ethers.utils.parseUnits(token.erc_1155_amount, token.decimals);

            let tokenAmountBN = ethers.BigNumber.from(amountBN);
            if (tokenAmountBN.isZero()) continue; // Skip if the token amount is zero

            let sellAmountBN = (tokenAmountBN.lt(remainingAmountBN)) ? tokenAmountBN : remainingAmountBN;

            tokenIds.push(token.erc_1155_token_id.toString());
            amounts.push(sellAmountBN.toString());

            remainingAmountBN = remainingAmountBN.sub(sellAmountBN);
        }

        // Check if the requested amount was fully covered by the token balances
        if (!remainingAmountBN.isZero()) {
            throw new Error("Not enough token balance to cover the requested sell amount.");
        }

        const MAX_TOKENIDS_PER_TX = Number(Config().max_trees_per_tx);
        let argsArray = [];

        // Chunk the tokens and amounts into batches according to MAX_TOKENIDS_PER_TX
        const batchCount = Math.ceil(tokenIds.length / MAX_TOKENIDS_PER_TX);
        for (let batch = 0; batch < batchCount; batch++) {
            let startIdx = batch * MAX_TOKENIDS_PER_TX;
            let endIdx = startIdx + MAX_TOKENIDS_PER_TX;

            const batchTokenIds = tokenIds.slice(startIdx, endIdx);
            const batchAmounts = amounts.slice(startIdx, endIdx);

            const args = [batchTokenIds, batchAmounts];
            argsArray.push({ args: args });
        }
        
        return argsArray;

    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
}
