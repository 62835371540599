import React, { useState } from 'react'
import Tooltip from 'components/utils/Tooltip'
import Information from 'icons/Information'

const StakingCards = ({
  totalCBY,
  enoughToStakeTrees,
  totalNftrees,
  totalStakedNftrees,
  totalSpots,
  availableSpots,
  yearlyEstimatedRewards,
  totalGenesisPlots,
  totalPremiumPlots,
  totalStandardPlots
}) => {

  const [showTooltip, setShowTooltip] = useState({
    stakeTooltip: false,
    plotTooltip: false,
    cbyTooltip: false,
    rewardTooltip: false,
  })

  const handleTooltip = value => {
    setShowTooltip(prevState => ({
      ...prevState,
      [value]: true,
    }))
  }
  const handleCloseTooltip = value => {
    setShowTooltip(prevState => ({
      ...prevState,
      [value]: false,
    }))
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-3 mb-3">
      <div className="relative flex flex-col pb-6 justify-between min-h-[148px] border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
        <div>
          <div className="pl-6 pt-3 flex justify-start space-x-4">
            <p className="text-white font-medium text-lg font-montserrat">STAKED TREES</p>
            <div
              onMouseEnter={e => handleTooltip('stakeTooltip')}
              onMouseLeave={e => handleCloseTooltip('stakeTooltip')}
              className="pt-1"
            >
              <div className="absolute top-2 right-2 cursor-pointer">
                <Information />
              </div>
              {showTooltip.stakeTooltip ? (
                <Tooltip
                  text={
                    'The number of trees you own is listed here. And the number of trees locked in the staking program. For example, 12/50. This means 12 trees are locked in the staking program while you own 50 trees'
                  }
                />
              ) : null}
            </div>
          </div>
          { totalCBY === null || totalSpots === null || availableSpots === null || totalNftrees === null || yearlyEstimatedRewards === null ? (
            <div className="pl-6">
              <div className="w-20 animate-spin z-51">
                <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
              </div>
            </div>
          ) : (
            <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">
              {totalStakedNftrees === null ? "0" : totalStakedNftrees} / {totalNftrees} trees
            </p>
          )}
        </div>
      </div>

      <div className="relative flex flex-col pb-5 justify-between min-h-[148px] border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
        <div>
          <div className="pl-6 pt-3 flex justify-start space-x-4">
            <p className="text-white font-medium text-lg font-montserrat">PLOTS AVAILABILITY</p>
            <div
              onMouseEnter={e => handleTooltip('plotTooltip')}
              onMouseLeave={e => handleCloseTooltip('plotTooltip')}
              className="pt-1"
            >
              <div className="absolute top-2 right-2 cursor-pointer">
                <Information />
              </div>
              {showTooltip.plotTooltip ? (
                <Tooltip
                  text={
                    'The number of spots available on your land to plant trees. For example, A genesis plot will offer 50 spots. If this amount is zero, you need more plots to stake more trees'
                  }
                />
              ) : null}
            </div>
          </div>
          { totalCBY === null || totalSpots === null || availableSpots === null || totalNftrees === null || yearlyEstimatedRewards === null ? (
            <div className="pl-6">
              <div className="w-20 animate-spin z-51">
                <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
              </div>
            </div>
          ) : (
            <div>
              <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">
              {totalStakedNftrees} / {totalGenesisPlots === null || totalPremiumPlots === null || totalStandardPlots === null ? "0" : (Number(totalGenesisPlots) * 50) + (Number(totalPremiumPlots) * 30) + (Number(totalStandardPlots) * 15)} trees
              </p>
              <p className="pl-6 pt-1 text-white font-normal text-lg font-montserrat">trees staked / available spots</p>
            </div>
          )}
        </div>
      </div>

      <div className="relative flex flex-col pb-5 justify-between min-h-[148px] border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
        <div>
          <div className="pl-6 pt-3 space-x-4 flex justify-start">
            <p className="text-white font-medium text-lg font-montserrat">AVAILABLE $CBY</p>
            <div
              onMouseEnter={e => handleTooltip('cbyTooltip')}
              onMouseLeave={e => handleCloseTooltip('cbyTooltip')}
              className="pt-1"
            >
              <div className="absolute top-2 right-2 cursor-pointer">
                <Information />
              </div>
              {showTooltip.cbyTooltip ? (
                <Tooltip
                  text={
                    'The amount of $CBY tokens available for staking. You need $5 worth of $CBY to stake one tree. The amount of trees you can still stake based on the amount of $CBY you own is listed here'
                  }
                />
              ) : null}
            </div>
          </div>
          { totalCBY === null || totalSpots === null || availableSpots === null || totalNftrees === null || yearlyEstimatedRewards === null ? (
            <div className="pl-6">
              <div className="w-20 animate-spin z-51">
                <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
              </div>
            </div>
          ) : (
            <div>
              <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">
                {' '}
                {totalCBY?.toFixed(2)} $CBY{' '}
              </p>
              <p className="pl-6 pt-1 text-white font-normal text-lg font-montserrat">
                Enough to stake <span className="font-bold">{enoughToStakeTrees} NFTrees</span>
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="relative flex flex-col pb-5 justify-between min-h-[148px] border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
        <div>
          <div className="pl-6 pt-3 space-x-4 flex justify-start">
            <p className="text-white font-medium text-lg font-montserrat">YEARLY EST.REWARDS</p>
            <div
              onMouseEnter={e => handleTooltip('rewardTooltip')}
              onMouseLeave={e => handleCloseTooltip('rewardTooltip')}
              className="pt-1"
            >
              <div className="absolute top-2 right-2 cursor-pointer">
                <Information />
              </div>
              {showTooltip.rewardTooltip ? (
                  <Tooltip
                    text={
                      'The estimated $aCO2 rewards based on your staking efforts'
                    }
                  />
              ) : null}
            </div>
          </div>
          { totalCBY === null || totalSpots === null || availableSpots === null || totalNftrees === null || yearlyEstimatedRewards === null ? (
            <div className="pl-6">
              <div className="w-20 animate-spin z-51">
                <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
              </div>
            </div>
          ) : (
            <>
            <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">
              {yearlyEstimatedRewards === null || Number(yearlyEstimatedRewards) < 0  ? "0" : yearlyEstimatedRewards} $aC02
            </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default StakingCards
