import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarbonUnitInput from 'components/utils/CarbonUnitInput'
import CarbonDropdownInput from 'components/utils/CarbonDropdownInput'
import TotalFootprint from './TotalFootprint'

//reducers
import { setCarbonFootprint, setSecondaryFootprint } from 'features/footprint/footprintSlice'

//icons
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'

const Secondary = ({ onBack, onNext, setPage }) => {
  const CO2_EMISSION_FACTORS = {
    foodAndDrinks: 0.6, //     1 euro of food 0.6
    pharmaAndInsurance: 2.27, // 1 euro of pharmacy (medicines) 2.27
    clothesAndShoes: 0.35, // 1 euro of textile and shoes 0.35
    paperProducts: 0.24, // 1 euro of paper products (newspapers/magazines) 0.24
    tvAndIT: 1, // 1 euro of computer equipment 1, 1 euro of TV/radio/telephone 1
    motorVehicle: 0.26, // 1 euro of vehicle parts 0.26
    furnitureAndManufacturing: 0.27, // 1 euro of furniture 0.27
    hotelsAndRestaurants: 0.32, // 1 euro of hotels/pubs 0.32
    bankingAndFinance: 0.34, // 1 euro of bank/insurance 0.34, 1 euro of insurance 0.15
    telephone: 0.21, // 1 euro of mobile costs 0.21
    recreational: 0.28, // 1 euro of recreation 0.28
    education: 0.22, // 1 euro of education 0.22
  }

  const CURRENCIES = {
    euro: 1,
    gbr: 0.88,
    usd: 1.09,
    jpy: 145.23,
    pln: 4.68,
  }

  const DIETS = {
    low: 1,
    medium: 1.25,
    high: 1.5,
    veg: 0.875,
    fish: 0.875,
    vegan: 0.6,
  }

  const timeUnits = ['per year', 'per month', 'per week']

  const currencyTypes = ['€ EUR', '£ GBR', '$ USD', '¥ JPY', 'zł PLN']
  const dietTypes = ['Low meat eater', 'Medium meat eater', 'High meat eater', 'vegeterian', 'Fish Eater', 'Vegan']
  const [foodUnit, setFoodUnit] = useState(timeUnits[0])
  const [pharmaUnit, setPharmaUnit] = useState(timeUnits[0])
  const [clothesUnit, setClothesUnit] = useState(timeUnits[0])
  const [paperUnit, setPaperUnit] = useState(timeUnits[0])
  const [tvUnit, setTvUnit] = useState(timeUnits[0])
  const [motorUnit, setMotorUnit] = useState(timeUnits[0])
  const [furnitureUnit, setFurnitureUnit] = useState(timeUnits[0])
  const [hotelUnit, setHotelUnit] = useState(timeUnits[0])
  const [bankUnit, setBankUnit] = useState(timeUnits[0])
  const [telephoneUnit, setTelephoneUnit] = useState(timeUnits[0])
  const [recreationalUnit, setRecreationalUnit] = useState(timeUnits[0])
  const [educationUnit, setEducationUnit] = useState(timeUnits[0])

  const [foodCalculation, setFoodCalculation] = useState(0)
  const [pharmaCalculation, setPharmaCalculation] = useState(0)
  const [clothesCalculation, setClothesCalculation] = useState(0)
  const [paperCalculation, setPaperCalculation] = useState(0)
  const [tvCalculation, setTvCalculation] = useState(0)
  const [motorCalculation, setMotorCalculation] = useState(0)
  const [furnitureCalculation, setFurnitureCalculation] = useState(0)
  const [hotelCalculation, setHotelCalculation] = useState(0)
  const [bankCalculation, setBankCalculation] = useState(0)
  const [telephoneCalculation, setTelephoneCalculation] = useState(0)
  const [recreationalCalculation, setRecreationalCalculation] = useState(0)
  const [educationCalculation, setEducationCalculation] = useState(0)
  const [currencyCalculation, setCurrencyCalculation] = useState('')

  const [sum, setSum] = useState(0)

  const [formData, setFormData] = useState({
    currency: currencyTypes[0],
    diet: dietTypes[0],
    foodAndDrinks: 0,
    pharmaAndInsurance: 0,
    clothesAndShoes: 0,
    paperProducts: 0,
    tvAndIT: 0,
    motorVehicle: 0,
    furnitureAndManufacturing: 0,
    hotelsAndRestaurants: 0,
    bankingAndFinance: 0,
    telephone: 0,
    recreational: 0,
    education: 0,
  })
  const [isFirstRendering, setIsFirstRendering] = useState(true)

  const {
    currency,
    diet,
    foodAndDrinks,
    pharmaAndInsurance,
    clothesAndShoes,
    paperProducts,
    tvAndIT,
    motorVehicle,
    furnitureAndManufacturing,
    hotelsAndRestaurants,
    bankingAndFinance,
    telephone,
    recreational,
    education,
  } = formData
  const { carbonFootprint, secondaryFootprint } = useSelector(state => state.footprint)

  const dispatch = useDispatch()

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleNavigation = type => {
    setPage(false)
    if (type === 'Next') {
      onNext(true)
    } else {
      onBack(true)
    }
  }

  const handleTimeCalculation = (unit, calculation, factor) => {
    if (unit === timeUnits[0]) {
      return calculation * factor
    } else if (unit === timeUnits[1]) {
      return (calculation * factor) / 12
    } else if (unit === timeUnits[2]) {
      return (calculation * factor) / 52
    }
  }

  const calculateDietFood = (fvalue, dvalue) => {
    if (dvalue === dietTypes[0]) {
      //for low meat eater
      return fvalue * DIETS.low
    } else if (dvalue === dietTypes[1]) {
      // for medium meat eater
      return fvalue * DIETS.medium
    } else if (dvalue === dietTypes[2]) {
      // for high meat eater
      return fvalue * DIETS.high
    } else if (dvalue === dietTypes[3]) {
      // for vegeterian
      return fvalue * DIETS.veg
    } else if (dvalue === dietTypes[4]) {
      // for fish eater
      return fvalue * DIETS.fish
    } else {
      return fvalue * DIETS.vegan // for vegan
    }
  }

  const handleCalculation = e => {
    e.preventDefault()
    const hotelCarbon = handleTimeCalculation(
      hotelUnit,
      hotelsAndRestaurants,
      CO2_EMISSION_FACTORS.hotelsAndRestaurants,
    )
    setHotelCalculation(hotelCarbon)
    const furnitureCarbon = handleTimeCalculation(
      furnitureUnit,
      furnitureAndManufacturing,
      CO2_EMISSION_FACTORS.furnitureAndManufacturing,
    )
    setFurnitureCalculation(furnitureCarbon)
    const motorCarbon = handleTimeCalculation(motorUnit, motorVehicle, CO2_EMISSION_FACTORS.motorVehicle)
    setMotorCalculation(motorCarbon)
    const clothesCarbon = handleTimeCalculation(clothesUnit, clothesAndShoes, CO2_EMISSION_FACTORS.clothesAndShoes)
    setClothesCalculation(clothesCarbon)
    const tvCarbon = handleTimeCalculation(tvUnit, tvAndIT, CO2_EMISSION_FACTORS.tvAndIT)
    setTvCalculation(tvCarbon)
    const dietFood = calculateDietFood(foodAndDrinks, diet)
    const foodCarbon = handleTimeCalculation(foodUnit, dietFood, CO2_EMISSION_FACTORS.foodAndDrinks)
    setFoodCalculation(foodCarbon)
    const pharmaCarbon = handleTimeCalculation(pharmaUnit, pharmaAndInsurance, CO2_EMISSION_FACTORS.pharmaAndInsurance)
    setPharmaCalculation(pharmaCarbon)
    const paperCarbon = handleTimeCalculation(paperUnit, paperProducts, CO2_EMISSION_FACTORS.paperProducts)
    setPaperCalculation(paperCarbon)
    const bankCarbon = handleTimeCalculation(bankUnit, bankingAndFinance, CO2_EMISSION_FACTORS.bankingAndFinance)
    setBankCalculation(bankCarbon)
    const telephoneCarbon = handleTimeCalculation(telephoneUnit, telephone, CO2_EMISSION_FACTORS.telephone)
    setTelephoneCalculation(telephoneCarbon)
    const recreationalCarbon = handleTimeCalculation(recreationalUnit, recreational, CO2_EMISSION_FACTORS.recreational)
    setRecreationalCalculation(recreationalCarbon)
    const educationCarbon = handleTimeCalculation(educationUnit, education, CO2_EMISSION_FACTORS.education)
    setEducationCalculation(educationCarbon)
    setCurrencyCalculation(currency)
  }

  const offsetSecondaryFootprint = () => {
    dispatch(setSecondaryFootprint(0))
    setFormData({
      currency: currencyTypes[0],
      diet: dietTypes[0],
      foodAndDrinks: 0,
      pharmaAndInsurance: 0,
      clothesAndShoes: 0,
      paperProducts: 0,
      tvAndIT: 0,
      motorVehicle: 0,
      furnitureAndManufacturing: 0,
      hotelsAndRestaurants: 0,
      bankingAndFinance: 0,
      telephone: 0,
      recreational: 0,
      education: 0,
    })
    window.open('https://carbondebits.io/shop/', '_blank')
  }

  const convertCurrency = (value, curr) => {
    if (curr === currencyTypes[0]) {
      // for EUR
      return value
    } else if (curr === currencyTypes[1]) {
      // for GBR
      return value / CURRENCIES.gbr
    } else if (curr === currencyTypes[2]) {
      // for USD
      return value / CURRENCIES.usd
    } else if (curr === currencyTypes[3]) {
      // for JPY
      return value / CURRENCIES.jpy
    } else if (curr === currencyTypes[4]) {
      // for PLN
      return value / CURRENCIES.pln
    }
  }

  const handleCancelElement = element => {
    let result = secondaryFootprint * 1000
    result = convertCurrency(result, currency)
    if (element === 'food') {
      result = result - foodCalculation
      setFormData({ ...formData, foodAndDrinks: 0 })
      setFoodCalculation(0)
    } else if (element === 'pharma') {
      result = result - pharmaCalculation
      setFormData({ ...formData, pharmaAndInsurance: 0 })
      setPharmaCalculation(0)
    } else if (element === 'clothes') {
      result = result - clothesCalculation
      setFormData({ ...formData, clothesAndShoes: 0 })
      setClothesCalculation(0)
    } else if (element === 'paper') {
      result = result - paperCalculation
      setFormData({ ...formData, paperProducts: 0 })
      setPaperCalculation(0)
    } else if (element === 'tv') {
      result = result - tvCalculation
      setFormData({ ...formData, tvAndIT: 0 })
      setTvCalculation(0)
    } else if (element === 'motor') {
      result = result - motorCalculation
      setFormData({ ...formData, motorVehicle: 0 })
      setMotorCalculation(0)
    } else if (element === 'furniture') {
      result = result - furnitureCalculation
      setFormData({ ...formData, furnitureAndManufacturing: 0 })
      setFurnitureCalculation(0)
    } else if (element === 'hotel') {
      result = result - hotelCalculation
      setFormData({ ...formData, hotelsAndRestaurants: 0 })
      setHotelCalculation(0)
    } else if (element === 'bank') {
      result = result - bankCalculation
      setFormData({ ...formData, bankingAndFinance: 0 })
      setBankCalculation(0)
    } else if (element === 'telephone') {
      result = result - telephoneCalculation
      setFormData({ ...formData, telephone: 0 })
      setTelephoneCalculation(0)
    } else if (element === 'recreational') {
      result = result - recreationalCalculation
      setFormData({ ...formData, recreational: 0 })
      setRecreationalCalculation(0)
    } else if (element === 'education') {
      result = result - educationCalculation
      setFormData({ ...formData, education: 0 })
      setEducationCalculation(0)
    }
    dispatch(setSecondaryFootprint(result / 1000))
  }

  useEffect(() => {
    if (!isFirstRendering) {
      let carbon = 0
      const newSum =
        hotelCalculation +
        furnitureCalculation +
        motorCalculation +
        clothesCalculation +
        tvCalculation +
        foodCalculation +
        pharmaCalculation +
        paperCalculation +
        bankCalculation +
        telephoneCalculation +
        recreationalCalculation +
        educationCalculation
      if (currency === currencyTypes[2]) {
        // for USD
        carbon = newSum * CURRENCIES.usd
      } else if (currency === currencyTypes[1]) {
        //for GBR
        carbon = newSum * CURRENCIES.gbr
      } else if (currency === currencyTypes[3]) {
        //for JPY
        carbon = newSum * CURRENCIES.jpy
      } else if (currency === currencyTypes[4]) {
        // for PLN
        carbon = newSum * CURRENCIES.pln
      } else {
        carbon = newSum // for EUR
      }
      setSum(carbon)
    } else {
      setIsFirstRendering(false)
    }
  }, [
    hotelCalculation,
    furnitureCalculation,
    motorCalculation,
    clothesCalculation,
    tvCalculation,
    foodCalculation,
    pharmaCalculation,
    paperCalculation,
    bankCalculation,
    telephoneCalculation,
    recreationalCalculation,
    educationCalculation,
    currencyCalculation,
  ])

  useEffect(() => {
    if (!isFirstRendering) {
      dispatch(setSecondaryFootprint(sum / 1000))
    } else {
      setIsFirstRendering(false)
    }
  }, [sum])

  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [secondaryFootprint])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      <div className="flex flex-col xl:w-[800px] 2xl:max-3xl:w-[650px] md:h-[724px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
          <div className="font-semibold text-xl">How much do you spend on each of the following?</div>
          <p className="text-base font-normal text-white text-opacity-80 tracking-wider">
            Enter your amount for each category below, and press the Calculate button
          </p>
        </div>

        <div className="pt-2 h-full">
          <form className="flex flex-col justify-between h-full" onSubmit={handleCalculation}>
            <div className="flex flex-col space-y-3 text-base font-normal text-white text-opacity-80 lg:max-xl:h-[525px]">
              <div className="flex flex-col space-y-2 pb-4">
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonDropdownInput
                    label={'Choose your currency'}
                    name={'currency'}
                    value={currency}
                    dropDownOptions={currencyTypes}
                    onChange={onChange}
                  />
                  <CarbonDropdownInput
                    label={'Diet'}
                    name={'diet'}
                    value={diet}
                    dropDownOptions={dietTypes}
                    onChange={onChange}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Food and drink products'}
                    name={'foodAndDrinks'}
                    value={foodAndDrinks}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setFoodUnit}
                  />
                  <CarbonUnitInput
                    label={'Pharmaceuticals and insurance'}
                    name={'pharmaAndInsurance'}
                    value={pharmaAndInsurance}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setPharmaUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Clothes, textiles and shoes'}
                    name={'clothesAndShoes'}
                    value={clothesAndShoes}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setClothesUnit}
                  />
                  <CarbonUnitInput
                    label={'Paper based products'}
                    name={'paperProducts'}
                    value={paperProducts}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setPaperUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Television, IT, radio and phone (eqp)'}
                    name={'tvAndIT'}
                    value={tvAndIT}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setTvUnit}
                  />
                  <CarbonUnitInput
                    label={'Motor vehicles (not including fuel costs)'}
                    name={'motorVehicle'}
                    value={motorVehicle}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setMotorUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Furniture and other manufactured goods'}
                    name={'furnitureAndManufacturing'}
                    value={furnitureAndManufacturing}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setFurnitureUnit}
                  />
                  <CarbonUnitInput
                    label={'Hotels, restaurants, and pubs etc.'}
                    name={'hotelsAndRestaurants'}
                    value={hotelsAndRestaurants}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setHotelUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Banking and finance'}
                    name={'bankingAndFinance'}
                    value={bankingAndFinance}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setBankUnit}
                  />
                  <CarbonUnitInput
                    label={'Telephone, mobile/cell phone call costs'}
                    name={'telephone'}
                    value={telephone}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setTelephoneUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label={'Recreational, cultural and sport activities'}
                    name={'recreational'}
                    value={recreational}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setRecreationalUnit}
                  />
                  <CarbonUnitInput
                    label={'Education'}
                    name={'education'}
                    value={education}
                    dropDownOptions={timeUnits}
                    onChange={onChange}
                    onUnitChange={setEducationUnit}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="bg-carbifyOrange text-bt font-bold text-base text-center w-full h-12 rounded-lg hover:bg-light_green hover:text-white"
              >
                Calculate & Add To Footprint
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <TotalFootprint
            title={'Secondary'}
            individualFootprint={secondaryFootprint}
            totalFootprint={carbonFootprint}
            offsetFootprint={offsetSecondaryFootprint}
          />

          <div className="flex flex-col py-3">
            {foodAndDrinks > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {foodAndDrinks} {foodUnit}
                  </b>{' '}
                  of Food and Drinks
                </p>
                <button
                  onClick={() => handleCancelElement('food')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {pharmaAndInsurance > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {pharmaAndInsurance} {pharmaUnit}
                  </b>{' '}
                  of Pharmaceuticals
                </p>
                <button
                  onClick={() => handleCancelElement('pharma')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {clothesAndShoes > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {clothesAndShoes} {clothesUnit}
                  </b>{' '}
                  of Clothes and Shoes
                </p>
                <button
                  onClick={() => handleCancelElement('clothes')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {paperProducts > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {paperProducts} {paperUnit}
                  </b>{' '}
                  of Paper based products
                </p>
                <button
                  onClick={() => handleCancelElement('paper')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {tvAndIT > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {tvAndIT} {tvUnit}
                  </b>{' '}
                  of Television, radio and phone
                </p>
                <button
                  onClick={() => handleCancelElement('tv')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {motorVehicle > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {motorVehicle} {motorUnit}
                  </b>{' '}
                  of Motor vehicle
                </p>
                <button
                  onClick={() => handleCancelElement('motor')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {furnitureAndManufacturing > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {furnitureAndManufacturing} {furnitureUnit}
                  </b>{' '}
                  of Furniture and manufacturing
                </p>
                <button
                  onClick={() => handleCancelElement('furniture')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {hotelsAndRestaurants > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {hotelsAndRestaurants} {hotelUnit}
                  </b>{' '}
                  of Hotels, Restaurants and pubs
                </p>
                <button
                  onClick={() => handleCancelElement('hotel')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {bankingAndFinance > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {bankingAndFinance} {bankUnit}
                  </b>{' '}
                  of Banking and Finance
                </p>
                <button
                  onClick={() => handleCancelElement('bank')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {telephone > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {telephone} {telephoneUnit}
                  </b>{' '}
                  of telephone call costs
                </p>
                <button
                  onClick={() => handleCancelElement('telephone')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {recreational ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {recreational} {recreationalUnit}
                  </b>{' '}
                  of Recreational activities
                </p>
                <button
                  onClick={() => handleCancelElement('recreational')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
            {education > 0 ? (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {currency} {education} {educationUnit}
                  </b>{' '}
                  of Education
                </p>
                <button
                  onClick={() => handleCancelElement('education')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex justify-between xxxs:mt-3">
          <div className="flex items-center justify-center pr-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Secondary
