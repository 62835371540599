import { createSlice } from '@reduxjs/toolkit'

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    showAlert: false,
  },
  reducers: {
    setAlert: state => {
      state.showAlert = true
    },
    resetAlert: state => {
      state.showAlert = false
    },
  },
})

export const { setAlert, resetAlert } = alertSlice.actions

export default alertSlice.reducer
