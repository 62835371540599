import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'

const initialState = {
  listedNftsOffers: [],
  listedNftsOffersLoading: false,
  error: null,
}

export const resetNftOffers = createAction('REVERT_ALL')
export const getListedNftsOffers = createAsyncThunk(
  'listedNftsOffers/getListedNftsOffers',
  async (listing_id, thunkAPI) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL4}/api/nft/listing/offers?listing=${listing_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const listedNftsOffersSlice = createSlice({
  name: 'listedNftsOffers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListedNftsOffers.pending, (state, action) => {
        state.listedNftsOffersLoading = true
        state.error = null
      })
      .addCase(getListedNftsOffers.fulfilled, (state, action) => {
        // console.log('Offer Loading has been fulfilled', action.payload)
        state.listedNftsOffersLoading = false
        state.listedNftsOffers = action.payload
      })
      .addCase(getListedNftsOffers.rejected, (state, action) => {
        // console.log("Offer Loading has been rejected");
        state.listedNftsOffersLoading = false
        state.error = action.payload.detail
      })
      .addCase(resetNftOffers, () => initialState)
  },
})

export const {} = listedNftsOffersSlice.actions
export default listedNftsOffersSlice.reducer
