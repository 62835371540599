import React, { useEffect, useState } from 'react'
import ListingCard from './cards/ListingCard'
import { CBY_ADDRESS } from 'constants/BlockchainConstants'
import { getAccount } from '@wagmi/core'
import moment from 'moment'
import { config } from 'BlockchainConfig'

function OtherListings({ listings, sortValue }) {
  const sortingCategories = [
    'Price low to high',
    'Price high to low',
    'Recently listed',
    'Oldest',
    'Best Offer',
    'My Listings',
    'Expired',
    'All',
    'Active',
    // 'Sold',
  ]

  const ids = [32, 50, 54, 60, 61, 65, 73, 74]

  const [cards, setCards] = useState([])
  const account = getAccount(config)

  useEffect(() => {
    if (listings) {
      let values = [...listings]
      if (sortValue === sortingCategories[0]) {
        values = values.sort((a, b) => {
          return a.buyoutPricePerToken - b.buyoutPricePerToken
        })
      } else if (sortValue === sortingCategories[1]) {
        values = values.sort((a, b) => {
          return b.buyoutPricePerToken - a.buyoutPricePerToken
        })
      } else if (sortValue === sortingCategories[2]) {
        values = values.sort((a, b) => {
          return b.startTime - a.startTime
        })
      } else if (sortValue === sortingCategories[3]) {
        values = values.sort((a, b) => {
          return a.startTime - b.startTime
        })
      } else if (sortValue === sortingCategories[4]) {
        values = values.sort((a, b) => {
          return b.bestOffer - a.bestOffer
        })
      } else if (sortValue === sortingCategories[5]) {
        values = values.filter(listing => {
          return listing.lister.toString().toLowerCase() === account.address.toString().toLowerCase()
        })

        console.log(account)
      } else if (sortValue === sortingCategories[6]) {
        values = values.filter(listing => {
          return listing.endTime <= moment().unix()
        })
      } else if (sortValue === sortingCategories[8]) {
        values = values.filter(listing => {
          return listing.endTime >= moment().unix()
        })
      }

      values = values.filter(listing => {
        return !ids.includes(parseInt(listing.listingId))
      })

      setCards([...values])
      // else if (sortValue === sortingCategories[9]) {
      //   let values = [...listings]
      //   values = values.filter(listing => {
      //     return listing.sold === true
      //   })
      //   setCards([...values])
      // }
    }
  }, [sortValue, listings])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-3 gap-y-3 mb-6">
        {cards?.map(listing => (
          <ListingCard
            key={listing.id}
            listingId={listing.listingId}
            title={listing?.metadata?.name || 'Standard'}
            image={listing?.metadata?.image}
            quantity={listing.quantity}
            currency={listing.currency.toLowerCase()}
            price={
              listing.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                ? listing.buyoutPricePerToken / 10 ** 18
                : listing.buyoutPricePerToken / 10 ** 18
            }
            offer={
              listing.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                ? listing.bestOffer / 10 ** 18
                : listing.bestOffer / 10 ** 18
            }
            startTime={listing.startTime}
            endTime={listing.endTime}
            auction={listing.listingType === 0 ? 'Fixed Price' : 'Bid Auction'}
          />
        ))}
      </div>
    </>
  )
}

export default OtherListings
