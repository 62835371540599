import React, { useState } from 'react'
import Information from '../../icons/Information'
import Tooltip from 'components/utils/Tooltip'

const Card = ({ cardHeading, loading, bottomHeading, amount, CBYsInDollars, tooltipInfo }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const handleTooltip = () => {
    setShowTooltip(true)
  }
  const handleCloseTooltip = () => {
    setShowTooltip(false)
  }
  return (
    <div className="relative flex flex-col pb-6 justify-between w-full border rounded-lg shadow-lg bg-darkGreen bg-opacity-70 group">
      <div>
        <div className="pl-6 pt-3 flex justify-start space-x-4">
          <p className="text-white font-medium text-lg font-montserrat">{cardHeading}</p>
          <div onMouseEnter={handleTooltip} onMouseLeave={handleCloseTooltip} className="pt-1 cursor-pointer">
            <div className="absolute right-2 top-2">
              <Information />
            </div>
            {showTooltip ? <Tooltip text={tooltipInfo} /> : null}
          </div>
        </div>
        {loading ? (
          <div className="pl-6">
            <div className="w-20 animate-spin z-51">
              <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
            </div>
          </div>
        ) : (
          <div>
            <p className="pl-6 pt-4 text-white text-3xl font-extrabold font-montserrat">{amount}</p>
            <p className="pl-6 pt-1 text-white font-normal text-lg font-montserrat">{CBYsInDollars}</p>
          </div>
        )}
        <p className="pl-6 font-normal text-xs">{bottomHeading}</p>
      </div>
    </div>
  )
}

export default Card
