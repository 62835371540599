"use client";

import React, { useEffect, useState, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterLines1 } from "../components/TreeOverview/icons/FilterLines1";
import { ChevronDown5, ChevronLeft5, ChevronRight, ChevronUp5 } from "../components/TreeOverview/icons/ChevronDown5";
import { CustomCheckbox } from "../components/TreeOverview/CheckBox";
import Layout from "layouts/Layout";
import PageHeading from "components/PageHeading";
import { fetchGlobalClusterData, fetchOverviewData, setSelectedTree, setSelectedCluster, fetchDetailsData } from "../features/tree-map/treeSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "components/utils/Spinner";

const Map = lazy(() => import('../components/map-tree/Map'));

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const formatCoordinates = (geo_location) => {
    if (!geo_location) return '-';
    const [latitude, longitude] = geo_location.split(' ').map(coord => parseFloat(coord).toFixed(6));
    return `${latitude} ${longitude}`;
};

const MapTree = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [batchLoading, setBatchLoading] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const { overviewData, detailsData, globalClusterData, selectedCluster, selectedTree, selectedFilters } = useSelector((state) => state.tree);


    // Initial data fetching
    useEffect(() => {
        if (overviewData === null) {
            dispatch(fetchOverviewData());
        }
        if (detailsData === null) {
            dispatch(fetchDetailsData());
        }
        if (globalClusterData === null) {
            dispatch(fetchGlobalClusterData());
        }
        if (selectedTree !== null && globalClusterData !== null) {
            dispatch(setSelectedCluster(globalClusterData.find(cluster => cluster.cluster_id === selectedTree.cluster_id)));
        }
        if (selectedTree === null && globalClusterData !== null && detailsData !== null) {
            dispatch(setSelectedCluster(globalClusterData.find(cluster => cluster.cluster_id === detailsData[0].cluster_id)));
        }

    }, [dispatch]);

    // Fetching data based on selectedCluster and selectedFilters
    useEffect(() => {
        const fetchData = async () => {
          setBatchLoading(true);
          try {
            if (selectedCluster !== null && selectedFilters.length === 0) {
              await dispatch(fetchDetailsData({ clusterId: selectedCluster.cluster_id, filters: [] }));
            } else if (selectedCluster !== null && selectedFilters.length > 0) {
              await dispatch(fetchDetailsData({ clusterId: selectedCluster.cluster_id, filters: selectedFilters }));
            }
          } catch (error) {
            console.error("Failed to fetch data", error);
          } finally {
            setBatchLoading(false);
          }
        };
      
        fetchData();
      }, [selectedCluster, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
          setBatchLoading(true);
          try {
            if (selectedFilters.length > 0) {
              await dispatch(fetchGlobalClusterData(selectedFilters));
            } else {
              await dispatch(fetchGlobalClusterData());
            }
          } catch (error) {
            console.error("Failed to fetch global cluster data", error);
          } finally {
            setBatchLoading(false);
          }
        };
      
        fetchData();
    }, [selectedFilters, dispatch]);

    useEffect(() => {
        if (detailsData) {
            dispatch(setSelectedTree(detailsData[0]));
        }
    }, [detailsData, dispatch])

    return (
        <>
            { batchLoading === true && <Spinner /> }
            <Layout title="Carbify | Map Tree" content="Map Tree Overview">
                <PageHeading>Map Tree Overview</PageHeading>
                <div className="flex flex-col lg:flex-row gap-8 pt-8 pb-12 sm:px-4 relative bg-cover bg-no-repeat bg-center">
                    <div className="flex flex-col w-full lg:w-1/2 items-start gap-4  relative  overflow-hidden">
                        <div className="relative w-full max-w-2xl " >
                            <div className="flex items-center gap-2.5 px-4 py-2 bg-[#23232326] rounded-lg border border-solid  " onClick={() => setShowFilters(!showFilters)}>
                                <FilterLines1 className="!relative !w-6 !h-6" color="white" />
                                <div className="relative w-fit [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#ffffff] text-base tracking-[0.08px] leading-6 whitespace-nowrap">
                                    Filters
                                </div>
                                {showFilters ? <ChevronUp5 className="!relative !w-6 !h-6 ml-auto" color="white" /> : <ChevronDown5 className="!relative !w-6 !h-6 ml-auto" color="white" />}
                            </div>
                            {showFilters && (
                                <div className="absolute top-full left-0 w-full bg-[#232323] rounded-lg shadow-lg z-10 border border-solid border-orange mt-2 select-none p-4">
                                    <div className="relative w-fit mt-1 mb-2 [font-family:'Montserrat',Helvetica] font-bold text-[#ffffff] text-base tracking-[0.08px] leading-6 whitespace-nowrap">
                                        By batch
                                    </div>
                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                        <CustomCheckbox label="Carbify Genesis NFTree" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #2" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #3" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #4" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #5" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #6" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #7" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #8" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #9" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #10" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #11" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #12" selectedFilters={selectedFilters} />
                                        <CustomCheckbox label="Carbify NFTree #13" selectedFilters={selectedFilters} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-full items-start gap-4 relative flex-1 grow p-6 bg-darkGreen bg-opacity-70 border rounded-lg">
                            <div className="flex justify-between items-center w-full gap-2.5 mb-4">
                                <div
                                    className="flex items-center gap-1.5 pr-3 py-2  border-solid border-white rounded-lg cursor-pointer hover-text-orange hover-stroke-orange"
                                    onClick={() => navigate(`/tree-overview`)}
                                >
                                    <ChevronLeft5 className="w-5 h-5" color="white" />
                                    <span className="font-medium text-white text-lg tracking-[0.07px] leading-[16.8px] hover:text-carbifyOrange">
                                        Back to list
                                    </span>
                                </div>
                                <div className="flex flex-col items-end">
                                    <div className="font-bold text-3xl text-white tracking-[0.08px] leading-6">
                                        Tree ID: {selectedTree?.nftree_id === undefined ? '-' : "#" + selectedTree.nftree_id}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-5 w-full">
                                {[
                                    { label: 'Planted by', value: 'Carbify' },
                                    { label: 'Audited by', value: 'the Global Carbon Standard' },
                                    { label: 'CO2 claimed by the owner', value: selectedTree?.aco2_claimed?.toFixed(2) ?? '-' },
                                    { label: 'CO2 used for carbon offsets', value: selectedTree?.aco2_burned_in_certificates?.toFixed(2) ?? '-' },
                                    { label: 'CO2 available to claim', value: selectedTree?.aco2_unclaimed?.toFixed(2) ?? '-' },
                                    { label: 'GEO Location', value: selectedTree?.geo_location ? formatCoordinates(selectedTree.geo_location) : '-' },
                                    { label: 'Batch number', value: selectedTree?.batch_number ?? '-' },
                                    { label: 'Planting date', value: selectedTree?.start_time ? formatDate(selectedTree.start_time) : '-' }
                                ].map((item, index) => (
                                    <div className="flex justify-between w-full" key={index}>
                                        <div className="font-medium text-[#ffffffbf] text-base">{item.label}</div>
                                        <div className="font-semibold text-white text-base">{item.value}</div>
                                    </div>
                                ))}
                            </div>

                            <div className="flex w-full justify-between items-center gap-5 mt-6">
                                <button
                                    className="w-1/3 flex items-center justify-center gap-1.5 px-3 py-2 border rounded-lg cursor-pointer text-white font-bold text-sm tracking-[0.07px] leading-[16.8px] hover:bg-carbifyOrange hover:border-carbifyOrange"
                                    onClick={() => {
                                        const selectedId = Number(selectedTree?.nftree_id);
                                        const selectedBatch = Number(selectedTree?.batch_number);
                                        const index = detailsData.findIndex(tree =>
                                            Number(tree.nftree_id) === selectedId && Number(tree.batch_number) === selectedBatch
                                        );
                                        if (index !== -1) {
                                            const newIndex = (index - 1 + detailsData.length) % detailsData.length;
                                            dispatch(setSelectedTree(detailsData[newIndex]));
                                        } else {
                                            dispatch(setSelectedTree(detailsData[detailsData.length - 1]));
                                        }
                                    }}
                                >
                                    <ChevronLeft5 className="w-5 h-5 white" color="white" />
                                    <span>Previous</span>
                                </button>
                                <button
                                    className="w-1/3 flex items-center justify-center gap-1.5 px-3 py-2 bg-white rounded-lg cursor-pointer text-green font-bold text-sm tracking-[0.07px] leading-[16.8px] hover:bg-carbifyOrange border hover:border-carbifyOrange hover:text-white hover-stroke-white"
                                    onClick={() => {
                                        const selectedId = Number(selectedTree?.nftree_id);
                                        const selectedBatch = Number(selectedTree?.batch_number);
                                        const index = detailsData.findIndex(tree =>
                                            Number(tree.nftree_id) === selectedId && Number(tree.batch_number) === selectedBatch
                                        );
                                        console.log("index", index);
                                        if (index !== -1) {
                                            const newIndex = (index + 1) % detailsData.length;
                                            dispatch(setSelectedTree(detailsData[newIndex]));
                                        } else {
                                            dispatch(setSelectedTree(detailsData[0]));
                                        }
                                    }}
                                >
                                    <span>Next</span>
                                    <ChevronRight className="w-5 h-5" color="#21563e" />
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="relative w-full lg:w-1/2 h-[600px] border rounded-lg overflow-hidden z-0">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Map
                                globalClusterData={globalClusterData}
                                selectedCluster={selectedCluster}
                                setSelectedCluster={setSelectedCluster}
                            />
                        </Suspense>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default MapTree;
