import React, { useEffect, useState } from 'react'
import { useAccount, useChainId, useSwitchChain } from 'wagmi'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Divider } from '@mui/material'

const NetworkSwitcher = () => {
  const targetChainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  const { isConnected } = useAccount()
  const chainId = useChainId()
  const { switchChain } = useSwitchChain()
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const checkChain = async () => {
      if (!isConnected || !chainId) return

      if (chainId !== targetChainId) {
        setShowAlert(true)
      } else {
        setShowAlert(false)
      }
    }

    checkChain()
  }, [chainId, isConnected, targetChainId])

  const handleSwitchChain = async () => {
    if (switchChain) {
      try {
        switchChain({ chainId: targetChainId })
      } catch (error) {
        console.error('Failed to switch network:', error)
      }
    }
  }

  return (
    <>
      {showAlert && (
        <Dialog
          open={showAlert}
          onClose={null} // Disable default close behavior
          disableBackdropClick // Disable closing on backdrop click
          disableEscapeKeyDown // Disable closing on pressing the Escape key
          PaperProps={{
            style: {
              backgroundColor: '#083D25',  // Matches the background color of the second switcher
              color: '#F8952D',  // Matches the text color of the second switcher
            }
          }}
        >
          <DialogTitle>Please Switch Network</DialogTitle>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Divider sx={{ width: '100%' }} />
          </Box>
          <DialogContent>
            <DialogContentText sx={{ color: '#AEB7C0' }}>
              Please switch to the correct chain ID in your wallet if you wish to proceed. Or press the button below to switch to Chain ID {targetChainId}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleSwitchChain} 
              sx={{
                color: '#F8952D', 
                '&:hover': {
                  backgroundColor: '#f8952d4d',  // Matching the hover effect
                },
                fontWeight: 'bold'
              }}
            >
              Switch Chain
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default NetworkSwitcher
