import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { twoFactorAuth } from "features/login/twoFactorAuth";
import { twoFactorAuthVerify } from "features/login/verifyTwoFactorAuth";
import AuthLayout from "layouts/AuthLayout";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TwoFactorAuthenticator = () => {
  const [code, setCode] = useState(Array(6).fill(""));
  const codeSentRef = useRef(false);

  const { userInfo } = useSelector((state) => state.authentication);

  const navigate = useNavigate();

  const handleChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next field automatically
      if (value && index < 5) {
        const nextSibling = document.querySelector(
          `input[name=code-${index + 1}]`,
        );
        if (nextSibling) {
          nextSibling.focus();
        }
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text").slice(0, 6);
    const newCode = pasteData.split("");
    setCode(newCode);

    // Automatically fill out the fields with the pasted code
    newCode.forEach((value, index) => {
      const input = document.querySelector(`input[name=code-${index}]`);
      if (input) {
        input.value = value;
      }
    });
  };

  const handleResend = () => {
    if (userInfo && userInfo.email) {
      twoFactorAuth(userInfo.email).then((response) => {
        console.log("2FA response:", response);
        if (response && response.message === "2FA code sent to your email") {
          toast.success("Code sent successfully");
        } else {
          toast.error("Failed to send code. Please try again");
        }
      });
    }
  };

  const handleVerify = (email, code) => {
    if (email && code) {
      twoFactorAuthVerify(email, code).then((response) => {
        console.log("2FA verify response:", response);
        if (response && response.message === "2FA code verified") {
          toast.success("2FA verification successful");
          navigate("/connect-wallet");
        } else {
          toast.error("2FA verification failed");
        }
      });
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.email && !codeSentRef.current) {
      console.log("userInfo - 2FA:", userInfo);
      codeSentRef.current = true; // Use the ref to track if the code has been sent
      twoFactorAuth(userInfo.email).then((response) => {
        console.log("2FA response:", response);
      });
    }
  }, [userInfo]);

  if (!userInfo || !userInfo.twoFactorAuth === true) {
    return null;
  }

  return (
    <AuthLayout title={"Carbify | Two Factor Authentication"}>
      <Card
        sx={{
          backgroundColor: "#21563e",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
        className="w-full"
      >
        <CardContent>
          <Typography
            variant="h4"
            sx={{
              color: "#F8952D",
              marginBottom: "16px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            2FA Verification
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: "#AEB7C0",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            Enter the 6 digit code sent to the registered email address
          </Typography>

          <FormControl fullWidth margin="dense">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  name={`code-${index}`}
                  value={code[index]}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onPaste={handlePaste}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#AEB7C0",
                      fontSize: "24px",
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#AEB7C0",
                      },
                      "&:hover fieldset": {
                        borderColor: "#AEB7C0",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F8952D",
                      },
                    },
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }}
                />
              ))}
            </Box>
          </FormControl>

          <Typography
            variant="body2"
            sx={{
              color: "#AEB7C0",
              marginTop: "16px",
              textAlign: "center",
            }}
          >
            Did not receive a code?{" "}
            <Button
              sx={{
                color: "#F8952D",
                textTransform: "none",
                padding: "0",
                margin: "0",
                fontWeight: "bold",
              }}
              onClick={handleResend}
            >
              Resend
            </Button>
          </Typography>

          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "16px",
              backgroundColor: "#F8952D",
              color: "#083D25",
              "&:hover": {
                backgroundColor: "orange",
              },
            }}
            onClick={() => handleVerify(userInfo.email, code.join(""))}
          >
            Verify
          </Button>

          <Typography
            variant="body2"
            sx={{
              color: "#F8952D",
              marginTop: "16px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Don’t share the verification code with anyone!
          </Typography>
        </CardContent>
      </Card>
    </AuthLayout>
  );
};

export default TwoFactorAuthenticator;
