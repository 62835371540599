import React, { useEffect, useState } from 'react'
import { useReadContract } from 'wagmi'
import { toast } from 'react-toastify'

// Constants
import { CarbifyNFTABI, PLOTS_ABI } from 'constants/BlockchainConstants'

function CreatorEarning({ setCommission, asset }) {
  const [creatorEarning, setCreatorEarning] = useState('')

  const { 
    data: creatorEarningFee,
    error: creatorEarningError,
  } = useReadContract({
    address: asset,
    abi: asset?.toLowerCase() === process.env.REACT_APP_CARBIFYNFT_ADDRESS?.toLowerCase() ? CarbifyNFTABI : PLOTS_ABI,
    functionName:
      asset?.toLowerCase() === process.env.REACT_APP_CARBIFYNFT_ADDRESS?.toLowerCase() ? 'getRoyaltyFee' : 'royaltyBps',
  })
  useEffect(() => {
    if (!creatorEarning && creatorEarningFee) {
      console.log('creatorEarningFee:', creatorEarningFee)
      setCreatorEarning(creatorEarningFee)
      setCommission(Number(creatorEarningFee) / 100)
    } else if (creatorEarningError) {
      toast.error('Error fetching creator earning:', creatorEarningError.cause)
    }
  }, [creatorEarningFee, creatorEarningError])

  return (
    <div>
      <p className="text-[22px] font-semibold">{creatorEarning ? Number(creatorEarning) / 100 : 0}%</p>
    </div>
  )
}

export default CreatorEarning
