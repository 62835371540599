/* global BigInt */
import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import Spinner from 'components/utils/Spinner'
import { toast } from 'react-toastify'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'
import { Navigate } from 'react-router-dom'

function AcceptOfferButton({ listingId, offeror, pricePerToken, decimals }) {
  console.log('The Final Price of Offer is:', pricePerToken)

  const {
    data: acceptOfferHash,
    writeContract: acceptOffer,
    isPending: isAcceptingOffer,
    isError: isAcceptingOfferError,
  } = useWriteContract()
  console.log('acceptOfferHash:', acceptOfferHash)
  const {
    isLoading: acceptingOffer,
    isSuccess: acceptingOfferSuccess,
    isError: acceptingOfferError
  } = useWaitForTransactionReceipt({ hash: acceptOfferHash })

  const handleAcceptOffer = async () => {
    console.log(BigInt(pricePerToken * 10 ** decimals))
    acceptOffer({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'acceptOffer',
      args: [listingId, offeror, BigInt(pricePerToken * 10 ** decimals)],
    })
  }

  useEffect(() => {
    if (acceptingOfferSuccess) {
      toast.success('Offer successfully accepted')
    } else if (isAcceptingOfferError || acceptingOfferError) {
      toast.error('An error occured while accepting offer')
    }
  }, [isAcceptingOfferError, acceptingOfferError, acceptingOfferSuccess])

  return (
    <>
      <button onClick={() => handleAcceptOffer()} className="bg-secondary py-1 px-3 rounded-lg mr-2.5">
        <span className="text-sm font-bold text-bt">Accept</span>
      </button>
      {(isAcceptingOffer || acceptingOffer) && <Spinner />}
      {acceptingOfferSuccess && <Navigate to={'/marketplace'} />}
    </>
  )
}

export default AcceptOfferButton
