import React from 'react'

// icons
import SwitchHorizontal from 'icons/SwitchHorizontal'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { ethers } from 'ethers'

const ActivityTable = ({ activityData }) => {
  return (
    <div className="w-full h-[600px] overflow-x-auto md:scrollbar-hide scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin bg-darkGreen bg-opacity-70 rounded-lg h-72 py-3 px-4 flex flex-col space-y-3 border">
      <div className="overflow-y-scroll scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin">
        <table className="w-full">
          <thead className="text-left">
            <tr className="sticky -top-0.5 border-b border-[#527766] bg-darkGreen bg-opacity-80 rounded-lg font-medium text-base tracking-wider z-10">
              <th className="px-2 py-1">Event</th>
              <th className="px-2 py-1">Item</th>
              <th className="px-2 py-1">Unit Price</th>
              <th className="px-2 py-1">Quantity</th>
              <th className="px-2 py-1">From</th>
              <th className="px-2 py-1">To</th>
              <th className="px-2 py-1">Date</th>
            </tr>
          </thead>
          <tbody className="">
            {activityData !== null && activityData.map((activity, index) => (
              <tr key={index} className="border-b border-[#527766]">
                <td className="px-2 py-2 flex space-x-2 items-center">
                  {activity.event === 'Made Offer' ? (
                    <SwitchHorizontal />
                  ) : (
                    <AiOutlineShoppingCart className="text-xl" />
                  )}{' '}
                  <span>{activity.event}</span>
                </td>
                <td className="px-2 py-2">
                  <div className="flex space-x-2 tracking-wider items-center">
                    {/* <div className="w-8 h-8">
                      <img className="w-8 h-8" src={`/assets/images/${activity.image}`}></img>
                    </div> */}
                    <div className="flex flex-col space-y-1">
                      <div>{activity.item}</div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2">{ethers.utils.formatEther(activity.unitPrice)} $CBY</td>
                <td className="px-4 py-2">{activity.quantity}</td>
                <td className="px-4 py-2">{activity.from.slice(0, 6)}...{activity.from.slice(-4)}</td>
                <td className="px-4 py-2">{activity.to === 'N/A' ? 'N/A' : `${activity.to.slice(0, 6)}...${activity.to.slice(-4)}`}</td>
                <td className="px-4 py-2 text-[#6FA8FF]">
                  <a href={activity.transaction_link} target="_blank">
                    {new Date(activity.date * 1000).toLocaleString().split(',')[0]}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ActivityTable
