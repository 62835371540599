import React from 'react'
import ListForSaleButton from '../buttons/ListForSaleButton'

const NftreeCard = ({ nft, name, image, handleListNftModal }) => {
  return (
    <div className="relative overflow-hidden bg-darkGreen bg-opacity-70 hover:bg-light_green border rounded-lg p-4 flex flex-col space-y-4 justify-start group">
      <div className="flex items-center justify-center">
        <img className="w-[203px] h-[304px] rounded-[10px]" src={image} />
      </div>
      <div className="flex flex-col mb-3">
        <div className="text-xl tracking-wider font-bold">{name}</div>
        <div className="text-l absolute tracking-wider bottom-7 right-7 font-bold">
          {nft.is_staked ? 'Staked' : 'Not Staked'}
        </div>
        {/* {nft.is_locked && ( */}
        <div className="text-l absolute tracking-wider bottom-7 left-6 font-bold">
          {nft.is_locked ? (nft.locked_cby / 10 ** 18).toPrecision(2) : 0} $CBY
        </div>
        {/* )} */}
        <div className="h-12"></div>
      </div>
      <div className="absolute -top-4 rounded-br-lg left-0 px-2 z-10 bg-white text-green text-lg font-bold text-center">
        #{parseInt(nft?.token_id)}
      </div>
      <div className="absolute bottom-0 left-0 transition-all transform ease-in translate-y-3 opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:duration-300 w-full px-4">
        <ListForSaleButton nft={nft} name={name} image={image} handleListNftModal={handleListNftModal} />
      </div>
    </div>
  )
}

export default NftreeCard
