import Layout from 'layouts/Layout'
import PageHeader, { PageHeadingWithTooltip } from '../components/PageHeading'
import RequestedCertificateContent from 'components/certificates/RequestedCertificateContent'
import { TooltipComponent } from 'components/certificates/map/OverviewCards'

function RequestedCerificates({ userCertificatesRequests, setCertificateGenerationData }) {
  return (
    <>
      { userCertificatesRequests === null || userCertificatesRequests?.length === 0 ? null : 
        <>
          <PageHeadingWithTooltip 
            styles={'text-grey !text-3xl'} 
            tooltip={
              <TooltipComponent 
                stroke='#101a08d5'
                tooltipContent={
                  'When you submit a request, please note that the Carbify team manually reviews each submission to ensure it complies with our guidelines and language standards. Once approved, you will be able to mint the certificate.'
                }
              />
            }
          >
            Requested Certificates
          </PageHeadingWithTooltip>
          <div>
            {
              <RequestedCertificateContent 
                userCertificatesRequests={userCertificatesRequests}
                setCertificateGenerationData={setCertificateGenerationData}
              />
            }
          </div>
        </>
      }
    </>
  )
}
export default RequestedCerificates
