import React from 'react'

function NftDescription({ description }) {
  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="p-6">
        <div className="mb-2.5">
          <h3 className="text-2xl font-semibold">Description</h3>
        </div>
        <div>
          <p className="text-base font-normal tracking-wide">
            {description ? description : 'No description available'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default NftDescription
