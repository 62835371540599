import { ethers } from 'ethers';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';
// Staking Contract ABI and Address
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`;

export const checkRemainingMerkleBalance = async (stakedMerkleProofData, walletAddress) => {
    try {
        let provider;

        provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);

        // Initialize ethers with a provider
        const stakingContract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, provider);

        const amount = stakedMerkleProofData.map((item) => item.amount)[0];

        // console.log('checkRemainingMerkleBalance - stakedMerkleProofData:', stakedMerkleProofData);MAX_TREES_PER_TRANSACTION

        // console.log('checkRemainingMerkleBalance - walletAddress:', walletAddress);MAX_TREES_PER_TRANSACTION

        // console.log('checkRemainingMerkleBalance - amounts:', amount);MAX_TREES_PER_TRANSACTION

        const isClaimed = await stakingContract.isClaimed(walletAddress, amount);

        // console.log('checkRemainingMerkleBalance - isClaimed:', isClaimed);MAX_TREES_PER_TRANSACTION

        const remainingMerkleBalance = await stakingContract.getRemainingMerkleReward(walletAddress, amount);

        const formattedRemainingMerkleBalance = Number(ethers.utils.formatEther(remainingMerkleBalance));

        // console.log('checkRemainingMerkleBalance - formattedRemainingMerkleBalance:', formattedRemainingMerkleBalance);

        if (formattedRemainingMerkleBalance === 0 && isClaimed === false) {
            const formattedAmount = Number(ethers.utils.formatEther(amount));
            return formattedAmount;
        } else if (formattedRemainingMerkleBalance > 0 && isClaimed === false) {
            return formattedRemainingMerkleBalance;
        } else if (isClaimed === true) {
            return 0;
        }

        // return combinedResult;
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message };
    }
}
