import axios from "axios";
import { Config } from "../../config.js";

const aco2DataApi = Config().aco2_data_api;

export const getAllRegularMerkleData = async (walletAddress) => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/merkleproofs/nonstaked`, {
            params: {
                wallet_address: walletAddress,
            },
        });

        if (response.status === 200) {
            return { merkle_proofs: response.data.merkle_proofs };
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
}
