import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import axiosInstance from 'utils/axiosInstance'

// Reducers
import { authenticate2fa } from './authSlice'

export const totpCreate = createAsyncThunk('totp/otpCreate', async (_, thunkAPI) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  try {
    const res = await axiosInstance.get('/totp/create/', config)

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const totpActivate = createAsyncThunk('totp/totpActivate', async ({ token }, thunkAPI) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  try {
    const res = await axiosInstance.post(`/totp/verify/${token}/`, config)
    if (res.status === 201) {
      const { dispatch } = thunkAPI
      dispatch(authenticate2fa(true))
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const totpVerify = createAsyncThunk('totp/totpVerify', async ({ token }, thunkAPI) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  try {
    const res = await axiosInstance.post(`/totp/verify/${token}/`, config)
    if (res.status === 201) {
      const { dispatch } = thunkAPI
      dispatch(authenticate2fa(true))
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const totpDelete = createAsyncThunk('totp/totpDelete', async (_, thunkAPI) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }
  try {
    const res = await axiosInstance.post('/totp/delete/', config)

    if (res.status === 201) {
      const { dispatch } = thunkAPI
      dispatch(authenticate2fa(false))
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

const initialState = {
  loading: false,
  qrcodeUrl: null,
  errors: null,
}

const otpSlice = createSlice({
  name: '2fa',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(totpCreate.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(totpCreate.fulfilled, (state, action) => {
        state.loading = false
        state.qrcodeUrl = action.payload.qrcodeUrl
      })
      .addCase(totpCreate.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })

      .addCase(totpActivate.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(totpActivate.fulfilled, (state, action) => {
        state.loading = false
        toast.success('Two-Factor Auth Enabled Successfully')
      })
      .addCase(totpActivate.rejected, (state, action) => {
        state.loading = false
        toast.error(action.payload.message)
      })

      .addCase(totpVerify.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(totpVerify.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(totpVerify.rejected, (state, action) => {
        state.loading = false
        toast.error(action.payload.message)
      })

      .addCase(totpDelete.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(totpDelete.fulfilled, state => {
        toast.success('Two-Factor Auth Disabled Successfully')
        state.loading = false
      })
      .addCase(totpDelete.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })
  },
})

export const {} = otpSlice.actions
export default otpSlice.reducer
