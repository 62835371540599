import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useDisconnect } from 'wagmi'

import WalletInfo from './WalletInfo'
import WalletAddress from './WalletAddress'
import ProfileBox from './ProfileBox'
import ConnectWalletButton from './ConnectWalletButton'
import WalletWarningPopup from 'components/popups/walletWarningPopup'
import { BsWallet2 } from 'react-icons/bs'

// Reducers
import { getUser, forceLogout } from 'features/authentication/authSlice'

const Header = () => {
  const dispatch = useDispatch()
  const { isConnected } = useAccount()
  const { userInfo } = useSelector(state => state.authentication)
  const [showWarning, setShowWarning] = useState(false)
  const { disconnect } = useDisconnect()

  useEffect(() => {
    const fetchUserAndCheckWallet = async () => {
      if (!userInfo) {
        await dispatch(getUser())
      }

      if (userInfo && !userInfo.linked_wallet && isConnected) {
        setShowWarning(true)
      } else {
        setShowWarning(false)
      }
    }

    fetchUserAndCheckWallet()
  }, [dispatch, userInfo, isConnected])

  const handleCancel = () => {
    disconnect()
    dispatch(forceLogout())
  }

  return (
    <div className="flex xxxs:max-md:flex-col xxxs:max-md:justify-start xxxs:max-md:items-start items-center justify-between lg:h-19 lg:mb-6 xxxs:max-lg:mb-2">
      <WalletAddress />
      {showWarning && <WalletWarningPopup onClose={handleCancel} />}
      <div className="flex items-center xxxs:max-md:justify-end xxxs:max-md:w-full z-40">
        {!isConnected && (
          <ConnectWalletButton text={'Connect Wallet'}>
            <div>
              <BsWallet2 size={18} />
            </div>
          </ConnectWalletButton>
        )}
        {isConnected && <WalletInfo />}
        <ProfileBox />
      </div>
    </div>
  )
}

export default Header
