import React from "react";

export const Wallet02 = ({ color = "#024728", className }) => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8V4.50065C16 3.66892 16 3.25305 15.8248 2.99747C15.6717 2.77418 15.4346 2.6225 15.1678 2.57708C14.8623 2.5251 14.4847 2.69937 13.7295 3.04792L4.85901 7.14199C4.18551 7.45284 3.84875 7.60827 3.60211 7.84932C3.38406 8.06242 3.21762 8.32255 3.1155 8.60983C3 8.93479 3 9.30568 3 10.0475V15M16.5 14.5H16.51M3 11.2L3 17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.7157 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8L6.2 8C5.0799 8 4.51984 8 4.09202 8.21799C3.7157 8.40973 3.40973 8.71569 3.21799 9.09202C3 9.51984 3 10.0799 3 11.2ZM17 14.5C17 14.7761 16.7761 15 16.5 15C16.2239 15 16 14.7761 16 14.5C16 14.2239 16.2239 14 16.5 14C16.7761 14 17 14.2239 17 14.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
