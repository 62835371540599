import React, { useState, useEffect } from 'react'
import { useAccount } from 'wagmi'
import { getCBYStakingAllowance } from 'features/staking/getCBYStakingAllowance'
import { getAllTreeData } from 'features/dashboard/getAllTreeData'
import { getLockedCBYPerPlotType } from 'features/staking/getLockedCBYPerPlotType'
import { calculateFiveDollarsWorthCBY } from 'features/staking/calculateFiveDollarsWorthCBY'
import { getLockedAmountAverage } from 'features/staking/getLockedAmountAverage'
import Popup from 'components/staking/Popup'
import UnstakePopup from 'components/staking/UnstakePopup'
import AdvancedPopup from 'components/staking/AdvancedPopup'
import Plots from 'components/staking/Plots'


function StakingCards({
  stakedNftreesOnGenesis,
  stakedNftreesOnPremium,
  stakedNftreeOnStandard,
  totalGenesisPlots,
  totalPremiumPlots,
  totalStandardPlots,
  availableGenesisPlots,
  availablePremiumPlots,
  availableStandardPlots,
  landPlotData,
  setLandPlotData,
  totalStakedNftrees,
  setTotalStakedNftrees,
  totalNftrees,
  setTotalLockedNftrees,
  totalLockedNftrees,
  setYearlyEstimatedRewards,
  setTotalCBY,
}) {
  const { address: walletAddress } = useAccount()

  const [showPopup, setShowPopup] = useState(false)
  const [showPopupUnstake, setShowPopupUnstake] = useState(false)
  const [showPopupAdvanced, setShowPopupAdvanced] = useState(false)

  const [loading, setLoading] = useState(false)

  const [treeData, setTreeData] = useState(null)
  const [lockedCby, setLockedCby] = useState(null)
  const [unstakeCBYData, setUnstakeCBYData] = useState(null)

  const [amount, setAmount] = useState('')
  const [amountUnstake, setAmountUnstake] = useState('')
  const [amountAdvanced, setAmountAdvanced] = useState('')
  const [title, setTitle] = useState('')
  const [requiredCBY, setRequiredCBY] = useState('')

  const [approvedAmount, setApprovedAmount] = useState('')
  const [approvalParams, setApprovalParams] = useState([])

  const handleClosePopup = () => {
    setShowPopup(false)
    setShowPopupUnstake(false)
    setShowPopupAdvanced(false)
    setAmount('')
    setAmountUnstake('')
    setAmountAdvanced('')
    setRequiredCBY('')
  }

  useEffect(() => {
    getAllTreeData(walletAddress).then(allTreeData => {
      setTreeData(allTreeData)
    })
  }, [walletAddress])

  useEffect(() => {
    getCBYStakingAllowance(walletAddress).then(data => {
      setApprovedAmount(Number(data.allowance))
    })
    calculateFiveDollarsWorthCBY().then(data => {
      if (data) {
        if (amount === '' || amountAdvanced === '' || amount === '0' || amountAdvanced === '0') {
          setRequiredCBY(0)
        }
        if (amount !== '') {
          const requiredCBYAmount = Number(amount) * data.formattedCBYPrice
          setRequiredCBY(requiredCBYAmount)
        } else if (amountAdvanced !== '') {
          const requiredCBYAmount = Number(amountAdvanced) * data.formattedCBYPrice
          setRequiredCBY(requiredCBYAmount)
        }
      }
    })
  }, [walletAddress, amount, amountAdvanced])

  useEffect(() => {
    if (treeData) {
      getLockedCBYPerPlotType(treeData).then(data => {
        setLockedCby(data)
      })
      getLockedAmountAverage(treeData).then(data => {
        if (data.error) {
          console.error('Error in getLockedAmountAverage:', data.error)
          setTimeout(() => {
            getLockedAmountAverage(treeData).then(data => {
              if (data.error) {
                console.error('Error in getLockedAmountAverage:', data.error)
                return
              }
              setUnstakeCBYData(data)
            })
          }, 5000);
        }
        setUnstakeCBYData(data)
      })
    }
  }, [treeData])

  return (
    <div>
      <Popup
        showPopup={showPopup}
        handleClosePopup={handleClosePopup}
        title={title}
        amount={amount}
        setAmount={setAmount}
        totalNftrees={totalNftrees}
        totalStakedNftrees={totalStakedNftrees}
        availableGenesisPlots={availableGenesisPlots}
        availableStandardPlots={availableStandardPlots}
        availablePremiumPlots={availablePremiumPlots}
        totalGenesisPlots={totalGenesisPlots}
        totalStandardPlots={totalStandardPlots}
        totalPremiumPlots={totalPremiumPlots}
        requiredCBY={requiredCBY}
        setLoading={setLoading}
        setTreeData={setTreeData}
        setLandPlotData={setLandPlotData}
        setTotalCBY={setTotalCBY}
        treeData={treeData}
        landPlotData={landPlotData}
        setApprovalParams={setApprovalParams}
        approvedAmount={approvedAmount}
        setApprovedAmount={setApprovedAmount}
        approvalParams={approvalParams}
        loading={loading}
        setYearlyEstimatedRewards={setYearlyEstimatedRewards}
        setTotalStakedNftrees={setTotalStakedNftrees}
        totalLockedNftrees={totalLockedNftrees}
        setTotalLockedNftrees={setTotalLockedNftrees}
      />
      <UnstakePopup 
        showPopupUnstake={showPopupUnstake}
        handleClosePopup={handleClosePopup}
        title={title}
        amountUnstake={amountUnstake}
        setAmountUnstake={setAmountUnstake}
        stakedNftreeOnStandard={stakedNftreeOnStandard}
        stakedNftreesOnPremium={stakedNftreesOnPremium}
        stakedNftreesOnGenesis={stakedNftreesOnGenesis}
        totalNftrees={totalNftrees}
        unstakeCBYData={unstakeCBYData}
        loading={loading}
        setLoading={setLoading}
        setTreeData={setTreeData}
        setLandPlotData={setLandPlotData}
        setTotalCBY={setTotalCBY}
        setYearlyEstimatedRewards={setYearlyEstimatedRewards}
        setTotalStakedNftrees={setTotalStakedNftrees}
        totalStakedNftrees={totalStakedNftrees}
        treeData={treeData}
        setTotalLockedNftrees={setTotalLockedNftrees}
        totalLockedNftrees={totalLockedNftrees}
      />
      <AdvancedPopup 
        showPopupAdvanced={showPopupAdvanced}
        handleClosePopup={handleClosePopup}
        title={title}
        amountAdvanced={amountAdvanced}
        setAmountAdvanced={setAmountAdvanced}
        totalNftrees={totalNftrees}
        totalLockedNftrees={totalLockedNftrees}
        totalStakedNftrees={totalStakedNftrees}
        stakedNftreeOnStandard={stakedNftreeOnStandard}
        stakedNftreesOnPremium={stakedNftreesOnPremium}
        stakedNftreesOnGenesis={stakedNftreesOnGenesis}
        requiredCBY={requiredCBY}
        approvedAmount={approvedAmount}
        setApprovalParams={setApprovalParams}
        setTreeData={setTreeData}
        setTotalCBY={setTotalCBY}
        setLoading={setLoading}
        treeData={treeData}
        setLandPlotData={setLandPlotData}
        approvalParams={approvalParams}
        setApprovedAmount={setApprovedAmount}
        loading={loading}
        setYearlyEstimatedRewards={setYearlyEstimatedRewards}
        setTotalStakedNftrees={setTotalStakedNftrees}
        setTotalLockedNftrees={setTotalLockedNftrees}
      />
      <Plots 
        totalGenesisPlots={totalGenesisPlots}
        totalPremiumPlots={totalPremiumPlots}
        totalStandardPlots={totalStandardPlots}
        availableGenesisPlots={availableGenesisPlots}
        availablePremiumPlots={availablePremiumPlots}
        availableStandardPlots={availableStandardPlots}
        stakedNftreesOnGenesis={stakedNftreesOnGenesis}
        stakedNftreesOnPremium={stakedNftreesOnPremium}
        stakedNftreeOnStandard={stakedNftreeOnStandard}
        lockedCby={lockedCby}
        treeData={treeData}
        landPlotData={landPlotData}
        setTitle={setTitle}
        setShowPopupUnstake={setShowPopupUnstake}
        setShowPopupAdvanced={setShowPopupAdvanced}
        setShowPopup={setShowPopup}
        setRequiredCBY={setRequiredCBY}
      />
    </div>
  )
}

export default StakingCards
