import axios from 'axios';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getAllPoolLogos = async () => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/aco2-pools/all-logo`);

        if (response.status === 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
