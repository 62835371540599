import { Navigate, Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useAccount } from 'wagmi'
import Spinner from 'components/utils/Spinner'
import useWalletLinkedCheck from 'hooks/useWalletLinkedCheck'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { checkAuth, forceLogout } from 'features/authentication/authSlice'

export const AuthenticatedRoutes = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isAuthenticated, is2faEnabled, is2faAuthenticated, loggedOut } = useSelector(state => state.authentication)

  useEffect(() => {
    if (!isAuthenticated && !loggedOut) {
      dispatch(checkAuth())
        .unwrap()
        .catch(() => {
          dispatch(forceLogout())
          console.log('Token verification failed. Redirecting to login...')
          navigate('/login')
        })
    }
  }, [dispatch, navigate, isAuthenticated, loggedOut])

  if (!isAuthenticated && !loggedOut) {
    return <Spinner />
  }

  if (is2faEnabled) {
    if (isAuthenticated && is2faAuthenticated) {
      return (
        <>
          <Outlet />
        </>
      )
    } else {
      return <Navigate to="/login" />
    }
  } else {
    return isAuthenticated ? (
      <>
        <Outlet />
      </>
    ) : (
      <Navigate to="/login" />
    )
  }
}

export const WalletAddressRoutes = () => {
  const { isConnected } = useAccount()

  useWalletLinkedCheck()

  return !isConnected ? <Navigate to="/connect-wallet" /> : <Outlet />
}
