import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'

const initialState = {
  listedNfts: null,
  listedNftsLoading: false,
  error: null,

  listedNft: null,
  listedNftLoading: false,
  listedNftError: null,

  isLoading: false,
}

export const resetListedNft = createAction('REVERT_ALL')

// No functionilty in backend yet
export const getListedNfts = createAsyncThunk('marketplace/getListedNfts', async (_, thunkAPI) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL4}/api/nft/listings`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getListedNft = createAsyncThunk('marketplace/getListedNft', async (listing_id, thunkAPI) => {
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL4}/api/nft/listings?listing=${listing_id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const postPurchase = createAsyncThunk('postPurchase', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`${process.env.REACT_APP_ACO2_DATA_API}/api/purchases/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 201) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const listingsSlice = createSlice({
  name: 'marketplace/listings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListedNfts.pending, (state, action) => {
        state.listedNftsLoading = true
        state.error = null
      })
      .addCase(getListedNfts.fulfilled, (state, action) => {
        // console.log("GetListedNFTS, I am fullfiled", action.payload.listings)
        state.listedNftsLoading = false
        state.listedNfts = action.payload
      })
      .addCase(getListedNfts.rejected, (state, action) => {
        console.log('I am rejected')
        state.listedNftsLoading = false
        state.error = action.payload
      })

      .addCase(getListedNft.pending, (state, action) => {
        state.listedNftLoading = true
        state.error = null
      })
      .addCase(getListedNft.fulfilled, (state, action) => {
        // console.log('GetListedNFT, I am fullfiled', action.payload[0])
        state.listedNftLoading = false
        state.listedNft = action.payload[0]
      })
      .addCase(getListedNft.rejected, (state, action) => {
        state.listedNftLoading = false
        state.listedNftError = action.payload
      })

      .addCase(postPurchase.pending, (state, action) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(postPurchase.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(postPurchase.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
      .addCase(resetListedNft, () => initialState)
  },
})

export const {} = listingsSlice.actions
export default listingsSlice.reducer
