import { CiWallet } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";

const BurnPopup = ({
    showPopupBurn,
    handleClosePopup,
    handleBurn,
    amount,
    handleTreeAmount,
    balance,
    burnBalance,
    buriningBatch,
    burnBatchAwaiting
}) => {
    return (
        <>
            {showPopupBurn ? (
                <div
                id="crypto-modal"
                tabindex="-1"
                aria-hidden="true"
                className="fixed flex justify-center bg-black bg-opacity-80 items-center  z-40
                    w-full h-full p-4  overflow-x-hidden overflow-y-auto inset-0 h-modal"
                >
                    <div className="relative w-full h-full max-w-md md:h-auto">
                        <div className="relative bg-green bg-opacity-80 p-6 border rounded-lg shadow">
                        <button
                            onClick={() => handleClosePopup()}
                            type="button"
                            className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="crypto-modal"
                        >
                            <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="flex items-center justify-center pb-2">
                            <div className="text-xl font-semibold">Burn $aCO2</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="mb-3">
                            <div className="relative py-2 space-y-1">
                                <label
                                for="amount"
                                className="block text-base font-bold tracking-wide"
                                >
                                $aCO2 Amount
                                </label>
                                <input
                                type="text"
                                name="amount"
                                id="amount"
                                className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                                placeholder="amount"
                                required="true"
                                value={amount}
                                onChange={handleTreeAmount}
                                />
                            </div>
                            <div className="flex space-x-1 text-sm font-normal">
                                <IoIosInformationCircleOutline className="w-4 h-4  mt-0.5 stroke-1" />
                                <span>Burn $aCO2 and receive an NFT Certificate</span>
                            </div>
                            </div>

                            <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                            <div className="flex justify-between">
                                <span>Available $aCO2 for burning</span>
                                <span>
                                {balance &&
                                    Number(balance.totalBalanceFormatted).toFixed(2)}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Amount of $aCO2 already burned</span>
                                <span>{burnBalance && Number(burnBalance.totalBurnedAmount).toFixed(2)}</span>
                            </div>
                            </div>
                            <>
                            <button
                                disabled={
                                amount === "" || buriningBatch || burnBatchAwaiting
                                }
                                onClick={() => handleBurn()}
                                className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                            >
                                <CiWallet className="w-6 h-6 stroke-1" />
                                <span className="text-lg tracking-wider">
                                {buriningBatch || burnBatchAwaiting
                                    ? "Burning..."
                                    : "Burn"}
                                </span>
                            </button>
                            </>
                        </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default BurnPopup;
