import { getERC721Data } from './getERC721Data'
import { ALL_LANDPLOT_ADDRESSES } from "constants/BlockchainConstants"

export const getAllLandplotData = async (walletAddress) => {
    let landplotData = []
    for (const erc721Address of ALL_LANDPLOT_ADDRESSES) {
        if (erc721Address === undefined) continue;
        const erc721Data = await getERC721Data(walletAddress, erc721Address)
        if (erc721Data && erc721Data.token_data) {
            landplotData = landplotData.concat(erc721Data.token_data)
        }
    }
    return landplotData
}