import React from 'react'
import { Link } from 'react-router-dom'
import AddToCartButton from '../buttons/AddToCartButton'
import CountdownTimerListings from './cardComponents/CountdownTimerListings'
import Price from './cardComponents/Price'
import BestOffer from './cardComponents/BestOffer'

// Constants
import { CBY_ADDRESS } from 'constants/BlockchainConstants'

const ListingCard = ({ listingId, title, image, quantity, currency, price, offer, startTime ,endTime, auction }) => {
  return (
    <div className="relative overflow-hidden bg-darkGreen bg-opacity-70 hover:bg-light_green border rounded-lg p-4 flex flex-col space-y-4 justify-start group">
      <Link to={`/listing/${listingId}`}>
        <div className="flex items-center justify-center">
          <img className="w-full h-full rounded-[10px]" src={image} />
        </div>
      </Link>
      <div className="flex flex-col mb-3">
        <div className="text-xl tracking-wider font-bold">{title}</div>
        <Price
          logo={currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? `carbify-logo-marketplace.png` : `carbify-blue-dollar.png`}
          price={price}
          unit={currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? `CBY` : `USDC`}
        />
        <div className="h-4">
          <BestOffer offer={offer} unit={currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? `CBY` : `USDC`} />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <CountdownTimerListings startTime={startTime} endTime={endTime} />
        <div className="tracking-wider text-xs font-semibold">{auction}</div>
      </div>
      <div className="absolute -top-4 rounded-br-lg left-0 w-11 z-10 bg-white text-bt text-lg font-bold text-center">
        x{quantity}
      </div>
      {/* <div className="absolute bottom-0 left-0 transition-all transform ease-in translate-y-3 opacity-0 group-hover:opacity-100 group-hover:-translate-y-3 group-hover:duration-300 w-full px-4">
        <AddToCartButton image={image} title={title} price={price} quantity={1} currency={currency === CBY_ADDRESS.toLowerCase() ? `CBY` : `USDC`} />
      </div> */}
    </div>
  )
}

export default ListingCard
