"use client";

import React, { useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material"; // Ensure you have @mui/material installed

// Custom styles for the Checkbox
const customCheckboxStyles = {
  color: "rgba(255, 150, 46, 1)",
  '&.Mui-checked': {
    color: "rgba(255, 150, 46, 1)",
  },
  '&:hover': {
    backgroundColor: "rgba(255, 150, 46, 0.08)", // Slight hover effect
  },
};

// Custom styles for the FormControlLabel
const customLabelStyles = {
  fontSize: '1rem', // Slightly bigger font size
  color: "#fff", // White color for the label
};

export const CustomCheckbox = ({ label, selectedFilters, setSelectedFilters }) => {
  const handleChange = (event) => {
    if (event.target.checked) {
      setSelectedFilters((prev) => [...prev, label]);
    } else {
      setSelectedFilters((prev) => prev.filter((item) => item !== label));
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={customCheckboxStyles}
          onChange={handleChange}
          checked={selectedFilters.includes(label)}
        />
      }
      label={label}
      sx={customLabelStyles}
    />
  );
};
