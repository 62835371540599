import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import axios from 'axios'

const authNodeLink = process.env.REACT_APP_API_URL_NODE_AUTH

const axiosInstance = axios.create({
  baseURL: authNodeLink,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const register = createAsyncThunk(
  'auth/register',
  async ({ username, email, country, password, confirmPassword, termsAndConditionsAccepted }, thunkAPI) => {
    try {
      const data = {
        username,
        email: email.toLowerCase(),
        country,
        password,
        confirmPassword,
        termsAndConditionsAccepted,
      }

      const response = await axiosInstance.post('/api/v1/auth/register', data)

      localStorage.clear()

      if (response.status === 201) {
        return response.data
      }
    } catch (err) {
      const errorData = err.response?.data
      if (errorData) {
        return thunkAPI.rejectWithValue(errorData)
      } else {
        return thunkAPI.rejectWithValue({ msg: 'An unexpected error occurred. Please try again later.' })
      }
    }
  },
)

export const resetPasswordEmail = createAsyncThunk('auth/forgot-password', async ({ email }, thunkAPI) => {
  try {
    const res = await axiosInstance('/api/v1/auth/forgot-password', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { email: email.toLowerCase() },
    })

    return res.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async ({ currentPassword, newPassword, confirmPassword }, thunkAPI) => {
    const data = {
      currentPassword,
      newPassword,
      confirmPassword,
    }

    try {
      const res = await axiosInstance('/api/v1/auth/forgot-password/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      })

      if (res.status >= 200) {
        return res.data
      }
    } catch (err) {
      const errorData = err.response?.data
      if (errorData) {
        return thunkAPI.rejectWithValue(errorData)
      } else {
        return thunkAPI.rejectWithValue({ msg: 'An unexpected error occurred. Please try again later.' })
      }
    }
  },
)

export const getUser = createAsyncThunk('auth/Profile', async (userId, thunkAPI) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
    withCredentials: true,
  }

  try {
    const res = await axiosInstance.get(`/api/v1/user-profile/logged-in-user`, config)

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const login = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const data = {
      email: email.toLowerCase(),
      password,
    }
    const response = await axiosInstance('/api/v1/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
      withCredentials: true,
    })
    if (response.status === 200) {
      return response.data
    }
  } catch (err) {
    const errorData = err.response?.data
    if (errorData) {
      return thunkAPI.rejectWithValue(errorData)
    } else {
      return thunkAPI.rejectWithValue({ msg: 'An unexpected error occurred. Please try again later.' })
    }
  }
})

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/api/v1/auth/logout', { withCredentials: true })

    localStorage.removeItem('walletConnection')
    localStorage.clear()
    thunkAPI.dispatch(resetAuth())

    return response.data
  } catch (err) {
    console.error(err)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const resetPasswordConfirm = createAsyncThunk(
  'auth/reset-password-confirm',
  async ({ password, confirmPassword, token }, thunkAPI) => {
    const data = {
      password,
      confirmPassword,
      token,
    }

    try {
      const res = await axiosInstance('/api/v1/auth/forgot-password/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      })

      if (res.status >= 200) {
        return res.data
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const forceLogout = createAsyncThunk('auth/forceLogout', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/api/v1/auth/force-logout', { withCredentials: true })

    localStorage.clear()
    thunkAPI.dispatch(resetAuth())

    return response.data
  } catch (err) {
    console.error(err)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const checkAuth = createAsyncThunk('auth/verify', async (_, thunkAPI) => {
  try {
    const res = await axiosInstance.post('/api/v1/auth/verify-token')

    const { dispatch } = thunkAPI
    await dispatch(getUser())
    return res.data
  } catch (err) {
    const { dispatch } = thunkAPI
    await dispatch(forceLogout())
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

const initialState = {
  isAuthenticated: false,
  is2faAuthenticated: false,
  forgotPasswordEmail: null,
  is2faEnabled: false,
  passwordUpdated: false,
  userInfo: null,
  loading: false,
  registered: false,
  loggedOut: false,
  errors: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: state => {
      state.isAuthenticated = false
      state.is2faAuthenticated = false
      state.is2faEnabled = false
      state.forgotPasswordEmail = null
      state.userInfo = null
      state.loading = false
      state.registered = false
      state.loggedOut = true
      state.errors = null
      Cookies.remove('accessToken')
      Cookies.remove('refreshToken')
      localStorage.clear()
    },

    resetRegistered: state => {
      state.registered = false
    },

    resetAuthErrors: state => {
      state.errors = null
    },

    resetPasswordUpdated: state => {
      state.passwordUpdated = false
    },

    authenticate2fa: (state, action) => {
      state.is2faEnabled = action.payload
      state.is2faAuthenticated = action.payload
      localStorage.setItem('is2faAuthenticated', JSON.stringify(action.payload))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(register.fulfilled, state => {
        state.loading = false
        state.registered = true
        toast.success('Account is created Successfully!')
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
        action.payload.username && toast.error(action.payload.username[0])
        action.payload.email && toast.error(action.payload.email[0])
        if (action.payload.message) {
          if (Array.isArray(action.payload.message)) {
            action.payload.message?.forEach(error => {
              toast.error(error)
            })
          } else {
            toast.error(action.payload.message)
          }
        }
      })

      .addCase(login.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false
        state.isAuthenticated = true
        state.userInfo = action.payload.user
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
        toast.error(action.payload?.message)
      })

      .addCase(getUser.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false
        state.userInfo = action.payload
        state.isAuthenticated = true
        state.is2faEnabled = state.userInfo?.isTwoFactorEnabled
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })
      .addCase(resetPasswordEmail.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(resetPasswordEmail.fulfilled, (state, action) => {
        state.loading = false
        state.forgotPasswordEmail = action.payload
        toast.success(action.payload.success)
      })
      .addCase(resetPasswordEmail.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
        toast.error(action.payload.message)
      })
      .addCase(resetPassword.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(resetPassword.fulfilled, state => {
        toast.success('Your Password is reset! Please login with new password')
        state.loading = false
        state.passwordUpdated = true
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
        action.payload.detail && toast.error(action.payload.detail)
        action.payload.password?.forEach(error => {
          toast.error(error)
        })
      })
      .addCase(resetPasswordConfirm.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(resetPasswordConfirm.fulfilled, state => {
        toast.success('Your Password is reset! Please login with new password')
        state.loading = false
        state.passwordUpdated = true
      })
      .addCase(resetPasswordConfirm.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
        action.payload?.detail && toast.error(action.payload.detail)
      })
  },
})

export const { authenticate2fa, resetAuth, resetRegistered, resetAuthErrors, resetPasswordUpdated } = authSlice.actions
export default authSlice.reducer
