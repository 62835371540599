import { CiWallet } from 'react-icons/ci'
import { Config } from '../../config'

const SuccessPopup = ({ 
    showSuccessPopup, 
    poolLogos, 
    selectedPool, 
    amount, 
    successTxHash, 
    handleClosePopup 
}) => {

    return (
        <>
            { showSuccessPopup ? (
                <div
                id="crypto-modal"
                tabindex="-1"
                aria-hidden="true"
                className="fixed flex justify-center items-center z-40 bg-black bg-opacity-80  w-full h-full p-4  overflow-x-hidden overflow-y-auto inset-0 h-modal"
                >
                <div className="relative w-full h-full max-w-md md:h-auto">
                    {/* Modal content */}
                    <div className="relative bg-light_green bg-opacity-80 p-6 border rounded-lg shadow">
                    {/* Modal header */}
                    <div className="flex flex-col items-center justify-center pb-2">
                        <div className='w-8 h-8'>
                        <img 
                            src={
                            poolLogos && poolLogos.find(logo => logo?.poolId === selectedPool?.id.toString())?.logoURL
                            } 
                            alt={`${selectedPool?.id} Logo`} 
                        />
                        </div>
                        <div className="text-xl font-bold text-center mt-2">Congratulations</div>
                        <div className="text-base font-semibold text-center mt-2">
                        You have successfully sold {amount} $aCO2 for {(Number(amount) * selectedPool?.exchangeRate * (1 - selectedPool?.poolFee / 100)).toFixed(2)} {selectedPool?.exchangeCurrencySymbol}
                        </div>
                    </div>
                    {/* Modal Body */}
                    {/* <AllowTrees /> */}
                    <div className="flex flex-col">
                        <div className="flex flex-col font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3 text-center">
                            <span>Transaction hash</span>
                            {successTxHash.map(tx => {
                            return (
                                <div className="flex justify-center">
                                    <a href={ (Config().chain_id).toString() === '137' ? `https://polygonscan.com/tx/${tx}` : `https://www.oklink.com/amoy/tx/${tx}` } target="_blank" rel="noreferrer" className="text-center hover:text-carbifyOrange hover:underline">
                                        {tx?.slice(0, 10) + '...' + tx?.slice(-10)}
                                    </a>
                                </div>
                            )
                            })}
                        </div>
                        <button
                        onClick={() => 
                            handleClosePopup()
                        }
                        className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                        <CiWallet className="w-6 h-6 stroke-1" />
                        <span className="text-lg tracking-wider">
                            Close
                        </span>
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            ) : null}
        </>
    )
}

export default SuccessPopup;
