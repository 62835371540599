import React from 'react';
import { Link } from 'react-router-dom';

const MenuButton = ({ children, text, active = false, path, isPreviousActive, isNextActive }) => {
  return (
    <Link
      to={path}
      className={`flex items-center justify-start block tracking-wider group py-4 ${
        active
          ? 'stroke-orange text-carbifyOrange border border-r-0 border-white pl-4 mr-0 rounded-l-[30px]  w-full -my-[2px]'
          : `border-r-8 text-white hover:rounded-l-[30px]  hover:bg-light_green hover:bg-opacity-40 group-hover:text-carbifyOrange hover:text-carbifyOrange hover:drop-shadow-2xl bg-black bg-opacity-70 ${
              isPreviousActive ? 'rounded-br-[30px]' : ''
            } ${isNextActive ? 'rounded-tr-[30px]' : ''} w-full`
      } ease-in-out duration-300 pl-12 font-bold`}
    >
      {children}
      <span className={`origin-left z-50 pl-4 text-lg ease-in-out ${active ? 'text-carbifyOrange' : 'group-hover:text-carbifyOrange text-white'}`}>{text}</span>
    </Link>
  );
}

export default MenuButton;
