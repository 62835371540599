/* global BigInt */
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { toast } from 'react-toastify'
import ApproveCurrency from 'components/MarketplaceV2/utils/ApproveCurrency'
import Spinner from 'components/utils/Spinner'
import CurrencySymbol from 'components/nfts/utils/CurrencySymbol'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function BuyNowButton({ currencyAddress, currencyAbi, quantityToBuy, totalPrice, decimals, listedNft }) {
  const [approvedAmount, setApprovedAmount] = useState('')

  const { address: walletAddress } = useAccount()

  const { 
    data: ApprovedAmountData,
    error: ApprovedAmountDataError,
  } = useReadContract({
    address: currencyAddress,
    abi: currencyAbi,
    functionName: 'allowance',
    args: [walletAddress, MARKETPLACE_ADDRESS],
  })
  useEffect(() => {
    if (ApprovedAmountData) {
      setApprovedAmount(Number(ApprovedAmountData))
    } else if (ApprovedAmountDataError) {
      toast.error('Error fetching approved amount:', ApprovedAmountDataError.cause)
    }
  }, [ApprovedAmountData, ApprovedAmountDataError])

  // Buy NFT
  const {
    data: buyNftHash,
    writeContract: buyNFT,
    isPending: isBuyingNft,
    isError: isBuyingNftError,
  } = useWriteContract()
  console.log('buyNftHash:', buyNftHash)
  const {
    isLoading: buyingNftLoading,
    isSuccess: buyingNftCompleted,
    isError: buyingNftError,
  } = useWaitForTransactionReceipt({ hash: buyNftHash })

  // Buy NFT
  const handleBuyNow = () => {
    if (quantityToBuy > listedNft.quantity) toast.error('Quantity exceeds order')
    else buyNFT({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'buy',
      args: [listedNft.listingId, walletAddress, quantityToBuy, BigInt(totalPrice)],
    })
  }

  // Approve Currency
  const {
    data: approveCurrencyHash,
    writeContract: approveCurrency,
    isPending: isApprovingCurrency,
    isError: isApprovingCurrencyError,
  } = useWriteContract()
  console.log('approveCurrencyHash:', approveCurrencyHash)
  const {
    isLoading: approvingCurrency,
    isSuccess: approvingCurrencySuccess,
    isError: approvingCurrencyError,
  } = useWaitForTransactionReceipt({ hash: approveCurrencyHash })

  // Buy NFT
  useEffect(() => {
    if (buyingNftCompleted) {
      toast.success('You have successfully bought this Nft!')
    } else if (buyingNftError || isBuyingNftError) {
      toast.error('An error occured while buying Nft')
    }
  }, [buyingNftCompleted, buyingNftError, isBuyingNftError])

  // Approve Currency
  useEffect(() => {
    if (approvingCurrencySuccess) {
      toast.success('Currency has been approved')
    } else if (approvingCurrencyError || isApprovingCurrencyError) {
      toast.error('An error occured while approving currency')
    }
  }, [approvingCurrencySuccess, approvingCurrencyError, isApprovingCurrencyError])

  return (
    <>
      {totalPrice <= approvedAmount ? (
        <>
          <button
            onClick={() => handleBuyNow()}
            className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
          >
            <CoinsStacked02 color="#1C4828" />
            <span>Complete purchase</span>
          </button>
          {(isBuyingNft || buyingNftLoading) && <Spinner />}
          {buyingNftCompleted && <Navigate to={'/nfts'} />}
        </>
      ) : (
        <div>
          <button
            onClick={() => approveCurrency({
              address: currencyAddress,
              abi: currencyAbi,
              functionName: 'approve',
              args: [MARKETPLACE_ADDRESS, BigInt(totalPrice * 10 ** 18)],
            })}
            className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
          >
            <CoinsStacked02 color="#1C4828" />
            <span>
              Approve <CurrencySymbol selectedCurrency={currencyAddress} />
            </span>
          </button>
        </div>
        // <>
        //   <ApproveCurrency
        //     currencyAddress={currencyAddress}
        //     currencyAbi={currencyAbi}
        //     amount={totalPrice}
        //     currencyDecimals={decimals}
        //   />
        // </>
      )}
      { isApprovingCurrency || approvingCurrency ? <Spinner /> : null}
    </>
  )
}

export default BuyNowButton
