/* global BigInt */
import React, { useEffect, useState } from 'react'
import { useAccount, useReadContract } from 'wagmi'
import { toast } from 'react-toastify'
import NftreeCard from './NftreeCard'
import NftreeBuyNowModal from '../modals/NftreeBuyNowModal'

// Constants
import {
  MARKETPLACE_ADDRESS,
  MARKETPLACE_ABI,
  USDCE_ADDRESS,
  USDC_ADDRESS,
  USDC_ABI,
  USDT_ADDRESS,
  USDT_ABI,
  CBY_ADDRESS,
  NFTREE_BATCH_15_ADDRESS,
} from 'constants/BlockchainConstants'

const assetContract = NFTREE_BATCH_15_ADDRESS
const assetTitle = 'NFTree Batch 15'

function NftreeBatch15Card() {
  const [showBuyNowModal, setShowBuyNowModal] = useState(false)
  const [currentSupply, setCurrentSupply] = useState('')
  const [supplyLimit, setSupplyLimit] = useState()
  const [pricePerToken, setPricePerToken] = useState('')
  const { address: walletAddress } = useAccount()
  const [usdcBalance, setUSDCBalance] = useState('')
  const [usdceBalance, setUSDCEBalance] = useState('')
  const [usdtBalance, setUSDTBalance] = useState('')
  const [currency, setCurrency] = useState('')
  const [tokenOwner, setTokenOwner] = useState('')
  const [tokenBalance, setTokenBalance] = useState('')
  const [isUSDC, setIsUSDC] = useState(false)

  const { data: NFTInfo } = useReadContract({
    address: MARKETPLACE_ADDRESS,
    abi: MARKETPLACE_ABI,
    functionName: 'NFTInfo',
    args: [assetContract],
  })
  useEffect(() => {
    if (NFTInfo) {
      setCurrentSupply(Number(NFTInfo[0]))
      setSupplyLimit(Number(NFTInfo[1]))
      setPricePerToken(Number(NFTInfo[2]))
      setTokenOwner(NFTInfo[4])
    }
  }, [NFTInfo])

  const { data: fetchedBalance } = useReadContract({
    address: assetContract,
    abi: {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    functionName: 'balanceOf',
    args: [tokenOwner],
  })
  useEffect(() => {
    if (fetchedBalance) {
      setTokenBalance(Number(fetchedBalance))
    }
  }, [fetchedBalance])

  const { data: balanceUsdc } = useReadContract({
    address: USDC_ADDRESS,
    abi: USDC_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (balanceUsdc) {
      setUSDCBalance(Number(balanceUsdc))
    }
  }, [balanceUsdc])

  const { data: balanceUsdce } = useReadContract({
    address: USDCE_ADDRESS,
    abi: USDC_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (balanceUsdce) {
      setUSDCEBalance(Number(balanceUsdce))
    }
  }, [balanceUsdce])

  const { data: balanceUsdt } = useReadContract({
    address: USDT_ADDRESS,
    abi: USDT_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (balanceUsdt) {
      setUSDTBalance(Number(balanceUsdt))
    }
  }, [balanceUsdt])

  useEffect(() => {
    if (usdcBalance >= usdceBalance) {
      setIsUSDC(true)
      if (usdcBalance >= usdtBalance) {
        setCurrency(USDC_ADDRESS)
      } else setCurrency(USDT_ADDRESS)
    } else {
      setIsUSDC(false)
      if (usdceBalance >= usdtBalance) {
        setCurrency(USDCE_ADDRESS)
      } else setCurrency(USDT_ADDRESS)
    }
  }, [usdcBalance, usdtBalance, usdceBalance, showBuyNowModal])

  return (
    <>
      <NftreeCard
        title={assetTitle}
        image={'/assets/images/batch_images/15.png'}
        quantity={supplyLimit - currentSupply}
        price={
          pricePerToken
            ? currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
              ? pricePerToken / 10 ** 18
              : pricePerToken / 10 ** 6
            : ''
        }
        currency={currency}
        setShowBuyNowModal={setShowBuyNowModal}
      />
      {
        <NftreeBuyNowModal
          showModal={showBuyNowModal}
          setShowModal={setShowBuyNowModal}
          title={assetTitle}
          image={'/assets/images/batch_images/15.png'}
          pricePerToken={pricePerToken ? pricePerToken / 10 ** 6 : ''}
          currency={currency}
          isUSDC={isUSDC}
          setCurrency={setCurrency}
          asset_contract={assetContract}
        />
      }
    </>
  )
}

export default NftreeBatch15Card
