import { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useDisconnect } from 'wagmi'
import { MdAccountBalanceWallet } from 'react-icons/md'
import { TbUsersPlus } from "react-icons/tb";
import withClickOutside from 'utils/withClickOutside'

// Actions
import { getCbyBalance } from 'features/wallet/walletSlice'
import SmallLoader from 'components/utils/SmallLoader'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance';

const WalletInfo = forwardRef(({ open, setOpen }, ref) => {
  const dispatch = useDispatch()
  const { disconnect } = useDisconnect()
  const { address, connector } = useAccount()

  const [aCO2Balance, setACO2Balance] = useState(null)

  const { loadingCBY, CBYBalance } = useSelector(state => state.wallet)

  const disconnectWallet = async () => {
    disconnect()
  }

  useEffect(() => {
    dispatch(getCbyBalance())
    getACO2TotalBalance(address).then(data => {
      if (!data.error) {
        setACO2Balance(data)
      }
    })
  }, [address])

  return (
    <div className="flex items-center relative space-x-2 p-2 px-4 rounded-lg" ref={ref}>
      <button onClick={() => setOpen(!open)} className="flex items-center">
        <MdAccountBalanceWallet size={'45'} />
      </button>
      {open && (
        <div className="bg-white text-gray-700 font-montserrat absolute -right-24 top-12 mt-3 w-64 h-52 rounded-lg z-10">
          <div className="h-full flex flex-col justify-between p-3">
            <div className="flex flex-col items-center">
              <h3 className="p-3 text-xl text-center font-bold whitespace-nowrap">{connector.name}</h3>
              {loadingCBY ? (
                <SmallLoader />
              ) : CBYBalance ? (
                <span className="text-xl text-center font-medium my-1.5">{`${CBYBalance.balance.toFixed(2)} $CBY`}</span>
              ) : (
                'Not Connected :('
              )}
              {aCO2Balance === null ? (
                <SmallLoader />
              ) : aCO2Balance ? (
                <span className="text-xl text-center font-medium my-1.5">{`${aCO2Balance.totalBalanceFormatted.toFixed(2)} $aCO2`}</span>
              ) : (
                'Not Connected :('
              )}
            </div>
            <button
              onClick={disconnectWallet}
              className="w-full bg-green text-white p-2 rounded-lg hover:bg-light_green hover:text-carbifyOrange hover:drop-shadow-xl hover:translate-y-0.5 ease-in-out duration-500"
            >
              {'disconnect'}
            </button>
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(WalletInfo)
