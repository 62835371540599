import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { disconnect } from '@wagmi/core'
import { getUser, resetAuth } from 'features/authentication/authSlice'
import axios from 'axios'

const initialState = {
  loading: false,
  errors: null,
  isUpdated: false,
  isDeleted: false,
}

const authNodeLink = process.env.REACT_APP_API_URL_NODE_AUTH

const axiosInstance = axios.create({
  baseURL: authNodeLink,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (userData, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/api/v1/user-profile/update', userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    thunkAPI.dispatch(getUser())
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data ? error.response.data : { message: 'An error occurred' })
  }
})

export const deleteUserProfile = createAsyncThunk('user/deleteUserProfile', async (currentPassword, thunkAPI) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const res = await axiosInstance.delete('/api/v1/user-profile/delete-account', { data: { currentPassword } }, config)

    if (res.status === 200) {
      const { dispatch } = thunkAPI
      dispatch(resetAuth())
      await disconnect()
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response?.data)
  }
})

export const userProfileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetProfileErrors: state => {
      state.errors = null
    },
    resetIsUpdated: state => {
      state.isUpdated = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateUserProfile.pending, state => {
        state.loading = true
        state.isUpdated = false
        state.errors = null
      })
      .addCase(updateUserProfile.fulfilled, state => {
        state.loading = false
        state.isUpdated = true
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })
      .addCase(deleteUserProfile.pending, (state, action) => {
        state.loading = true
        state.isDeleted = false
        state.errors = null
      })
      .addCase(deleteUserProfile.fulfilled, (state, action) => {
        state.loading = false
        state.isDeleted = true
      })
      .addCase(deleteUserProfile.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })
  },
})

export const { resetProfileErrors, resetIsUpdated } = userProfileSlice.actions

export default userProfileSlice.reducer
