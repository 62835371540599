import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'

const initialState = {
  loadingAco2Rewards: false,
  loadingUserNFTrees: false,
  nftrees: null,
  aco2_rewards: null,
  errors: null,
}

export const getAvailableAco2Rewards = createAsyncThunk('staking/getAvailableAco2Rewards', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(`/staking/aco2-rewards/${account.address}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const stakingSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAvailableAco2Rewards.pending, state => {
        state.loadingAco2Rewards = true
      })
      .addCase(getAvailableAco2Rewards.fulfilled, (state, action) => {
        state.loadingAco2Rewards = false
        state.aco2_rewards = action.payload
      })
      .addCase(getAvailableAco2Rewards.rejected, (state, action) => {
        state.loadingAco2Rewards = false
        state.errors = action.payload
      })
  },
})

export const {} = stakingSlice.actions

export default stakingSlice.reducer
