import { useDispatch } from 'react-redux';
import { useAccount } from 'wagmi';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Divider } from '@mui/material';
import { checkLinkWallet, linkWallet } from 'features/wallet/WalletLinking';
import { getUser } from 'features/authentication/authSlice';

const WalletWarningPopup = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();

  const handleWalletConnect = async (e) => {
    e.preventDefault(); // Prevent the default behavior
    e.stopPropagation(); // Stop the event from propagating
    // alert('handleWalletConnect called'); // For debugging
    // setMessage('handleWalletConnect called'); // For debugging

    if (!address) {
      alert('Please connect your wallet to proceed.');
      // setMessage('No wallet connected.');
      return;
    }
    try {
      await dispatch(linkWallet(address));
      await dispatch(checkLinkWallet());
      dispatch(getUser());
      // setMessage('Wallet linked successfully.');
    } catch (error) {
      console.error('Error linking wallet:', error);
      // setMessage('Failed to link wallet.');
      alert('Failed to link wallet. Please try again.');
    }
  };

  return (
    <Dialog
      open={true}
      onClose={null} // Disable the ability to close the modal
      disableEscapeKeyDown // Prevent closing on pressing Escape
      disableBackdropClick // Prevent closing by clicking on the backdrop
      PaperProps={{
        style: {
          backgroundColor: '#083D25', // Matches the background color of the Switch Chain modal
          color: '#F8952D',  // Matches the text color of the Switch Chain modal
        }
      }}
    >
      <DialogTitle sx={{ color: '#F8952D' }}>Confirm Wallet Link</DialogTitle>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Divider sx={{ width: '100%' }} />
      </Box>
      <DialogContent>
        <DialogContentText sx={{ color: '#AEB7C0' }}>
          Please confirm that you want to permanently link your wallet to this account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={(e) => handleWalletConnect(e)}
          sx={{
            color: '#F8952D', 
            '&:hover': {
              backgroundColor: '#f8952d4d', // Matching the hover effect
            },
            fontWeight: 'bold'
          }}
        >
          Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletWarningPopup;
