import React from 'react'

const CheckCircle = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.423584" width="20" height="20" rx="10" fill="#FF962E" />
      <path
        d="M15.0902 6.79166L8.6735 13.2083L5.75684 10.2917"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckCircle
