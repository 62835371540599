import { Config } from '../../config.js';

export const withdrawACO2FromPool = async (poolId, totalTokenCount) => {
    try {

        const MAX_TOKENIDS_PER_TX = Config().max_trees_per_tx;
        let argsArray = [];
        
        // Calculate how many batches we need to process
        const batchCount = Math.ceil(totalTokenCount / MAX_TOKENIDS_PER_TX);

        for (let i = 0; i < batchCount; i++) {
            // Calculate tokens to withdraw in the current batch
            const tokensToWithdraw = Math.min(MAX_TOKENIDS_PER_TX, totalTokenCount - i * MAX_TOKENIDS_PER_TX);
            console.log("Batch / batchCount", i + 1, "/", batchCount, "tokensToWithdraw:", tokensToWithdraw);
            const args = [
                poolId.toString(),
                tokensToWithdraw
            ];
            argsArray.push({ args: args });
            console.log("Batch", i, "txReceipt:", args);
        }

        return argsArray;
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
}
