/* global BigInt */
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { toast } from 'react-toastify'
import ApproveCurrency from 'components/MarketplaceV2/utils/ApproveCurrency'
import Spinner from 'components/utils/Spinner'
import CurrencySymbol from 'components/nfts/utils/CurrencySymbol'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function MakeOfferButton({ currencyAddress, currencyAbi, listedNft, quantityToBuy, pricePerToken, endDate }) {
  const [approvedAmount, setApprovedAmount] = useState('')

  const { address: walletAddress } = useAccount()

  const { 
    data: ApprovedAmountData,
    error: ApprovedAmountDataError,
   } = useReadContract({
    address: currencyAddress,
    abi: currencyAbi,
    functionName: 'allowance',
    args: [walletAddress, MARKETPLACE_ADDRESS],
  })
  useEffect(() => {
    if (ApprovedAmountData) {
      setApprovedAmount(Number(ApprovedAmountData))
    } else if (ApprovedAmountDataError) {
      console.log('ApprovedAmountDataError',ApprovedAmountDataError);
      toast.error('Error fetching approved amount:', ApprovedAmountDataError.cause)
    }
  }, [ApprovedAmountData, ApprovedAmountDataError])

  // Make Offer
  const {
    data: makeOfferHash,
    writeContract: makeOffer,
    isPending: isMakingOffer,
    isError: isMakingOfferError,
  } = useWriteContract()
  console.log('makeOfferHash:', makeOfferHash)
  const {
    isSuccess: makingOfferSuccess,
    isError: makingOfferError,
    isLoading: makingOfferLoading
  } = useWaitForTransactionReceipt({ hash: makeOfferHash })

  // Make Offer
  const handleOffer = () => {
    makeOffer({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'offer',
      args: [listedNft.listingId, quantityToBuy, BigInt(pricePerToken * 10 ** 18), endDate],
    })
  }

  // Approve Currency
  const {
    data: approveCurrencyHash,
    writeContract: approveCurrencyWrite,
    isPending: isApprovingCurrency,
    isError: isApprovingCurrencyError,
  } = useWriteContract()
  console.log('approveCurrencyHash:', approveCurrencyHash)
  const {
    isSuccess: approvingCurrencySuccess,
    isError: approvingCurrencyError,
    isLoading: approvingCurrencyLoading,
  } = useWaitForTransactionReceipt({ hash: approveCurrencyHash })

  // Make Offer
  useEffect(() => {
    if (makingOfferSuccess) {
      toast.success('You have successfully made the offer!')
    } else if (makingOfferError || isMakingOfferError) {
      toast.error('Error while making the offer')
    }
  }, [makingOfferError, makingOfferSuccess, isMakingOfferError])

  // Approve Currency
  useEffect(() => {
    if (approvingCurrencySuccess) {
      toast.success('Currency has been approved!')
    } else if (approvingCurrencyError || isApprovingCurrencyError) {
      toast.error('Error while approving the currency')
    }
  }, [approvingCurrencyError, approvingCurrencySuccess, isApprovingCurrencyError])

  return (
    <>
      {pricePerToken * 10 ** 18 * quantityToBuy <= approvedAmount ? (
        <>
          <button
            onClick={handleOffer}
            className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
          >
            <CoinsStacked02 color="#1C4828" />
            <span>{listedNft.listingType === 0 ? 'Make offer' : 'Bid'}</span>
          </button>
          {(isMakingOffer || makingOfferLoading) && <Spinner />}
          {makingOfferSuccess && <Navigate to={'/marketplace'} />}
        </>
      ) : (
        <div>
          <button
            onClick={() => approveCurrencyWrite({
              address: currencyAddress,
              abi: currencyAbi,
              functionName: 'approve',
              args: [MARKETPLACE_ADDRESS, BigInt(pricePerToken * 10 ** 18 * quantityToBuy)],
            })}
            className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
          >
            <CoinsStacked02 color="#1C4828" />
            <span>
              Approve <CurrencySymbol selectedCurrency={currencyAddress} />
            </span>
          </button>
        </div>
      )}
      {isApprovingCurrency || approvingCurrencyLoading ? <Spinner /> : null}
    </>
  )
}

export default MakeOfferButton
