import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      suggestedMin: 0.22,
      ticks: {
        color: 'rgba(255, 255, 255, 0.75)',
      },
      grid: {
        color: 'grey',
        borderColor: 'grey',
      },
    },
    x: {
      ticks: {
        color: 'rgba(255, 255, 255, 0.75)',
      },
      grid: {
        display: false,
        borderColor: 'grey',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
}

const LineChart = history => {
  const labels = history?.graphData?.map(item =>
    new Date(item.timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    }),
  )

  const data = {
    labels,
    datasets: [
      {
        data: history?.graphData?.map(item => item.price),
        borderColor: 'rgba(248, 149, 45, 1)',
        fill: true,
        backgroundColor: 'rgba(255, 150, 46, 0.25)',
      },
    ],
  }
  return <Line options={options} data={data} />
}

export default LineChart
