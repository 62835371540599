import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarbonDropdownInput from 'components/utils/CarbonDropdownInput'
import CarbonUnitInput from 'components/utils/CarbonUnitInput'
import TotalFootprint from './TotalFootprint'

//reducers
import { setCarbonFootprint, setPrivateTransportFootprint } from 'features/footprint/footprintSlice'

//icons
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'

const PrivateTransport = ({ onBack, onNext, setPage }) => {
  const CO2_EMISSION_FACTORS = {
    diesel: 0.168, // 1 km of diesel-powered car 0.168
    lpg: 0.198, // 1 km of LPG-powered car 0.198
    gasoline: 0.174, // 1 km of gasoline-powered car 0.174
    plugIn: 0.07, // 1 km of plug-in electric car 0.07
    electric: 0, // 1 km of electric car 0
    scooter50cc: 0.0057, // 1 km of 50cc scooter 0.0057
    motorbike125cc: 0.064, // 1 km of up to 125cc motorbike 0.064
    motorbike500cc: 0.078, // 1 km of up to 500cc motorbike 0.078
    motorbike500ccAbove: 1.02, // 1 km of above 500cc motorbike 1.02
  }

  const EFFICIENCY_FACTORS = {
    gasoline: 2.31,
    diesel: 2.68,
    lpg: 1.51,
  }

  const milesValue = 1.609
  const vehicleTypes = ['Car', 'Van', '50cc scooter', '125cc motorbike', '500cc motorbike', '500cc above motorbike']
  const units = ['g/km', 'km/l']
  // const valueTypes = ['Small', 'Medium', 'Large']
  const mileageTypes = ['km', 'miles']
  const fuelTypes = ['Gasoline', 'Diesel', 'lpg', 'Electric', 'Plug-in Hybrid']
  const secondFuelTypes = ['Gasoline', 'Diesel', 'lpg']
  // const databaseTypes = ['Average van, motorbike & car']
  // const modelTypes = ['50cc scooter', '125cc motorbike', '500cc motorbike', '500cc above motorbike']

  const [formData, setFormData] = useState({
    mileage: 0,
    // database: databaseTypes[0],
    vehicle: vehicleTypes[0],
    fuel: fuelTypes[0],
    // model: modelTypes[0],
    // transportValue: valueTypes[0],
    efficiency: 0,
    secondFuel: fuelTypes[0],
  })
  const [mileageUnit, setMileageUnit] = useState(mileageTypes[0])
  const [efficencyUnit, setEfficencyUnit] = useState(units[0])
  const { mileage, database, vehicle, fuel, model, transportValue, efficiency, secondFuel } = formData
  const { carbonFootprint, privateTransportFootprint } = useSelector(state => state.footprint)

  const dispatch = useDispatch()
  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleNavigation = type => {
    setPage(false)
    if (type === 'Next') {
      onNext(true)
    } else {
      onBack(true)
    }
  }

  const calculateBikeFuel = (vehicle, mil) => {
    if (vehicle === vehicleTypes[2]) {
      return mil * CO2_EMISSION_FACTORS.scooter50cc
    } else if (vehicle === vehicleTypes[3]) {
      return mil * CO2_EMISSION_FACTORS.motorbike125cc
    } else if (vehicle === vehicleTypes[4]) {
      return mil * CO2_EMISSION_FACTORS.motorbike500cc
    } else if (vehicle === vehicleTypes[5]) {
      return mil * CO2_EMISSION_FACTORS.motorbike500ccAbove
    }
  }

  const calculateCarFuel = (fuelValue, mil) => {
    if (fuelValue === fuelTypes[0]) {
      return mil * CO2_EMISSION_FACTORS.gasoline
    } else if (fuelValue === fuelTypes[1]) {
      return mil * CO2_EMISSION_FACTORS.diesel
    } else if (fuelValue === fuelTypes[2]) {
      return mil * CO2_EMISSION_FACTORS.lpg
    } else if (fuelValue === fuelTypes[3]) {
      return mil * CO2_EMISSION_FACTORS.electric
    } else if (fuelValue === fuelTypes[4]) {
      return mil * CO2_EMISSION_FACTORS.plugIn
    }
  }

  const calculateEfficency = (eff, effUnit, mil, milUnit, fuel) => {
    let total = eff * (milUnit === mileageTypes[0] ? mil : mil * milesValue) * 1000
    if (fuel === secondFuelTypes[0]) {
      return total * (effUnit === units[0] ? EFFICIENCY_FACTORS.gasoline : EFFICIENCY_FACTORS.gasoline / 2320)
    } else if (fuel === secondFuelTypes[1]) {
      return total * (effUnit === units[0] ? EFFICIENCY_FACTORS.diesel : EFFICIENCY_FACTORS.diesel / 2650)
    } else if (fuel === secondFuelTypes[2]) {
      return total * (effUnit === units[0] ? EFFICIENCY_FACTORS.lpg : EFFICIENCY_FACTORS.lpg / 1900)
    }
  }

  const handleCalculation = e => {
    e.preventDefault()
    let sum = 0,
      milFuel = 0,
      modelEnergy = 0
    if (efficiency > 0) {
      //in terms of efficency
      modelEnergy = calculateEfficency(efficiency, efficencyUnit, mileage, mileageUnit, secondFuel)
      sum = modelEnergy
    } else {
      if (vehicle === vehicleTypes[0] || vehicle === vehicleTypes[1]) {
        // for car and van
        milFuel = calculateCarFuel(fuel, mileage)
      } else {
        //for bike categories
        milFuel = calculateBikeFuel(vehicle, mileage)
      }
      sum = mileageUnit === mileageTypes[0] ? milFuel : milFuel * milesValue
    }
    dispatch(setPrivateTransportFootprint(sum / 1000))
  }

  const offsetPrivateTransportFootprint = offsetFlag => {
    dispatch(setPrivateTransportFootprint(0))
    setFormData({
      mileage: 0,
      // database: databaseTypes[0],
      vehicle: vehicleTypes[0],
      fuel: fuelTypes[0],
      // model: modelTypes[0],
      // transportValue: valueTypes[0],
      efficiency: 0,
      secondFuel: fuelTypes[0],
    })
    if (offsetFlag) {
      window.open('https://carbondebits.io/shop/', '_blank')
    }
  }
  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [privateTransportFootprint])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      <div className="flex flex-col xl:w-[800px] 2xl:max-3xl:w-[650px] md:h-[724px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
          <div className="font-semibold text-xl">How do you get around?</div>
          <p className="text-base font-normal text-white text-opacity-80 tracking-wider">
            You can enter details for up to 2 cars.
          </p>
        </div>

        <div className="flex flex-col justify-between h-full py-4 tracking-wider">
          <form className="flex flex-col space-y-3" onSubmit={handleCalculation}>
            <div className="flex flex-col space-y-3 text-base font-normal text-white text-opacity-80 border-b border-copyToClipboard pb-12 md:h-[525px]">
              <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label="Mileage"
                    name="mileage"
                    value={mileage}
                    dropDownOptions={mileageTypes}
                    onChange={onChange}
                    onUnitChange={setMileageUnit}
                  />
                  <CarbonDropdownInput
                    label="Vehicle"
                    name="vehicle"
                    value={vehicle}
                    dropDownOptions={vehicleTypes}
                    onChange={onChange}
                    disabled={efficiency > 0}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonDropdownInput
                    label="Fuel"
                    name="fuel"
                    value={fuel}
                    dropDownOptions={fuelTypes}
                    onChange={onChange}
                    disabled={efficiency > 0 || vehicleTypes.slice(2, 6).includes(vehicle)}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col space-y-3">
                  <CarbonUnitInput
                    label="Or enter efficiency"
                    name="efficiency"
                    value={efficiency}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setEfficencyUnit}
                  />
                  <CarbonDropdownInput
                    label="Fuel"
                    name="secondFuel"
                    value={secondFuel}
                    dropDownOptions={secondFuelTypes}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <button
                type="submit"
                className="bg-carbifyOrange text-bt font-bold text-base text-center w-full h-12 rounded-lg hover:bg-[#024728] hover:text-white"
              >
                Calculate & Add To Footprint
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <TotalFootprint
            title="Private Transport"
            individualFootprint={privateTransportFootprint}
            totalFootprint={carbonFootprint}
            offsetFootprint={offsetPrivateTransportFootprint}
          />
          {mileage > 0 && efficiency <= 0 ? (
            <div className="flex flex-col p-3">
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>
                    {mileage} {mileageUnit}
                  </b>{' '}
                  in a {vehicle} using fuel {fuel}
                </p>
                <button
                  onClick={() => offsetPrivateTransportFootprint(false)}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          ) : null}
          {efficiency > 0 ? (
            <div className="flex flex-col p-3">
              <div className="flex justify-between">
                <p className="text-base">
                  <b>
                    {efficiency} {efficencyUnit}
                  </b>{' '}
                  with {secondFuel} and traveled {mileage} {mileageUnit}
                </p>
                <button
                  onClick={() => offsetPrivateTransportFootprint(false)}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex justify-between xxxs:max-md:mt-3 mt-4">
          <div className="flex items-center justify-center pr-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-cardGreen hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-cardGreen hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateTransport
