import React from 'react'
import CurrencySymbol from './CurrencySymbol'

function TotalProjectedEarning({ totalPrice, commission, selectedCurrency }) {
  return (
    <div className="w-full flex justify-between mb-2.5">
      <p className="text-[22px] font-semibold">TOTAL Projected Earnings</p>
      <p className="text-[22px] font-semibold">
        {totalPrice && commission ? totalPrice - (totalPrice * commission) / 100 : 0}{' '}
        {<CurrencySymbol selectedCurrency={selectedCurrency} />}
      </p>
    </div>
  )
}

export default TotalProjectedEarning
