import React, { useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AuthLayout from 'layouts/AuthLayout'
import PasswordField from 'components/utils/PasswordField'
import { resetPasswordConfirm } from 'features/authentication/authSlice'

function ResetPassword() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  console.log(token)
  const { isAuthenticated, passwordUpdated } = useSelector(state => state.authentication)

  const [formData, setFormData] = useState({
    password: '',
  })

  const { password, confirmPassword } = formData

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('The passwords do not match!')
      return
    }

    dispatch(resetPasswordConfirm({ password, confirmPassword, token }))
      .unwrap()
      .then(response => {
        toast.success('Password reset successfully!')
      })
      .catch(error => {
        toast.error(error.msg || 'An unexpected error occurred. Please try again later.')
      })
  }

  if (passwordUpdated) return <Navigate to="/login" />
  if (isAuthenticated) return <Navigate to="/dashboard" />

  return (
    <AuthLayout title={'Carbify | Reset Password'}>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">Reset Password</h1>
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <PasswordField onChange={onChange} value={formData.password} label={'Password'} name={'password'} />
          <PasswordField
            label={'Confirm Password'}
            onChange={onChange}
            value={formData.confirmPassword}
            name={'confirmPassword'}
          />
          <button
            type="submit"
            className="w-full bg-carbifyOrange text-bt focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Reset Password
          </button>
        </form>
      </div>
    </AuthLayout>
  )
}

export default ResetPassword
