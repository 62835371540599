export const lockCBYIntoTrees = async (amount, treeData) => {
    try {

        // Filter trees that are not locked and not staked
        const unlockedAndUnstakedTrees = treeData.filter(tree => !tree.is_locked && !tree.is_staked);

        if (amount > unlockedAndUnstakedTrees.length) {
            return { error: "Not enough trees!" };
        }

        // Limit the number of trees to the requested amount
        const amountOfTrees = Math.min(amount, unlockedAndUnstakedTrees.length);
        console.log("Amount of Trees:", amountOfTrees);

        return { success: true, lockedTreeCount: amountOfTrees, unlockedAndUnstakedTrees: unlockedAndUnstakedTrees };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
