import { NFTREE_ABI } from 'constants/BlockchainConstants';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useAccount, useReadContract } from 'wagmi'

function OwnedNftrees({ contractAddress  }) {

  const {address: walletAddress}  = useAccount();


  const [ownedNftreeBalance, setOwnedNftreeBalance] = useState(0)


  const {
    data: balanceOfData,
    error: balanceOfError,
  } = useReadContract({
    address: contractAddress,
    abi: NFTREE_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })

  useEffect(() => {
    
    console.log('walletAddress',  walletAddress);
    console.log('fetching balance', contractAddress, balanceOfData);
    if (balanceOfData) {
      console.log('fetching balance', contractAddress, balanceOfData);
      setOwnedNftreeBalance(Number(balanceOfData))
    } else if (balanceOfError) {
      console.log('balanceOfError', balanceOfError);
      toast.error('Error fetching balance of:', balanceOfError.cause)
    }
  }, [balanceOfData, balanceOfError])

  return (
    <>
      { Number(ownedNftreeBalance) > 0 &&
        <div className="w-full bg-[#B3C8BF] rounded-lg">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[#232323] text-2xl font-semibold">You own x{ownedNftreeBalance}</p>
              </div>
              <div>
                <button className="bg-[#024728] text-base font-bold py-1 px-3 rounded-lg">List for sale</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default OwnedNftrees
