import React, { useEffect, useState } from 'react'
import CurrencySymbol from 'components/nfts/utils/CurrencySymbol'
import ModalsWrapper from 'components/utils/ModalsWrapper'
import MakeOfferButton from '../buttons/MakeOfferButton'
import UnixDatePicker from 'components/nfts/inputFields/UnixDatePicker'
import AmountField from 'components/nfts/inputFields/AmountField'

// COnstants
import { CBY_ADDRESS, USDC_ADDRESS, CBY_ABI } from 'constants/BlockchainConstants'
import moment from 'moment'

function MakeOfferModal({ listedNft, showModal, setShowModal }) {
  const [currencyAddress, setCurrencyAddress] = useState('')
  const [currencyAbi, setCurrencyAbi] = useState('')
  const [pricePerToken, setPricePerToken] = useState('')
  const [quantityToBuy, setQuantityToBuy] = useState(1)
  const [endDate, setEndDate] = useState(moment().add(1, 'days')._d)

  useEffect(() => {
    if (listedNft?.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()) {
      setCurrencyAddress(CBY_ADDRESS.toLowerCase())
      setCurrencyAbi(CBY_ABI)
    } else {
      setCurrencyAddress(USDC_ADDRESS.toLowerCase())
      setCurrencyAbi('')
    }
  }, [listedNft])

  const toUTCTimeStamp = date => {
    const utcTimestamp = new Date(date).toISOString()
    const utcTimestampInMilliseconds = Date.parse(utcTimestamp)
    return Math.floor(utcTimestampInMilliseconds / 1000)
  }

  const handleQuantityChange = e => {
    // Ensure the value is a non-negative integer (you can customize this validation)
    const newValue = Math.max(0, parseInt(e.target.value, 10) || 0)
    setQuantityToBuy(newValue)
  }

  const handleIncQuantityToBuy = () => {
    setQuantityToBuy(quantityToBuy + 1)
  }

  const handleDecQuantityToBuy = () => {
    setQuantityToBuy(quantityToBuy - 1)
  }

  return (
    <ModalsWrapper
      title={listedNft.listingType === 0 ? 'Make an offer' : 'Place Bid'}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="overflow-y-auto max-h-[80vh]">
        {/* Nft image & title */}
        <div className="flex w-full space-x-6 pb-4 mb-3 border-b">
          <div className="w-16 h-24">
            <img className="rounded-[5px]" src={listedNft?.metadata.image} />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-xl font-bold">{listedNft?.title}</h3>
            <p className="text-base font-semibold">Carbify NFTs</p>
          </div>
        </div>
        {/*  */}
        {/* <div className="w-full flex justify-between mb-2.5">
          <p className="text-[22px] font-semibold">Quantity</p>
          <div className="flex justify-between px-3 items-center rounded-3xl bg-white bg-opacity-25 font-normal text-3xl">
            <button
              disabled={quantityToBuy === 1}
              onClick={handleDecQuantityToBuy}
              className={`pr-4 ${quantityToBuy === 1 ? 'text-zinc-400' : ''}`}
            >
              -
            </button>
            <span className="text-xl font-normal">{quantityToBuy}</span>
            <button
              disabled={quantityToBuy === listedNft.quantity}
              onClick={handleIncQuantityToBuy}
              className={`pl-4 ${quantityToBuy === listedNft.quantity ? 'text-zinc-400' : ''}`}
            >
              +
            </button>
          </div>
        </div> */}
        {/* End Date Picker */}
        {listedNft.listingType === 0 && (
          <div className="mb-2.5">
            <UnixDatePicker label={'Valid till'} setDate={setEndDate} currDate={endDate} />
          </div>
        )}
        {/* Price Per Token */}
        <div className="mb-2.5">
          <AmountField label={'Price'} setPrice={setPricePerToken} />
        </div>
        {/*  */}
        <div className="w-full flex justify-between mb-2.5">
          <p className="text-[22px] font-semibold">Total price</p>
          <p className="text-[22px] font-semibold">
            {pricePerToken * quantityToBuy} {<CurrencySymbol selectedCurrency={listedNft?.currency} />}
          </p>
        </div>
        {/*  */}
        <div>
          <MakeOfferButton
            currencyAddress={currencyAddress}
            currencyAbi={currencyAbi}
            listedNft={listedNft}
            quantityToBuy={quantityToBuy}
            pricePerToken={pricePerToken}
            endDate={toUTCTimeStamp(endDate)}
          />
        </div>
      </div>
    </ModalsWrapper>
  )
}

export default MakeOfferModal
