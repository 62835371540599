import React from 'react'
import RarePlotCard from './cards/RarePlotCard'
import StandardPlotCard from './cards/StandardPlotCard'
import NftreeBatch10Card from './cards/NftreeBatch10Card'
import NftreeBatch11Card from './cards/NftreeBatch11Card'
import NftreeBatch12Card from './cards/NftreeBatch12Card'
import NftreeBatch13Card from './cards/NftreeBatch13Card'
import { HeroCard } from 'components/dashboard/CertificateManagement '
import NftreeBatch15Card from './cards/NftreeBatch15Card'

function CarbifyListings() {
  return (
    <>
      <HeroCard 
        topText={'Carbify Listings'}
        bottomText={'The Carbify Listings page features all the objects for sale directly from our company, with detailed prices and specifications.'}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-3 gap-y-3">
        {/*<NftreeBatch10Card />*/}
        {/*<NftreeBatch11Card />*/}
        {/*<NftreeBatch12Card />*/}
        {/*<NftreeBatch13Card />*/}
        <NftreeBatch15Card />
        <RarePlotCard />
        <StandardPlotCard />
      </div>
    </>
  )
}

export default CarbifyListings
