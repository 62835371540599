import React from 'react'
import { useSelector } from 'react-redux'
import Attribute from './Attribute'

function Attributes() {
  const { listedNft } = useSelector(state => state.listings)

  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="p-4">
        <div>
          <p className="text-2xl font-semibold mb-4">Attributes</p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {listedNft?.metadata?.attributes?.map((attribute, index) => (
              <Attribute key={index} heading={attribute.trait_type} text={attribute.value} />
            ))}
            {listedNft.tokenType === 1 && (
              <>
                <Attribute heading="STAKED" text={listedNft.isStaked ? 'Yes' : 'No'} />
                <Attribute
                  heading="AMOUNT CBY IN NFT"
                  text={listedNft.cby > 0 ? (listedNft.cby / 10 ** 18).toPrecision(3) : 0}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Attributes
