import axios from 'axios';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getWalletActivity = async (walletAddress) => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/nft/listing/activity?wallet_address=${walletAddress}`);
        const { data } = response;
        return data;
    } catch (err) {
        console.error("Error:", err.message);
        return null;
    }
};
