import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();
const MAX_BATCH_SIZE = Config().max_calls_per_multicall_batch || 20;  // Define max batch size or set a sensible default

export const getLockedAmountAverage = async (erc721Data) => {
    try {
        let totalLockedAmount = 0;
        let totalLockedAmountWithoutFee = 0;
        let totalFee = 0;
        let lockedTokenCount = 0;
        let totalFeePercentage = 0;  // Accumulate fee percentage to compute an average later

        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({
            multicallAddress: Config().contract_addresses.multi_call,
            provider
        });
        const stakingContract = new web3.eth.Contract(STAKING_ABI, STAKING_ADDRESS);

        const processBatch = async (tokens) => {
            const calls = tokens.map(token => stakingContract.methods.getStakeInfo(token.token_id, token.contract_address.toLowerCase()));
            return await multicall.aggregate(calls);
        };

        if (erc721Data) {
            for (let i = 0; i < erc721Data.length; i += MAX_BATCH_SIZE) {
                const batchTokens = erc721Data.slice(i, Math.min(i + MAX_BATCH_SIZE, erc721Data.length));
                const result = await processBatch(batchTokens.filter(token => token.is_locked));

                for (const treeStakeData of result) {
                    lockedTokenCount++;
                    const stakingTime = Number(treeStakeData[2]);

                    const currentTime = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
                    const stakedDuration = (currentTime - stakingTime) / (60 * 60 * 24 * 365); // Duration in years

                    let feePercentage = 0; // Reset for each token
                    if (stakedDuration < 1) {
                        feePercentage = 7.5;
                    } else if (stakedDuration < 2) {
                        feePercentage = 3.75;
                    } else {
                        feePercentage = 1.75;
                    }

                    const formattedCBYAmount = Number(treeStakeData[5]) / 10 ** 18;
                    const feeAmount = (formattedCBYAmount * feePercentage) / 100;
                    totalLockedAmount += formattedCBYAmount;
                    totalLockedAmountWithoutFee += (formattedCBYAmount - feeAmount);
                    totalFee += feeAmount;
                    totalFeePercentage += feePercentage; // Accumulate for averaging
                }
            }
        }

        const averageLockedAmount = lockedTokenCount ? totalLockedAmount / lockedTokenCount : 0;
        const averageLockedAmountWithoutFee = lockedTokenCount ? totalLockedAmountWithoutFee / lockedTokenCount : 0;
        const averageFee = lockedTokenCount ? totalFee / lockedTokenCount : 0;
        const averageFeePercentage = lockedTokenCount ? totalFeePercentage / lockedTokenCount : 0;

        return {
            averageLockedAmount,
            averageLockedAmountWithoutFee,
            averageFee,
            feePercentage: averageFeePercentage  // Corrected to provide average percentage
        };
    } catch (error) {
        console.error("Error:", error);
        return {
            error: error.message,
            averageLockedAmount: 0,
            averageLockedAmountWithoutFee: 0,
            averageFee: 0,
            feePercentage: 0
        };
    }
};
