import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { ethers } from 'ethers';
import { STAKING_ABI } from '../../constants/BlockchainConstants';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const STAKING = STAKING_ABI;
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();

const MAX_CALLS_PER_MULTICALL_BATCH = Config().max_calls_per_multicall_batch || 20;  // Define max batch size or set a sensible default

export const getStakingACO2TotalPendingReward = async (erc721Data) => {
    try {
        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({ multicallAddress: Config().contract_addresses.multi_call, provider });
        const stakingContract = new web3.eth.Contract(STAKING, STAKING_ADDRESS);

        const lockedAndStakedTrees = erc721Data.filter(tree => tree.is_locked && tree.is_staked);

        let totalPendingReward = ethers.BigNumber.from(0);
        let moreDetaildPendingReward = [];

        // Batch process rewards calculations
        const processRewardsInBatches = async (tokens) => {
            let results = [];
            let calls = [];
            for (let i = 0; i < tokens.length; i++) {
                calls.push(stakingContract.methods.calculateRewards(tokens[i].token_id, tokens[i].contract_address));
                if (calls.length >= MAX_CALLS_PER_MULTICALL_BATCH || i === tokens.length - 1) {
                    const batchResults = await multicall.aggregate(calls);
                    results = results.concat(batchResults); // Adjust according to the actual response structure
                    calls = []; // Clear calls for next batch
                }
            }
            return results;
        };

        // Execute the batch processing
        const results = await processRewardsInBatches(lockedAndStakedTrees);

        results.forEach((pendingReward, index) => {
            const pendingRewardFormatted = ethers.utils.formatUnits(pendingReward, 18);
            totalPendingReward = totalPendingReward.add(pendingReward);

            moreDetaildPendingReward.push({
                nftree_id: lockedAndStakedTrees[index].token_id,
                batch_address: lockedAndStakedTrees[index].contract_address,
                pending_reward_formatted: pendingRewardFormatted
            });
        });

        const totalPendingRewardFormatted = ethers.utils.formatUnits(totalPendingReward, 18);
        return { totalPendingRewardFormatted, moreDetaildPendingReward };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
