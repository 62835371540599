/* global BigInt */
import React, { useEffect, useState } from 'react'

function AmountField({ label, name = 'amount', listingType = 0, price = '', setPrice }) {
  const [value, setValue] = useState(price)

  useEffect(() => {
    if (listingType === 1) {
      setValue(0)
    }
    setPrice(value)
  }, [value, listingType])

  return (
    <div className="space-y-1">
      <label htmlFor={name} className="block text-xl font-bold cursor-pointer">
        {label}
      </label>
      <input
        readOnly={listingType === 1}
        type="number"
        name={name}
        id={name}
        placeholder="Amount"
        required="true"
        value={value}
        onChange={e => setValue(e.target.value)}
        className="text-white-600 bg-white bg-opacity-10 border border-white border-opacity-50 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
      />
    </div>
  )
}

export default AmountField
