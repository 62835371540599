import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { ToastContainer } from 'react-toastify'
import { config } from './BlockchainConfig'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { AuthenticatedRoutes, WalletAddressRoutes } from 'utils/PrivateRoutes'
import RegistrationPage from 'pages/RegistrationPage'
import LoginPage from 'pages/LoginPage'
import TwoFactorAuthenticator from 'pages/TwoFactorAuthenticator'
import ConnectWallet from 'pages/ConnectWallet'
import Dashboard from 'pages/Dashboard'
import Nfts from 'pages/Nfts'
import NftreesPage from 'pages/NftreesPage'
import PlotsPage from 'pages/PlotsPage'
import ProfilePage from 'pages/ProfilePage'
import Staking from 'pages/Staking'
import ACO2Pools from 'pages/ACO2Pools'
import MarketPlace from 'pages/Marketplace'
import PageNotFound from 'pages/PageNotFound'
import ForgotPassword from 'pages/ForgotPassword'
import CarbonCalculator from 'pages/CarbonCalculator'
import ListingDetails from 'pages/ListingDetails'

// Reducers
import NetworkSwitcher from 'components/NetworkSwitcher'

import NftsPage from 'pages/NftsPage'
import ResetPassword from 'pages/ForgotPasswordConfirm'
import TreeOverview from 'pages/TreeOverview'
import MapTree from 'pages/MapTree'
import CertificatesPage from 'pages/CertificatesPage'


function App() {
  const queryClient = new QueryClient()

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <NetworkSwitcher />
          <ToastContainer />
          <Routes>

            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/carbon-footprint-calculator" element={<CarbonCalculator publicCalculator={true} />} />

            <Route element={<AuthenticatedRoutes />}>
              <Route path="/two-factor-authentication" element={<TwoFactorAuthenticator />} />
              <Route path="/connect-wallet" element={<ConnectWallet />} />
              <Route element={<WalletAddressRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/nfts" element={<Nfts />} />
                <Route path="/nftrees/:asset_contract" element={<NftreesPage />} />
                <Route path="/plots/:asset_contract" element={<PlotsPage />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/marketplace" element={<MarketPlace />} />
                <Route path="/aCO2-pools" element={<ACO2Pools />} />
                <Route path="/carbon-calculator" element={<CarbonCalculator />} />
                <Route path="/tree-overview" element={<TreeOverview />} />
                <Route path="/map-tree" element={<MapTree />} />
                <Route path="/profile" element={<ProfilePage />} />
                {/* <Route path="/certificates" element={<Certificates />} /> */}
                <Route path="/certificates" element={<CertificatesPage />} />
                <Route path="/carbifyNfts/:asset_contract" element={<NftsPage />} />
                <Route path="/listing/:listing_id" element={<ListingDetails />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App
