import React from 'react'

function ModalsWrapper({ showModal, setShowModal, title, children }) {
  const closeModal = () => {
    setShowModal(false)
  }

  const handleOverlayClick = () => {
    closeModal()
  }

  const handleModalClick = event => {
    event.stopPropagation()
  }

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50" onClick={handleOverlayClick}>
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="relative w-[727px] z-10" onClick={handleModalClick}>
            <div className="border rounded-lg shadow-lg relative bg-light_green bg-opacity-70 p-6 outline-none focus:outline-none">
              <div className="flex items-start justify-between mb-3">
                <h3 className="text-3xl font-semibold">{title}</h3>
                <button
                  onClick={closeModal}
                  type="button"
                  className="text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalsWrapper
