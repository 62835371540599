import React from 'react'

function SelectCountry({ handleSelectCountry, userCountry, countries, isCalculator }) {
  return (
    <>
      <select
        value={userCountry}
        onChange={handleSelectCountry}
        id="country"
        className={`appearance-none ${
          isCalculator ? 'bg-cardGreen' : 'bg-inputFields'
        } border border-white border-opacity-50 text-white text-sm rounded-lg focus:outline-0 focus:ring-blue-500 focus:border-carbifyOrange block w-full p-3`}
      >
        {countries.map(country => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
    </>
  )
}

export default SelectCountry
