import React, { useState } from 'react';
import { Snackbar, Slide, IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Notification = ({ title, message, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //   TransitionComponent={(props) => <Slide {...props} direction="left" />}
      autoHideDuration={10000} // Adjust the duration as needed
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#083D25',
          color: '#F8952D',
          padding: '16px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          minWidth: '300px',
          maxWidth: '300px',
        },
      }}
    >
      <Box 
        display="flex" 
        alignItems="center" 
        sx={{ 
            backgroundColor: '#083D25', 
            // backgroundColor: '#023B14B3',
            padding: '20px', 
            borderRadius: '10px',
            borderColor: 'white',
            borderWidth: '1px',
            maxWidth: '450px',
        }}
    >
        <Box flexGrow={1}>
          <Typography variant="h6" component="div" sx={{ color: '#F8952D', fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: '#AEB7C0' }}>
            {message}
          </Typography>
        </Box>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          sx={{
            marginLeft: 2,
            color: '#F8952D', // Default color for the button
            '&:hover': {
              backgroundColor: 'rgba(248, 149, 45, 0.1)', // Slight orange background on hover
            },
            '&:focus': {
              backgroundColor: 'rgba(248, 149, 45, 0.2)', // Slightly more intense on focus
            },
            '&:active': {
              backgroundColor: 'rgba(248, 149, 45, 0.3)', // Even more intense on active state
            },
          }}
        >
          <CloseIcon
            fontSize="small"
            sx={{
              color: '#F8952D', // Color for the icon itself
            }}
          />
        </IconButton>
      </Box>
    </Snackbar>
  );
};

export default Notification;
