import { ethers } from "ethers";
import { isClaimedStakingMerkle } from "./isClaimedStakedMerkle.js";

export const claimStakingMerkle = async (stakedMerkleProofData, walletAddress) => {
    // Arrange the stakedMerkleProofData array so that the enteries with the most amount of tokens (amount) are first
    stakedMerkleProofData.sort((a, b) => {
        const aAmount = ethers.BigNumber.from(a.amount.hex);
        const bAmount = ethers.BigNumber.from(b.amount.hex);
    
        if (aAmount.gt(bAmount)) {
            return -1; // a is greater than b
        } else if (aAmount.lt(bAmount)) {
            return 1; // b is greater than a
        } else {
            return 0; // a and b are equal
        }
    });
    // // Remove any items from the array that have already been claimed
    // console.log("stakedMerkleProofData before:", stakedMerkleProofData);
    // const isClaimed = await isClaimedStakingMerkle(stakedMerkleProofData, walletAddress, true)
    // console.log("isClaimed:", isClaimed);
    // for (let i = stakedMerkleProofData.length - 1; i >= 0; i--) {
    //     if (isClaimed[i].isClaimed === true) {
    //         // Remove the item from the array
    //         stakedMerkleProofData.splice(i, 1);
    //     }
    // }
    // console.log("stakedMerkleProofData after:", stakedMerkleProofData);
    try {

        const amounts = stakedMerkleProofData.map((item) => item.amount);
        const proofs = stakedMerkleProofData.map((item) => item.proof);

        console.log("stakedMerkleProofData amounts:", amounts);
        console.log("stakedMerkleProofData proofs:", proofs);

        return {
            success: true,
            amounts: amounts,
            proofs: proofs
        }

    } catch (error) {
        console.log("error:", error);
        return { error: error.message };
    }
};