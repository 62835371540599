import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Reducers
import { setAlert, resetAlert } from 'features/Alerts/alertSlice'

function FlashMessage({ type, errors, setSuccessMessage, children = null, action = null }) {
  const dispatch = useDispatch()
  const { showAlert } = useSelector(state => state.alert)

  useEffect(() => {
    dispatch(setAlert())
    const id = setTimeout(() => {
      dispatch(resetAlert())
      dispatch(action())
      setSuccessMessage('')
    }, 3000)
    return () => {
      clearTimeout(id)
    }
  }, [dispatch])

  return (
    <>
      {children && (
        <div className="fixed top-30 right-4">
          {
            <p className={`${type === 'error' ? 'text-red-500' : 'text-bt'} bg-copyToClipboard p-4  rounded-lg mb-1`}>
              {children}
            </p>
          }
        </div>
      )}
      {showAlert && errors && (
        <div className="fixed top-10 right-4">
          {errors.detail && (
            <p
              className={`${
                type === 'error' ? 'text-red-500' : 'text-green-500'
              } bg-copyToClipboard p-4  rounded-lg mb-1`}
            >
              {errors.detail}
            </p>
          )}
          {errors.email && (
            <p
              className={`${
                type === 'error' ? 'text-red-500' : 'text-green-500'
              } bg-copyToClipboard p-4  rounded-lg mb-1`}
            >
              {errors.email[0]}
            </p>
          )}
          {errors.username && (
            <p
              className={`${
                type === 'error' ? 'text-red-500' : 'text-green-500'
              } bg-copyToClipboard p-4  rounded-lg mb-1`}
            >
              {errors.username[0]}
            </p>
          )}
          {errors.current_password && (
            <p
              className={`${
                type === 'error' ? 'text-red-500' : 'text-green-500'
              } bg-copyToClipboard p-4  rounded-lg mb-1`}
            >
              {errors.current_password[0]}
            </p>
          )}

          {errors.password &&
            errors.password.map(error => (
              <p
                className={`${
                  type === 'error' ? 'text-red-500' : 'text-green-500'
                } bg-copyToClipboard p-4 rounded-lg mb-1`}
              >
                {error}
              </p>
            ))}
          {errors.new_password &&
            errors.new_password.map(error => (
              <p
                className={`${
                  type === 'error' ? 'text-red-500' : 'text-green-500'
                } bg-copyToClipboard p-4 rounded-lg mb-1`}
              >
                {error}
              </p>
            ))}
        </div>
      )}
    </>
  )
}

export default FlashMessage
