import axios from "axios";

const aCO2_API = process.env.REACT_APP_ACO2_DATA_API;

export const getDetailsAndOverviewUser = async (userTreeData) => {
    try {
        const body = {
            nft_ids: userTreeData.map((tree) => Number(tree.token_id)),
            nft_addresses: userTreeData.map((tree) => tree.contract_address),
        };

        const response = await axios.post(`${aCO2_API}/api/nft/details`, body);

        let details_data = [...response.data.details_data];

        let overview_data = {
            total_trees: 0,
            total_batches: 0,
            total_aco2_generated: 0,
            total_aco2_burned_in_certificates: 0
        }

        overview_data.total_trees = response.data.details_data.length;
        overview_data.total_batches = new Set(response.data.details_data.map((item) => item.batch_address)).size;

        for (const detail of response.data.details_data) {
            overview_data.total_aco2_generated += detail.aco2_generated_over_lifetime;
            overview_data.total_aco2_burned_in_certificates += detail.aco2_burned_in_certificates;
        }

        return { overview_data: overview_data, details_data: details_data };
    } catch (error) {
        console.log("Error in getDetailsAndOverviewUser: ", error);
        return null;
    }
};