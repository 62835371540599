import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useDisconnect } from 'wagmi'

// Icons
import ChevronDownIcon from 'icons/ChevronDownIcon'
import ChevronUpIcon from 'icons/ChevronUpIcon'
import Profile from 'icons/Profile'
import Logout from 'icons/Logout'

// Reducers
import { logout } from 'features/authentication/authSlice'
import Settings from 'icons/Settings'

function ProfileBoxDropdown({ open }) {
  const dispatch = useDispatch()
  const { disconnect } = useDisconnect()

  const handleLogout = async () => {
    try {
      await disconnect()

      setTimeout(async () => {
        await dispatch(logout()).unwrap()
      }, 1000)
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <div>
      <div className="p-5 jsutify-between">{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
      {open && (
        <div className="flex flex-col justify-between bg-white absolute right-0 5xl:-right-20 mt-1 w-48 h-fit rounded-lg z-10 text-sm text-black">
          <div>
            <Link
              to="/profile"
              className="block px-4 py-2 rounded-tl-lg rounded-tr-lg hover:bg-green hover:text-white flex border-b border-green"
            >
              <Settings />
              <div className="px-3 h-4">Settings</div>
            </Link>
          </div>
          {/* <div>
            <Link
              to="/settings"
              className="block px-4 py-2 hover:bg-green hover:text-white flex border-b border-green"
            >
              <Settings />
              <div className="px-3 h-4">Settings</div>
            </Link>
          </div> */}
          <div>
            <button
              onClick={handleLogout}
              className="block w-full rounded-bl-lg rounded-br-lg px-4 py-2 text-sm text-gray-700 hover:bg-red-500 hover:text-white hover:bg-green flex"
            >
              <Logout />
              <div className="px-3">Log out</div>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileBoxDropdown
