import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();
const GENESIS_ADDRESS = `${Config().contract_addresses.genesis_address}`;
const RARE_ADDRESS = `${Config().contract_addresses.rare_address}`;
const STANDARD_ADDRESS = `${Config().contract_addresses.standard_address}`;
const MAX_CALLS_PER_MULTICALL_BATCH = Config().max_calls_per_multicall_batch;

export const getStakingRewardsMultiple = async (erc721Data) => {

    let stakedTreesCount = 0;
    let treesStakedOnGenesisPlots = 0;
    let treesStakedOnRarePlots = 0;
    let treesStakedOnStandardPlots = 0;
    let reward = 0;
    try {
        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({
            multicallAddress: Config().contract_addresses.multi_call,
            provider
        });
        const stakingContract = new web3.eth.Contract(STAKING_ABI, STAKING_ADDRESS);

        if (erc721Data) {
            const tokenIds = erc721Data.map((item) => item.token_id);
            const contractAddresses = erc721Data.map((item) => item.contract_address.toLowerCase());

            let calls = [];
            let results = [];

            // Batch processing
            for (let i = 0; i < tokenIds.length; i += MAX_CALLS_PER_MULTICALL_BATCH) {
                const tokenIdChunk = tokenIds.slice(i, i + MAX_CALLS_PER_MULTICALL_BATCH);
                const contractAddressChunk = contractAddresses.slice(i, i + MAX_CALLS_PER_MULTICALL_BATCH);
                calls.push(stakingContract.methods.getStakeInfoMultiple(tokenIdChunk, contractAddressChunk));
                if (calls.length >= MAX_CALLS_PER_MULTICALL_BATCH || i + MAX_CALLS_PER_MULTICALL_BATCH >= tokenIds.length) {
                    const batchResults = await multicall.aggregate(calls);
                    results = results.concat(batchResults); // Ensure your response handling logic matches the actual multicall response structure
                    calls = []; // Reset the calls array for the next batch
                }
            }

            let combinedResults = results.flat();

            // Processing results
            combinedResults.forEach((treeStakeData) => {
                stakedTreesCount++;
                const plotAddress = treeStakeData[10];
                if (plotAddress === GENESIS_ADDRESS) {
                    treesStakedOnGenesisPlots++;
                } else if (plotAddress === RARE_ADDRESS) {
                    treesStakedOnRarePlots++;
                } else if (plotAddress === STANDARD_ADDRESS) {
                    treesStakedOnStandardPlots++;
                }
            });

            reward = calculateReward(treesStakedOnGenesisPlots, treesStakedOnRarePlots, treesStakedOnStandardPlots);
        }

        return {
            stakingReward: reward,
            stakedTreesCount,
            treesStakedOnGenesisPlots,
            treesStakedOnRarePlots,
            treesStakedOnStandardPlots
        };
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message, stakingReward: 0, stakedTreesCount: 0, treesStakedOnGenesisPlots: 0, treesStakedOnRarePlots: 0, treesStakedOnStandardPlots: 0 };
    }
}

function calculateReward(genesisCount, rareCount, standardCount) {
    return (genesisCount * (175 * 1)) + 
           (rareCount * (175 * 0.9)) + 
           (standardCount * (175 * 0.8));
}
