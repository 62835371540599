import axios from "axios";

const api_url = process.env.REACT_APP_ACO2_DATA_API;

export const getDetailsAndOverviewCertificates = async (userCertificates, userTrees) => {
    try {
        // go through each certificate.attributes object and create a new array of objects with nftree_id and batch_address
        let certificateNftreeData = [];
        for (let certificate of userCertificates) {
            for (let attr of certificate.attributes) {
                certificateNftreeData.push({
                    nftree_id: attr.nftree_id,
                    batch_address: attr.batch_address
                });
            }
        }

        const certificateData = {
            nft_ids: certificateNftreeData.map((item) => item.nftree_id),
            nft_addresses: certificateNftreeData.map((item) => item.batch_address.toLowerCase()),
        };

        const treeData = {
            nft_ids: userTrees.map((tree) => Number(tree.token_id)),
            nft_addresses: userTrees.map((tree) => tree.contract_address.toLowerCase()),
        };

        // create the body object with the certificate and tree data no duplicate pairs
        const body = {
            nft_ids: [...new Set([...certificateData.nft_ids, ...treeData.nft_ids])],
            nft_addresses: [...new Set([...certificateData.nft_addresses, ...treeData.nft_addresses])],
        };

        const response = await axios.post(`${api_url}/api/nft/details`, body);

        // create a copy of the response data
        let details_data = [...response.data.details_data];

        // construct the overview data object
        let overview_data = {
            total_trees: 0,
            total_batches: 0,
            total_aco2_generated: 0,
            total_aco2_burned_in_certificates: 0
        };

        // get the total trees and total batches
        overview_data.total_trees = response.data.details_data.length;
        overview_data.total_batches = new Set(response.data.details_data.map((item) => item.batch_address)).size;

        // get the total aco2 generated and total aco2 burned in certificates
        for (let detail of response.data.details_data) {
            overview_data.total_aco2_generated += detail.aco2_generated_over_lifetime;
            for (let certificate of userCertificates) {
                if (Object.keys(detail.aco2_burned_per_certificate).includes(certificate.name)) {
                    overview_data.total_aco2_burned_in_certificates += Number(detail.aco2_burned_per_certificate[certificate.name]);
                }
            }
        }

        return { overview_data: overview_data, details_data: details_data}
    } catch (error) {
        console.error("Error fetching Nftree details: ", error);
        return null;
    }
};
