import React from 'react'

const QuantityField = ({ label, max, quantity, setQuantity, name = 'quantity', required = true }) => {
  const handleMax = () => {
    setQuantity(max)
  }

  const handleQuantity = e => {
    setQuantity(e.target.value)
  }

  return (
    <div className="flex-1 space-y-1">
      <label htmlFor={name} className="block text-xl font-bold">
        {label}
      </label>
      <div className="relative">
        <input
          type="number"
          name={name}
          id={name}
          className="appearance-none tracking-wide text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
          placeholder={label}
          required={required}
          value={quantity}
          min={1}
          max={max}
          onChange={handleQuantity}
        />
        <div
          onClick={handleMax}
          className="absolute inset-y-0 right-0 pr-2 flex items-center text-sm leading-5 cursor-pointer"
        >
          <span className="text-[#024728] text-sm font-medium bg-secondary px-2.5 py-0.5 rounded">Max</span>
        </div>
      </div>
    </div>
  )
}

export default QuantityField
