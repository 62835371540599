import { ethers } from 'ethers';
import { Config } from '../../config.js';
import { aCO2Pool_ABI } from '../../constants/BlockchainConstants.js'

const aCO2Pool_ADDRESS = `${Config().contract_addresses.aco2_pool_address}`;
const TEAM_MEMBER_ROLE = '0x5146a08baf902532d0ee2f909971144f12ca32651cd70cbee1117cddfb3b3b33';

export const checkIfHasTeamRole = async (address) => {
    try {
        const provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        const aCO2Pool = new ethers.Contract(aCO2Pool_ADDRESS, aCO2Pool_ABI, provider);
        const hasRole = await aCO2Pool.hasRole(TEAM_MEMBER_ROLE, address);
        return hasRole;
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
}
