import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Divider } from '@mui/material';

const WarningModal = ({ open, title, message, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose} // Allows the modal to be closed via the provided onClose function
      disableEscapeKeyDown // Prevent closing on pressing Escape
      disableBackdropClick // Prevent closing by clicking on the backdrop
      PaperProps={{
        style: {
          backgroundColor: '#083D25', // Matches the background color of the example modal
          color: '#F8952D',  // Matches the text color of the example modal
        }
      }}
    >
      <DialogTitle sx={{ color: '#F8952D' }}>{title}</DialogTitle>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Divider sx={{ width: '100%' }} />
      </Box>
      <DialogContent>
        <DialogContentText sx={{ color: '#AEB7C0' }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} // Calls the provided onClose function when clicked
          sx={{
            color: '#F8952D', 
            '&:hover': {
              backgroundColor: '#f8952d4d', // Matching the hover effect
            },
            fontWeight: 'bold'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
