import React, { useEffect, useState } from 'react'
import moment from 'moment'

//icons
import { GiSandsOfTime } from 'react-icons/gi'

const CountdownTimerListings = ({ startTime, endTime }) => {
  const [timeDisplay, setTimeDisplay] = useState('')

  useEffect(() => {
    // Convert Unix timestamp to a Moment object
    const startMoment = moment.unix(startTime)
    const endMoment = moment.unix(endTime)
    // Current moment
    const now = moment()

    if (now.isBefore(startMoment)) {
      // Calculate the elapsed time in minutes
      const duration = moment.duration(startMoment.diff(now))
      const elapsedMinutes = duration.asMinutes()

      // Calculate elapsed hours and remaining minutes
      const elapsedHours = Math.floor(elapsedMinutes / 60)
      const remainingMinutes = Math.floor(elapsedMinutes % 60)

      setTimeDisplay(`${elapsedHours} h, ${remainingMinutes} m`)
    } else if (now.isAfter(endMoment)) {
      setTimeDisplay(`Expired`)
    } else {
      // Calculate the remaining time in minutes
      const duration = moment.duration(endMoment.diff(now))
      const remainingMinutes = duration.asMinutes()

      // Calculate remaining hours and remaining minutes
      const remainingHours = Math.floor(remainingMinutes / 60)
      const remainingMinutesInHour = Math.floor(remainingMinutes % 60)

      setTimeDisplay(`${remainingHours}h, ${remainingMinutesInHour}m`)
    }
  }, [])

  return (
    <div className="flex space-x-1">
      <GiSandsOfTime />
      <div className="tracking-wider text-xs font-semibold">{timeDisplay}</div>
    </div>
  )
}

export default CountdownTimerListings
