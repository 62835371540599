import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const CarbonDropdownInput = ({
  label,
  name,
  value,
  dropDownOptions,
  onChange,
  placeholder,
  required = true,
  disabled = false,
}) => {
  const [showOption, setShowOption] = useState(false)
  const handleDropdown = () => {
    setShowOption(!showOption)
  }

  return (
    <div className="relative space-y-1 3xl:w-[360px] xs:max-3xl:w-[280px] xxxs:max-xs:w-[195px] font-medium text-base">
      <label htmlFor={name}>{label}</label>
      <div className="relative">
        <select
          value={value}
          disabled={disabled}
          onChange={onChange}
          id={name}
          name={name}
          required={required}
          className="appearance-none bg-white bg-opacity-10 text-white border border-white border-opacity-50 rounded-lg w-full h-10 focus:outline-0 focus:ring-blue-500 focus:border-carbifyOrange p-2 pr-10"
          onClick={handleDropdown}
        >
          {placeholder && !value ? (
            <option value="" disabled>
              {placeholder}
            </option>
          ) : null}
          {dropDownOptions.map(opt => (
            <option key={opt} value={opt} className='bg-darkGreen'>
              {opt}
            </option>
          ))}
        </select>
        <p className="absolute bottom-2 right-3 flex space-x-1">
          {showOption ? (
            <FiChevronUp className="w-6 h-6" onClick={handleDropdown} />
          ) : (
            <FiChevronDown className="w-6 h-6" onClick={handleDropdown} />
          )}
        </p>
      </div>
      {/* {showOption ? (
        <div className="absolute z-50 right-0 rounded-lg flex flex-col w-32 bg-white text-darkGreen">
          {dropDownOptions.map((item, index) => {
            return (
              <p
                key={index}
                className={`flex justify-center items-center p-1 border-b border-darkGreen hover:bg-[#024728] ${index === 0 && 'hover:rounded-t-lg'} ${index === dropDownOptions.length - 1 && 'hover:rounded-b-lg'} hover:text-white`}
                onClick={() => {
                  setShowOption(false)
                  onChange({ target: { name, value: item } })
                }}
              >
                {item}
              </p>
            )
          })}
        </div>
      ) : null} */}
    </div>
  )
}

export default CarbonDropdownInput
