import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  loading: false,
  errors: null,
  flightEmission: null,
}

const API_KEY = 'Bearer Ymqn8XQBFUyiBEJMnrELSw'

export const getFlightEmission = createAsyncThunk(
  'carbon-calculator/getFlightEmission',
  async ({ type, passengers, legs }, thunkAPI) => {
    const data = {
      type,
      passengers,
      legs,
    }

    try {
      const res = await axios('https://www.carboninterface.com/api/v1/estimates', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: API_KEY,
        },
        data,
      })

      if (res.status === 201) {
        return res.data
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const flightSlice = createSlice({
  name: 'flight',
  initialState,
  reducers: {
    resetFlightEmission: state => {
      state.flightEmission = null
    },
    resetFlightErrors: state => {
      state.errors = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFlightEmission.pending, state => {
        state.loading = true
        state.errors = null
      })
      .addCase(getFlightEmission.fulfilled, (state, action) => {
        state.loading = false
        state.flightEmission = action.payload
      })
      .addCase(getFlightEmission.rejected, (state, action) => {
        state.loading = false
        state.errors = action.payload
      })
  },
})
export const { resetFlightEmission, resetFlightErrors } = flightSlice.actions
export default flightSlice.reducer
