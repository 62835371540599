import axios from 'axios';
import { ethers } from 'ethers';
import { Config } from '../../config.js';

const crcDataApi = Config().crc_api;

export const getACO2TotalUnusedBurnedAmount = async (walletAddress) => {
    try {
        const response = await axios.get(`${crcDataApi}/api/tokens/unused/${walletAddress}`);
        if (response.data.unusedTokens.length === 0) {
            return { totalUnusedBurnedAmount: "0", moreDetaildUnusedBurnBalance: [] };
        } else {
            let totalUnusedBurnedAmount = response.data.totalUnusedTokens.replace('n', '');  // Remove 'n' from total amount if present
            let moreDetaildUnusedBurnedAmount = response.data.unusedTokens.map((token) => ({
                ...token,
                unused: token.amount.replace('n', '')  // Remove 'n' from each unused amount
            }));

            return {
                totalUnusedBurnedAmount: ethers.utils.formatUnits(totalUnusedBurnedAmount, 18),
                moreDetaildUnusedBurnBalance: moreDetaildUnusedBurnedAmount,
            };
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { totalUnusedBurnedAmount: "0", moreDetaildUnusedBurnBalance: [], error: err.message };
    }
}
