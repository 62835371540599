import React from 'react'


const MONTHS = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const RequestedCertificateContent = ({ userCertificatesRequests, setCertificateGenerationData }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="relative flex-grow">
        <div className="flex-grow">
            <div className="flex flex-col w-full bg-darkGreen bg-opacity-70 border rounded-lg mb-6 overflow-hidden">
                <div className="px-4 py-3 flex flex-col flex-1 h-graphCardHeight">
                    <div className="overflow-auto h-full">
                        <div className="hidden lg:block">
                        <table className="w-full text-white text-center table-fixed ">
                            <thead className="sticky top-0">
                                <tr className="border-b border-light_green font-medium text-base tracking-wider">
                                    <th className="px-4 py-2 w-2/12">Certificate Id</th>
                                    <th className="px-4 py-2 w-1/12">Amount(KG)</th>
                                    <th className="px-4 py-2 w-3/12">Title</th>
                                    <th className="px-4 py-2 w-1/12">Month</th>
                                    <th className="px-4 py-2 w-1/12">Year</th>
                                    <th className="px-4 py-2 w-2/12">Issued Date</th>
                                    <th className="px-4 py-2 w-2/12">Carbon Offset Certificate</th>
                                </tr>
                            </thead>
                        </table>
                        <div className="h-graphCardHeight overflow-y-scroll">
                            <table className="w-full text-white text-center table-fixed">
                                <tbody>
                                    {userCertificatesRequests !== null && userCertificatesRequests !== undefined && (
                                        userCertificatesRequests?.map(certificate => (
                                            <tr key={certificate.id} className="border-b border-light_green">
                                                <td className="px-4 py-2 w-2/12 text-center">N/A</td>
                                                <td className="px-4 py-2 w-1/12 text-center">{certificate.amount}</td>
                                                <td className="px-4 py-2 w-3/12 text-center">{certificate.title}</td>
                                                <td className="px-4 py-2 w-1/12 text-center">{MONTHS[parseInt(certificate.date.split('-')[1]) - 1]}</td>
                                                <td className="px-4 py-2 w-1/12 text-center">{certificate.date.split('-')[0]}</td>
                                                <td className="px-4 py-2 w-2/12 text-center">{certificate.date.split('T')[0]}</td>
                                                <td className="px-4 py-2 w-2/12 text-center">
                                                    <button 
                                                        className={`${certificate.status === 'rejected' ? 'bg-red disabled:hover:bg-red' : 'bg-carbifyOrange disabled:hover:bg-carbifyOrange'} text-white px-4 py-2 rounded-lg hover:bg-opacity-80 transition duration-300 disabled:opacity-50`}
                                                        onClick={() => setCertificateGenerationData({ amount: certificate.amount, title: certificate.title, reason: certificate.reason, date: certificate.date, _id: certificate._id })}
                                                        disabled={certificate.status === 'pending' || certificate.status === 'rejected'}
                                                    >
                                                        {certificate.status === 'pending' ? 'Pending' : certificate.status === 'rejected' ? 'Rejected' : 'Mint'}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className="block lg:hidden">
                            <div className="h-graphCardHeight overflow-y-scroll">
                                {userCertificatesRequests !== null && userCertificatesRequests !== undefined && (
                                    userCertificatesRequests?.map(certificate => (
                                        <div key={certificate.id} className="border-b border-light_green p-4">
                                            <p className="text-white"><strong>Certificate Id:</strong> N/A</p>
                                            <p className="text-white"><strong>Amount(KG):</strong> {certificate.amount}</p>
                                            <p className="text-white"><strong>Title:</strong> {certificate.title}</p>
                                            <p className="text-white"><strong>Month:</strong> {MONTHS[parseInt(certificate.date.split('-')[1]) - 1]}</p>
                                            <p className="text-white"><strong>Year:</strong> {certificate.date.split('-')[0]}</p>
                                            <p className="text-white"><strong>Issued Date:</strong> {certificate.date.split('T')[0]}</p>
                                            <div className="flex justify-center">
                                                <button 
                                                    className={`${certificate.status === 'rejected' ? 'bg-red disabled:hover:bg-red' : 'bg-carbifyOrange disabled:hover:bg-carbifyOrange'} text-white px-4 py-2 rounded-lg hover:bg-opacity-80 transition duration-300 disabled:opacity-50`}
                                                    onClick={() => setCertificateGenerationData({ amount: certificate.amount, title: certificate.title, reason: certificate.reason, date: certificate.date, _id: certificate._id })}
                                                    disabled={certificate.status === 'pending' || certificate.status === 'rejected'}
                                                >
                                                    {certificate.status === 'pending' ? 'Pending' : certificate.status === 'rejected' ? 'Rejected' : 'Mint'}
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default RequestedCertificateContent
