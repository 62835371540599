import { React } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import TradeStationHeaderCountdown from './TradeStationHeaderCountdown'

function TradeStationHeader() {
  const { listedNft } = useSelector(state => state.listings)
  const startTime = listedNft?.startTime
  const endTime = listedNft?.endTime

  return (
    <div className="w-full rounded-lg">
      <div className="px-3 py-1.5">
        {startTime > moment.utc() ? (
          <p className="text-base font-normal tracking-wide mb-2.5">
            Sale starts {moment.unix(startTime).format('DD-MM-YYYY HH:mm:ss')}
          </p>
        ) : (
          <p className="text-base font-normal tracking-wide mb-2.5">
            Sale ends {moment.unix(endTime).format('DD-MM-YYYY HH:mm:ss')}
          </p>
        )}
        <TradeStationHeaderCountdown startTime={startTime} endTime={endTime} />
      </div>
    </div>
  )
}

export default TradeStationHeader
