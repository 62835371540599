import React from 'react'

function HorizentalBarChartIcon({ width = 24, height = 24, active }) {
  return (
    <div>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          active ? 'stroke-carbifyOrange' : 'stroke-white group-hover:stroke-carbifyOrange ease-in-out duration-500'
        } `}
      >
        <path
          d="M19.25 10.875V6.625C19.25 5.92493 19.25 5.5749 19.1138 5.30751C18.9939 5.07231 18.8027 4.88108 18.5675 4.76124C18.3001 4.625 17.9501 4.625 17.25 4.625H1.75M14.25 17.125V21.375C14.25 22.0751 14.25 22.4251 14.1138 22.6925C13.9939 22.9277 13.8027 23.1189 13.5675 23.2388C13.3001 23.375 12.9501 23.375 12.25 23.375H1.75M1.75 1.5L1.75 26.5M1.75 17.125L22.25 17.125C22.9501 17.125 23.3001 17.125 23.5675 16.9888C23.8027 16.8689 23.9939 16.6777 24.1138 16.4425C24.25 16.1751 24.25 15.8251 24.25 15.125V12.875C24.25 12.1749 24.25 11.8249 24.1138 11.5575C23.9939 11.3223 23.8027 11.1311 23.5675 11.0112C23.3001 10.875 22.9501 10.875 22.25 10.875L1.75 10.875L1.75 17.125Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default HorizentalBarChartIcon
