import CardContainer from "components/containers/CardContainer";

const TotalPlotsCard = ({ totalGenesisPlots, totalPremiumPlots, totalStandardPlots, totalPlots }) => {
    return (
        <>
            <>
            <CardContainer styles={'h-full border rounded-lg shadow-lg bg-darkGreen bg-opacity-70'}>
                <div className="flex w-full justify-between">
                    <span className="text-3xl font-extrabold">Landplots owned</span>
                    <div className="absolute -top-0 rounded-bl-lg right-0 px-2 z-10 bg-white text-bt text-lg font-bold text-center rounded-tr-[7px]">{totalPlots === null ? "0" : totalPlots}</div>
                </div>
                <div className="flex flex-col justify-center w-full h-full">
                    <div className='mt-14 space-y-1'>
                    <p className="text-base font-semibold">{totalGenesisPlots === null ? "0" : totalGenesisPlots} Genesis Plots</p>
                    <p className="text-base font-semibold">{totalPremiumPlots === null ? "0" : totalPremiumPlots} Rare Plots</p>
                    <p className="text-base font-semibold">{totalStandardPlots === null ? "0" : totalStandardPlots} Standard Plots</p>
                    </div>
                    <img className="absolute w-52 h-52 right-0 bottom-1 2xl:top-[86px]" src="/assets/images/dashboard_landplot_card.png" alt="landplot" />
                </div>
            </CardContainer>
            </>
        </>
    );
};

export default TotalPlotsCard;
