import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import axios from 'axios'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'

const aco2DataApi = process.env.REACT_APP_ACO2_DATA_API

export const getCbyBalance = createAsyncThunk('wallet/getCbyBalance', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    /*const res = await axiosInstance.get(`/wallets/balance/${account.address}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })*/
    const res = await axios.get(`${aco2DataApi}/api/cby/balance`, {
      params: {
        wallet_address: account.address,
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

const initialState = {
  walletAddress: localStorage.getItem('walletAddress') ? JSON.parse(localStorage.getItem('walletAddress')) : null,
  CBYBalance: null,
  loading: false,
  loadingCBY: false,
  errors: null,
  success: false,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload
      localStorage.setItem('walletAddress', JSON.stringify(action.payload))
    },
    resetWallet: state => {
      state.walletAddress = null
      state.loading = false
      state.errors = null
      state.success = false
      localStorage.removeItem('walletAddress')
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCbyBalance.pending, state => {
        state.loadingCBY = true
      })
      .addCase(getCbyBalance.fulfilled, (state, action) => {
        state.loadingCBY = false
        state.CBYBalance = action.payload
      })
      .addCase(getCbyBalance.rejected, (state, action) => {
        state.loadingCBY = false
        state.errors = action.payload
      })
  },
})

export const { setWalletAddress, resetWallet } = walletSlice.actions

export default walletSlice.reducer
