import React from 'react'

function SwitchHorizontal({ color = 'white', width = 24, height = 24 }) {
  return (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4238 15H1.42383M1.42383 15L5.42383 11M1.42383 15L5.42383 19M1.42383 5H17.4238M17.4238 5L13.4238 1M17.4238 5L13.4238 9"
        stroke={`${color}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SwitchHorizontal
