import axios from 'axios';
import { ethers } from 'ethers';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getACO2TotalBalance = async (walletAddress) => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/aco2/wallet/count?wallet_address=${walletAddress}`);
        
        const tokenData = response.data.token_count;

        let totalBalance = 0;

        tokenData.forEach((token) => {
            totalBalance += Number(ethers.utils.formatUnits(token.balance, token.decimals));
        });

        return { totalBalanceFormatted: totalBalance, moreDetaildBalance: tokenData };
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
