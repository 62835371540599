import React, { useState } from 'react'
import { useAccount } from 'wagmi'
import AddressCopyToClipBoard from 'components/MarketplaceV2/utils/AddressCopyToClipBoard'
import CountdownTimer from 'components/MarketplaceV2/components/cards/cardComponents/CountdownTimer'
import CurrencySymbol from 'components/nfts/utils/CurrencySymbol'
import AcceptOfferButton from '../buttons/AcceptOfferButton'
import { CBY_ADDRESS } from 'constants/BlockchainConstants'

function Offers({ listingType, offersList }) {
  const { address: walletAddress } = useAccount()

  const [isExpired, setIsExpired] = useState(false)

  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="p-4 pr-2">
        <div>
          <p className="text-2xl font-semibold">Offers</p>
        </div>
        <div className="w-full overflow-x-auto md:scrollbar-hide scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin rounded-lg h-72 py-3 flex flex-col space-y-3">
          <div className="h-full overflow-y-scroll scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin">
            <table className="w-full">
              <thead className="text-left">
                <tr className="sticky -top-0.5 border-b border-[#527766] bg-darkGreen bg-opacity-80 rounded-lg font-medium text-base tracking-wider z-10">
                  <th className="px-1 py-1">Unit Price</th>
                  <th className="px-1 py-1">Qty</th>
                  <th className="px-1 py-1">Total offer</th>
                  {listingType === 0 && <th className="px-1 py-1">Expiry Date</th>}
                  <th className="px-1 py-1">From</th>
                  <th className="px-1 py-1"></th>
                </tr>
              </thead>
              <tbody>
                {offersList?.map(offer => (
                  <tr key={offer.offeror}>
                    <td className="px-1 py-2 font-bold">
                      {offer.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                        ? offer.totalOfferAmount / offer.quantityWanted / 10 ** 18
                        : offer.totalOfferAmount / offer.quantityWanted / 10 ** 18}{' '}
                      <CurrencySymbol selectedCurrency={offer.currency} />
                    </td>
                    <td className="px-1 py-2">{offer.quantityWanted}</td>
                    <td className="px-1 py-2">
                      {offer.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                        ? offer.totalOfferAmount / 10 ** 18
                        : offer.totalOfferAmount / 10 ** 18}{' '}
                      <CurrencySymbol selectedCurrency={offer.currency} />
                    </td>
                    {listingType === 0 && (
                      <td className="px-1 py-2">
                        <CountdownTimer setIsExpired={setIsExpired} time={offer.expirationTimestamp} />
                      </td>
                    )}
                    <td className="px-1 py-2 text-[#6FA8FF]">
                      <AddressCopyToClipBoard address={offer.offeror} />
                    </td>
                    {offer.listingOwner === walletAddress.toLowerCase() && listingType === 0 && (
                      <td className="">
                        {!isExpired && (
                          <div className="flex items-center">
                            <AcceptOfferButton
                              listingId={offer.listingId}
                              offeror={offer.offeror}
                              pricePerToken={
                                offer.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                                  ? offer.totalOfferAmount / offer.quantityWanted / 10 ** 18
                                  : offer.totalOfferAmount / offer.quantityWanted / 10 ** 18
                              }
                              decimals={offer.currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? 18 : 18}
                            />
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offers
