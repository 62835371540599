import React, { useState, useRef, useEffect } from 'react'
import Layout from 'layouts/Layout'
import PageHeader from '../components/PageHeading'
import GetStarted from 'components/co2Calculator/GetStarted'
import House from 'components/co2Calculator/House'
import AirTravel from 'components/co2Calculator/AirTravel'
import PrivateTransport from 'components/co2Calculator/PrivateTransport'
import PublicTransport from 'components/co2Calculator/PublicTransport'
import Secondary from 'components/co2Calculator/Secondary'
import Results from 'components/co2Calculator/Results'

//icons
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { GiBus, GiCityCar, GiCommercialAirplane, GiGolfFlag, GiHouse } from 'react-icons/gi'

const CarbonCalculator = ({ publicCalculator }) => {
  const [step, setStep] = useState(1)
  const [progress, setProgress] = useState(1)
  const [getStarted, setGetStarted] = useState(true)
  const [house, setHouse] = useState(false)
  const [airTravel, setAirTravel] = useState(false)
  const [privateTransport, setPrivateTransport] = useState(false)
  const [publicTransport, setPublicTransport] = useState(false)
  const [secondary, setSecondary] = useState(false)
  const [results, setResults] = useState(false)

  const divRef1 = useRef(null)
  const divRef2 = useRef(null)
  const divRef3 = useRef(null)
  const divRef4 = useRef(null)
  const divRef5 = useRef(null)
  const divRef6 = useRef(null)
  const divRef7 = useRef(null)

  const handleSelect = (current, setPage) => {
    current.forEach(c => c(false))
    setPage(true)
  }

  const calculateWidth = (array, space) => {
    let sum = 0
    array.forEach(item => {
      sum += item.current.offsetWidth
    })
    const screenWidth = window.innerWidth
    if (screenWidth < 1280) {
      sum += space * 12
    } else if (1280 < screenWidth && screenWidth <= 1440) {
      sum += space * 25
    } else {
      sum += space * 60
    }

    return sum
  }

  const slideLeft = () => {
    let slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft - 200
  }

  const slideRight = () => {
    let slider = document.getElementById('slider')
    slider.scrollLeft = slider.scrollLeft + 200
  }

  const navigateLeft = () => {
    if (getStarted) {
      setGetStarted(true)
    } else if (house) {
      setHouse(false)
      setGetStarted(true)
    } else if (airTravel) {
      setAirTravel(false)
      setHouse(true)
    } else if (privateTransport) {
      setPrivateTransport(false)
      setAirTravel(true)
    } else if (publicTransport) {
      setPublicTransport(false)
      setPrivateTransport(true)
    } else if (secondary) {
      setSecondary(false)
      setPublicTransport(true)
    } else {
      setResults(false)
      setSecondary(true)
    }
  }

  const navigateRight = () => {
    if (getStarted) {
      setGetStarted(false)
      setHouse(true)
    } else if (house) {
      setHouse(false)
      setAirTravel(true)
    } else if (airTravel) {
      setAirTravel(false)
      setPrivateTransport(true)
    } else if (privateTransport) {
      setPrivateTransport(false)
      setPublicTransport(true)
    } else if (publicTransport) {
      setPublicTransport(false)
      setSecondary(true)
    } else if (secondary) {
      setSecondary(false)
      setResults(true)
    } else {
      setResults(false)
      setGetStarted(true)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (getStarted) {
        setStep(1)
        setProgress(calculateWidth([divRef1], 0) + 'px')
      } else if (house) {
        setStep(2)
        setProgress(calculateWidth([divRef1, divRef2], 1) + 'px')
      } else if (airTravel) {
        setStep(3)
        setProgress(calculateWidth([divRef1, divRef2, divRef3], 2) + 'px')
      } else if (privateTransport) {
        setStep(4)
        setProgress(calculateWidth([divRef1, divRef2, divRef3, divRef4], 3) + 'px')
      } else if (publicTransport) {
        setStep(5)
        setProgress(calculateWidth([divRef1, divRef2, divRef3, divRef4, divRef5], 4) + 'px')
      } else if (secondary) {
        setStep(6)
        setProgress(calculateWidth([divRef1, divRef2, divRef3, divRef4, divRef5, divRef6], 5) + 'px')
      } else {
        setStep(7)
        setProgress(calculateWidth([divRef1, divRef2, divRef3, divRef4, divRef5, divRef6, divRef7], 6) + 'px')
      }
    }
    handleResize() // call once on mount

    window.addEventListener('resize', handleResize) // add listener for resize event

    return () => {
      window.removeEventListener('resize', handleResize) // remove listener on unmount
    }
  }, [getStarted, house, airTravel, privateTransport, publicTransport, secondary, results])

  return (
    <Layout
      title="Carbify | Carbon Footprint Calculator"
      content="carbon footprint calculator"
      publicCalculator={publicCalculator}
    >
      <PageHeader>Carbon Footprint Calculator</PageHeader>
      <div className="flex flex-col space-y-7 px-4">
        <div className="text-center">
          <p className="text-lg text-white mb-4 ">
            Reduce your environmental impact by understanding and minimizing your carbon footprint. Discover practical tips, eco-friendly lifestyle choices, and sustainable practices to lower your carbon emissions on our webpage. Join us in the journey towards a greener, more sustainable future.
          </p>
          <div className="relative flex flex-col">
            <MdChevronLeft
              size={40}
              className="absolute bottom-1 -left-4 hidden md:max-xl:block opacity-50 cursor-pointer hover:opacity-100"
              onClick={slideLeft}
            />
            <MdChevronRight
              size={40}
              className="hidden md:max-xl:block absolute bottom-2 right-5 xs:max-md:right-3 lg:max-xl:-right-3 xxxs:max-xs:right-0 xl:hidden opacity-50 cursor-pointer hover:opacity-100"
              onClick={slideRight}
            />
            <div className="relative flex justify-between items-center font-semibold text-2xl tracking-wider py-3 md:hidden">
              {getStarted ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Get Started</p>
              ) : null}
              {house ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">House</p>
              ) : null}
              {airTravel ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Air Travel</p>
              ) : null}
              {privateTransport ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Private Transport</p>
              ) : null}
              {publicTransport ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Public Transport</p>
              ) : null}
              {secondary ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Secondary</p>
              ) : null}
              {results ? (
                <p className="text-center text-carbifyOrange w-full underline underline-offset-8">Results</p>
              ) : null}
              <MdChevronLeft
                size={40}
                className="absolute bottom-1 -left-4 opacity-50 cursor-pointer hover:opacity-100"
                onClick={navigateLeft}
              />
              <MdChevronRight
                size={40}
                className="absolute bottom-2 right-5 xs:max-md:right-3 xxxs:max-xs:right-0 lg:hidden opacity-50 cursor-pointer hover:opacity-100"
                onClick={navigateRight}
              />
            </div>
            <div
              id="slider"
              className="xxxs:max-md:hidden relative flex justify-center items-center font-semibold text-2xl tracking-wider py-3 overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
            >
              <div className="flex justify-center items-center space-x-4 mb-6 flex-wrap">
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef1}
                    onClick={() => {
                      handleSelect(
                        [setHouse, setAirTravel, setPrivateTransport, setPublicTransport, setSecondary, setResults],
                        setGetStarted,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 1 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 1 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiGolfFlag size={30} />
                    </div>
                    <span className="text-sm">Get Started</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 2 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef2}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setAirTravel, setPrivateTransport, setPublicTransport, setSecondary, setResults],
                        setHouse,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 2 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 2 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiHouse size={30} />
                    </div>
                    <span className="text-sm">Your House</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 3 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef3}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setHouse, setPrivateTransport, setPublicTransport, setSecondary, setResults],
                        setAirTravel,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 3 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 3 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiCommercialAirplane size={30} />
                    </div>
                    <span className="text-sm">Air Travel</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 4 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef4}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setAirTravel, setHouse, setPublicTransport, setSecondary, setResults],
                        setPrivateTransport,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 4 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 4 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiCityCar size={30} />
                    </div>
                    <span className="text-sm">Private Transport</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 5 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef5}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setAirTravel, setPrivateTransport, setHouse, setSecondary, setResults],
                        setPublicTransport,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 5 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 5 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiBus size={30} />
                    </div>
                    <span className="text-sm">Public Transport</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 6 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max mb-4 md:mb-0">
                  <div
                    ref={divRef6}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setAirTravel, setPrivateTransport, setPublicTransport, setHouse, setResults],
                        setSecondary,
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 6 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 6 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <GiBus size={30} />
                    </div>
                    <span className="text-sm">Secondary Details</span>
                  </div>
                  <div className={`h-0.5 border-t-2 border-dotted w-12 ${step >= 7 ? 'border-carbifyOrange' : 'border-white'}`}></div>
                </div>
                <div className="flex items-center space-x-2 min-w-max">
                  <div
                    ref={divRef7}
                    onClick={() => {
                      handleSelect(
                        [setGetStarted, setAirTravel, setPrivateTransport, setPublicTransport, setHouse, setSecondary],
                        setResults
                      );
                    }}
                    style={{ userSelect: 'none' }}
                    className={`flex flex-col items-center ${step >= 7 ? 'text-carbifyOrange' : 'text-white'}`}
                  >
                    <div className={`p-1 ${step >= 7 ? 'text-white bg-light_green rounded-full border ' : 'text-gray-500 bg-gray-200 rounded-full'}`}>
                      <AiOutlineInfoCircle size={30} />
                    </div>
                    <span className="text-sm">Results</span>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        {getStarted ? <GetStarted onNext={setHouse} setPage={setGetStarted} /> : null}
        {house ? <House onBack={setGetStarted} onNext={setAirTravel} setPage={setHouse} /> : null}
        {airTravel ? <AirTravel onBack={setHouse} onNext={setPrivateTransport} setPage={setAirTravel} /> : null}
        {privateTransport ? (
          <PrivateTransport onBack={setAirTravel} onNext={setPublicTransport} setPage={setPrivateTransport} />
        ) : null}
        {publicTransport ? (
          <PublicTransport onBack={setPrivateTransport} onNext={setSecondary} setPage={setPublicTransport} />
        ) : null}
        {secondary ? <Secondary onBack={setPublicTransport} onNext={setResults} setPage={setSecondary} /> : null}
        {results ? <Results onBack={setSecondary} onNext={setGetStarted} setPage={setResults} /> : null}
      </div>
    </Layout>
  )
}

export default CarbonCalculator
