import React from 'react'

function PageHeading({ children, styles }) {
  const defaultStyles = ' text-grey  ';
  return (
    <div className="mb-6">
      <h1 className={`text-4xl font-bold tracking-wider ${styles ? styles : defaultStyles}`}>{children}</h1>
    </div>
  )
}

export const PageHeadingWithTooltip = ({ children, tooltip, styles }) => {
  return (
    <div className="mb-6">
      <h1 className={`text-4xl font-bold tracking-wider ${styles ? styles : 'text-grey'}`}>{children}{tooltip}</h1>
    </div>
  )
}

export default PageHeading
