import React, { useState, useEffect } from 'react'
import AddressCopyToClipBoard from '../../utils/AddressCopyToClipBoard'
import { useReadContract } from 'wagmi'
import { toast } from 'react-toastify'

// Constants
import { PLOTS_ABI, CarbifyNFTABI } from 'constants/BlockchainConstants'

function NftDetails({ contractAddress, tokenId, tokenStandard }) {
  const [creatorEarning, setCreatorEarning] = useState('')
  const [abi, setABI] = useState('')

  useEffect(() => {
    setABI(
      contractAddress?.toLowerCase() === process.env.REACT_APP_CARBIFYNFT_ADDRESS?.toLowerCase()
        ? CarbifyNFTABI
        : PLOTS_ABI,
    )
  }, [contractAddress])

  const { 
    data: creatorEarningFee,
    error: creatorEarningFeeError,
  } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName:
      contractAddress?.toLowerCase() === process.env.REACT_APP_CARBIFYNFT_ADDRESS?.toLowerCase()
        ? 'getRoyaltyFee'
        : 'royaltyBps',
  })
  useEffect(() => {
    if (creatorEarningFee) {
      setCreatorEarning(creatorEarningFee)
    } else if (creatorEarningFeeError) {
      toast.error('Error fetching creator earning fee:', creatorEarningFeeError.cause)
    }
  }, [creatorEarningFee, creatorEarningFeeError])

  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="p-6">
        <div className="flex justify-between mb-2.5">
          <p className="text-base font-semibold">Contract Address</p>
          <p className="text-base font-normal text-secondary">
            {contractAddress && <AddressCopyToClipBoard address={contractAddress} />}
          </p>
        </div>

        <div className="flex justify-between mb-2.5">
          <p className="text-base font-semibold">Token ID</p>
          <p className="text-base font-normal">#{tokenId && tokenId}</p>
        </div>

        <div className="flex justify-between mb-2.5">
          <p className="text-base font-semibold">Token Standard</p>
          <p className="text-base font-normal">{tokenStandard && tokenStandard}</p>
        </div>

        <div className="flex justify-between mb-2.5">
          <p className="text-base font-semibold">Chain</p>
          <p className="text-base font-normal">Polygon</p>
        </div>

        <div className="flex justify-between mb-2.5">
          <p className="text-base font-semibold">Creator Earnings</p>
          <p className="text-base font-normal">{creatorEarning ? Number(creatorEarning) / 100 : 0}%</p>
        </div>
      </div>
    </div>
  )
}

export default NftDetails
