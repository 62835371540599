import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import { toast } from 'react-toastify'
import { getAllPoolData } from 'features/aco2-pools/getAllPoolData'
import Spinner from 'components/utils/Spinner'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance'
import { getERC721Data } from 'features/dashboard/getERC721Data'
import { isApprovedForAll } from 'features/aco2-pools/isApprovedACO2'
import { getUserPoolData } from 'features/aco2-pools/getUserPoolData'
import { checkIfHasTeamRole } from 'features/aco2-pools/checkIfHasTeamRole'
import { getAllPoolLogos } from 'features/aco2-pools/getAllPoolLogos'
import SuccessPopup from 'components/aco2-pools/SuccessPopup'
import SellPopup from 'components/aco2-pools/SellPopup'
import PoolsMap from 'components/aco2-pools/PoolsMap'

function ACO2Pools() {
  const { address } = useAccount()

  const [showSellAco2Popup, setShowSellAco2Popup] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [isApprovedACO2, setIsApprovedACO2] = useState(false)
  const [isTeamMember, setIsTeamMember] = useState(false)
  const [loading, setLoading] = useState(false)

  const [amount, setAmount] = useState('')

  const [selectedPool, setSelectedPool] = useState(null)
  const [successTxHash, setSuccessTxHash] = useState([])
  const [aco2TotalBalance, setAco2TotalBalance] = useState(null)
  const [totalPledgedAmount, setTotalPledgedAmount] = useState(null)
  const [userPoolData, setUserPoolData] = useState(null)
  const [aco2Pools, setAco2Pools] = useState(null)
  const [poolLogos, setPoolLogos] = useState(null)
  const [erc721Data, setErc721Data] = useState(null)

  const handleClosePopup = () => {
    setShowSellAco2Popup(false)
    setShowSuccessPopup(false)
    setTotalPledgedAmount(null)
    setSelectedPool(null)
    setSuccessTxHash([])
    setAmount('')
  }

  useEffect(() => {
    getAllPoolData().then(data => {
      if (data === null) {
        console.error('Error:', data)
      } else {
      setAco2Pools(data.pool_data);
      }
    })
    getAllPoolLogos().then(data => {
      setPoolLogos(data)
    })
    getUserPoolData(address).then(data => {
      setUserPoolData(data.user_pool_data)
    })
    getERC721Data(address).then(data => {
      setErc721Data(data.token_data)
    })
    isApprovedForAll(address).then(data => {
      setIsApprovedACO2(data)
    })
    checkIfHasTeamRole(address).then(data => {
      setIsTeamMember(data)
    })
  }, [address])

  useEffect(() => {
    if (address && erc721Data) {
      getACO2TotalBalance(address).then(data => {
        if (!data.error) {
          setAco2TotalBalance(data)
        }
      })
    }
  }, [erc721Data])

  useEffect(() => {
    if (selectedPool && userPoolData) {
      const pool = userPoolData.find(pool => pool.poolId === selectedPool.id)
      if (pool) {
        setTotalPledgedAmount(Number(ethers.utils.formatUnits(pool.aco2Amount, 18)).toString())
      }
    }
  }, [selectedPool, userPoolData])

  useEffect(() => {
    if (selectedPool && userPoolData && showSuccessPopup === false && loading === false) {
      // Calculate reusable values once
      const numericAmount = Number(amount);
      const totalBalance = Number(aco2TotalBalance.totalBalanceFormatted);
      const userClaimsCapAmount = Number(ethers.utils.formatUnits(selectedPool.userClaimsCap, selectedPool.exchangeCurrencyDecimals)) / Number(selectedPool.exchangeRate);
      const remainingSellableAmount = userClaimsCapAmount - Number(totalPledgedAmount);
  
      // Start with the most specific condition
      if (numericAmount > remainingSellableAmount) {
        toast.error('Amount exceeds your remaining sellable amount in this pool');
      } else if (numericAmount > userClaimsCapAmount) {
        toast.error('Amount exceeds max sellable per wallet');
      } else if (numericAmount > totalBalance) {
        toast.error('Insufficient $aCO2 balance');
      }
    }
  }, [amount, selectedPool, userPoolData, totalPledgedAmount]);

  return (
    <Layout title='Carbify | CO2 Pools' content='CO2 Pools'>
      <PageHeading>
        CO2 Pools
      </PageHeading>
      { aco2Pools === null || loading ? <Spinner /> : null}
      <PoolsMap 
        aco2Pools={aco2Pools}
        poolLogos={poolLogos}
        isTeamMember={isTeamMember}
        setShowSellAco2Popup={setShowSellAco2Popup}
        setSelectedPool={setSelectedPool}
        userPoolData={userPoolData}
        aco2TotalBalance={aco2TotalBalance}
        loading={loading}
        setLoading={setLoading}
        setAco2Pools={setAco2Pools}
        setPoolLogos={setPoolLogos}
        setUserPoolData={setUserPoolData}
        setErc721Data={setErc721Data}
      />
      <SellPopup 
        showSellAco2Popup={showSellAco2Popup}
        handleClosePopup={handleClosePopup}
        poolLogos={poolLogos}
        selectedPool={selectedPool}
        amount={amount}
        aco2TotalBalance={aco2TotalBalance}
        totalPledgedAmount={totalPledgedAmount}
        isApprovedACO2={isApprovedACO2}
        setLoading={setLoading}
        setErc721Data={setErc721Data}
        setIsApprovedACO2={setIsApprovedACO2}
        loading={loading}
        isTeamMember={isTeamMember}
        setAco2Pools={setAco2Pools}
        setPoolLogos={setPoolLogos}
        setUserPoolData={setUserPoolData}
        setShowSellAco2Popup={setShowSellAco2Popup}
        setShowSuccessPopup={setShowSuccessPopup}
        setSuccessTxHash={setSuccessTxHash}
        setAmount={setAmount}
      />
      <SuccessPopup 
        showSuccessPopup={showSuccessPopup}
        poolLogos={poolLogos}
        selectedPool={selectedPool}
        amount={amount}
        successTxHash={successTxHash}
        handleClosePopup={handleClosePopup}
      />
    </Layout>
  )
}

export default ACO2Pools
