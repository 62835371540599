/* global BigInt */
import { CiWallet } from 'react-icons/ci';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { Config } from '../../config';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { CBY_ABI, STAKING_ABI } from 'constants/BlockchainConstants';
import { lockCBYIntoTrees } from 'features/staking/lockCBYIntoTrees';
import { unstakeTrees } from 'features/staking/unstakeTrees';
import { getAllTreeData } from 'features/dashboard/getAllTreeData';
import { getCBYBalance } from 'features/staking/getCBYBalance';
import { useEffect, useState } from 'react';
import { getAllLandplotData } from 'features/dashboard/getAllLandplotData';

const AdvancedPopup = ({
    showPopupAdvanced,
    handleClosePopup,
    title,
    amountAdvanced,
    setAmountAdvanced,
    totalNftrees,
    totalLockedNftrees,
    totalStakedNftrees,
    stakedNftreeOnStandard,
    stakedNftreesOnPremium,
    stakedNftreesOnGenesis,
    requiredCBY,
    approvedAmount,
    setApprovalParams,
    setTreeData,
    setTotalCBY,
    setLoading,
    treeData,
    setLandPlotData,
    approvalParams,
    setApprovedAmount,
    loading,
    setYearlyEstimatedRewards,
    setTotalStakedNftrees,
    setTotalLockedNftrees,
}) => {
    const { address: walletAddress } = useAccount()

    const [lockParamsQueue, setLockParamsQueue] = useState([])
    const [lockParams, setLockParams] = useState([])

    const [unstakeParamsQueue, setUnstakeParamsQueue] = useState([])
    const [unstakeParams, setUnstakeParams] = useState([])

    const handleAdvancedTreeAmount = e => {
        const value = e.target.value
        // Regular expression to test for a whole number
        const isWholeNumber = /^\d*$/
    
        if (isWholeNumber.test(value)) {
          setAmountAdvanced(value.toString())
          // setRequiredCBY((e.target.value * 5) / cbyLivePrice?.weeklyPrice)
        }
    }

    // Approving CBY for staking
    const {
        data: approveHash,
        writeContract: approveWrite,
        isPending: isApproving,
        isError: approveWriteError,
    } = useWriteContract()
    const {
        isLoading: approveAwaiting,
        isSuccess: approveSuccess,
        isError: approveError,
    } = useWaitForTransactionReceipt({ hash: approveHash })

    // Unstaking trees
    const { 
        data: unstakeHash, 
        writeContract: unstakeWrite,
        isPending: isUnstaking,
        isError: unstakeWriteError 
    } = useWriteContract()
    const { 
        isLoading: unstakeAwaiting, 
        isSuccess: unstakeSuccess, 
        isError: unstakeError 
    } = useWaitForTransactionReceipt({ hash: unstakeHash })

    // Locking CBY into trees
    const {
        data: lockHash,
        writeContract: lockWrite,
        isPending: isLocking,
        isError: lockWriteError,
    } = useWriteContract()
    const {
        isLoading: lockAwaiting,
        isSuccess: lockSuccess,
        isError: lockError,
    } = useWaitForTransactionReceipt({ hash: lockHash })

    // Approving CBY for staking
    useEffect(() => {
        if (approveSuccess) {
            toast.success('Successfully approved CBY for staking')
            setApprovedAmount(Number(approvalParams?.args[1]))
        } else if (approveWriteError || approveError) {
            toast.error('Error in approving CBY for staking')
            setTreeData(null)
            setTotalCBY(null)
            setTimeout(() => {
                Promise.all([
                    getAllTreeData(walletAddress),
                    getAllLandplotData(walletAddress),
                    getCBYBalance(walletAddress),
                ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
                    setTreeData(allTreeData)
                    setLandPlotData(allLandplotData)
                    setTotalCBY(Number(cbyBalance.balanceFormatted))
                    setLoading(false)
                })
        }, 5000)
        }
    }, [approveAwaiting, approveError, approveWriteError, approveSuccess])

    // Unstaking trees
    useEffect(() => {
        if (unstakeParamsQueue.length > 0) {
        console.log('unstakeParamsQueue:', unstakeParamsQueue)
        setUnstakeParams(unstakeParamsQueue[0])
        } else if (unstakeParamsQueue.length === 0 && loading === true) {
        setTimeout(() => {
            Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
            })
        }, 5000)
        }
    }, [unstakeParamsQueue])
    useEffect(() => {
        if (unstakeParams?.args?.length > 0) {
        console.log('unstakeParams:', unstakeParams)
        unstakeWrite({
            address: Config().contract_addresses.staking_address.toLowerCase(),
            abi: STAKING_ABI,
            functionName: 'unstakeMultiple',
            args: unstakeParams?.args,
        })
        }
    }, [unstakeParams])
    useEffect(() => {
        if (unstakeSuccess) {
        toast.success('Successfully unstaked trees')
        let amountOfTrees = 0
        amountOfTrees = unstakeParams?.args[0].length
        setYearlyEstimatedRewards(prevState => prevState - Number(amountOfTrees) * 175)
        setTotalStakedNftrees(Number(totalStakedNftrees) - Number(amountOfTrees))
        if (unstakeParams?.args[2] === false) {
            setTotalLockedNftrees(Number(totalLockedNftrees) + Number(amountOfTrees)) // unncomment this if you want to add the unstaked trees to the locked trees (aka if you don't unlock the CBY from the trees automatically when unstaking)
        }
        setUnstakeParamsQueue(prevQueue => prevQueue.slice(1))
        } else if (unstakeWriteError || unstakeError) {
        toast.error('Error in unstaking trees')
        setTreeData(null)
        setLandPlotData(null)
        setTimeout(() => {
            Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
            })
        }, 5000)
        }
    }, [unstakeAwaiting, unstakeError, unstakeWriteError, unstakeSuccess])

    // Locking CBY into trees
    useEffect(() => {
        if (lockParamsQueue.length > 0) {
            console.log('lockParamsQueue:', lockParamsQueue)
            setLockParams(lockParamsQueue[0])
        } else if (lockParamsQueue.length === 0 && loading === true) {
        setTimeout(() => {
            Promise.all([
                getAllTreeData(walletAddress),
                getAllLandplotData(walletAddress),
                getCBYBalance(walletAddress),
            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
                setTreeData(allTreeData)
                setLandPlotData(allLandplotData)
                setTotalCBY(Number(cbyBalance.balanceFormatted))
                setLoading(false)
            })
        }, 5000)
        }
    }, [lockParamsQueue])
    useEffect(() => {
        if (lockParams?.args?.length > 0) {
            lockWrite({
                address: Config().contract_addresses.staking_address.toLowerCase(),
                abi: STAKING_ABI,
                functionName: 'lockMultiple',
                args: lockParams?.args,
            })
        }
    }, [lockParams])
    useEffect(() => {
        if (lockSuccess) {
            toast.success('Successfully locked CBY into trees')
            let amountOfTrees = 0
            amountOfTrees = lockParams?.args[0].length
            setTotalLockedNftrees(prevState => Number(prevState) + Number(amountOfTrees))
            setLockParamsQueue(prevQueue => prevQueue.slice(1))
        } else if (lockWriteError || lockError) {
            toast.error('Error in locking CBY into trees')
            setTreeData(null)
            setTotalCBY(null)
            setTimeout(() => {
                getAllTreeData(walletAddress).then(allTreeData => {
                setTreeData(allTreeData)
                })
                getCBYBalance(walletAddress).then(data => {
                setTotalCBY(Number(data.balanceFormatted))
                })
                setLoading(false)
            }, 5000)
        }
    }, [lockSuccess, lockError, lockWriteError, lockAwaiting])

    return (
        <>
            {showPopupAdvanced ? (
                <div
                id="crypto-modal"
                tabindex="-1"
                aria-hidden="true"
                className="fixed flex justify-center items-center z-50  w-full h-full p-4 mt-12 overflow-x-hidden overflow-y-auto inset-0 h-modal"
                >
                    <div className="relative w-full h-full max-w-md md:h-auto">
                        <div className="relative bg-light_green p-6 border rounded-lg shadow">
                        <button
                            onClick={() => handleClosePopup()}
                            type="button"
                            className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="crypto-modal"
                        >
                            <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="flex items-center justify-center pb-2">
                            <div className="text-xl font-semibold text-center">
                            Advanced NFTree configuration on {title} Landplot
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="mb-3">
                            <div className="relative py-2 space-y-1">
                                <label for="amount" className="block text-base font-bold tracking-wide">
                                Trees amount
                                </label>
                                <input
                                type="text"
                                name="amount"
                                id="amount"
                                className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                                placeholder="amount"
                                required="true"
                                value={amountAdvanced}
                                onChange={handleAdvancedTreeAmount}
                                />
                            </div>
                            <div className="flex space-x-1 text-sm font-normal">
                                <IoIosInformationCircleOutline className="w-4 h-4  mt-0.5 stroke-1" />
                                <span>Each tree requires $5 worth of $CBY for locking</span>
                            </div>
                            </div>

                            <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                            <div className="flex justify-between">
                                <span>Amount of locked NFTrees</span>
                                <span>
                                {totalNftrees === null || totalLockedNftrees === null || Number(totalLockedNftrees) < 0
                                    ? '0'
                                    : Number(totalLockedNftrees)}{' '}
                                /{' '}
                                {totalNftrees === null || totalLockedNftrees === null
                                    ? '0'
                                    : Number(totalNftrees) - Number(totalStakedNftrees)}{' '}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Amount of staked NFTrees on {title}</span>
                                <span>
                                {title === 'Genesis'
                                    ? stakedNftreesOnGenesis
                                    : title === 'Rare'
                                    ? stakedNftreesOnPremium
                                    : stakedNftreeOnStandard}{' '}
                                / {totalStakedNftrees === null ? '0' : totalStakedNftrees}{' '}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Total CBY needed for locking</span>
                                <span>{parseFloat(Number(requiredCBY)?.toFixed(2)).toString()} $CBY</span>
                            </div>
                            </div>
                            {approvedAmount < BigInt(requiredCBY * 10 ** 18) ? (
                            <button
                                disabled={isApproving || approveAwaiting || amountAdvanced === '' || treeData === null}
                                onClick={() => {
                                    const args = [
                                        `${Config().contract_addresses.staking_address}`.toLowerCase(),
                                        ethers.constants.MaxUint256,
                                    ]
                                    toast.success('Submitting approval request')
                                    setApprovalParams({ args: args })
                                    approveWrite({
                                        address: Config().contract_addresses.cby_address.toLowerCase(),
                                        abi: CBY_ABI,
                                        functionName: 'approve',
                                        args: args,
                                    })
                                }}
                                className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                            >
                                <CiWallet className="w-6 h-6 stroke-1" />
                                <span className="text-lg tracking-wider">
                                {isApproving || approveAwaiting ? 'Approving CBY...' : 'Approve CBY'}
                                </span>
                            </button>
                            ) : (
                            <>
                                <button
                                disabled={isLocking || lockAwaiting || amountAdvanced === '' || treeData === null}
                                onClick={() => {
                                    setLoading(true)
                                    toast.success('Submitting lock request')
                                    // least cby is locked first
                                    const sortedTreeData = treeData.sort((a, b) => {
                                    const aAmount = a.locked_cby
                                    const bAmount = b.locked_cby

                                    if (aAmount > bAmount) {
                                        return 1 // a is greater than b
                                    } else if (aAmount < bAmount) {
                                        return -1 // b is greater than a
                                    } else {
                                        return 0 // a and b are equal
                                    }
                                    })

                                    console.log('sortedTreeData:', sortedTreeData)

                                    lockCBYIntoTrees(Number(amountAdvanced), sortedTreeData)
                                    .then(data => {
                                        if (data.success) {
                                        setTreeData(null)
                                        setTotalCBY(null)
                                        let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
                                        let query = [];

                                        const amountOfTrees = data.lockedTreeCount;
                                        const unlockedAndUnstakedTrees = data.unlockedAndUnstakedTrees;

                                        console.log('unlockedAndUnstakedTrees:', unlockedAndUnstakedTrees);
                                        console.log('amountOfTrees:', amountOfTrees);

                                        // Calculate how many chunks we'll have - No need to adjust MAX_TREES_PER_TX here
                                        const chunks = Math.ceil(amountOfTrees / MAX_TREES_PER_TX);

                                        for (let i = 0; i < chunks; i++) {
                                            let start = i * MAX_TREES_PER_TX;
                                            let end = start + MAX_TREES_PER_TX;
                                            const chunk = unlockedAndUnstakedTrees.slice(start, end);

                                            const treeIds = chunk.map(tree => Number(tree.token_id));
                                            const treeAddresses = chunk.map(tree => tree.contract_address);

                                            const args = [treeIds, treeAddresses];
                                            query.push({ args: args });
                                        }
                                        console.log('query:', query);
                                        setLockParamsQueue(query);
                                        }
                                        if (data.error) {
                                        console.log('Error in lockCBYIntoTrees:', data.error)
                                        if (data.error === 'Not enough trees found') {
                                            toast.error(data.error)
                                        } else {
                                            toast.error('Error in locking CBY into trees')
                                        }
                                        setTreeData(null)
                                        setTotalCBY(null)
                                        setTimeout(() => {
                                            Promise.all([
                                            getAllTreeData(walletAddress),
                                            getCBYBalance(walletAddress),
                                            ]).then(([allTreeData, cbyBalance]) => {
                                            setTreeData(allTreeData)
                                            setTotalCBY(Number(cbyBalance.balanceFormatted))
                                            setLoading(false)
                                            })
                                        }, 2500)
                                        return;
                                        }
                                    })
                                    .catch(err => {
                                        console.error('Error in lockCBYIntoTrees:', err)
                                    })
                                }}
                                className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed mb-2"
                                >
                                <CiWallet className="w-6 h-6 stroke-1" />
                                <span className="text-lg tracking-wider">
                                    {isLocking || lockAwaiting ? 'Locking $CBY...' : 'Lock $CBY'}
                                </span>
                                </button>
                                <button
                                disabled={isUnstaking || unstakeAwaiting || amountAdvanced === '' || treeData === null}
                                onClick={() => {
                                    setLoading(true)
                                    toast.success('Submitting unstake request')
                                    const _shouldUnlock = false
                                    unstakeTrees(Number(amountAdvanced), treeData, title)
                                    .then(data => {
                                        if (data.success) {
                                        setTreeData(null)
                                        setLandPlotData(null)
                                        let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx)
                                        let query = []

                                        const amountOfTrees = data.unstakedTreesCount
                                        const lockedAndStakedTrees = data.lockedAndStakedTrees

                                        console.log('lockedAndStakedTrees: ', lockedAndStakedTrees)
                                        console.log('amountOfTrees: ', amountOfTrees)

                                        if (amountOfTrees <= MAX_TREES_PER_TX) {
                                            MAX_TREES_PER_TX = amountOfTrees
                                        }

                                        console.log('MAX_TREES_PER_TX: ', MAX_TREES_PER_TX)

                                        // Split trees into chunks of MAX_TREES_PER_TX
                                        for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
                                            const chunk = lockedAndStakedTrees.slice(i, i + MAX_TREES_PER_TX)
                                            if (chunk.length === 0) {
                                            break
                                            }
                                            console.log('Processing chunk:', chunk)

                                            const treeIds = chunk.map(tree => Number(tree.token_id))
                                            const treeAddresses = chunk.map(tree => tree.contract_address)
                                            
                                            const args = [treeIds, treeAddresses, _shouldUnlock]
                                            query.push({ args: args })
                                        }
                                        console.log('query:', query)
                                        setUnstakeParamsQueue(query)
                                        }
                                        if (data.error) {
                                        if (data.error === 'Not enough staked trees found') {
                                            toast.error(data.error)
                                        } else if (data.error) {
                                            toast.error(data.error)
                                        } else {
                                            toast.error('Error in unstaking trees')
                                            console.log('Error in unstaking trees:', data.error)
                                        }
                                        setTreeData(null)
                                        setLandPlotData(null)
                                        setTimeout(() => {
                                            Promise.all([
                                            getAllTreeData(walletAddress),
                                            getAllLandplotData(walletAddress),
                                            getCBYBalance(walletAddress),
                                            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
                                            setTreeData(allTreeData)
                                            setLandPlotData(allLandplotData)
                                            setTotalCBY(Number(cbyBalance.balanceFormatted))
                                            setLoading(false)
                                            })
                                        }, 2500)
                                        return;
                                        }
                                    })
                                    .catch(err => {
                                        console.error('Error in unstakeTrees:', err)
                                    })
                                }}
                                className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                                >
                                <CiWallet className="w-6 h-6 stroke-1" />
                                <span className="text-lg tracking-wider">
                                    {isUnstaking || unstakeAwaiting ? 'Unstaking $CBY...' : 'Unstake $CBY'}
                                </span>
                                </button>
                            </>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
};

export default AdvancedPopup;
