import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import { toast } from 'react-toastify'

const initialState = {
  requestingCBYTokens: false,
  tokens: null,
  isSubmitted: null,
  errors: null,
}

// Not used yet
export const buyTokens = createAsyncThunk('tokens/buyTokens', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`/tokens/buy-tokens/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const tokensSlice = createSlice({
  name: 'plots',
  initialState,
  reducers: {
    resetIsSubmitted: state => {
      state.isSubmitted = null
    },
    resetBuyTokenErrors: state => {
      state.errors = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(buyTokens.pending, state => {
        state.requestingCBYTokens = true
      })
      .addCase(buyTokens.fulfilled, (state, action) => {
        state.requestingCBYTokens = false
        state.isSubmitted = true
        state.tokens = action.payload
      })
      .addCase(buyTokens.rejected, (state, action) => {
        state.requestingCBYTokens = false
        toast.error('Something went wrong! Unable to Submit yor request.')
      })
  },
})

export const { resetIsSubmitted, resetBuyTokenErrors } = tokensSlice.actions

export default tokensSlice.reducer
