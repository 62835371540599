import { useEffect, useState } from "react";
import { CiWallet } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TooltipComponent } from "./map/OverviewCards";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: 'white', // Match the text color
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Match the background color
        borderColor: 'rgba(255, 255, 255, 0.5)', // Match the border color
        '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.5)', // Default border color
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.7)', // Hover border color
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F8952D', // Focused border color
        },
    },
    '& .MuiSvgIcon-root': {
        color: 'white', // Match the icon color
    },
    width: '100%',
    padding: '2.5px', // Adjust padding to match
}));

export function CustomTextField(props) {
    return <StyledTextField variant="outlined" {...props} />;
}

const CreatePopup = ({
    showPopupCreate,
    handleClosePopup,
    handleSubmitCertificateRequest,
    burnedAmount,
    handleBurnedAmount,
    title,
    handleTitle,
    reason,
    handleReason,
    date,
    handleDate,
    unusedBurnBalance,
    generatingCertificate,
    loading,
}) => {

    const debouncedBurnedAmount = useDebounce(burnedAmount, 500);
    useEffect(() => {
        if (unusedBurnBalance !== null) {
            if (
                debouncedBurnedAmount !== "" && 
                (Number(debouncedBurnedAmount) > Number(unusedBurnBalance.totalUnusedBurnedAmount))
            ) {
                toast.error('Amount of burned $aCO2 exceeds the total amount of unused burned $aCO2')
            }
            if (
                debouncedBurnedAmount !== "" && 
                debouncedBurnedAmount < 10
            ) {
                toast.error('Amount of burned $aCO2 must be greater than 10')
            }
        }
    }, [debouncedBurnedAmount]);

    // check if date is in the future and toast error
    useEffect(() => {
        if (date !== "") {
            if (new Date(date.split('/').reverse().join('/')) > new Date()) {
                toast.error('Date must be today or in the past')
            }
        }
    }, [date]);

    return (
        <>
            {showPopupCreate ? (
                <div
                id="crypto-modal"
                tabindex="-1"
                aria-hidden="true"
                className="fixed flex justify-center items-center z-40  w-full h-full p-4 bg-black bg-opacity-80 overflow-x-hidden overflow-y-auto inset-0 h-modal"
                >
                <div className="relative w-full h-full max-w-md md:h-auto">
                    <div className="relative bg-green bg-opacity-80 p-6 border rounded-lg shadow">
                    <button
                        onClick={() => handleClosePopup()}
                        type="button"
                        className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-hide="crypto-modal"
                    >
                        <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="flex items-center justify-center pb-2">
                        <div className="text-xl font-semibold">
                            Submit Certificate Request
                        </div>
                        <TooltipComponent 
                            tooltipContent={
                                <div>
                                    <img src="CertificateTooltip.jpg" alt="example" style={{ width: '600px', height: 'auto', borderRadius: '8px' }} />
                                    {/* <p>Image Description</p> */}
                                </div>
                            }
                        />

                    </div>
                    <div className="flex flex-col">
                        <div className="mb-3">
                        <div className="relative py-2 space-y-1">
                            <div className="inline-flex items-center">
                                <label
                                for="amount"
                                className="block text-base font-bold tracking-wide"
                                >
                                    CO2 Amount (Per Kg)
                                </label>
                                {/* <TooltipComponent tooltipText={'text'}/> */}
                            </div>
                            {/* <input
                            type="text"
                            name="amount"
                            id="amount"
                            className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                            placeholder="Amount"
                            required="true"
                            value={burnedAmount}
                            onChange={handleBurnedAmount}
                            /> */}
                            <CustomTextField
                                id="amount"
                                // label="CO2 Amount (Per Kg)"
                                placeholder="Amount"
                                value={burnedAmount}
                                onChange={handleBurnedAmount}
                            />
                        </div>
                        <div className="relative py-2 space-y-1">
                            <div className="inline-flex items-center">
                                <label
                                for="title"
                                className="block text-base font-bold tracking-wide"
                                >
                                Certificate Title
                                </label>
                                {/* <TooltipComponent tooltipText={'text'}/> */}
                            </div>
                            {/* <input
                            type="text"
                            name="title"
                            id="title"
                            className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                            placeholder="Title"
                            required="true"
                            value={title}
                            onChange={handleTitle}
                            /> */}
                            <CustomTextField
                                id="title"
                                // label="Certificate Title"
                                placeholder="Title"
                                value={title}
                                onChange={handleTitle}
                            />
                        </div>
                        <div className="relative py-2 space-y-1">
                            <div className="inline-flex items-center">
                                <label
                                for="reason"
                                className="block text-base font-bold tracking-wide"
                                >
                                Certificate Reason
                                </label>
                                {/* <TooltipComponent tooltipText={'text'}/> */}
                            </div>
                            {/* <input
                            type="text"
                            name="reason"
                            id="reason"
                            className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                            placeholder="Reason"
                            required="true"
                            value={reason}
                            onChange={handleReason}
                            /> */}
                            <CustomTextField
                                id="reason"
                                name="reason"
                                // label="Certificate Reason"
                                placeholder="Reason"
                                // required="true"
                                value={reason}
                                onChange={handleReason}
                            />

                        </div>
                        <div className="relative py-2 space-y-1">
                            <div className="inline-flex items-center">
                                <label
                                for="date"
                                className="block text-base font-bold tracking-wide"
                                >
                                Certificate Date
                                </label>
                                {/* <TooltipComponent tooltipText={'text'}/> */}
                            </div>
                            {/* <input
                            type="text"
                            name="date"
                            id="date"
                            className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                            placeholder="dd/mm/yyyy"
                            required="true"
                            value={date}
                            onChange={handleDate}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={date ? new Date(date.split('/').reverse().join('/')) : null}
                                    onChange={handleDate}
                                    slotProps={{
                                        textField: {
                                            margin: "dense",
                                            sx: {
                                                '& .MuiInputBase-input': {
                                                    color: 'white', // Match the text color
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Match the background color
                                                    borderColor: 'rgba(255, 255, 255, 0.5)', // Match the border color
                                                    '& fieldset': {
                                                        borderColor: 'rgba(255, 255, 255, 0.5)', // Default border color
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'rgba(255, 255, 255, 0.7)', // Hover border color
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#F8952D', // Focused border color
                                                    },
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white', // Match the icon color
                                                },
                                                width: '100%',
                                                padding: '2.5px', // Adjust padding to match
                                            },
                                        },
                                        day: {
                                            sx: {
                                                '&.Mui-selected': {
                                                    backgroundColor: '#F8952D !important'
                                                },
                                                '&.Mui-selected:hover': {
                                                    backgroundColor: '#F8952D',
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#f8952d4d',
                                                },
                                                '&.MuiPickersDay-today': {
                                                    backgroundColor: '#f8952d4d',
                                                },
                                                color: '#AEB7C0'
                                            }
                                        },
                                        yearButton: {
                                            sx: {
                                                '&.Mui-selected': {
                                                    backgroundColor: '#F8952D !important'
                                                },
                                                '&:hover': {
                                                    backgroundColor: '#f8952d4d',
                                                },
                                            }
                                        },
                                        desktopPaper: {
                                            sx: {
                                                backgroundColor: '#21563E',
                                                color: '#AEB7C0'
                                            }
                                        },
                                        mobilePaper: {
                                            sx: {
                                                backgroundColor: '#21563E',
                                                color: '#AEB7C0'
                                            }
                                        },
                                        actionBar: {
                                            sx: {
                                                '& .css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
                                                    color: '#F8952D !important',
                                                    '&:hover': {
                                                        backgroundColor: '#f8952d4d !important',
                                                    }
                                                },
                                            }
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="flex space-x-1 text-sm font-normal">
                            <IoIosInformationCircleOutline className="w-4 h-4  mt-0.5 stroke-1" />
                            <span>Submit a request to generate an NFT Carbon Reduction Certificate</span>
                        </div>
                        </div>

                        <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                        <div className="flex justify-between">
                            <span>Amount of unused burned $aCO2</span>
                            <span>
                            {unusedBurnBalance &&
                                Number(unusedBurnBalance.totalUnusedBurnedAmount).toFixed(2)
                            }
                            </span>
                        </div>
                        </div>
                        <>
                        <button
                            disabled={ 
                                burnedAmount === "" || 
                                title === "" || 
                                reason === "" || 
                                date === "" || 
                                generatingCertificate ||
                                Number(burnedAmount) > Number(unusedBurnBalance.totalUnusedBurnedAmount) ||
                                Number(burnedAmount) < 10 ||
                                new Date(date.split('/').reverse().join('/')) > new Date() ||
                                loading === true
                            }
                            onClick={() => handleSubmitCertificateRequest()}
                            className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                            <CiWallet className="w-6 h-6 stroke-1" />
                            <span className="text-lg tracking-wider">Submit Request</span>
                        </button>
                        </>
                    </div>
                    </div>
                </div>
                </div>
            ) : null}
        </>
    );
};

export default CreatePopup;
