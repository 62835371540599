import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDisconnect } from 'wagmi'
import { Link, Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { countries } from 'json/countries'
import SelectCountry from 'components/utils/SelectCountry'
import PasswordField from 'components/utils/PasswordField'
import AuthLayout from 'layouts/AuthLayout'
import useDisplayError from 'hooks/useDisplayError'

// Reducers
import { register } from 'features/authentication/authSlice'

function RegistrationPage() {
  const dispatch = useDispatch()

  const { isAuthenticated, registered, loading, errors } = useSelector(state => state.authentication)

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    country: countries[0].name,
    password: '',
    confirmPassword: '',
    termsAndConditionsAccepted: false,
  })
  const [errorMessage, setErrorMessage] = useState('')

  const { username, email, country, password, confirmPassword, termsAndConditionsAccepted } = formData
  const { disconnect } = useDisconnect()

  useDisplayError(errors)

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrorMessage('')
  }

  const handleSelectCountry = e => {
    setFormData({ ...formData, country: e.target.value })
  }

  if (registered) return <Navigate to="/login" />
  if (isAuthenticated) return <Navigate to="/dashboard" />

  const handleCheckboxChange = e => {
    setFormData({ ...formData, termsAndConditionsAccepted: e.target.checked })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('The passwords confirmation does not match!')
    } else if (!termsAndConditionsAccepted) {
      toast.error('Please indicate that you have read and agree to the Terms and Conditions')
    } else {
      disconnect()
      dispatch(register({ username, email, country, password, confirmPassword, termsAndConditionsAccepted }))
    }
  }

  return (
    <AuthLayout title={'Carbify | Register'} loading={loading}>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2x">Create an account</h1>
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <div className="flex flex-col 2xl:flex-row justify-between 2xl:space-x-3 space-y-3 2xl:space-y-0">
            <div className="w-full">
              <label for="text" className="block mb-2 text-base font-medium">
                Username
              </label>
              <input
                type="text"
                name="username"
                id="username"
                className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                placeholder="username"
                required="true"
                value={username}
                onChange={onChange}
              />
            </div>
            <div className="w-full">
              <label for="email" className="block mb-2 text-base font-medium">
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                placeholder="name@company.com"
                required="true"
                value={email}
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <label for="country" className="block mb-2 text-base font-medium">
              Country
            </label>
            <SelectCountry handleSelectCountry={handleSelectCountry} userCountry={country} countries={countries} />
          </div>
          <PasswordField label={'Password'} onChange={onChange} value={password} name={'password'} />
          <PasswordField
            label={'Confirm Password'}
            onChange={onChange}
            value={confirmPassword}
            name={'confirmPassword'}
          />
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="termsAndConditions"
                name="termsAndConditions"
                aria-describedby="terms"
                type="checkbox"
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                required="true"
                defaultChecked={termsAndConditionsAccepted}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="ml-3 text-sm">
              <label for="terms" className="font-light">
                I accept the{' '}
                <a
                  className="font-medium text-carbifyOrange hover:underline"
                  href="https://www.carbify.io/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-carbifyOrange text-bt focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Create an account
          </button>
          <p className="text-sm font-light text-white">
            Already have an account?{' '}
            <Link to={'/login'} className="font-medium text-carbifyOrange hover:underline">
              {' '}
              Login here
            </Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  )
}

export default RegistrationPage
