import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAccount, useReadContract } from 'wagmi'
import { toast } from 'react-toastify'

// Constants
import { PLOTS_ABI, RARE_PLOT_ADDRESS, GENESIS_PLOT_ADDRESS, MARKETPLACE_ADDRESS, MARKETPLACE_ABI, } from 'constants/BlockchainConstants'


const PlotsCard = ({ asset_contract, image, title, updateNftPrice }) => {
  const [totalCount, setTotalCount] = useState(Number(0))
  const { address: walletAddress } = useAccount()
  const [pricePerToken, setPricePerToken] = useState(null)


  const {
    data: nftInfo,
    error: nftInfoError,
  } = useReadContract({
    address: MARKETPLACE_ADDRESS,
    abi: MARKETPLACE_ABI,
    functionName: 'NFTInfo',
    args: [asset_contract],
  })
  useEffect(() => {
    if (nftInfo) {
      setPricePerToken(Number(nftInfo[2])/10e6) // 10e6 is the number of decimals in USDC
      updateNftPrice(asset_contract, Number(nftInfo[2]))
    } else if (nftInfoError) {
      // toast.error('Error fetching NFT Info:', nftInfoError.cause)
    }
  }, [nftInfo, nftInfoError])

  const {
    data: balanceOfData,
    error: balanceOfError,
  } = useReadContract({
    address: asset_contract,
    abi: PLOTS_ABI,
    functionName: 'balanceOf',
    args: [walletAddress],
  })
  useEffect(() => {
    if (balanceOfData) {
      setTotalCount(Number(balanceOfData))
    } else if (balanceOfError) {
      toast.error('Error fetching balance of plots:', balanceOfError.cause)
    }
  }, [balanceOfData, balanceOfError])

  // Conditionally render nothing if totalCount is zero
  if (totalCount === 0 || totalCount === '') {
    return null
  }

  return (
    <div className="relative overflow-hidden bg-darkGreen bg-opacity-70 hover:bg-light_green border rounded-lg p-4 flex flex-col space-y-4 justify-start group">
      <Link to={`/plots/${asset_contract}`}>
        <div className="flex items-center justify-center mb-3">
          <img className="w-full h-full object-cover rounded-[10px]" src={image} alt={title} />
        </div>
        <div className="flex flex-col items-center mb-3 text-white">
          <div className="text-xl tracking-wider font-bold">{title}</div>
          <div className="text-lg mt-2">{pricePerToken === 0 || pricePerToken === null ? '' : `Last sale: ${pricePerToken} USDC`}</div>
        </div>
        <div className="absolute bottom-0 right-0 p-2 transition-transform transform translate-x-full group-hover:translate-x-0">
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </div>
      </Link>
      <div className="absolute -top-1 left-2 px-2 z-10 bg-white text-green text-base font-bold rounded">
        x{totalCount}
      </div>
    </div>
  )
}

export default PlotsCard
