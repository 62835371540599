
import { CiWallet, CiShoppingCart, CiInboxOut } from 'react-icons/ci';
import { Link } from 'react-router-dom';

const Plots = ({
    totalGenesisPlots,
    totalPremiumPlots,
    totalStandardPlots,
    availableGenesisPlots,
    availablePremiumPlots,
    availableStandardPlots,
    stakedNftreesOnGenesis,
    stakedNftreesOnPremium,
    stakedNftreeOnStandard,
    lockedCby,
    treeData,
    landPlotData,
    setTitle,
    setShowPopupUnstake,
    setShowPopup,
    setRequiredCBY
}) => {

    const handleStake = (name) => {
        setTitle(name)
        setRequiredCBY(0)
        setShowPopup(true)
    }

    const handleUnstake = (name) => {
        setTitle(name)
        setShowPopupUnstake(true)
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-5">

                <div className="flex flex-col 2xl:min-w-stakingCardWidth h-auto bg-darkGreen bg-opacity-70 border rounded-lg p-6 overflow-hidden">
                    <div className='flex justify-center'>
                        <div className="flex bg-white text-green text-base 2xl:text-lg font-bold items-center justify-center max-w-max h-7 p-1 rounded mr-2">
                            x{totalGenesisPlots}
                        </div>
                        <div className="font-bold text-2xl 2xl:text-3xl relative 2xl:bottom-[3px]">
                            Genesis Landplot
                        </div>
                    </div>
                    <div className="flex flex-col 2xl:relative 2xl:flex-row 2xl:mb-20">
                        <div className="font-bold text-3xl 2xl:mt-32">
                            {/* Genesis <br className="hidden 2xl:block" /> Landplot */}
                        </div>
                        <div className="min-h-[350px] 2xl:absolute 2xl:left-[68px] 2xl:w-[233px] 2xl:min-h-[270px]">
                            <img
                                className="w-full h-full object-contain"
                                src="/assets/images/genesis-plot.png"
                                alt="Genesis Plot"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full border-b border-white border-opacity-50 justify-center pb-4 mt-[72px]">
                        <div className="font-semibold text-lg tracking-wide">
                            {availableGenesisPlots === null ? "0" : availableGenesisPlots} staking spots available
                        </div>
                    </div>
                    <div className="flex flex-col py-3 space-y-2">
                        <div className="flex space-x-4">
                            <div className="w-7 h-7">
                                <img src={`/assets/images/carbify-staking-tree.png`} alt="Tree" className="object-contain" />
                            </div>
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {availableGenesisPlots === null ||
                                    totalGenesisPlots * 50 -
                                    Number(availableGenesisPlots) <
                                    0
                                    ? "0"
                                    : totalGenesisPlots * 50 -
                                    Number(availableGenesisPlots)}
                                /
                                {availableGenesisPlots === null
                                    ? "0"
                                    : totalGenesisPlots * 50}{" "}
                                trees staked
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <img className="w-8 h-8 object-contain" src={`/assets/images/carbify_logo.png`} alt="CBY Logo" />
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {lockedCby === null
                                    ? "0"
                                    : lockedCby.genesisLockedCBY?.toFixed(
                                        2,
                                    )}{" "}
                                CBY locked
                            </div>
                        </div>
                        <div className="flex space-x-3">
                            {totalGenesisPlots === 0 ? (
                                <Link to="/marketplace" className='w-full'>
                                    <button
                                        disabled={ treeData === null || landPlotData === null }
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiShoppingCart className="w-6 h-6 stroke-1 " />
                                        <span className="text-lg tracking-wider">Buy Genesis Landplots</span>
                                    </button>
                                </Link>
                            ) : totalGenesisPlots > 0 && stakedNftreesOnGenesis === 0 ? (
                                <button
                                    disabled={treeData === null || landPlotData === null || availableGenesisPlots === null}
                                    onClick={() => handleStake("Genesis", totalGenesisPlots, stakedNftreesOnGenesis, 50, 0)}
                                    className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                >
                                    <CiWallet className="w-6 h-6 stroke-1" />
                                    <span className="text-lg tracking-wider">Stake trees</span>
                                </button>
                            ) : (
                                <>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availableGenesisPlots === null}
                                        onClick={() => handleStake("Genesis", totalGenesisPlots, stakedNftreesOnGenesis, 50, 0)}
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiWallet className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Stake trees</span>
                                    </button>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availableGenesisPlots === null}
                                        onClick={() => handleUnstake("Genesis")}
                                        className="flex items-center justify-center w-full tracking-tighter text-white hover:text-darkGreen hover:bg-white text-bt border rounded-lg disabled:border-0 shadow-xl shadow-black-500/50 py-2 font-bold space-x-1 disabled:text-darkGreen disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    >
                                        <CiInboxOut className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Unstake trees</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col 2xl:min-w-stakingCardWidth h-auto bg-darkGreen bg-opacity-70 border rounded-lg p-6 overflow-hidden">
                    <div className='flex justify-center'>
                        <div className="flex bg-white text-green text-base 2xl:text-lg font-bold items-center justify-center max-w-max h-7 p-1 rounded mr-2">
                            x{totalPremiumPlots}
                        </div>
                        <div className="font-bold text-2xl 2xl:text-3xl relative 2xl:bottom-[3px]">
                            Premium Landplot
                        </div>
                    </div>
                    <div className="flex flex-col 2xl:relative 2xl:flex-row 2xl:mb-20">
                        <div className="font-bold text-3xl 2xl:mt-32">
                            {/* Premium <br className="hidden 2xl:block" /> Landplot */}
                        </div>
                        <div className="min-h-[350px] 2xl:absolute 2xl:left-[68px] 2xl:w-[233px] 2xl:min-h-[270px]">
                            <img
                                className="w-full h-full object-contain mt-12"
                                src="/assets/images/premium-plot.png"
                                alt="Premium Plot"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full border-b border-white border-opacity-50 justify-center pb-4 mt-[72px]">
                        <div className="font-semibold text-lg tracking-wide">
                            {availablePremiumPlots === null ? "0" : availablePremiumPlots} staking spots available
                        </div>
                    </div>
                    <div className="flex flex-col py-3 space-y-2">
                        <div className="flex space-x-4">
                            <div className="w-7 h-7">
                                <img src={`/assets/images/carbify-staking-tree.png`} alt="Tree" className="object-contain" />
                            </div>
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {availablePremiumPlots === null || totalPremiumPlots * 30 - Number(availablePremiumPlots) < 0 ? "0" : totalPremiumPlots * 30 - Number(availablePremiumPlots)}/{availablePremiumPlots === null ? "0" : totalPremiumPlots * 30} trees staked
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <img className="w-8 h-8 object-contain" src={`/assets/images/carbify_logo.png`} alt="CBY Logo" />
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {lockedCby === null ? "0" : lockedCby.rareLockedCBY?.toFixed(2)} CBY locked
                            </div>
                        </div>
                        <div className="flex space-x-3">
                            {totalPremiumPlots === 0 ? (
                                <Link to="/marketplace" className='w-full'>
                                    <button
                                        disabled={treeData === null || landPlotData === null}
                                        onClick={() => { }}
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiShoppingCart className="w-6 h-6 stroke-1 " />
                                        <span className="text-lg tracking-wider">Buy Premium Landplots</span>
                                    </button>
                                </Link>
                            ) : totalPremiumPlots > 0 && stakedNftreesOnPremium === 0 ? (
                                <button
                                    disabled={treeData === null || landPlotData === null || availablePremiumPlots === null}
                                    onClick={() => handleStake("Rare", totalPremiumPlots, stakedNftreesOnPremium, 30, 1)}
                                    className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                >
                                    <CiWallet className="w-6 h-6 stroke-1" />
                                    <span className="text-lg tracking-wider">Stake trees</span>
                                </button>
                            ) : (
                                <>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availablePremiumPlots === null}
                                        onClick={() => handleStake("Rare", totalPremiumPlots, stakedNftreesOnPremium, 30, 1)}
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiWallet className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Stake trees</span>
                                    </button>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availablePremiumPlots === null}
                                        onClick={() => handleUnstake("Rare")}
                                        className="flex items-center justify-center w-full tracking-tighter text-white hover:text-darkGreen hover:bg-white text-bt border rounded-lg disabled:border-0 shadow-xl shadow-black-500/50 py-2 font-bold space-x-1 disabled:text-darkGreen disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    >
                                        <CiInboxOut className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Unstake trees</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col 2xl:min-w-stakingCardWidth h-auto bg-darkGreen bg-opacity-70 border rounded-lg p-6 overflow-hidden">
                    <div className='flex justify-center'>
                        <div className="flex bg-white text-green text-lg font-bold items-center justify-center max-w-max h-7 p-1 rounded mr-2">
                            x{totalStandardPlots}
                        </div>
                        <div className="font-bold text-2xl 2xl:text-3xl relative 2xl:bottom-[3px]">
                            Standard Landplot
                        </div>
                    </div>
                    <div className="flex flex-col 2xl:relative 2xl:flex-row 2xl:mb-20">
                        <div className="font-bold text-3xl 2xl:mt-32">
                            {/* Standard <br className="hidden 2xl:block" /> Landplot */}
                        </div>
                        <div className="min-h-[350px] 2xl:absolute 2xl:left-[68px] 2xl:w-[233px] 2xl:min-h-[270px]">
                            <img
                                className="w-full h-full object-contain mt-12"
                                src="/assets/images/standard-plot.png"
                                alt="Standard Plot"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full border-b border-white border-opacity-50 justify-center pb-4 mt-[72px]">
                        <div className="font-semibold text-lg tracking-wide">
                            {availableStandardPlots === null ? "0" : availableStandardPlots} staking spots available
                        </div>
                    </div>
                    <div className="flex flex-col py-3 space-y-2">
                        <div className="flex space-x-4">
                            <div className="w-7 h-7">
                                <img src={`/assets/images/carbify-staking-tree.png`} alt="Tree" className="object-contain" />
                            </div>
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {availableStandardPlots === null || totalStandardPlots * 15 - Number(availableStandardPlots) < 0 ? "0" : totalStandardPlots * 15 - Number(availableStandardPlots)}/{availableStandardPlots === null ? "0" : totalStandardPlots * 15} trees staked
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <img className="w-8 h-8 object-contain" src={`/assets/images/carbify_logo.png`} alt="CBY Logo" />
                            <div className="flex items-center font-semibold text-lg tracking-wide">
                                {lockedCby === null ? "0" : lockedCby.standardLockedCBY?.toFixed(2)} CBY locked
                            </div>
                        </div>
                        <div className="flex space-x-3">
                            {totalStandardPlots === 0 ? (
                                <Link to="/marketplace" className='w-full'>
                                    <button
                                        disabled={treeData === null || landPlotData === null}
                                        onClick={() => { }}
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiShoppingCart className="w-6 h-6 stroke-1 " />
                                        <span className="text-lg tracking-wider">Buy Standard Landplots</span>
                                    </button>
                                </Link>
                            ) : totalStandardPlots > 0 && stakedNftreeOnStandard === 0 ? (
                                <button
                                    disabled={treeData === null || landPlotData === null || availableStandardPlots === null}
                                    onClick={() => handleStake("Standard", totalStandardPlots, stakedNftreeOnStandard, 15, 2)}
                                    className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                >
                                    <CiWallet className="w-6 h-6 stroke-1" />
                                    <span className="text-lg tracking-wider">Stake trees</span>
                                </button>
                            ) : (
                                <>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availableStandardPlots === null}
                                        onClick={() => handleStake("Standard", totalStandardPlots, stakedNftreeOnStandard, 15, 2)}
                                        className="flex items-center justify-center tracking-tighter bg-white hover:bg-carbifyOrange hover:text-white text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed w-full"
                                    >
                                        <CiWallet className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Stake trees</span>
                                    </button>
                                    <button
                                        disabled={treeData === null || landPlotData === null || availableStandardPlots === null}
                                        onClick={() => handleUnstake("Standard")}
                                        className="flex items-center justify-center w-full tracking-tighter text-white hover:text-darkGreen hover:bg-white text-bt border rounded-lg disabled:border-0 shadow-xl shadow-black-500/50 py-2 font-bold space-x-1 disabled:text-darkGreen disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    >
                                        <CiInboxOut className="w-6 h-6 stroke-1" />
                                        <span className="text-lg tracking-wider">Unstake trees</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Plots;
