import { getERC721Balance } from './getERC721Balance'
import { ALL_LANDPLOT_ADDRESSES } from "constants/BlockchainConstants"
import { Config } from '../../config.js'

export const getAllLandplotBalances = async (walletAddress) => {
    let landplotData = { total_plot_count: 0, detailed_plot_count: {} };
    let promises = [];

    for (const erc721Address of ALL_LANDPLOT_ADDRESSES) {
        const promise = getERC721Balance(walletAddress, erc721Address).then(data => {
            if (erc721Address === Config().contract_addresses.genesis_address) {
                landplotData.total_plot_count += data.token_count;
                landplotData.detailed_plot_count.genesis = data.token_count;
            } else if (erc721Address === Config().contract_addresses.rare_address) {
                landplotData.total_plot_count += data.token_count;
                landplotData.detailed_plot_count.rare = data.token_count;
            } else if (erc721Address === Config().contract_addresses.standard_address) {
                landplotData.total_plot_count += data.token_count;
                landplotData.detailed_plot_count.standard = data.token_count;
            }
        });
        promises.push(promise);
    }

    // Wait for all promises to resolve
    await Promise.all(promises);
    
    return landplotData;
}
