import axios from 'axios';

const authApi = process.env.REACT_APP_API_URL_NODE_AUTH;

export const twoFactorAuthVerify = async (email, code) => {
    try {

        const response = await axios.post(
            `${authApi}api/v1/auth/2fa/verify`,
            {
                email: email,
                code: code,
            },
            {
                withCredentials: true,
            }
        );

        if (response.status === 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
