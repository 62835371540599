import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useDisconnect } from 'wagmi'
import { toast } from 'react-toastify'

import { forceLogout } from 'features/authentication/authSlice'
import { checkCorrectLinkWallet } from 'features/wallet/WalletLinking'

const useWalletLinkedCheck = () => {
  const dispatch = useDispatch()
  const { address } = useAccount()
  const { userInfo } = useSelector(state => state.authentication)
  const { disconnect } = useDisconnect()

  useEffect(() => {
    if (address && userInfo?.linked_wallet) {
      dispatch(checkCorrectLinkWallet(address))
        .unwrap()
        .catch(error => {
          if (error.isCritical) {
            const errorMessage =
              error.message || 'Wallet address error, please contact support! You will be logged out in 2 seconds.'
            toast.error(errorMessage)
            setTimeout(() => {
              disconnect()
              setTimeout(() => {
                dispatch(forceLogout())
              }, 1000)
            }, 2000)
          } else {
            toast.error(error.message)
          }
        })
    }
  }, [dispatch, address, userInfo?.linked_wallet, disconnect])
}

export default useWalletLinkedCheck
