
import GraphContainer from 'components/containers/GraphContainer';
import LineChart from 'components/dashboard/LineChart';
import SmallLoader from 'components/utils/SmallLoader';
import { BsGraphUp } from 'react-icons/bs'

const LineChartGraph = ({ cbyLivePrice, loadingCbyLivePrice }) => {
    return (
        <>
            <GraphContainer>
                <div className="border-b-2 p-3 border-green w-full">
                    <p className="font-semibold text-2xl">Carbify Token $CBY</p>
                </div>
                <div className="p-5 w-full">
                    <div className="flex font-semibold space-x-5 text-2xl">
                    <span className="text-carbifyOrange font-extrabold w-8 h-8">
                        <BsGraphUp />
                    </span>
                    {loadingCbyLivePrice ? (
                        <SmallLoader />
                    ) : (
                        `1 CBY =  $${
                        cbyLivePrice?.cby_price ? cbyLivePrice?.cby_price : ''
                        }`
                    )}
                    </div>
                    <div className="absolute w-full h-56 left-1 pr-4">
                    <LineChart graphData={cbyLivePrice?.cby_chart_data}/>
                    </div>
                </div>
            </GraphContainer>
        </>
    );
};

export default LineChartGraph;
