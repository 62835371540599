import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'features/authentication/authSlice'
import otpReducer from 'features/authentication/otpSlice'
import userProfileReducer from 'features/user/userProfileSlice'
import walletReducer from 'features/wallet/walletSlice'
import ownedNftsReducer from 'features/nfts/ownedNftSlice'
import alertReducer from 'features/Alerts/alertSlice'
import stakingReducers from 'features/staking/stakingSlice'
import stakeReducers from 'features/stake/stakeSlice'
import buyTokenReducer from 'features/tokens/buyTokenSlice'
import aco2Reducer from 'features/aco2/aco2Slice'
import aco2PoolsReducer from 'features/aco2/aco2PoolsSclice'
import footprintReducer from 'features/footprint/footprintSlice'
import flightSlice from 'features/footprint/flightSlice'
// import snapShotReducer from 'features/nfts/snapShotSlice'
import cbyLivePriceReducer from 'features/cby/cbyLivePriceSlice'
import nftreesReducer from 'features/nfts/nftreesSlice'
import plotsReducer from 'features/nfts/plotsSlice'
import listingReducer from 'features/marketplace/listingSlice'
import listedNftsOffersReducer from 'features/nfts/offersSlice'
import cartReducer from 'components/MarketplaceV2/features/cart/cartSlice'
import treeReducer from 'features/tree-map/treeSlice'

export const store = configureStore({
  reducer: {
    authentication: authReducer,
    otp: otpReducer,
    userProfile: userProfileReducer,
    wallet: walletReducer,
    ownedNfts: ownedNftsReducer,
    alert: alertReducer,
    staking: stakingReducers,
    stake: stakeReducers,
    aco2: aco2Reducer,
    aco2Pools: aco2PoolsReducer,
    buyToken: buyTokenReducer,
    footprint: footprintReducer,
    flight: flightSlice,
    // snapShot: snapShotReducer,
    cbyLivePrice: cbyLivePriceReducer,
    nftrees: nftreesReducer,
    plots: plotsReducer,
    listings: listingReducer,
    listedNftsOffers: listedNftsOffersReducer,
    cart: cartReducer,
    tree: treeReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})
