const CertificateTable = ({ userCertificateData }) => {
    return (
        <div className="flex flex-col w-full bg-darkGreen bg-opacity-70 border rounded-lg mb-6 overflow-hidden">
            <div className="px-4 py-3 flex flex-col flex-1 h-graphCardHeight">
                <div className="overflow-auto h-full">
                    <div className="hidden lg:block">
                    <table className="w-full text-white text-center table-fixed">
                        <thead className="sticky top-0">
                            <tr className="border-b border-light_green font-medium text-base tracking-wider">
                            <th className="px-4 py-2 w-3/16">Certificate Id</th>
                            <th className="px-4 py-2 w-2/16">Amount(KG)</th>
                            <th className="px-4 py-2 w-3/16">Title</th>
                            <th className="px-4 py-2 w-3/16">Reason</th>
                            <th className="px-4 py-2 w-1/12">Month</th>
                            <th className="px-4 py-2 w-1/12">Year</th>
                            <th className="px-4 py-2 w-2/12">Issued Date</th>
                            <th className="px-4 py-2 w-2/12">Carbon Offset Certificate</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="h-graphCardHeight overflow-y-scroll">
                        <table className="w-full text-white text-center table-fixed">
                            <tbody>
                                {userCertificateData !== null && userCertificateData !== undefined && (
                                    userCertificateData.map(certificate => (
                                        <tr key={certificate.id} className="border-b border-light_green">
                                            <td className="px-4 py-2 w-3/16 text-center">{certificate.token_id || certificate.tokenId}</td>
                                            <td className="px-4 py-2 w-2/16 text-center">{certificate.total_aco2_burned}</td>
                                            <td className="px-4 py-2 w-3/16 text-center">{certificate.company_name}</td>
                                            <td className="px-4 py-2 w-3/16 text-center">{certificate.reason}</td>
                                            <td className="px-4 py-2 w-1/12 text-center">{certificate.date.split(' ')[0]}</td>
                                            <td className="px-4 py-2 w-1/12 text-center">{certificate.date.split(' ')[2]}</td>
                                            <td className="px-4 py-2 w-2/12 text-center">{certificate.date}</td>
                                            <td className="px-4 py-2 w-2/12 text-center">
                                                <button 
                                                    className="bg-carbifyOrange text-white px-4 py-2 rounded-lg hover:bg-opacity-80 transition duration-300"
                                                    onClick={() => window.open(certificate.image, '_blank')}
                                                >Download</button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <div className="block lg:hidden">
                        <div className="h-graphCardHeight overflow-y-scroll">
                            {userCertificateData !== null && userCertificateData !== undefined && (
                                userCertificateData.map(certificate => (
                                    <div key={certificate.id} className="border-b border-light_green p-4">
                                        <div className="flex justify-between text-white">
                                            <strong>Certificate Id:</strong>
                                            <span>{certificate.token_id || certificate.tokenId}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Amount(KG):</strong>
                                            <span>{certificate.total_aco2_burned}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Title:</strong>
                                            <span>{certificate.company_name}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Reason:</strong>
                                            <span>{certificate.reason}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Month:</strong>
                                            <span>{certificate.date.split(' ')[0]}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Year:</strong>
                                            <span>{certificate.date.split(' ')[2]}</span>
                                        </div>
                                        <div className="flex justify-between text-white">
                                            <strong>Issued Date:</strong>
                                            <span>{certificate.date}</span>
                                        </div>
                                        <div className="flex justify-center">
                                            <button 
                                                className="bg-carbifyOrange text-white px-4 py-2 rounded-lg mt-2 hover:bg-opacity-80 transition duration-300"
                                                onClick={() => window.open(certificate.image, '_blank')}
                                            >Download</button>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertificateTable;