import React from 'react'
import SearchByName from 'components/MarketplaceV2/components/filters/SearchByName'
import SortFilter from 'components/MarketplaceV2/components/filters/SortFilter'
import FilterByType from 'components/MarketplaceV2/components/filters/FilterByType'
import ReasonFilter from 'components/MarketplaceV2/components/filters/ReasonFilter'
import CertificateTable from './CertificateTable'

const CertificateContent = ({ userCertificateData }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="relative flex-grow">
        <div className="flex-grow">
          <CertificateTable userCertificateData={userCertificateData} />
        </div>
      </div>
    </div>
  )
}

export default CertificateContent
