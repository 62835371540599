import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'

const initialState = {
  loadingAco2Pools: false,
  aco2Pools: null,
  errors: null,
}

export const getAco2Pools = createAsyncThunk('aco2Pools/getAco2Pools', async (_, thunkAPI) => {
  try {
    const res = await axiosInstance.get(`/aco2-pools/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const aco2PoolsSlice = createSlice({
  name: 'aco2Pools',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAco2Pools.pending, state => {
        state.loadingAco2Pools = true
      })
      .addCase(getAco2Pools.fulfilled, (state, action) => {
        state.loadingAco2Pools = false
        state.aco2Pools = action.payload
      })
      .addCase(getAco2Pools.rejected, (state, action) => {
        state.loadingAco2Pools = false
        state.errors = action.payload
      })
  },
})

export const {} = aco2PoolsSlice.actions

export default aco2PoolsSlice.reducer
