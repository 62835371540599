import React, { useEffect, useState } from "react";
import {
  useAccount,
  useWriteContract,
  useWaitForTransactionReceipt,
  useSignMessage,
} from "wagmi";
import { getERC721Data } from "features/dashboard/getERC721Data";
import { getACO2TotalBalance } from "features/dashboard/getACO2TotalBalance";
import { burnACO2Rewards } from "features/dashboard/burnACO2";
import { getACO2TotalBurnedAmount } from "features/dashboard/getACO2TotalBurnedAmount";
import { aCO2_ABI } from "constants/BlockchainConstants";
import { Config } from "../../config.js";
import { toast } from "react-toastify";
import Spinner from "components/utils/Spinner.js";
import { generateCertificate } from "features/dashboard/generateCertificate.js";
import BurnPopup from "components/certificates/BurnPopup.js";
import CreatePopup from "components/certificates/CreatePopup.js";
import GenerateCertficateCard from "./GenerateCertficateCard.js";
import { getACO2TotalUnusedBurnedAmount } from "features/dashboard/getACO2TotalUnusedBurnedAmount.js";
import { useSelector } from "react-redux";
import { getUserCertificates } from "features/certificates/getUserCertificates.js";
import { submitCertificateRequest } from "features/certificates/submitCertificateRequest.js";
import { getUserCertificateRequests } from "features/certificates/getUserCertificateRequests.js";
import { removeCertificateRequest } from "features/certificates/removeCertificateRequest.js";

const CarbonCertificateCard = ({ 
  balance, 
  setBalance,
  setUserCertificates, 
  setUserCertificatesRequests, 
  certificateGenerationData, 
  setCertificateGenerationData 
}) => {
  const { address } = useAccount();

  const [showPopupBurn, setShowPopupBurn] = useState(false);
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [generatingCertificate, setGeneratingCertificate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [burnBalance, setBurnBalance] = useState(null);
  const [unusedBurnBalance, setUnusedBurnBalance] = useState(null);
  const [erc721Data, setErc721Data] = useState(null);

  const [amount, setAmount] = useState("");

  const [burnedAmount, setBurnedAmount] = useState("");
  const [title, setTitle] = useState("");
  const [reason, setReason] = useState("");
  const [date, setDate] = useState("");

  const [burnBatchParamsQueue, setBurnBatchParamsQueue] = useState([]);
  const [burnBatchParams, setBurnBatchParams] = useState([]);

  const { userInfo } = useSelector((state) => state.authentication);

  const { 
    signMessage, 
    isSuccess: signatureApproved, 
    isError: signatureDenied, 
    data: signature 
  } = useSignMessage();

  const {
    data: burnBatchHash,
    writeContract: burnBatchWrite,
    isPending: buriningBatch,
    isError: burnBatchError,
  } = useWriteContract();
  const { isSuccess: isBurningBatchSuccess, isError: isBurningBatchError, isLoading: burnBatchAwaiting } = 
    useWaitForTransactionReceipt({ hash: burnBatchHash });

  const handleClosePopup = () => {
    setShowPopupBurn(false);
    setShowPopupCreate(false);
    setAmount("");
    setBurnedAmount("");
    setTitle("");
    setReason("");
    setDate("");
  };

  const handleOpenPopupBurn = () => {
    setShowPopupBurn(true);
  };

  const handleOpenPopupCreate = () => {
    setShowPopupCreate(true);
  };

  const handleTreeAmount = (e) => {
    const value = e.target.value;
    // Regular expression to test for a whole number
    const isWholeNumber = /^\d*$/;

    if (isWholeNumber.test(value)) {
      setAmount(value.toString());
      // Uncomment and update the next line as necessary for your application
      // setRequiredCBY((value * 5) / cbyLivePrice?.weeklyPrice);
    }
  };

  const handleBurnedAmount = (e) => {
    const value = e.target.value;
    // Regular expression to test for a whole number
    const isWholeNumber = /^\d*$/;

    if (isWholeNumber.test(value)) {
      setBurnedAmount(value);
    }
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
  };

  const handleReason = (e) => {
    const value = e.target.value;
    setReason(value);
  };

  const handleDate = (newDate) => {
    if (newDate) {
        const formattedDate = newDate.toLocaleDateString('en-GB');
        setDate(formattedDate);
    } else {
        setDate('');
    }
  };

  const handleBurn = async () => {
    try {
      setLoading(true);
      let tokenData = balance.moreDetaildBalance.map((balance) => {
        return {
          token_id: balance.tokenId,
          contract_address: balance.contractAddress,
          erc_1155_token_id: balance.tokenId,
          erc_1155_amount: balance.balance,
        };
      });
      burnACO2Rewards(tokenData, amount).then((data) => {
        if (!data.error) {
          toast.success("Successfully submitted burn request");
          console.log("burnACO2Rewards - data:", data);
          setBurnBatchParamsQueue(data);
        } else {
          toast.error("Error submitting burn request");
          if (address && erc721Data) {
            getACO2TotalBalance(address, erc721Data.token_data).then((result) => {
              if (!result.error) {
                setBalance(result);
              }
            });
            getACO2TotalBurnedAmount(address).then((result) => {
              if (!result.error) {
                setBurnBalance(result);
              } else {
                setTimeout(() => {
                  getACO2TotalBurnedAmount(address).then((result) => {
                    if (!result.error) {
                      setBurnBalance(result)
                    }
                  });
                }, [3000])
              }
            });
            getACO2TotalUnusedBurnedAmount(address).then((result) => {
              if (!result.error) {
                setUnusedBurnBalance(result);
              }
            });
          }
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error submitting burn request");
      if (address && erc721Data) {
        getACO2TotalBalance(address, erc721Data.token_data).then((result) => {
          if (!result.error) {
            setBalance(result);
          }
        });
        getACO2TotalBurnedAmount(address).then((result) => {
          if (!result.error) {
            setBurnBalance(result);
          } else {
            setTimeout(() => {
              getACO2TotalBurnedAmount(address).then((result) => {
                if (!result.error) {
                  setBurnBalance(result)
                }
              });
            }, [3000])
          }
        });
        getACO2TotalUnusedBurnedAmount(address).then((result) => {
          if (!result.error) {
            setUnusedBurnBalance(result);
          }
        });
      }
      setLoading(false);
      return { error: error.message };
    }
  };

  const handleSubmitCertificateRequest = async () => {
    try {
      setLoading(true);
      const requestData = {
        amount: burnedAmount,
        // date format: dd/mm/yyyy should be yyyy/mm/dd
        date: date.split("/").reverse().join("/"),
        reason: reason,
        title: title,
        email: userInfo?.email,
      };
      submitCertificateRequest(requestData).then((data) => {
        if (data && data.message === 'Certificate request created successfully.') {
          toast.success("Successfully submitted certificate request. Closing popup...");
          getUserCertificateRequests().then((data) => {
            if (data) {
              setUserCertificatesRequests(data);
              setLoading(false);
              setTimeout(() => {
                handleClosePopup();
              }, 1000);
            }
          });
        } else {
          toast.error("Error submitting certificate request");
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error submitting certificate request");
      return { error: error.message };
    }
  };

  useEffect(() => {
    const handleGenerateCertificate = async (amount, title, reason, date) => {
      // if date is not in the format dd/mm/yyyy return
      // if (!date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      //   toast.error('Date must be in the format dd/mm/yyyy')
      //   return
      // }
      setGeneratingCertificate(true);
      setBurnedAmount(amount);
      setTitle(title);
      setReason(reason);
      // date is in `2024-08-26T12:34:18.628Z` format should be dd/mm/yyyy
      console.log("date:", date.split("T")[0].split("-").reverse().join("/"));
      setDate(date.split("T")[0].split("-").reverse().join("/"));
      const formattedAmount = amount.toString() + "000000000000000000"; // 18 decimals
      const message = address.toLowerCase() + " " + formattedAmount;
      signMessage({ message: message });
    };
    if (certificateGenerationData !== null) {
      const { amount, title, reason, date } = certificateGenerationData;
      handleGenerateCertificate(amount, title, reason, date);
    }
  }, [certificateGenerationData]);

  useEffect(() => {
    if (burnBatchParamsQueue.length > 0) {
      setBurnBatchParams(burnBatchParamsQueue[0]);
    } else if (burnBatchParamsQueue.length === 0 && loading === true) {
      if (address && erc721Data) {
        setTimeout(() => {
          getACO2TotalBalance(address, erc721Data.token_data).then((result) => {
            if (!result.error) {
              setBalance(result);
            }
          });
          getACO2TotalBurnedAmount(address).then((result) => {
            if (!result.error) {
              setBurnBalance(result);
            } else {
              setTimeout(() => {
                getACO2TotalBurnedAmount(address).then((result) => {
                  if (!result.error) {
                    setBurnBalance(result)
                  }
                });
              }, [3000])
            }
          })
          .then(getACO2TotalUnusedBurnedAmount(address).then((result) => {
            if (!result.error) {
              setUnusedBurnBalance(result);
            }
          }))
          .then(() => setLoading(false));
        }, 5000);
      }
    }
  }, [burnBatchParamsQueue]);
  useEffect(() => {
    if (burnBatchParams?.args?.length > 0) {
      burnBatchWrite({
        args: burnBatchParams.args,
        abi: aCO2_ABI,
        address: Config().contract_addresses.aco2_address.toLowerCase(),
        functionName: "batchBurn",
      });
    }
  }, [burnBatchParams]);
  useEffect(() => {
    if (isBurningBatchSuccess) {
      toast.success("Successfully burned $aCO2");
      setBurnBatchParamsQueue((prev) => prev.slice(1));
    } else if (isBurningBatchError || burnBatchError) {
      toast.error("Error burning $aCO2");
      if (address && erc721Data) {
        getACO2TotalBalance(address, erc721Data.token_data).then((result) => {
          if (!result.error) {
            setBalance(result);
          }
        });
        getACO2TotalBurnedAmount(address).then((result) => {
          if (!result.error) {
            setBurnBalance(result);
          } else {
            setTimeout(() => {
              getACO2TotalBurnedAmount(address).then((result) => {
                if (!result.error) {
                  setBurnBalance(result)
                }
              });
            }, [3000])
          }
        });
        getACO2TotalUnusedBurnedAmount(address).then((result) => {
          if (!result.error) {
            setUnusedBurnBalance(result);
          }
        });
        setLoading(false);
      }
    }
  }, [isBurningBatchSuccess, isBurningBatchError, burnBatchError]);

  useEffect(() => {
    if (signatureApproved) {
      toast.success('Signature approved!')
      const formattedAmount = 
        burnedAmount.toString() + "000000000000000000"; // 18 decimals
      generateCertificate(
        address,
        formattedAmount,
        date,
        reason,
        title,
        signature,
        userInfo._id
      ).then((data) => {
        console.log("data:", data);
        setTimeout(() => {
          getACO2TotalUnusedBurnedAmount(address).then((result) => {
            if (!result.error) {
              setUnusedBurnBalance(result);
            }
          });
          const companyId = userInfo?._id; // prodcution
          // const companyId = "6658e0e04cbfbe6b999d61d7"; // development
          getUserCertificates(companyId, null).then((data) => {
            if (data.user_certificates_data) {
              setUserCertificates(data.user_certificates_data);
            }
          });
          removeCertificateRequest(certificateGenerationData._id).then((data) => {
            setCertificateGenerationData(null);
            setBurnedAmount("");
            setTitle("");
            setReason("");
            setDate("");
            getUserCertificateRequests().then((data) => {
              if (data) {
                setUserCertificatesRequests(data);
              }
            });
          });
        }, 2000)
        if (data.code === 200) {
          toast.success("Successfully generated certificate");
          setGeneratingCertificate(false);
        } else {
          toast.error("Error generating certificate");
          setGeneratingCertificate(false);
        }
      });
    } else if (signatureDenied) {
      toast.error('Signature denied')
      setCertificateGenerationData(null);
      setBurnedAmount("");
      setTitle("");
      setReason("");
      setDate("");
      setGeneratingCertificate(false)
    }
  }, [signatureApproved, signatureDenied])

  useEffect(() => {
    getERC721Data(address, undefined).then((data) => {
      // undefined = all erc721 contracts
      setErc721Data(data);
    });
    getACO2TotalBurnedAmount(address).then((result) => {
      if (!result.error) {
        setBurnBalance(result);
      } else {
        setTimeout(() => {
          getACO2TotalBurnedAmount(address).then((result) => {
            if (!result.error) {
              setBurnBalance(result)
            }
          });
        }, [3000])
      }
    });
    getACO2TotalUnusedBurnedAmount(address).then((result) => {
      if (!result.error) {
        setUnusedBurnBalance(result);
      }
    });
  }, [address]);

  useEffect(() => {
    if (address && erc721Data) {
      getACO2TotalBalance(address, erc721Data.token_data).then((result) => {
        if (!result.error) {
          setBalance(result);
        }
      });
    }
  }, [address, erc721Data]);

  return (
    <>
      { burnBatchAwaiting || buriningBatch || generatingCertificate ? <Spinner /> : null }
      <BurnPopup 
        showPopupBurn={showPopupBurn}
        handleClosePopup={handleClosePopup}
        handleBurn={handleBurn}
        amount={amount}
        handleTreeAmount={handleTreeAmount}
        balance={balance}
        burnBalance={burnBalance}
        buriningBatch={buriningBatch}
        burnBatchAwaiting={burnBatchAwaiting}
      />
      <CreatePopup 
        showPopupCreate={showPopupCreate}
        handleClosePopup={handleClosePopup}
        handleSubmitCertificateRequest={handleSubmitCertificateRequest}
        burnedAmount={burnedAmount}
        handleBurnedAmount={handleBurnedAmount}
        title={title}
        handleTitle={handleTitle}
        reason={reason}
        handleReason={handleReason}
        date={date}
        handleDate={handleDate}
        unusedBurnBalance={unusedBurnBalance}
        generatingCertificate={generatingCertificate}
        loading={loading}
      />
      <GenerateCertficateCard 
        handleOpenPopupBurn={handleOpenPopupBurn}
        handleOpenPopupCreate={handleOpenPopupCreate}
        balance={balance}
        showPopupBurn={showPopupBurn}
        showPopupCreate={showPopupCreate}
      />
    </>
  );
};

export default CarbonCertificateCard;
