/* global BigInt */
import { CiWallet } from 'react-icons/ci';
import { getAllLandplotData } from 'features/dashboard/getAllLandplotData';
import { getAllTreeData } from 'features/dashboard/getAllTreeData';
import { getCBYBalance } from 'features/staking/getCBYBalance';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';
import { Config } from '../../config';
import { CBY_ABI, STAKING_ABI } from 'constants/BlockchainConstants';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { stakeTrees } from 'features/staking/stakeTrees';
import { estimateStakingGasCost } from 'features/staking/estimateStakingContractGasCost';


const Popup = ({ 
    showPopup,
    handleClosePopup,
    title,
    amount,
    setAmount,
    totalNftrees,
    totalStakedNftrees,
    availableGenesisPlots,
    availableStandardPlots,
    availablePremiumPlots,
    totalGenesisPlots,
    totalStandardPlots,
    totalPremiumPlots,
    requiredCBY,
    setLoading,
    setTreeData,
    setLandPlotData,
    setTotalCBY,
    treeData,
    landPlotData,
    setApprovalParams,
    approvedAmount,
    setApprovedAmount,
    approvalParams,
    loading,
    setYearlyEstimatedRewards,
    setTotalStakedNftrees,
    totalLockedNftrees,
    setTotalLockedNftrees
}) => {
  const { address: walletAddress } = useAccount()

  // Staking
  const [stakeParamsQueue, setStakeParamsQueue] = useState([])
  const [stakeParams, setStakeParams] = useState([])
  const [stakingGasCost, setStakingGasCost] = useState(0)

  const handleTreeAmount = e => {
    const value = e.target.value
    // Regular expression to test for a whole number
    const isWholeNumber = /^\d*$/

    if (isWholeNumber.test(value)) {
      setAmount(value.toString())
      // Uncomment and update the next line as necessary for your application
      // setRequiredCBY((value * 5) / cbyLivePrice?.weeklyPrice);
    }
  }
  
  // Approving CBY for staking
  const {
      data: approveHash,
      writeContract: approveWrite,
      isPending: isApproving,
      isError: approveWriteError,
  } = useWriteContract()
  const {
      isLoading: approveAwaiting,
      isSuccess: approveSuccess,
      isError: approveError,
  } = useWaitForTransactionReceipt({ hash: approveHash })

  // Staking
  const { 
      data: stakeHash, 
      writeContract: stakeWrite,
      isPending: isStaking,
      isError: stakeWriteError 
  } = useWriteContract()
  const { 
      isLoading: stakeAwaiting, 
      isSuccess: stakeSuccess, 
      isError: stakeError 
  } = useWaitForTransactionReceipt({ hash: stakeHash })

  // Approving CBY for staking
  useEffect(() => {
      if (approveSuccess) {
        toast.success('Successfully approved CBY for staking')
        setApprovedAmount(Number(approvalParams?.args[1]))
      } else if (approveWriteError || approveError) {
        toast.error('Error in approving CBY for staking')
        setTreeData(null)
        setTotalCBY(null)
        setTimeout(() => {
          Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
          ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
          })
        }, 5000)
      }
  }, [approveAwaiting, approveError, approveWriteError, approveSuccess])

  // Staking
  useEffect(() => {
      if (stakeParamsQueue.length > 0) {
        console.log('stakeParamsQueue:', stakeParamsQueue)
        setStakeParams(stakeParamsQueue[0])
      } else if (stakeParamsQueue.length === 0 && loading === true) {
        setTimeout(() => {
          Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
          ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
          })
        }, 5000)
      }
  }, [stakeParamsQueue])
  useEffect(() => {
      if (stakeParams?.args?.length > 0) {
        console.log('stakeParams:', stakeParams)
        stakeWrite({
          address: Config().contract_addresses.staking_address.toLowerCase(),
          abi: STAKING_ABI,
          functionName: 'stakeMultiple',
          args: stakeParams?.args,
        })
      }
  }, [stakeParams])
  useEffect(() => {
      if (stakeSuccess) {
        toast.success('Successfully staked trees')
        let amountOfTrees = 0
        amountOfTrees = stakeParams?.args[0].length
        setYearlyEstimatedRewards(prevState => prevState + Number(amountOfTrees) * 175)
        setTotalStakedNftrees(Number(totalStakedNftrees) + Number(amountOfTrees))
        if (Number(totalLockedNftrees) > 0) setTotalLockedNftrees(Number(totalLockedNftrees) - Number(amountOfTrees))
        setStakeParamsQueue(prevQueue => prevQueue.slice(1))
      } else if (stakeWriteError || stakeError) {
        toast.error('Error in staking trees')
        setTreeData(null)
        setLandPlotData(null)
        setTimeout(() => {
          Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
          ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
          })
        }, 5000)
      }
  }, [stakeSuccess, stakeError, stakeWriteError, stakeAwaiting])

  // Staking gas cost
  useEffect(() => {
    const calculateTotalGasCost = async () => {
        if (amount === '' || treeData === null || landPlotData === null) {
            setStakingGasCost('0');
            return;
        }

        setLoading(true);

        // most cby is locked first
        const sortedTreeData = treeData.sort((a, b) => b.locked_cby - a.locked_cby);
        const data = await stakeTrees(Number(amount), sortedTreeData, landPlotData, title);

        if (data.success) {
            let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
            const amountOfTrees = data.stakedTreesCount;

            const promises = [];
            for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
                const endIndex = Math.min(i + MAX_TREES_PER_TX, amountOfTrees);
                const treeIdsChunk = data.stakedTreeIds.slice(i, endIndex);
                const treeAddressesChunk = data.selectedTreeAddresses.slice(i, endIndex);
                const plotIdsChunk = data.stakedPlotIds.slice(i, endIndex);
                const plotAddressesChunk = data.selectedPlotAddresses.slice(i, endIndex);

                if (treeIdsChunk.length === 0) break;

                const promise = estimateStakingGasCost(
                    'stakeMultiple',
                    treeIdsChunk,
                    treeAddressesChunk,
                    plotIdsChunk,
                    plotAddressesChunk
                ).then(gasCostData => parseFloat(Number(gasCostData.formattedCost).toFixed(4)));

                promises.push(promise);
            }

            const costs = await Promise.all(promises);
            const totalGasCost = costs.reduce((acc, cost) => acc + cost, 0);
            setLoading(false);
            setStakingGasCost(totalGasCost.toFixed(4));
        } else {
            setStakingGasCost('0');
            setLoading(false);
        }
        setLoading(false);
    };

    calculateTotalGasCost();
  }, [amount, treeData, landPlotData]);

  return (
      <>
        {showPopup ? (
            <div
            id="crypto-modal"
            tabindex="-1"
            aria-hidden="true"
            className="fixed flex justify-center items-center z-50 bg-black bg-opacity-80 w-full h-full p-4  overflow-x-hidden overflow-y-auto inset-0 h-modal"
            >
            <div className="relative w-full h-full max-w-md md:h-auto">
                <div className="relative bg-light_green bg-opacity-80 p-6 border rounded-lg shadow">
                <button
                    onClick={() => handleClosePopup()}
                    type="button"
                    className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-hide="crypto-modal"
                >
                    <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center justify-center pb-2">
                    <div className="text-xl font-semibold">Stake NFTrees on {title} Landplot</div>
                </div>
                <div className="flex flex-col">
                    <div className="mb-3">
                    <div className="relative py-2 space-y-1">
                        <label for="amount" className="block text-base font-bold tracking-wide">
                        Trees amount
                        </label>
                        <input
                        type="text"
                        name="amount"
                        id="amount"
                        className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                        placeholder="amount"
                        required="true"
                        value={amount}
                        onChange={handleTreeAmount}
                        />
                    </div>
                    <div className="flex space-x-1 text-sm font-normal">
                        <IoIosInformationCircleOutline className="w-4 h-4  mt-0.5 stroke-1" />
                        <span>Each tree requires $5 worth of $CBY for staking</span>
                    </div>
                    </div>
                    <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                    <div className="flex justify-between">
                        <span>Available NFTrees for staking</span>
                        <span>
                        {totalNftrees === null || totalStakedNftrees === null
                            ? '0'
                            : Number(totalStakedNftrees)}{' '}
                        / {totalNftrees === null ? '0' : totalNftrees}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span>Spots available on plots</span>
                        <span>
                        {title === 'Genesis'
                            ? availableGenesisPlots
                            : title === 'Rare'
                            ? availablePremiumPlots
                            : availableStandardPlots}{' '}
                        /{' '}
                        {title === 'Genesis'
                            ? totalGenesisPlots * 50
                            : title === 'Rare'
                            ? totalPremiumPlots * 30
                            : totalStandardPlots * 15}{' '}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span>Total CBY needed for staking</span>
                        <span>{parseFloat(Number(requiredCBY)?.toFixed(2)).toString()} $CBY</span>
                    </div>
                    <div className="flex justify-between">
                        <span>MATIC needed → gas fee (est.)</span>
                        <span>{stakingGasCost} $MATIC</span>
                    </div>
                    </div>
                    {approvedAmount < BigInt(requiredCBY * 10 ** 18) ? (
                    <button
                        disabled={isApproving || approveAwaiting || amount === '' || treeData === null}
                        onClick={() => {
                            const args = [
                                `${Config().contract_addresses.staking_address}`.toLowerCase(),
                                ethers.constants.MaxUint256,
                            ]
                            toast.success('Submitting approval request')
                            setApprovalParams({ args: args })
                            approveWrite({
                            address: Config().contract_addresses.cby_address.toLowerCase(),
                            abi: CBY_ABI,
                            functionName: 'approve',
                            args: args,
                            })
                        }}
                        className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                        <CiWallet className="w-6 h-6 stroke-1" />
                        <span className="text-lg tracking-wider">
                        {isApproving || approveAwaiting ? 'Approving CBY...' : 'Approve CBY'}
                        </span>
                    </button>
                    ) : (
                    <>
                        <button
                        disabled={
                            isStaking || stakeAwaiting || amount === '' || treeData === null || landPlotData === null || loading
                        }
                        onClick={() => {
                            setLoading(true)
                            toast.success('Submitting staking request')
                            // most cby is locked first
                            const sortedTreeData = treeData.sort((a, b) => {
                            const aAmount = a.locked_cby
                            const bAmount = b.locked_cby

                            if (aAmount > bAmount) {
                                return -1 // a is greater than b
                            } else if (aAmount < bAmount) {
                                return 1 // b is greater than a
                            } else {
                                return 0 // a and b are equal
                            }
                            })

                            stakeTrees(Number(amount), sortedTreeData, landPlotData, title)
                            .then(async data => {
                                if (data.success) {
                                setTreeData(null)
                                setLandPlotData(null)
                                let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx)
                                let query = []

                                const amountOfTrees = data.stakedTreesCount
                                const selectedTreeIds = data.stakedTreeIds
                                const selectedTreeAddresses = data.selectedTreeAddresses
                                const selectedPlotIds = data.stakedPlotIds
                                const selectedPlotAddresses = data.selectedPlotAddresses

                                if (amountOfTrees <= MAX_TREES_PER_TX) {
                                    MAX_TREES_PER_TX = amountOfTrees
                                }

                                for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
                                    const treeIdsChunk = selectedTreeIds.slice(i, i + MAX_TREES_PER_TX)
                                    const treeAddressesChunk = selectedTreeAddresses.slice(i, i + MAX_TREES_PER_TX)

                                    // Assuming that selectedPlotIds and selectedPlotAddresses are prepared beforehand
                                    // and are the same length as the tree arrays.
                                    const plotIdsChunk = selectedPlotIds.slice(i, i + MAX_TREES_PER_TX)
                                    const plotAddressesChunk = selectedPlotAddresses.slice(i, i + MAX_TREES_PER_TX)

                                    if (
                                    treeIdsChunk.length === 0 ||
                                    treeAddressesChunk.length === 0 ||
                                    plotIdsChunk.length === 0 ||
                                    plotAddressesChunk.length === 0
                                    ) {
                                    break
                                    }
                                    const args = [treeIdsChunk, treeAddressesChunk, plotIdsChunk, plotAddressesChunk]
                                    query.push({ args: args })
                                }
                                console.log('query:', query)
                                setStakeParamsQueue(query)
                                }
                                if (data.error) {
                                if (data.error === 'Not enough locked trees found') {
                                    toast.error(data.error)
                                } else if (data.error) {
                                    toast.error(data.error)
                                } else {
                                    toast.error('Error in staking trees')
                                }
                                setTreeData(null)
                                setLandPlotData(null)
                                setTimeout(() => {
                                    Promise.all([
                                    getAllTreeData(walletAddress),
                                    getAllLandplotData(walletAddress),
                                    getCBYBalance(walletAddress),
                                    ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
                                    setTreeData(allTreeData)
                                    setLandPlotData(allLandplotData)
                                    setTotalCBY(Number(cbyBalance.balanceFormatted))
                                    setLoading(false)
                                    })
                                }, 2500)
                                return;
                                }
                            })
                            .catch(err => {
                                console.error('Error in stakeTrees:', err)
                            })
                        }}
                        className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                        <CiWallet className="w-6 h-6 stroke-1" />
                        <span className="text-lg tracking-wider">
                            {isStaking || stakeAwaiting ? 'Staking...' : loading === true ? 'Calculating total gas cost...' : 'Stake'}
                        </span>
                        </button>
                        <span className="text-xs font-normal text-center mt-2">
                        <i>
                            When you press the 'Stake' button, you will need to sign several transactions with your wallet,
                            including approval, locking, and staking.
                        </i>
                        </span>
                    </>
                    )}
                </div>
                </div>
            </div>
            </div>
        ) : null}
      </> 
  )
};

export default Popup;
