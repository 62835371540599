import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import Header from 'components/header/Header'
import Sidebar from 'components/Sidebar'

// Icons
import { HiMenu, HiX } from 'react-icons/hi'
import Notification from 'components/popups/Notification'

function Layout({ title, content, publicCalculator, children }) {
  const { isAuthenticated } = useSelector(state => state.authentication)

  const [isOpen, setIsOpen] = useState(false)
  const [showNotification, setShowNotification] = useState(true)

  const toggleMenu = value => {
    setIsOpen(!value)
  }
  return (
    <>
      <div className="relative flex h-screen bg-carbifyBackground bg-no-repeat bg-center lg:bg-cover text-white overflow-auto font-montserrat">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={content} />
        </Helmet>
        {/* Sidebar */}
        <Sidebar isOpen={isOpen} toggleMenu={toggleMenu} isPublicCalculator={publicCalculator} />
        {/* Overlay */}
        {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={() => toggleMenu(isOpen)}></div>}
        {/* Content */}
        <div className="flex-1 relative lg:ml-72">
          <div className="flex items-center justify-between mb-1 pl-8 pr-6 py-3 lg:hidden bg-black bg-opacity-50">
            <button
              onClick={() => toggleMenu(isOpen)}
              className="text-carbifyOrange text-center p-1 rounded-lg hover:text-white focus:white focus:outline-none lg:hidden"
            >
              {isOpen ? <HiX className="h-8 w-8" /> : <HiMenu className="h-8 w-8" />}
            </button>
            <Link>
              <img src="/assets/images/Logo1.png" className="block w-48 object-contain h-18" alt="Cabify Logo" />
            </Link>
            <div className="w-6"></div>
          </div>
          <div className="max-w-[1363px] mx-auto pl-8 pr-6 py-6">
            {isAuthenticated && <Header />}
            {children}
          </div>
        </div>
      </div>
      <Notification 
        title={'Please choose a good RPC server'}
        // add a break tag to the message prop to break the message into two lines
        message={'Bad RPC server can cause the app to not work properly, high fees, and slow transactions.'}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      />
    </>
  )
}

export default Layout
