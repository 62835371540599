import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useReadContract } from 'wagmi'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import NftsCard from 'components/nfts/cards/NftsCard'
import NftreeBatchCard from 'components/nfts/cards/NftreeBatchCard'
import PlotsCard from 'components/nfts/cards/PlotsCard'
import ListNftModal from 'components/nfts/modals/ListNftModal'
import {toast} from 'react-toastify'

// Constants
import {
  NFT_ADDRESS,
  NFTREE_BATCH_1_ADDRESS,
  NFTREE_BATCH_2_ADDRESS,
  NFTREE_BATCH_3_ADDRESS,
  NFTREE_BATCH_4_ADDRESS,
  NFTREE_BATCH_5_ADDRESS,
  NFTREE_BATCH_6_ADDRESS,
  NFTREE_BATCH_7_ADDRESS,
  NFTREE_BATCH_8_ADDRESS,
  NFTREE_BATCH_9_ADDRESS,
  NFTREE_BATCH_10_ADDRESS,
  NFTREE_BATCH_11_ADDRESS,
  NFTREE_BATCH_12_ADDRESS,
  NFTREE_BATCH_13_ADDRESS,
  NFTREE_BATCH_15_ADDRESS,
  GENESIS_PLOT_ADDRESS,
  RARE_PLOT_ADDRESS,
  STANDARD_PLOT_ADDRESS,
  CarbifyNFTABI,
  PLOTS_ABI,
  NFTREE_ABI,
  MARKETPLACE_ADDRESS, 
  MARKETPLACE_ABI,
} from 'constants/BlockchainConstants'

// Actions
import { getNfts } from 'features/nfts/ownedNftSlice'
import { getAllTreeData } from 'features/dashboard/getAllTreeData'
import { getAllLandplotData } from 'features/dashboard/getAllLandplotData'
import Spinner from 'components/utils/Spinner'
import { getERC721Data } from 'features/dashboard/getERC721Data'
import { useNavigate } from 'react-router-dom'


let nftItems  = [
  { type:'nftree', asset_contract: NFTREE_BATCH_1_ADDRESS, title: 'Carbify Genesis NFTree', image: '/assets/images/batch_images/1.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_2_ADDRESS, title: 'Carbify NFTree #2', image: '/assets/images/batch_images/2.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_3_ADDRESS, title: 'Carbify NFTree #3', image: '/assets/images/batch_images/3.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_4_ADDRESS, title: 'Carbify NFTree #4', image: '/assets/images/batch_images/4.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_5_ADDRESS, title: 'Carbify NFTree #5', image: '/assets/images/batch_images/5.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_6_ADDRESS, title: 'Carbify NFTree #6', image: '/assets/images/batch_images/6.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_7_ADDRESS, title: 'Carbify NFTree #7', image: '/assets/images/batch_images/7.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_8_ADDRESS, title: 'Carbify NFTree #8', image: '/assets/images/batch_images/8.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_9_ADDRESS, title: 'Carbify NFTree #9', image: '/assets/images/batch_images/9.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_10_ADDRESS, title: 'NFTree Batch #10', image: '/assets/images/batch_images/10.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_11_ADDRESS, title: 'NFTree Batch #11', image: '/assets/images/batch_images/11.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_12_ADDRESS, title: 'NFTree Batch #12', image: '/assets/images/batch_images/12.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_13_ADDRESS, title: 'NFTree Batch #13', image: '/assets/images/batch_images/13.png' },
  { type:'nftree', asset_contract: NFTREE_BATCH_15_ADDRESS, title: 'NFTree Batch #15', image: '/assets/images/batch_images/15.png' },
  { type:'plot', asset_contract: GENESIS_PLOT_ADDRESS, title: 'Genesis Plots', image: '/assets/images/Genesis_Plot_NFT_Card.png' },
  { type:'plot', asset_contract: RARE_PLOT_ADDRESS, title: 'Rare Plots', image: '/assets/images/Rare_Plot_NFT_Card.png' },
  { type:'plot', asset_contract: STANDARD_PLOT_ADDRESS, title: 'Standard Plots', image: '/assets/images/Standard_Plot_NFT_Card.png' }
];

function Nfts() {

  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('Price high to low');
  const [nftPrices, setNftPrices] = useState({});
  const [userData, setUserData] = useState(null);

  const [nft, setNft] = useState({})
  const { ownedNfts } = useSelector(state => state.ownedNfts)
  const { address } = useAccount()
  const navigate = useNavigate()

  // const result = contracts.forEach(element => {
  //   return readContracts({
  //     contracts: [
  //       {
  //         ...element,
  //         functionName: 'name',
  //       },
  //     ],
  //   })
  // })

  // useEffect(() => {
  //   dispatch(getNfts({ pageKey: 0, amount: 30 }))
  // }, [walletAddress])

  const handleListNftModal = (nft, showModal) => {
    setNft(nft)
    setShowModal(showModal)
  };

  const updateNftPrice = (asset_contract, price) => {
    setNftPrices(prevPrices => ({
      ...prevPrices,
      [asset_contract]: price,
    }));
  };

  const filteredNFTs = userData !== null && userData
    .filter(nft => nft.title?.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      switch (sortOption) {
        case 'Price high to low':
          return (nftPrices[b.asset_contract] || 0) - (nftPrices[a.asset_contract] || 0);
        case 'Price low to high':
          return (nftPrices[a.asset_contract] || 0) - (nftPrices[b.asset_contract] || 0);
        case 'Recently listed':
          // Add sorting logic for Recently listed
          break;
        case 'Best offer':
          // Add sorting logic for Best offer
          break;
        case 'Highest last sale':
          // Add sorting logic for Highest last sale
          break;
        case 'Recently sold':
          // Add sorting logic for Recently sold
          break;
        case 'Recently created':
          // Add sorting logic for Recently created
          break;
        case 'Most viewed':
          // Add sorting logic for Most viewed
          break;
        case 'Oldest':
          // Add sorting logic for Oldest
          break;
        case 'Most favorited':
          // Add sorting logic for Most favorited
          break;
        default:
          return 0;
      }
    });

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  useEffect(() => {
    if (address) {
      getERC721Data(address).then((data) => {
        console.log("NFTs - data", data.token_data)
        if (data.token_data === undefined) {
          toast.error('No NFTs found for this wallet address, routing back to dashboard')
          navigate('/dashboard')
          return
        } 
        const formattedData = data.token_data.map((tree) => {
          return {
            type: nftItems.find(nft => nft?.asset_contract?.toLowerCase() === tree.contract_address.toLowerCase()).type,
            asset_contract: nftItems.find(nft => nft?.asset_contract?.toLowerCase() === tree.contract_address.toLowerCase()).asset_contract,
            title: nftItems.find(nft => nft?.asset_contract?.toLowerCase() === tree.contract_address.toLowerCase()).title,
            image: nftItems.find(nft => nft?.asset_contract?.toLowerCase() === tree.contract_address.toLowerCase()).image
          }
        })
        // create a Set from the formattedData to remove duplicates
        const uniqueData = [...new Set(formattedData.map(item => item.asset_contract))].map(asset_contract => {
          return formattedData.find(item => item.asset_contract === asset_contract)
        })
        setUserData(uniqueData)
      })
    }
  }, [address])

  return (
    <>
      { userData === null && <Spinner /> }
      <Layout title="Carbify | NFTs" content="Your NFTs">
        <PageHeading>NFTs</PageHeading>
        {/* Search Bar */}
        {/* <div className="flex justify-between items-center mb-6">
          <input
            type="text"
            placeholder="Search"
            className="w-full px-4 py-2 text-black rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="ml-4 px-4 py-2 text-black rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Price low to high">Price low to high</option>
            <option value="Price high to low">Price high to low</option> */}
            {/* <option value="Recently listed">Recently listed</option>
            <option value="Best offer">Best offer</option>
            <option value="Highest last sale">Highest last sale</option>
            <option value="Recently sold">Recently sold</option>
            <option value="Recently created">Recently created</option>
            <option value="Most viewed">Most viewed</option>
            <option value="Oldest">Oldest</option>
            <option value="Most favorited">Most favorited</option> */}
          {/* </select>
        </div> */}

        {/* Nfts */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-3 gap-y-3 mb-6">
        {userData !== null && filteredNFTs.map((nft, index) => {
            if (nft.type === 'nftree') {
              return (
                <NftreeBatchCard
                  key={index}
                  asset_contract={nft.asset_contract}
                  title={nft.title}
                  image={nft.image}
                  updateNftPrice={updateNftPrice}
                />
              );
            } else if (nft.type === 'plot') {
              return (
                <PlotsCard
                  key={index}
                  asset_contract={nft.asset_contract}
                  title={nft.title}
                  image={nft.image}
                  updateNftPrice={updateNftPrice}

                />
              );
            }
        })}

          {/* {ownedNfts?.token_data?.map(nfts => (
            <>
              <NftCard key={nfts.token_id} nft={nfts} handleListNftModal={handleListNftModal} />
            </>
          ))} */}

          {userData !== null && <NftsCard asset_contract={NFT_ADDRESS} title={'Carbify NFTs'} image={`/assets/images/cbnfts.png`} />}

          {nft && (
            <ListNftModal
              nft={nft}
              name={nft?.token_data?.[0]?.metadata?.name}
              image={nft?.token_data?.[0]?.metadata?.image}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </Layout>
    </>
  )
}
export default Nfts
