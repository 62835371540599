import { CiWallet } from 'react-icons/ci';
import { toast } from 'react-toastify';
import { Config } from '../../config';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { useEffect, useState } from 'react';
import { unstakeTrees } from 'features/staking/unstakeTrees';
import { getAllTreeData } from 'features/dashboard/getAllTreeData';
import { getAllLandplotData } from 'features/dashboard/getAllLandplotData';
import { getCBYBalance } from 'features/staking/getCBYBalance';
import { STAKING_ABI } from 'constants/BlockchainConstants';

const UnstakePopup = ({
    showPopupUnstake,
    handleClosePopup,
    title,
    amountUnstake,
    setAmountUnstake,
    stakedNftreeOnStandard,
    stakedNftreesOnPremium,
    stakedNftreesOnGenesis,
    totalNftrees,
    unstakeCBYData,
    loading,
    setLoading,
    setTreeData,
    setLandPlotData,
    setTotalCBY,
    setYearlyEstimatedRewards,
    setTotalStakedNftrees,
    totalStakedNftrees,
    treeData,
    setTotalLockedNftrees,
    totalLockedNftrees
}) => {
    const { address: walletAddress } = useAccount()

    const [unstakeParamsQueue, setUnstakeParamsQueue] = useState([])
    const [unstakeParams, setUnstakeParams] = useState([])

    const handleUnstakeTreeAmount = e => {
        const value = e.target.value
        // Regular expression to test for a whole number
        const isWholeNumber = /^\d*$/
    
        if (isWholeNumber.test(value)) {
          setAmountUnstake(value.toString())
          // setRequiredCBY((e.target.value * 5) / cbyLivePrice?.weeklyPrice)
        }
    }

    // Unstaking trees
    const { 
        data: unstakeHash, 
        writeContract: unstakeWrite,
        isPending: isUnstaking,
        isError: unstakeWriteError,
        error: unstakeWriteErrorMessage
    } = useWriteContract()
    const { 
        isLoading: unstakeAwaiting, 
        isSuccess: unstakeSuccess, 
        isError: unstakeError 
    } = useWaitForTransactionReceipt({ hash: unstakeHash })

    // Unstaking trees
    useEffect(() => {
        if (unstakeParamsQueue.length > 0) {
        console.log('unstakeParamsQueue:', unstakeParamsQueue)
        setUnstakeParams(unstakeParamsQueue[0])
        } else if (unstakeParamsQueue.length === 0 && loading === true) {
        setTimeout(() => {
            Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
            })
        }, 5000)
        }
    }, [unstakeParamsQueue])
    useEffect(() => {
        if (unstakeParams?.args?.length > 0) {
        console.log('unstakeParams:', unstakeParams)
        unstakeWrite({
            address: Config().contract_addresses.staking_address.toLowerCase(),
            abi: STAKING_ABI,
            functionName: 'unstakeMultiple',
            args: unstakeParams?.args,
        })
        }
    }, [unstakeParams])
    useEffect(() => {
        if (unstakeSuccess) {
        toast.success('Successfully unstaked trees')
        let amountOfTrees = 0
        amountOfTrees = unstakeParams?.args[0].length
        setYearlyEstimatedRewards(prevState => prevState - Number(amountOfTrees) * 175)
        setTotalStakedNftrees(Number(totalStakedNftrees) - Number(amountOfTrees))
        if (unstakeParams?.args[2] === false) {
            setTotalLockedNftrees(Number(totalLockedNftrees) + Number(amountOfTrees)) // unncomment this if you want to add the unstaked trees to the locked trees (aka if you don't unlock the CBY from the trees automatically when unstaking)
        }
        setUnstakeParamsQueue(prevQueue => prevQueue.slice(1))
        } else if (unstakeWriteError || unstakeError) {
        toast.error('Error in unstaking trees')
        setTreeData(null)
        setLandPlotData(null)
        setTimeout(() => {
            Promise.all([
            getAllTreeData(walletAddress),
            getAllLandplotData(walletAddress),
            getCBYBalance(walletAddress),
            ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
            setTreeData(allTreeData)
            setLandPlotData(allLandplotData)
            setTotalCBY(Number(cbyBalance.balanceFormatted))
            setLoading(false)
            })
        }, 5000)
        }
    }, [unstakeAwaiting, unstakeError, unstakeWriteError, unstakeSuccess])

    return (
        <>
        {showPopupUnstake ? (
            <div
            id="crypto-modal"
            tabindex="-1"
            aria-hidden="true"
            className="fixed flex justify-center items-center z-50  bg-black bg-opacity-80 w-full h-full p-4  overflow-x-hidden overflow-y-auto inset-0 h-modal"
            >
                <div className="relative w-full h-full max-w-md md:h-auto">
                    <div className="relative bg-light_green bg-opacity-80 p-6 border rounded-lg shadow">
                    <button
                        onClick={() => handleClosePopup()}
                        type="button"
                        className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        data-modal-hide="crypto-modal"
                    >
                        <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="flex items-center justify-center pb-2">
                        <div className="text-xl font-semibold">Unstake NFTrees on {title} Landplot</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="mb-3">
                        <div className="relative py-2 space-y-1">
                            <label for="amount" className="block text-base font-bold tracking-wide">
                            Trees amount
                            </label>
                            <input
                            type="text"
                            name="amount"
                            id="amount"
                            className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                            placeholder="amount"
                            required="true"
                            value={amountUnstake}
                            onChange={e => handleUnstakeTreeAmount(e)}
                            />
                        </div>
                        </div>

                        <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                        <div className="flex justify-between">
                            <span>Available NFTrees for withdrawal</span>
                            <span>
                            {title === 'Genesis'
                                ? stakedNftreesOnGenesis
                                : title === 'Rare'
                                ? stakedNftreesOnPremium
                                : stakedNftreeOnStandard}{' '}
                            / {totalNftrees === null ? '0' : totalNftrees}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>Amount of $CBY you will get (est.)</span>
                            <span>
                            {isNaN(unstakeCBYData?.averageLockedAmountWithoutFee)
                                ? '0' + ' $CBY'
                                : unstakeCBYData === null || unstakeCBYData === undefined
                                ? 'Calculating...'
                                : parseFloat(
                                    (unstakeCBYData?.averageLockedAmountWithoutFee * Number(amountUnstake))?.toFixed(2),
                                ).toString() + ' $CBY'}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>Unstaked Fee (est.)</span>
                            <span>
                            {isNaN(unstakeCBYData?.averageFee)
                                ? '0' + ' $CBY'
                                : unstakeCBYData === null || unstakeCBYData === undefined
                                ? 'Calculating...'
                                : parseFloat((unstakeCBYData?.averageFee * Number(amountUnstake))?.toFixed(2)).toString() +
                                ' $CBY'}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>Percentage (est.)</span>
                            <span>
                            {amountUnstake === '0' || amountUnstake === ''
                                ? '0%'
                                : unstakeCBYData === null || unstakeCBYData === undefined
                                ? 'Calculating...'
                                : parseFloat(unstakeCBYData?.feePercentage?.toFixed(2)).toString() + '%'}
                            </span>
                        </div>
                        </div>
                        <button
                        disabled={isUnstaking || unstakeAwaiting || amountUnstake === '' || treeData === null}
                        onClick={() => {
                            setLoading(true)
                            toast.success('Submitting unstake request')
                            const _shouldUnlock = true // if true your locked CBY will get returned to you when unstaking
                            unstakeTrees(Number(amountUnstake), treeData, title)
                            .then(data => {
                                if (data.success) {
                                setTreeData(null)
                                setLandPlotData(null)
                                let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx)
                                let query = []

                                const amountOfTrees = data.unstakedTreesCount
                                const lockedAndStakedTrees = data.lockedAndStakedTrees

                                console.log('lockedAndStakedTrees: ', lockedAndStakedTrees)
                                console.log('amountOfTrees: ', amountOfTrees)

                                if (amountOfTrees <= MAX_TREES_PER_TX) {
                                    MAX_TREES_PER_TX = amountOfTrees
                                }

                                console.log('MAX_TREES_PER_TX: ', MAX_TREES_PER_TX)

                                // Split trees into chunks of MAX_TREES_PER_TX
                                for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
                                    const chunk = lockedAndStakedTrees.slice(i, i + MAX_TREES_PER_TX)
                                    if (chunk.length === 0) {
                                    break
                                    }
                                    console.log('Processing chunk:', chunk)

                                    const treeIds = chunk.map(tree => Number(tree.token_id))
                                    const treeAddresses = chunk.map(tree => tree.contract_address)

                                    // const testArgs = [['11896'], ['0xa6d6f78cf6ef44a69980aa8e39583c19afa705e6'], [true]]
                                    const args = [treeIds, treeAddresses, _shouldUnlock]
                                    query.push({ args: args })
                                }
                                console.log('query:', query)
                                setUnstakeParamsQueue(query)
                                }
                                if (data.error) {
                                if (data.error === 'Not enough staked trees found') {
                                    toast.error(data.error)
                                } else if (data.error) {
                                    toast.error(data.error)
                                } else {
                                    toast.error('Error in unstaking trees')
                                    console.log('Error in unstaking trees:', data.error)
                                }
                                setTreeData(null)
                                setLandPlotData(null)
                                setTimeout(() => {
                                    Promise.all([
                                    getAllTreeData(walletAddress),
                                    getAllLandplotData(walletAddress),
                                    getCBYBalance(walletAddress),
                                    ]).then(([allTreeData, allLandplotData, cbyBalance]) => {
                                    setTreeData(allTreeData)
                                    setLandPlotData(allLandplotData)
                                    setTotalCBY(Number(cbyBalance.balanceFormatted))
                                    setLoading(false)
                                    })
                                }, 2500)
                                return;
                                }
                            })
                            .catch(err => {
                                console.error('Error in unstakeTrees:', err)
                            })
                        }}
                        className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed mb-2"
                        >
                        <CiWallet className="w-6 h-6 stroke-1" />
                        <span className="text-lg tracking-wider">
                            {isUnstaking || unstakeAwaiting ? 'Withdrawing $CBY...' : 'Withdraw $CBY'}
                        </span>
                        </button>

                        <span className="text-xs font-normal text-center">
                        <i>
                            When you press the 'Withdraw $CBY' button, you will both unstake and unlock the desired amount of
                            NFTrees.
                        </i>
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        ) : null}
        </>
    )
};

export default UnstakePopup;
