import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import axios from 'axios'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'
import { ALL_NFTREE_ADDRESSES } from 'constants/BlockchainConstants'

const aco2DataApi = process.env.REACT_APP_ACO2_DATA_API

const initialState = {
  loadingAllNftreesOfUser: false,
  nftreesLoading: false,
  allNftreesOfUser: null,
  nftrees: null,
  errors: null,
}

export const getNftrees = createAsyncThunk(
  'nftrees/getNftrees',
  async ({ asset_contract, pageKey }, thunkAPI) => {
    let account = getAccount(config)

    console.log('url', `${
          process.env.REACT_APP_ACO2_DATA_API
        }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${asset_contract.toLowerCase()}&pageKey=${pageKey}`)

    try {
      const res = await axiosInstance.get(
        `${
          process.env.REACT_APP_ACO2_DATA_API
        }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${asset_contract.toLowerCase()}&pageKey=${pageKey}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (res.status === 200) {
        return res.data
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getAllNFTreesOfUser = createAsyncThunk('nftrees/getAllNFTreesOfUser', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL2}/trees/${account.address.toLowerCase()}/count`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    console.log('The response for ALL NFTrees', res)

    const responses = await Promise.all(
      ALL_NFTREE_ADDRESSES.map(a =>
        axios.get(`${aco2DataApi}/api/erc721/wallet/count`, {
          params: { wallet_address: account.address.toLowerCase(), erc721_address: a.toLowerCase() },
        }),
      ),
    )

    if (responses.length > 0 && responses[0].status >= 200) {
      const totalCount = responses.map(r => r.data?.token_count).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      return { treesCount: totalCount, stakedTreesCount: 0 }
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const nftreesSlice = createSlice({
  name: 'nftrees',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNftrees.pending, (state, action) => {
        state.nftreesLoading = true
        state.errors = null
      })
      .addCase(getNftrees.fulfilled, (state, action) => {
        console.log('ACTION', action.payload)
        state.nftreesLoading = false
        state.nftrees = { token_data: action.payload.token_data, pageKey: action.payload.pageKey }
      })
      .addCase(getNftrees.rejected, (state, action) => {
        state.nftreesLoading = false
        state.errors = action.payload
      })
      .addCase(getAllNFTreesOfUser.pending, state => {
        state.loadingAllNftreesOfUser = true
      })
      .addCase(getAllNFTreesOfUser.fulfilled, (state, action) => {
        state.loadingAllNftreesOfUser = false
        state.allNftreesOfUser = action.payload
      })
      .addCase(getAllNFTreesOfUser.rejected, (state, action) => {
        state.loadingAllNftreesOfUser = false
        state.errors = action.payload
      })
  },
})

export const {} = nftreesSlice.actions
export default nftreesSlice.reducer
