import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import MenuButton from './dashboard/MenuButton'

// Icons
import { RiHome6Line } from 'react-icons/ri'
import { HiMenu, HiX } from 'react-icons/hi'
import { GiBurningTree } from 'react-icons/gi'
import HandCoinIcon from 'icons/HandCoinIcon'
import HorizentalBarChartIcon from 'icons/HorizentalBarChartIcon'
import Wallet05 from 'icons/Wallet05'
import Logout02 from 'icons/Logout02'
import ShoppingCartIcon from 'icons/ShoppingCartIcon'
import Calculator from 'icons/Calculator'
import Certificate from 'icons/Certificate'
import { useDisconnect } from 'wagmi'

// Reducers
import { logout } from 'features/authentication/authSlice'

function Sidebar({ isOpen, toggleMenu, isPublicCalculator }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { disconnect } = useDisconnect()

  const { isAuthenticated } = useSelector(state => state.authentication)

  const handleLogout = async () => {
    try {
      await disconnect()

      setTimeout(async () => {
        await dispatch(logout()).unwrap()
      }, 1000)
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  const menuItems = [
    { text: 'Dashboard', path: '/dashboard', icon: <RiHome6Line size={24} /> },
    { text: 'My NFTs', path: '/nfts', icon: <Wallet05 size={24} /> },
    { text: 'Staking', path: '/staking', icon: <HandCoinIcon size={24} /> },
    { text: 'Marketplace', path: '/marketplace', icon: <ShoppingCartIcon size={24} /> },
    { text: 'Certificates', path: '/certificates', icon: <Certificate size={24} /> },
    { text: 'CO2 Pools', path: '/aCO2-pools', icon: <HorizentalBarChartIcon size={24} /> },
    { text: 'Calculator', path: '/carbon-calculator', icon: <Calculator size={24} /> },
    { text: 'Tree Overview', path: '/tree-overview', icon: <HandCoinIcon size={24} /> },
    { text: 'Map Tree', path: '/map-tree', icon: <HandCoinIcon size={24} /> },
  ]


  let borderTopClass = '';
  let borderBottomClass = '';

  menuItems.forEach((item, index) => {
    if (location.pathname === item.path) {
      if (index === 0) {
        borderTopClass = 'rounded-br-[30px]';
      }
      if (index === menuItems.length - 1) {
        borderBottomClass = 'rounded-tr-[30px]';
      }
    }
  });

  return (
    <div
      className={`fixed inset-y-0 left-0 w-72  transition-all duration-300 ease-in-out transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 z-50 sm:z-10 border-r-0 p-0 overflow-y-auto`}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex items-center justify-center p-4 border-r-8 bg-black bg-opacity-70">
            <button
              onClick={() => toggleMenu(isOpen)}
              className="lg:hidden border border-lg border-carbifyOrange rounded-full p-2 text-carbifyOrange hover:text-white focus:text-white focus:outline-none"
            >
              {isOpen ? <HiX className="h-6 w-6" /> : <HiMenu className="h-6 w-6" />}
            </button>
          </div>
          <div className={`border-r-8 pb-8 bg-black bg-opacity-70 ${borderTopClass}`}>
            <Link to={'/'}>
              <img src="/assets/images/Logo1.png" className="block w-60 object-contain h-18 mx-auto" alt="Carbify Logo" />
            </Link>
          </div>
          <div className="w-full flex flex-col justify-between">
            {isAuthenticated &&
              menuItems.map((item, index) => {
                const isActive = location.pathname === item.path
                const isPreviousActive = index < menuItems.length - 1 && location.pathname === menuItems[index + 1].path
                const isNextActive = index > 0 && location.pathname === menuItems[index - 1].path

                return (
                  <div className={isActive ? 'z-10 bg-gradient-to-r from-black/70 to-transparent w-[92.5%]' : isNextActive ? 'hover:bg-black hover:bg-opacity-70 rounded-tr-[30px]' : isPreviousActive ? 'hover:bg-black hover:bg-opacity-70 rounded-br-[30px]' : 'hover:bg-black hover:bg-opacity-70'}>
                    <MenuButton
                      key={item.text}
                      text={item.text}
                      active={isActive}
                      path={item.path}
                      isPreviousActive={isPreviousActive}
                      isNextActive={isNextActive}
                    >
                      {item.icon}
                    </MenuButton>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={`border-r-8 bg-black bg-opacity-70 flex-grow ${borderBottomClass}`}>
          {!isPublicCalculator || isAuthenticated ? (
            <div className="p-4 flex flex-col h-full justify-end">
              {/* <button
                onClick={handleLogout}
                className="flex items-center justify-start block w-full tracking-wider group bg-darkGreen text-white hover:bg-light_green hover:text-carbifyOrange hover:drop-shadow-2xl hover:translate-y-0.5 ease-in-out duration-500 pl-12 py-4 rounded-lg shadow-xl shadow-black-500/50 font-bold"
              >
                <Logout02 />
                <span className="origin-left pl-4 text-lg">Log out</span>
              </button> */}
            </div>
          ) : (
            <MenuButton
              text={'Carbidash'}
              active={location.pathname === '/login'}
              path={'/login'}
            >
              <GiBurningTree size={24} />
            </MenuButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
