import { getStakingACO2TotalPendingReward } from './getStakingACO2TotalPendingReward';

export const claimStakingACO2Rewards = async (erc721Data) => {
    try {
        let allTokenIds = [];
        let allTokenAddresses = [];

        const estimateRewards = await getStakingACO2TotalPendingReward(erc721Data);
        const freshData = estimateRewards.moreDetaildPendingReward;

        console.log('claimStakingACO2Rewards.js: before', freshData);

        // sort the data by Number(pending_reward_formatted)
        freshData.sort((a, b) => Number(b.pending_reward_formatted) - Number(a.pending_reward_formatted));

        console.log('claimStakingACO2Rewards.js: after', freshData);

        for (let i = 0; i < freshData.length; i++) {
            allTokenIds.push(freshData[i].nftree_id);
            allTokenAddresses.push(freshData[i].batch_address);
        }

        console.log('claimStakingACO2Rewards.js: allTokenIds:', allTokenIds);
        console.log('claimStakingACO2Rewards.js: allTokenAddresses:', allTokenAddresses);

        return {
            success: true,
            allTokenIds: allTokenIds,
            allTokenAddresses: allTokenAddresses
        }

    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
