import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNftreeOverview } from "../tree-overview/getNftreeOverview";
import { getNftreeDetails } from "../tree-overview/getNftreeDetails";
import { getGlobalClusterData } from "features/certificates/getGlobalClusterData";

export const fetchOverviewData = createAsyncThunk("tree/fetchOverviewData", async () => {
  const response = await getNftreeOverview();
  return response.overview_data;
});

export const fetchDetailsData = createAsyncThunk(
  "tree/fetchDetailsData",
  async ({ clusterId, filters }) => {
    const response = await getNftreeDetails(null, null, null, clusterId, filters);
    return response.details_data;
  }
);

export const fetchGlobalClusterData = createAsyncThunk(
  "tree/fetchGlobalClusterData",
  async (filters) => {
    const response = await getGlobalClusterData(filters);
    // console.log('getGlobalClusterData',response);

    return response.global_cluster_data;
  }
);

const treeSlice = createSlice({
  name: "tree",
  initialState: {
    overviewData: null,
    detailsData: null,
    globalClusterData: null,
    selectedCluster: null,
    selectedTree: null,
    selectedFilters: [], // Initialize as an array
    batchLoading: false,
  },
  reducers: {
    setSelectedCluster: (state, action) => {
      state.selectedCluster = action.payload;
    },
    setSelectedTree: (state, action) => {
      state.selectedTree = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = Array.isArray(action.payload) ? action.payload : []; // Ensure it's an array
    },
    setBatchLoading: (state, action) => {
      state.batchLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverviewData.fulfilled, (state, action) => {
        state.overviewData = action.payload;
      })
      .addCase(fetchDetailsData.fulfilled, (state, action) => {
        state.detailsData = action.payload;
      })
      .addCase(fetchGlobalClusterData.fulfilled, (state, action) => {
        state.globalClusterData = action.payload;
      });
  },
});

export const { setSelectedCluster, setSelectedTree, setSelectedFilters, setBatchLoading } = treeSlice.actions;

export default treeSlice.reducer;
