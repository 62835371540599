/* global BigInt */
import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { toast } from 'react-toastify'
import Spinner from 'components/utils/Spinner'
import CurrencySymbol from 'components/nfts/utils/CurrencySymbol'

// Constants
import { MARKETPLACE_ADDRESS } from 'constants/BlockchainConstants'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function ApproveCurrency({ currencyAddress, currencyAbi, currencyDecimals, amount }) {
  // Approve Currency
  const {
    data: approveCurrencyHash,
    writeContract: approvedCurrencyWrite,
    isPending: isApprovingCurrency,
    isError: isApprovingCurrencyError,
  } = useWriteContract()
  console.log('approveCurrencyHash:', approveCurrencyHash)
  const {
    isLoading: approvingCurrencyLoading,
    isSuccess: approvingCurrencySuccess,
    isError: approvingCurrencyError,
  } = useWaitForTransactionReceipt({ hash: approveCurrencyHash })

  // Approve Currency
  useEffect(() => {
    if (approvingCurrencySuccess) {
      toast.success('Currency has been approved')
    } else if (isApprovingCurrencyError || approvingCurrencyError) {
      toast.error('An error occured while approving currency')
    }
  }, [isApprovingCurrencyError, approvingCurrencyError, approvingCurrencySuccess])

  return (
    <div>
      <button
        onClick={() => approvedCurrencyWrite({
          address: currencyAddress,
          abi: currencyAbi,
          functionName: 'approve',
          args: [MARKETPLACE_ADDRESS, BigInt(amount * 10 ** currencyDecimals)],
        })}
        className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
      >
        <CoinsStacked02 color="#1C4828" />
        <span>
          Approve <CurrencySymbol selectedCurrency={currencyAddress} />
        </span>
      </button>
      {(isApprovingCurrency || approvingCurrencyLoading) && <Spinner />}
    </div>
  )
}

export default ApproveCurrency
