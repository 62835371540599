import { useEffect } from 'react'
import { toast } from 'react-toastify'

const useDisplayError = errors => {
  useEffect(() => {
    if (errors && errors.msg) {
      toast.error(errors.msg)
    }
  }, [errors])
}

export default useDisplayError
