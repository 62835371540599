import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'
import { toast } from 'react-toastify'

const initialState = {
  loadingStakedStatus: false,
  loadingStakedReward: false,
  loadingStakedTree: false,
  staked_tree: null,
  staked_status: null,
  staked_reward: null,
  errors: null,
  loading: false,
  groupUUID: null,
  result: null,
  cbyAmountToTransfer: 0,
  cbyPenaltyAmount: 0,
  loadingEstimate: false,
  transactionHash: null,
  loadingUnstake: false,
  count: 0,
}

export const getStakedTree = createAsyncThunk('stake/getStakedTree', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(`/stake/stakedtree/${account.address}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getStakedStatus = createAsyncThunk('stake/getStakedStatus', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(`/stake/status/${account.address}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    console.log('Got Status ', res.data)

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getStakedReward = createAsyncThunk('stake/getStakedReward', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(`/stake/reward/${account.address}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const postEstimated = createAsyncThunk('postEstimated', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`/stake/unstake/estimate`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 201) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const postUnstake = createAsyncThunk('postUstake', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`/stake/unstake`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 201) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const postStake = createAsyncThunk('stake', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`/stake/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 201) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const postSaveTxn = createAsyncThunk('saveTxn', async (data, thunkAPI) => {
  try {
    const res = await axiosInstance.post(`/stake/saveTxn`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 201) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const stakeSlice = createSlice({
  name: 'stake',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getStakedStatus.pending, state => {
        state.loadingStakedStatus = true
      })
      .addCase(getStakedStatus.fulfilled, (state, action) => {
        state.loadingStakedStatus = false
        state.staked_status = action.payload
      })
      .addCase(getStakedStatus.rejected, (state, action) => {
        state.loadingStakedStatus = false
        state.errors = action.payload
      })
      .addCase(getStakedReward.pending, state => {
        state.loadingStakedReward = true
      })
      .addCase(getStakedReward.fulfilled, (state, action) => {
        state.loadingStakedReward = false
        state.staked_reward = action.payload
      })
      .addCase(getStakedReward.rejected, (state, action) => {
        state.loadingStakedReward = false
        state.errors = action.payload
      })
      .addCase(getStakedTree.pending, state => {
        state.loadingStakedTree = true
      })
      .addCase(getStakedTree.fulfilled, (state, action) => {
        state.loadingStakedTree = false
        state.staked_tree = action.payload
      })
      .addCase(getStakedTree.rejected, (state, action) => {
        state.loadingStakedTree = false
        state.errors = action.payload
      })
      .addCase(postStake.pending, state => {
        state.loading = true
      })
      .addCase(postStake.fulfilled, (state, action) => {
        state.loading = false
        state.groupUUID = action.payload.groupUUID
      })
      .addCase(postStake.rejected, (state, action) => {
        state.loading = false
        state.groupUUID = null
        state.errors = action.payload
        // toast.error(action.payload.message)
      })
      .addCase(postEstimated.pending, state => {
        state.loadingEstimate = true
      })
      .addCase(postEstimated.fulfilled, (state, action) => {
        state.loadingEstimate = false
        state.cbyAmountToTransfer = action.payload.cbyAmountToTransfer
        state.cbyPenaltyAmount = action.payload.cbyPenaltyAmount
      })
      .addCase(postEstimated.rejected, (state, action) => {
        state.loadingEstimate = false
        state.cbyAmountToTransfer = 0
        state.cbyPenaltyAmount = 0
        // toast.error(action.payload.message)
      })
      .addCase(postSaveTxn.pending, state => {
        state.loadingSaveTxn = true
      })
      .addCase(postSaveTxn.fulfilled, (state, action) => {
        state.loadingSaveTxn = false
        state.count = action.payload.count
        toast.success('Transaction Successfull!')
      })
      .addCase(postSaveTxn.rejected, (state, action) => {
        state.loadingSaveTxn = false
        // toast.error(action.payload.message)
      })
      .addCase(postUnstake.pending, state => {
        state.loadingUnstake = true
      })
      .addCase(postUnstake.fulfilled, (state, action) => {
        state.loadingUnstake = false
        state.transactionHash = action.payload.transactionHash
        toast.success('Transaction Successfull!')
      })
      .addCase(postUnstake.rejected, (state, action) => {
        state.loadingUnstake = false
        state.errors = action.payload
        // toast.error(action.payload.message)
      })
  },
})

export const {} = stakeSlice.actions

export default stakeSlice.reducer
