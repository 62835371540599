import React from 'react'
import { Helmet } from 'react-helmet'
import Spinner from 'components/utils/Spinner'

function AuthLayout({ title, loading, children }) {
  return (
    <div className="bg-cover bg-center h-screen relative bg-carbifyBackground text-white">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {loading && <Spinner />}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 h-screen flex flex-col justify-center items-center">
        <div className="container mx-auto overflow-y-auto p-6">
          <div className="w-full flex justify-center items-center">
            <img src="/assets/images/Logo1.png" className="block w-72 object-contain h-32" alt="Cabify Logo" />
          </div>
          <div className="w-full flex justify-center items-center">
            <div className="bg-green rounded-lg shadow w-full md:w-2/3 lg:w-1/2">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
