import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { GENESIS_PLOT_ABI, RARE_PLOT_ABI, STANDARD_PLOT_ABI } from '../../constants/BlockchainConstants';
import { Config } from '../../config.js';

// Landplots Contract ABI and Address
const STANDARD_ABI = STANDARD_PLOT_ABI;
const RARE_ABI = RARE_PLOT_ABI;
const GENESIS_ABI = GENESIS_PLOT_ABI;
const STANDARD_ADDRESS = `${Config().contract_addresses.standard_address}`.toLowerCase();
const RARE_ADDRESS = `${Config().contract_addresses.rare_address}`.toLowerCase();
const GENESIS_ADDRESS = `${Config().contract_addresses.genesis_address}`.toLowerCase();
const MAX_BATCH_SIZE = Config().max_calls_per_multicall_batch;  // Ensure this config value exists or set a sensible default

export const getAvailablePlotBalances = async (erc721Data) => {
    try {
        let standardBalance = 0;
        let rareBalance = 0;
        let genesisBalance = 0;
        let totalBalance = 0;

        const provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(provider);
        const multicall = new Multicall({
            multicallAddress: Config().contract_addresses.multi_call,
            provider
        });

        const landPlots = new web3.eth.Contract(STANDARD_ABI, STANDARD_ADDRESS);
        const rarePlots = new web3.eth.Contract(RARE_ABI, RARE_ADDRESS);
        const genesisPlots = new web3.eth.Contract(GENESIS_ABI, GENESIS_ADDRESS);

        // Process available plot balances in batches
        const processAvailablePlots = async (contract, tokens, address) => {
            let balance = 0;
            for (let i = 0; i < tokens.length; i += MAX_BATCH_SIZE) {
                const tokenBatch = tokens.slice(i, i + MAX_BATCH_SIZE);
                const results = await multicall.aggregate(
                    tokenBatch.map(token => contract.methods.getAvailablePlotCapacity(token.token_id))
                );
                results.forEach(result => {
                    const plotBalance = Number(result);
                    balance += plotBalance;
                    totalBalance += plotBalance;
                });
            }
            return balance;
        };

        if (erc721Data) {
            standardBalance = await processAvailablePlots(landPlots, erc721Data.filter(token => token.contract_address.toLowerCase() === STANDARD_ADDRESS), STANDARD_ADDRESS);
            rareBalance = await processAvailablePlots(rarePlots, erc721Data.filter(token => token.contract_address.toLowerCase() === RARE_ADDRESS), RARE_ADDRESS);
            genesisBalance = await processAvailablePlots(genesisPlots, erc721Data.filter(token => token.contract_address.toLowerCase() === GENESIS_ADDRESS), GENESIS_ADDRESS);
        }

        return {
            standardAvailablePlots: standardBalance, 
            rareAvailablePlots: rareBalance, 
            genesisAvailablePlots: genesisBalance,
            totalAvailablePlots: totalBalance
        };
    } catch (err) {
        console.error("Error:", err);
        return {
            error: err.message,
            standardAvailablePlots: 0,
            rareAvailablePlots: 0,
            genesisAvailablePlots: 0,
            totalAvailablePlots: 0
        };
    }
};
