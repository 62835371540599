import React, { useEffect, useState } from 'react'
import { marketplaceItems } from 'components/MarketplaceV2/json/marketplaceItems'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CountdownTimerListings from '../cards/cardComponents/CountdownTimerListings'
import { CBY_ADDRESS } from 'constants/BlockchainConstants'

const TabularView = ( {listings, sortValue} ) => {

  const sortingCategories = [
    'Price low to high',
    'Price high to low',
    'Recently listed',
    'Oldest',
    'Best Offer',
    'My Listings',
    'Expired',
    'All',
    'Active',
    // 'Sold',
  ]
  const ids = [32, 50, 54, 60, 61, 65, 73, 74]

  const [cards, setCards] = useState([...listings]);
  const account = getAccount(config)

  useEffect(() => {
    if (listings) {
      let values = [...listings]
      if (sortValue === sortingCategories[0]) {
        values = values.sort((a, b) => {
          return a.buyoutPricePerToken - b.buyoutPricePerToken
        })
      } else if (sortValue === sortingCategories[1]) {
        values = values.sort((a, b) => {
          return b.buyoutPricePerToken - a.buyoutPricePerToken
        })
      } else if (sortValue === sortingCategories[2]) {
        values = values.sort((a, b) => {
          return b.startTime - a.startTime
        })
      } else if (sortValue === sortingCategories[3]) {
        values = values.sort((a, b) => {
          return a.startTime - b.startTime
        })
      } else if (sortValue === sortingCategories[4]) {
        values = values.sort((a, b) => {
          return b.bestOffer - a.bestOffer
        })
      } else if (sortValue === sortingCategories[5]) {
        values = values.filter(listing => {
          return listing.lister.toString().toLowerCase() === account.address.toString().toLowerCase()
        })

        console.log(account)
      } else if (sortValue === sortingCategories[6]) {
        values = values.filter(listing => {
          return listing.endTime <= moment().unix()
        })
      } else if (sortValue === sortingCategories[8]) {
        values = values.filter(listing => {
          return listing.endTime >= moment().unix()
        })
      }

      values = values.filter(listing => {
        return !ids.includes(parseInt(listing.listingId))
      })

      setCards([...values])
      // else if (sortValue === sortingCategories[9]) {
      //   let values = [...listings]
      //   values = values.filter(listing => {
      //     return listing.sold === true
      //   })
      //   setCards([...values])
      // }
    }
  }, [sortValue])
  return (
    <>
      <div className="w-full overflow-x-auto md:scrollbar-hide scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin bg-darkGreen bg-opacity-70 border rounded-lg h-72 py-3 px-4 flex flex-col space-y-3">
        <div className="overflow-y-scroll scrollbar scrollbar-thumb-carbifyOrange scrollbar-track-[#FFCB97] scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-thin">
          <table className="w-full tracking-wider">
            <thead className="text-left">
              <tr className="sticky -top-0.5  border-b border-[#527766] font-medium text-base z-10 bg-darkGreen bg-opacity-80">
                <th className="px-2 py-3">Item</th>
                <th className="px-4 py-3">Current Price</th>
                <th className="px-4 py-3">Best Offer</th>
                <th className="px-4 py-3">Sale Ends</th>
                <th className="px-4 py-3">Owner</th>
                <th className="px-4 py-3">Time listed</th>
              </tr>
            </thead>
            <tbody className="font-medium text-base">
              { cards.map(item => (
                <tr key={item.id}>
                  <Link to={`/listing/${item.id}`}>
                  <td className="px-2 py-2 flex space-x-2 items-center">
                    <div className="w-8 h-8">
                      <img className="w-8 h-8" src={item?.metadata?.image}></img>
                    </div>
                    <div className="flex flex-col space-y-1 text-xs">
                      <div className="font-bold">{item?.metadata?.name || 'Standard'}</div>
                      <div className="font-semibold">Carbify NFTs</div>
                    </div>

                  </td>
                  </Link>
                  <td className="px-4 py-2 text-base font-medium">
                    
                  {item.buyoutPricePerToken !== '' ? 
                      item.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                      ? item.buyoutPricePerToken / 10 ** 18 + ' CBY' 
                      : item.buyoutPricePerToken / 10 ** 18 + ' USDC' 
                    : '--'}
                    {/* {item.buyoutPricePerToken / 10 ** 18} {item.currency.toLowerCase() === CBY_ADDRESS.toLowerCase() ? `CBY` : `USDC`} */}
                  </td>
                  <td className="px-4 py-2 text-base font-medium">
                    {item.bestOffer !== '' ? 
                      item.currency.toLowerCase() === CBY_ADDRESS.toLowerCase()
                      ? item.bestOffer / 10 ** 18 + ' CBY' 
                      : item.bestOffer / 10 ** 18 + ' USDC' 
                    : '--'}
                  </td>
                  <td className="px-4 py-2 text-base font-medium">
                  <CountdownTimerListings startTime={item.startTime} endTime={item.endTime} />
                  </td>
                  <td className="px-4 py-2 text-base font-medium">{item?.lister?.slice(0, 6)}...</td>
                  <td className="px-4 py-2 text-base font-medium">{moment.unix(item.startTime).format("MM/DD/YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default TabularView
