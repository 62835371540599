import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'

const authNodeLink = process.env.REACT_APP_API_URL_NODE_AUTH

const axiosInstance = axios.create({
  baseURL: authNodeLink,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const linkWallet = createAsyncThunk('wallet/linkWallet', async (walletAddress, thunkAPI) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/user-profile/link-wallet`,
      {
        walletAddress,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )

    if (response.status === 200) {
      thunkAPI.dispatch(setWalletAddress(walletAddress))
      return response.data
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const checkLinkWallet = createAsyncThunk('wallet/checkLinkWallet', async (_, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`/api/v1/user-profile/check-link-wallet`, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    return response.data.linked
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const checkCorrectLinkWallet = createAsyncThunk('wallet/checkLinkWallet', async (_, thunkAPI) => {
  let account = getAccount(config)
  const walletAddress = account.address

  try {
    const response = await axiosInstance.get(
      `/api/v1/user-profile/verify-wallet-linked?walletAddress=${walletAddress}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    )

    return response.data.linked
  } catch (error) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      isCritical: error.response.data.isCritical,
    })
  }
})

const initialState = {
  walletAddress: localStorage.getItem('walletAddress') ? JSON.parse(localStorage.getItem('walletAddress')) : null,
  loadingLinkWallet: false,
  linkWalletError: null,
  linkWalletSuccess: false,
  linked: false,
  errors: null,
  success: false,
}
export const walletLink = createSlice({
  name: 'walletLink',
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload
      localStorage.setItem('walletAddress', JSON.stringify(action.payload))
    },
    resetWallet: state => {
      state.walletAddress = null
      state.loading = false
      state.errors = null
      state.success = false
      localStorage.removeItem('walletAddress')
    },
  },
  extraReducers(builder) {
    builder
      .addCase(linkWallet.pending, state => {
        state.loadingLinkWallet = true
        state.linkWalletError = null
        state.linkWalletSuccess = false
      })
      .addCase(linkWallet.fulfilled, (state, action) => {
        state.loadingLinkWallet = false
        state.walletAddress = action.payload.walletAddress
        state.linkWalletSuccess = true
      })
      .addCase(linkWallet.rejected, (state, action) => {
        state.loadingLinkWallet = false
        state.linkWalletError = action.payload
        state.linkWalletSuccess = false
      })
      .addCase(checkLinkWallet.fulfilled, (state, action) => {
        state.linked = action.payload
      })
      .addCase(checkCorrectLinkWallet.rejected, (state, action) => {
        if (action.payload?.message === 'Wallet address mismatch') {
          state.linkWalletError = action.payload
          state.linkWalletSuccess = false
        }
      })
  },
})

export const { setWalletAddress, resetWallet } = walletLink.actions

export default walletLink.reducer
