import axios from 'axios';
import { Config } from '../../config.js';

const crcDataAPI = Config().crc_api;

export const generateCertificate = async (walletAddress, totalACO2Burned, date, reason, company_name, signature, user_id) => { // add  txMintNftHash, tokenId
    try {
        // test values
        const test = {
            "total_aco2_burned": "12000000000000000000000",
            "date": "18/09/2023",
            "reason": "THX Network helps communities to gain and retain members using quests, points and rewards. THX Network offsets their emissions 100%, effectively becoming a carbon-neutral company and protocol.",
            "company_name": "THX Network",
            "txMintNftHash": "0xa3efe7496c0d5cbdabf770a11be84bab0c730d14d84cf92cbdad936a86aeab76",
            "tokenId": 111
        }

        console.log('totalACO2Burned:', totalACO2Burned)
        console.log('date:', date)
        console.log('reason:', reason)
        console.log('company_name:', company_name)

        const response = await axios.post(`${crcDataAPI}/api/certificate/generate`, {
            wallet_address: walletAddress.toString(),
            total_aco2_burned: totalACO2Burned.toString(),
            date: date.toString(),
            reason: reason.toString(),
            company_name: company_name.toString(),
            signature: signature,
            qr_code_option: true,
            user_id: user_id
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
