import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import axios from 'axios'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'
import { toast } from 'react-toastify'

const initialState = {
  loadingAco2Rewards: false,
  loadingClaimAco2Rewards: false,
  aco2Rewards: null,
  aco2RewardsClaimed: null,
  errors: null,
}

export const getAco2Rewards = createAsyncThunk('aco2s/getAco2Rewards', async (_, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL2}/stake/reward/${account.address}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const claimAco2Rewards = createAsyncThunk('aco2s/claimAco2Rewards', async (_, thunkAPI) => {
  let account = getAccount(config)
  const data = {
    walletAddress: account.address,
  }
  try {
    const res = await axios(`${process.env.REACT_APP_API_URL2}/stake/claim`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data,
    })

    if (res.status >= 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const aco2Slice = createSlice({
  name: 'aco2s',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAco2Rewards.pending, state => {
        state.loadingAco2Rewards = true
      })
      .addCase(getAco2Rewards.fulfilled, (state, action) => {
        state.loadingAco2Rewards = false
        state.aco2Rewards = action.payload
      })
      .addCase(getAco2Rewards.rejected, (state, action) => {
        state.loadingAco2Rewards = false
        state.errors = action.payload
      })

      .addCase(claimAco2Rewards.pending, state => {
        state.loadingClaimAco2Rewards = true
      })
      .addCase(claimAco2Rewards.fulfilled, (state, action) => {
        state.loadingClaimAco2Rewards = false
        console.log('aco2', action)
        state.aco2RewardsClaimed = action.payload
        toast.success('You have claimed the aco2 rewards')
      })
      .addCase(claimAco2Rewards.rejected, (state, action) => {
        state.loadingClaimAco2Rewards = false
        state.errors = action.payload
      })
  },
})

export const {} = aco2Slice.actions

export default aco2Slice.reducer
