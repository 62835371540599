import { Config } from '../../config.js';
// ERC1155 Contract ABI and Address
const ALL_TREE_ADDRESSES = `${Config().all_tree_addresses}`.toLowerCase();

export const claimACO2Rewards = async (erc721Data) => {
    try {

        let allTokenIds = [];
        let allTokenAddresses = [];

        for (let i = 0; i < erc721Data.length; i++) {
            if (
                ALL_TREE_ADDRESSES.includes(erc721Data[i].contract_address)
            ) {
                allTokenIds.push(erc721Data[i].token_id);
                allTokenAddresses.push(erc721Data[i].contract_address);
            }
        }

        return {
            success: true,
            allTokenIds: allTokenIds,
            allTokenAddresses: allTokenAddresses
        }

    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
