import clsx from "clsx";
import PageHeading from "components/PageHeading";
import Spinner from "components/utils/Spinner";
import { getClustersFromTrees } from "features/certificates/getClustersFromTrees";
import { getDetailsAndOverviewCertificates } from "features/certificates/getDetailsAndOverviewCertificates";
import { getGlobalClusterData } from "features/certificates/getGlobalClusterData";
import { getUserCertificates } from "features/certificates/getUserCertificates";
import { getAllTreeData } from "features/dashboard/getAllTreeData";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";

function OffsetMap({
  firstComponent: FirstComponent,
  secondaryComponent: SecondaryComponent,
  className,
  isSmallScreen,
  userCertificates,
  setUserCertificates
}) {
  const [isExtended, setIsExtended] = useState(true);
  const [batchLoading, setBatchLoading] = useState(false);
  const firstComponentRef = useRef(null);
  const secondaryComponentRef = useRef(null);

  const [globalOverviewData, setGlobalOverviewData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [overallDetailsData, setOverallDetailsData] = useState(null);
  const [detailsData, setDetailsData] = useState(null);
  const [globalClusterData, setGlobalClusterData] = useState(null);
  const [backupGlobalClusterData, setBackupGlobalClusterData] = useState(null);
  const [userTreeData, setUserTreeData] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [selectedTree, setSelectedTree] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const { userInfo: user } = useSelector(state => state.authentication);
  const { address } = useAccount();

  useEffect(() => {
    if (user && userCertificates === null) {
      const companyId = user?._id; // prodcution
      // const companyId = "6658e0e04cbfbe6b999d61d7"; // development
      getAllTreeData(address).then((treeData) => {
        setUserTreeData(treeData);
        // console.log("getAllTreeData - treeData", treeData);
        getUserCertificates(companyId, null).then((data) => {
          if (data.user_certificates_data) {
            setUserCertificates(data.user_certificates_data);
            getDetailsAndOverviewCertificates(data.user_certificates_data, treeData).then((data) => {
              // console.log("getDetailsAndOverviewCertificates - data", data);
              if (data === null) data = {
                details_data: null,
                overview_data: {
                  total_aco2_burned_in_certificates: 0,
                  total_aco2_generated: 0,
                  total_batches: 0,
                  total_trees: 0
                }
              }
              if (data.overview_data) {
                setGlobalOverviewData(data.overview_data);
                setOverviewData(data.overview_data);
              }
              if (data.details_data) {
                setOverallDetailsData(data.details_data);
                // create a set of unique cluster_ids
                let clusterIds = new Set(data.details_data.map((item) => item.cluster_id));
                getGlobalClusterData(null, Array.from(clusterIds)).then((data) => {
                  // console.log("getGlobalClusterData - data", data);
                  if (data.global_cluster_data) {
                    setGlobalClusterData(data.global_cluster_data);
                    setBackupGlobalClusterData(data.global_cluster_data);
                    let clusterData = [...data.global_cluster_data];
                    // console.log("regular clusterData", clusterData);
                    // Extract the highest `cluster_id` from `clusterData`
                    let highestClusterId = Math.max(...clusterData.map(cluster => cluster.cluster_id));
                    if (user.trees) {
                      getClustersFromTrees(user.trees, 10).then((treesData) => {
                        // console.log("getClustersFromTrees - data", treesData);
                        // Create a Set for clusterData `cluster_id`s for quick lookup
                        let clusterIdSet = new Set(clusterData.map(cluster => cluster.cluster_id));
                        // Iterate over `getClustersFromTrees - data` and update duplicates
                        let updatedTreesData = treesData.map(cluster => {
                          // Transform `cluster_center.geo_location` to `cluster_center_coordinates`
                          let updatedCluster = {
                            ...cluster,
                            cluster_center_coordinates: cluster.cluster_center.geo_location,
                            // inside of trees change id to nftree_id everything else should stay the same
                            trees: cluster.trees.map(tree => {
                              return {
                                ...tree,
                                nftree_id: tree.id,
                                id: tree.id,
                              };
                            })
                          };
                          // Remove the nested `cluster_center`
                          delete updatedCluster.cluster_center;
                          // Handle duplicate `cluster_id`
                          if (clusterIdSet.has(updatedCluster.cluster_id)) {
                            updatedCluster.cluster_id = ++highestClusterId;
                          }
                          return updatedCluster;
                        });
                        // console.log("Updated Trees Data", updatedTreesData);
                        // Merge `updatedTreesData` with `clusterData`
                        clusterData = [...clusterData, ...updatedTreesData];
                        // // console.log("updated clusterData", clusterData);
                        setGlobalClusterData(clusterData);
                        setBackupGlobalClusterData(clusterData);
                      });
                    }
                  }
                });
              } else if (!data.details_data) {
                if (user?.trees) {
                  let clusterData = [];
                  getClustersFromTrees(user.trees, 10).then((treesData) => {
                    // console.log("getClustersFromTrees - data", treesData);
                    // Create a Set for clusterData `cluster_id`s for quick lookup
                    let clusterIdSet = new Set(clusterData.map(cluster => cluster.cluster_id));
                    // Iterate over `getClustersFromTrees - data` and update duplicates
                    let updatedTreesData = treesData.map(cluster => {
                      // Transform `cluster_center.geo_location` to `cluster_center_coordinates`
                      let updatedCluster = {
                        ...cluster,
                        cluster_center_coordinates: cluster.cluster_center.geo_location,
                        // inside of trees change id to nftree_id everything else should stay the same
                        trees: cluster.trees.map(tree => {
                          return {
                            ...tree,
                            nftree_id: tree.id,
                            id: tree.id,
                          };
                        })
                      };
                      // Remove the nested `cluster_center`
                      delete updatedCluster.cluster_center;
                      return updatedCluster;
                    });
                    // console.log("Updated Trees Data", updatedTreesData);
                    // Merge `updatedTreesData` with `clusterData`
                    clusterData = [...clusterData, ...updatedTreesData];
                    // console.log("updated clusterData", clusterData);
                    setGlobalClusterData(clusterData);
                    setBackupGlobalClusterData(clusterData);
                  });
                }
              }
            });
          }
        });
      });
    }
  }, [user, backupGlobalClusterData]);

  useEffect(() => {
    if (selectedCluster?.trees) {
      setBatchLoading(true);
      setDetailsData(selectedCluster.trees);
      // console.log("selectedCluster.trees", selectedCluster.trees);
      setSelectedTree(selectedCluster.trees[0]);
      setBatchLoading(false);
    }
    if (selectedCluster !== null && selectedFilters.length === 0 && !selectedCluster.trees) {
      setBatchLoading(true);
      // find all the details data for the selected cluster in the overall details data
      let clusterDetailsData = overallDetailsData.filter((item) => item.cluster_id === selectedCluster.cluster_id);
      setDetailsData(clusterDetailsData);
      setSelectedTree(clusterDetailsData[0]);
      setBatchLoading(false);
    } else if (selectedCluster !== null && selectedFilters.length > 0 && !selectedCluster.trees) {
      setBatchLoading(true);
      // add certificates mentioned in selectedFilters to a new array from userCertificates
      let selectedCertificates = [];
      for (let filter of selectedFilters) {
        let certificate = userCertificates.find((item) => item.name === filter);
        selectedCertificates.push(certificate);
      }

      // console.log("selectedCertificates", selectedCertificates);

      // go through each certificate.attributes object and compare with overallDetailsData and if found add object to new array
      let clusterDetailsData = [];
      for (let certificate of selectedCertificates) {
        if (certificate !== undefined) {
          // console.log("certificate", certificate);
          for (let attr of certificate.attributes) {
            let detail = overallDetailsData.find((item) => item.nftree_id === attr.nftree_id && item.batch_address.toLowerCase() === attr.batch_address.toLowerCase());
            if (detail && detail.cluster_id === selectedCluster.cluster_id) {
              clusterDetailsData.push(detail);
            }
          }
        } else if (certificate === undefined) {
          for (let tree of userTreeData) {
            let detail = overallDetailsData.find((item) => item.nftree_id === Number(tree.token_id) && item.batch_address.toLowerCase() === tree.contract_address.toLowerCase());
            if (detail && detail.cluster_id === selectedCluster.cluster_id) {
              clusterDetailsData.push(detail);
            }
          }
        }
      }

      // console.log("clusterDetailsData", clusterDetailsData);

      setDetailsData(clusterDetailsData);
      setSelectedTree(clusterDetailsData[0]);

      setBatchLoading(false);
    }
  }, [selectedCluster]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      setBatchLoading(true);
      setSelectedCluster(null);
      setSelectedTree(null);
      // add certificates mentioned in selectedFilters to a new array from userCertificates
      let selectedCertificates = [];
      for (let filter of selectedFilters) {
        if (filter !== "Owned trees") {
          let certificate = userCertificates.find((item) => item.name === filter);
          selectedCertificates.push(certificate);
        }
      }
      // console.log("selectedCertificates", selectedCertificates);
      // go through each certificate.attributes object and compare with overallDetailsData and if found add cluster_id to a new set
      let clusterIds = new Set();
      let treesInCertificate = [];
      for (let certificate of selectedCertificates) {
        for (let attr of certificate.attributes) {
          let detail = overallDetailsData.find(
            (item) => item.nftree_id === attr.nftree_id && item.batch_address.toLowerCase() === attr.batch_address.toLowerCase()
          );
          if (detail) {
            clusterIds.add(detail.cluster_id);
            treesInCertificate.push(detail);
          }
        }
      }
      // remove duplicates from treesInCertificate using nftree_id and batch_address
      treesInCertificate = treesInCertificate.filter((item, index, self) => self.findIndex((t) => t.nftree_id === item.nftree_id && t.batch_address === item.batch_address) === index);
      // console.log("treesInCertificate", treesInCertificate);
      // console.log("clusterIds", clusterIds);
      let newOverviewData = {
        total_trees: 0,
        total_batches: 0,
        total_aco2_generated: 0,
        total_aco2_burned_in_certificates: 0
      }
      // get the total trees and total batches
      newOverviewData.total_trees = treesInCertificate.length;
      newOverviewData.total_batches = new Set(treesInCertificate.map((item) => item.batch_address)).size;
      // get the total aco2 generated and total aco2 burned in certificates
      for (let detail of treesInCertificate) {
        newOverviewData.total_aco2_generated += detail.aco2_generated_over_lifetime;
        for (let certificate of selectedCertificates) {
          if (Object.keys(detail.aco2_burned_per_certificate).includes(certificate.name)) {
            newOverviewData.total_aco2_burned_in_certificates += Number(detail.aco2_burned_per_certificate[certificate.name]);
          }
        }
      }
      // create an array from the set of cluster_ids and compare with backup backupGlobalClusterData and if found add to new array
      let newGlobalClusterData = [];
      for (let clusterId of Array.from(clusterIds)) {
        let cluster = backupGlobalClusterData.find((item) => item.cluster_id === clusterId);
        if (cluster) {
          newGlobalClusterData.push(cluster);
        }
      }
      if (selectedFilters.includes("Owned trees")) {
        const treeData = {
          nft_ids: userTreeData.map((tree) => Number(tree.token_id)),
          nft_addresses: userTreeData.map((tree) => tree.contract_address.toLowerCase()),
        };
        // go into the overallDetailsData and find all the details for the user trees
        let treeDetails = overallDetailsData.filter((detail) => treeData.nft_ids.includes(detail.nftree_id) && treeData.nft_addresses.includes(detail.batch_address.toLowerCase()));
        const treeClusters = backupGlobalClusterData.filter((cluster) => treeDetails.map((detail) => detail.cluster_id).includes(cluster.cluster_id));
        newGlobalClusterData = [...newGlobalClusterData, ...treeClusters];

        newOverviewData.total_trees += treeDetails.length;
        newOverviewData.total_batches += 1;
        newOverviewData.total_aco2_generated += treeDetails.reduce((acc, detail) => acc + detail.aco2_generated_over_lifetime, 0);
        newOverviewData.total_aco2_burned_in_certificates += treeDetails.reduce((acc, detail) => acc + detail.aco2_burned_in_certificates, 0);
      }
      setGlobalClusterData(newGlobalClusterData);
      setOverviewData(newOverviewData);
      setBatchLoading(false);
    } else if (selectedFilters.length === 0 && globalClusterData !== null) {
      setBatchLoading(true);
      setGlobalClusterData(backupGlobalClusterData);
      setOverviewData(globalOverviewData);
      setBatchLoading(false);
    } 
  }, [selectedFilters]);

  return (
    <>
      { userCertificates === null || userCertificates.length === 0 ? null : 
      <>
        { batchLoading === true ? <Spinner /> : null }
        <PageHeading styles={'text-grey !text-3xl'}>Offset Map</PageHeading>
        <div
          className={clsx("w-full", className, "flex flex-col md:flex-row gap-4 mb-6")}
        >
          <div
            ref={firstComponentRef}
            className={clsx(
              "transition-all flex flex-col md:flex-row items-center rounded-xl duration-300 ease-in-out",
              "w-full md:flex-grow",
              isExtended ? "md:w-2/3" : "md:w-1/3"
            )}
          >
            <FirstComponent 
              overviewData={overviewData}
              globalClusterData={globalClusterData}
              selectedCluster={selectedCluster}
              setSelectedCluster={setSelectedCluster}
              selectedTree={selectedTree}
              setSelectedTree={setSelectedTree}
              detailsData={detailsData}
              userCertificates={userCertificates}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              isSmallScreen={isSmallScreen}
            />
          </div>
          <div
            ref={secondaryComponentRef}
            className={clsx(
              "transition-all z-10 rounded-xl flex flex-col md:flex-row items-center duration-300 ease-in-out",
              "w-full md:flex-grow",
              isExtended ? "md:w-1/3" : "md:w-2/3"
            )}
          >
            <SecondaryComponent 
              extended={!isExtended}
              overviewData={overviewData}
            />
          </div>
        </div>
      </>
      }
    </>
  );
}

export default OffsetMap;
