import React from 'react'
import CurrencySymbol from './CurrencySymbol'

function TotalPrice({ totalPrice, selectedCurrency }) {
  console.log(selectedCurrency)

  return (
    <div className="w-full flex justify-between mb-2.5">
      <p className="text-[22px] font-semibold">Total price</p>
      <p className="text-[22px] font-semibold">
        {totalPrice ? totalPrice : 0} {<CurrencySymbol selectedCurrency={selectedCurrency} />}
      </p>
    </div>
  )
}

export default TotalPrice
