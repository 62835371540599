import React from 'react'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function ListForSaleButton({ nft, handleListNftModal }) {
  return (
    <button
      onClick={() => handleListNftModal(nft, true)}
      className="flex space-x-3 items-center justify-center bg-white text-green hover:bg-carbifyOrange hover:text-white tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full hover-stroke-white"
    >
      <CoinsStacked02 color="#1C4828" />
      <span>List for Sale</span>
    </button>
  )
}

export default ListForSaleButton
