import React from 'react'

function HandCoinIcon({ width = 24, height = 24, active }) {
  return (
    <div>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          active ? 'stroke-carbifyOrange' : 'stroke-white group-hover:stroke-carbifyOrange ease-in-out duration-500'
        } `}
      >
        <path
          d="M15.9119 9.43982C15.1963 9.94994 14.3207 10.25 13.375 10.25C10.9588 10.25 9 8.29125 9 5.875C9 3.45875 10.9588 1.5 13.375 1.5C14.9412 1.5 16.3152 2.32302 17.0881 3.56018M6.5 24.1089H9.76286C10.1883 24.1089 10.6111 24.1596 11.0235 24.2608L14.4711 25.0986C15.2192 25.2808 15.9985 25.2986 16.7543 25.1518L20.5662 24.4101C21.5732 24.214 22.4995 23.7318 23.2254 23.0256L25.9224 20.4021C26.6925 19.6542 26.6925 18.4405 25.9224 17.6913C25.2289 17.0168 24.1309 16.9409 23.3464 17.5129L20.2032 19.8061C19.7531 20.1351 19.2054 20.3123 18.6421 20.3123H15.6069L17.5388 20.3122C18.6278 20.3122 19.5098 19.4542 19.5098 18.3949V18.0114C19.5098 17.1319 18.8945 16.365 18.0176 16.1523L15.0357 15.4272C14.5505 15.3095 14.0535 15.25 13.5539 15.25C12.3479 15.25 10.1649 16.2485 10.1649 16.2485L6.5 17.7811M24 7.125C24 9.54125 22.0412 11.5 19.625 11.5C17.2088 11.5 15.25 9.54125 15.25 7.125C15.25 4.70875 17.2088 2.75 19.625 2.75C22.0412 2.75 24 4.70875 24 7.125ZM1.5 17.25L1.5 24.5C1.5 25.2001 1.5 25.5501 1.63624 25.8175C1.75608 26.0527 1.94731 26.2439 2.18251 26.3638C2.4499 26.5 2.79993 26.5 3.5 26.5H4.5C5.20007 26.5 5.5501 26.5 5.81749 26.3638C6.05269 26.2439 6.24392 26.0527 6.36376 25.8175C6.5 25.5501 6.5 25.2001 6.5 24.5V17.25C6.5 16.5499 6.5 16.1999 6.36376 15.9325C6.24392 15.6973 6.05269 15.5061 5.81749 15.3862C5.5501 15.25 5.20007 15.25 4.5 15.25L3.5 15.25C2.79993 15.25 2.4499 15.25 2.18251 15.3862C1.94731 15.5061 1.75608 15.6973 1.63624 15.9325C1.5 16.1999 1.5 16.5499 1.5 17.25Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default HandCoinIcon
