import React from 'react'
import { useAccount } from 'wagmi'
import { Navigate } from 'react-router-dom'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'

function ConnectWallet() {
  const { isConnected } = useAccount()

  if (isConnected) return <Navigate to={'/dashboard'} />
  return (
    <Layout title="Carbify | Connect Wallet" content="Connect to Wallet">
      <PageHeading>Connect your wallet to begin</PageHeading>
    </Layout>
  )
}

export default ConnectWallet
