import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js'
import { Scatter } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      suggestedMin: 0,
      ticks: {
        color: 'rgba(255, 255, 255, 0.75)',
      },
      grid: {
        color: 'grey',
        borderColor: 'grey',
      },
    },
    x: {
      type: 'category',
      ticks: {
        color: 'rgba(255, 255, 255, 0.75)',
      },
      grid: {
        display: false,
        borderColor: 'grey',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

const ScatterChart = ({ carbon }) => {
  const data = {
    datasets: [
      {
        label: 'Data',
        data: [
          { x: 'Your Footprint', y: carbon },
          { x: 'Average Footprint', y: 4.79 },
          { x: 'Country Footprint', y: 11 },
        ],
        borderColor: 'rgba(248, 149, 45, 1)',
        backgroundColor: 'rgba(255, 150, 46, 0.25)',
      },
    ],
  }
  return <Scatter options={options} data={data} />
}

export default ScatterChart
