import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'
import { useAccount, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import SmallAco2Loader from 'components/utils/SmallAco2Loader'
import Spinner from 'components/utils/Spinner'
import { Config } from '../../config.js'
import { toast } from 'react-toastify'

// API
import { getERC721Data } from 'features/dashboard/getERC721Data'
import { getACO2TotalPendingReward } from 'features/dashboard/getACO2TotalPendingReward'
import { claimACO2Rewards } from 'features/dashboard/claimACO2'
import { claimRegularMerkle } from 'features/dashboard/claimRegularMerkle'
import { getAllRegularMerkleData } from 'features/dashboard/getAllRegularMerkleData'
import { aCO2_ABI } from 'constants/BlockchainConstants'
import { getACO2TotalBalance } from 'features/dashboard/getACO2TotalBalance.js'
import { IoMdInformationCircleOutline } from 'react-icons/io'

const ClaimAco2Card = ({ balance, setBalance, setACO2TotalBalance, totalTrees }) => {
  const { address } = useAccount();

  const { loadingClaimAco2Rewards } = useSelector(state => state.aco2)
  
  const [erc721Data, setErc721Data] = useState(null)
  const [pendingRewards, setPendingRewards] = useState(null)
  const [merkleProofs, setMerkleProofs] = useState(null)
  const [tokensPerMonth, setTokensPerMonth] = useState(null)
  const [claimMerkle, setClaimMerkle] = useState(false)
  const [loading, setLoading] = useState(false)

  const [claimRegularParamsQueue, setClaimRegularParamsQueue] = useState([])
  const [claimRegularParams, setClaimRegularParams] = useState([])
  
  const [claimMerkleParamsQueue, setClaimMerkleParamsQueue] = useState([])
  const [claimMerkleParams, setClaimMerkleParams] = useState([])

  // Claim Regular aCO2
  const {
    data: claimRegularHash,
    writeContract: claimRegularWrite,
    isPending: claimingRegular,
    isError: claimRegularWriteError,
  } = useWriteContract()
  const {
    isLoading: claimRegularAwaiting,
    isSuccess: claimRegularSuccess,
    isError: claimRegularError,
  } = useWaitForTransactionReceipt({ hash: claimRegularHash })

  // Claim Merkle aCO2
  const {
    data: claimMerkleHash,
    writeContract: claimMerkleWrite,
    error: claimMerkleWriteError,
    isPending: claimingMerkle,
    isError: claimingMerkleWriteError,
  } = useWriteContract()
  const {
    isLoading: claimMerkleAwaiting,
    isSuccess: claimMerkleSuccess,
    isError: claimMerkleError,
  } = useWaitForTransactionReceipt({ hash: claimMerkleHash })

  const claimAco2 = async () => {
    try {
      setLoading(true);
      setBalance(null);
      setPendingRewards(null);
      claimACO2Rewards(erc721Data.token_data).then(data => {
        if (data.success) {
          console.log('claimAco2 - data:', data); // good
          toast.success('Successfully submitted regular $aCO2 claim request');
          let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
          const allTokenIds = data.allTokenIds;
          const allTokenAddresses = data.allTokenAddresses;
          let query = [];
  
          // Calculate how many chunks we'll have
          const chunks = Math.ceil(allTokenIds.length / MAX_TREES_PER_TX);
  
          for (let i = 0; i < chunks; i++) {
            // Calculate start and end indices for slicing
            let start = i * MAX_TREES_PER_TX;
            let end = start + MAX_TREES_PER_TX;
            const tokenIdsChunk = allTokenIds.slice(start, end);
            const tokenAddressesChunk = allTokenAddresses.slice(start, end);
            const args = [tokenAddressesChunk, tokenIdsChunk];
            query.push({ args: args });
          }
          console.log('query:', query);
          setClaimRegularParamsQueue(query);
        } else {
          toast.error('Error submitting regular aCO2 claim request')
          getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
            setErc721Data(data)
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error('Error submitting regular aCO2 claim request')
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      });
      setLoading(false);
      // Handle any errors here
    }
  }

  const claimMerkleAco2 = async () => {
    try {
      setLoading(true);
      setBalance(null);
      setPendingRewards(null);
      claimRegularMerkle(merkleProofs).then(data => {
        if (data.success) {
          toast.success('Successfully submitted merkle $aCO2 claim request');
          let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx);
          let query = [];
          const tokenIds = data.tokenIds;
          const amounts = data.amounts;
          const contractAddresses = data.contractAddresses;
          const merkleProofs = data.merkleProofs;
  
          // Calculate how many chunks we'll have
          const chunks = Math.ceil(tokenIds.length / MAX_TREES_PER_TX);
  
          for (let i = 0; i < chunks; i++) {
            // Calculate start and end indices for slicing
            let start = i * MAX_TREES_PER_TX;
            let end = start + MAX_TREES_PER_TX;
            const treeIdsChunk = tokenIds.slice(start, end);
            const treeAmountsChunk = amounts.slice(start, end);
            const treeContractAddressesChunk = contractAddresses.slice(start, end);
            const treeMerkleProofsChunk = merkleProofs.slice(start, end);
            const args = [treeIdsChunk, treeAmountsChunk, treeContractAddressesChunk, treeMerkleProofsChunk];
            query.push({ args: args });
          }
          console.log('query:', query);
          setClaimMerkleParamsQueue(query);
        } else {
          toast.error('Error submitting merkle aCO2 claim request')
          setBalance(null);
          setPendingRewards(null);
          getAllRegularMerkleData(address).then(data => {
            if (data.merkle_proofs !== undefined) {
              setMerkleProofs(data.merkle_proofs)
              const hasUnclaimedMerkle = data.merkle_proofs.some(proof => proof.isClaimed === false)
              setClaimMerkle(hasUnclaimedMerkle)
            }
          })
          getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
            setErc721Data(data)
          });
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error('Error submitting merkle aCO2 claim request')
      setBalance(null);
      setPendingRewards(null);
      getAllRegularMerkleData(address).then(data => {
        if (data.merkle_proofs !== undefined) {
          setMerkleProofs(data.merkle_proofs)
          const hasUnclaimedMerkle = data.merkle_proofs.some(proof => proof.isClaimed === false)
          setClaimMerkle(hasUnclaimedMerkle)
        }
      })
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      });
      setLoading(false);
    }
  }

  // Claim Regular aCO2
  useEffect(() => {
    if (claimRegularParamsQueue.length > 0) {
      setClaimRegularParams(claimRegularParamsQueue[0])
    } else if (claimRegularParamsQueue.length === 0 && loading === true) {
      setTimeout(() => {
        getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
          setErc721Data(data)
        });
        setLoading(false);
      }, 5000);
    }
  }, [claimRegularParamsQueue])
  useEffect(() => {
    if (claimRegularParams?.args?.length > 0) {
      claimRegularWrite({
        address: (Config().contract_addresses.aco2_address).toLowerCase(),
        abi: aCO2_ABI,
        functionName: 'claimTokens',
        args: claimRegularParams?.args
      })
    }
  }, [claimRegularParams])
  useEffect(() => {
    if (claimRegularSuccess) {
      toast.success('Successfully claimed $aCO2 tokens')
      setClaimRegularParamsQueue(prev => prev.slice(1))
    } else if (claimRegularError || claimRegularWriteError) {
      toast.error('Error claiming $aCO2 tokens')
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      });
      setLoading(false);
    }
  }, [claimRegularSuccess, claimRegularError, claimRegularAwaiting, claimRegularWriteError])

  // Claim Merkle aCO2
  useEffect(() => {
    if (claimMerkleParamsQueue.length > 0) {
      setClaimMerkleParams(claimMerkleParamsQueue[0])
    } else if (claimMerkleParamsQueue.length === 0) {
      setTimeout(() => {
        getAllRegularMerkleData(address).then(data => {
          if (data.merkle_proofs !== undefined) {
            setMerkleProofs(data.merkle_proofs)
            const hasUnclaimedMerkle = data.merkle_proofs.some(proof => proof.isClaimed === false)
            setClaimMerkle(hasUnclaimedMerkle)
          }
        })
        getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
          setErc721Data(data)
        });
        setLoading(false);
      }, 5000);
    }
  }, [claimMerkleParamsQueue])
  useEffect(() => {
    if (claimMerkleParams?.args?.length > 0) {
      // Deep clone the args array to prevent mutating the original data
      let transformedArgs = JSON.parse(JSON.stringify(claimMerkleParams.args));
  
      // Convert BigNumber to string in the specific index (1 in your case)
      transformedArgs[1] = transformedArgs[1].map(bn => 
        ethers.BigNumber.from(bn.hex).toString()
      );
      console.log('Transformed BigNumber to String:', transformedArgs[1]);
      claimMerkleWrite({
        address: (Config().contract_addresses.aco2_address).toLowerCase(),
        abi: aCO2_ABI,
        functionName: 'claimRegularMerkleReward',
        args: transformedArgs
      });
    }
  }, [claimMerkleParams]);
  useEffect(() => {
    if (claimMerkleSuccess) {
      toast.success('Successfully claimed $aCO2 tokens')
      setClaimMerkleParamsQueue(prev => prev.slice(1))
      if(claimMerkleParamsQueue.length === 0 && loading === true) {
        setClaimMerkle(false)
        claimAco2();
      }
    } else if (claimMerkleError || claimingMerkleWriteError) {
      toast.error('Error submitting merkle aCO2 claim request')
      setBalance(null);
      setPendingRewards(null);
      getAllRegularMerkleData(address).then(data => {
        if (data.merkle_proofs !== undefined) {
          setMerkleProofs(data.merkle_proofs)
          const hasUnclaimedMerkle = data.merkle_proofs.some(proof => proof.isClaimed === false)
          setClaimMerkle(hasUnclaimedMerkle)
        }
      })
      getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
        setErc721Data(data)
      });
      setLoading(false);
    }
  }, [claimMerkleSuccess, claimMerkleError, claimMerkleAwaiting, claimingMerkleWriteError])

  useEffect(() => {
    getERC721Data(address, undefined).then(data => { // undefined = all erc721 contracts
      setErc721Data(data)
    })
    getAllRegularMerkleData(address).then(data => {
      if (data.merkle_proofs !== undefined) {
        setMerkleProofs(data.merkle_proofs)
        const hasUnclaimedMerkle = data.merkle_proofs.some(proof => proof.isClaimed === false)
        setClaimMerkle(hasUnclaimedMerkle)
      }
    })
  }, [address])

  useEffect(() => {
    if (address && erc721Data) {
      getACO2TotalBalance(address).then((result) => {
        if (!result.error) {
          setBalance(result);
          setACO2TotalBalance(Number(result.totalBalanceFormatted.toFixed(2)))
        }
      });
    }
  }, [address, erc721Data])

  useEffect(() => {
    if (address && erc721Data !== null && merkleProofs !== null) {
      getACO2TotalBalance(address).then(result => {
        if (!result.error) {
          setBalance(result);
        }
      });
      getACO2TotalPendingReward(erc721Data.token_data).then(result => {
        if (!result.error) {
          let totalAmount = 0;
          if (merkleProofs.length === 0) {
            setPendingRewards(result);
            return;
          }
          for (let i = 0; i < merkleProofs.length; i++) {
            if (merkleProofs[i].isClaimed === false) {
              const hexString = merkleProofs[i].aco2_amount.hex;
              if (hexString) {
                const amount = ethers.BigNumber.from(hexString);
                const formattedAmount = ethers.utils.formatEther(amount);
                totalAmount += Number(formattedAmount);
              }
            }
          }
          const addedAmount = Number(result.totalPendingRewardFormatted) + Number(totalAmount);
          setPendingRewards({ ...result, totalPendingReward: addedAmount, totalPendingRewardFormatted: addedAmount.toFixed(2) });
        }
      });
    }
  }, [erc721Data, merkleProofs])

  useEffect(() => {
    if (totalTrees !== null) {
      // calculate how many tokens will be generated in 30 days knowing that 1 tree generates 175 tokens per year
      const tokensPerMonth = (175 / 12) * totalTrees;
      setTokensPerMonth(tokensPerMonth);
    }
  }, [totalTrees])

  return (
    <>
        {loadingClaimAco2Rewards || claimRegularAwaiting || claimingRegular || claimMerkleAwaiting || claimingMerkle || loading ? <Spinner /> : null}
        <div className="flex flex-col items-stretch h-full bg-darkGreen bg-opacity-70 rounded-lg border mb-6">
            <div className="border-b-2 p-3 border-green w-full mb-6">
                <p className="font-semibold text-2xl text-white text-center">Claim your aCO2</p>
            </div>
            <div className="flex flex-col items-center w-full rounded px-6 text-center">
            If you own trees, they will generate $aCO2 tokens. Each tree generates 175 tokens per year. There is a minimum threshold that must be met before you can claim, to prevent transaction costs from exceeding the returns. A claim will generate one transaction per tree, which can be quite resource-intensive if you own multiple trees.
            </div>
            <div className="p-6 space-y-6 flex-1">
                <div className="flex flex-col items-center w-full rounded border-2 border-green">
                <div className="flex flex-col md:flex-row p-3 w-full border-b-2 border-green gap-2.5 items-center justify-between">
                        <p className="font-semibold text-lg text-center md:text-left">Wallet balance</p>
                        <div className="flex flex-row justify-end h-9">
                            {balance === null ? (
                                <SmallAco2Loader />
                            ) : (
                                <div className="flex items-center">
                                    <img className="h-6 w-6 mt-0.5 mr-2" src="/assets/images/aco2_logo.png" alt="" />
                                    <span className="flex font-normal h-full justify-center items-center">
                                        {balance && Number(balance.totalBalanceFormatted).toFixed(2)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row p-3 w-full gap-2.5 items-center justify-between">
                        <p className="font-semibold text-lg text-center md:text-left">Available to claim</p>
                        <div className="flex flex-row justify-end h-9">
                            {pendingRewards === null ? (
                                <SmallAco2Loader />
                            ) : (
                                <div className="flex items-center">
                                    <img className="h-6 w-6 mt-0.5 mr-2" src="/assets/images/aco2_logo.png" alt="" />
                                    <span className="flex font-normal h-full justify-center items-center">
                                        {pendingRewards && Number(pendingRewards.totalPendingRewardFormatted).toFixed(2)}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <button
                        onClick={() => {
                            if (claimMerkle === true) {
                                console.log('claiming merkle')
                                claimMerkleAco2();
                            } else {
                                console.log('claiming regular')
                                claimAco2();
                            }
                        }}
                        disabled={tokensPerMonth === null || balance === null || pendingRewards === null || Number(pendingRewards.totalPendingRewardFormatted) === 0 || loading || Number(pendingRewards.totalPendingRewardFormatted) < Number(tokensPerMonth)}
                        className="bg-white w-full space-x-2 text-green font-bold text-base font-montserrat rounded-lg py-3 items-center justify-center flex flex-row hover:bg-carbifyOrange hover:text-white disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-green"
                    >
                        Claim available $aCO2
                    </button>
                    {tokensPerMonth !== null && 
                      <div className="justify-center mt-2 text-white font-light text-center inline-flex items-center">
                        <span>You need at least {tokensPerMonth.toFixed(2)} $aCO2 available in order to claim</span> 
                        <IoMdInformationCircleOutline className="ml-1" />
                      </div>
                    }
                </div>
            </div>
        </div>
    </>
);


}

export default ClaimAco2Card
