import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectCountry from 'components/utils/SelectCountry'
import { countries } from 'json/countries'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// Icons
import { BiRightArrowCircle } from 'react-icons/bi'
import { BsCalendar } from 'react-icons/bs'

// Reducer
import { setInfo } from 'features/footprint/footprintSlice'
import { toast } from 'react-toastify'

const GetStarted = ({ onNext, setPage }) => {
  const dispatch = useDispatch()
  const [isClicked, setIsClicked] = useState(0);

  const [formData, setFormData] = useState({
    country: countries[0].name,
    userType:'',
    startDate: new Date(),
    endDate: new Date(),
  })

  const { country,userType, startDate, endDate } = formData

  const handleSelectUserType = e => {
    setFormData({ ...formData, userType: e })
    e=='individual'? setIsClicked(1):setIsClicked(2);
  }

  const changeStartDate = date => {
    setFormData({ ...formData, startDate: date })
  }

  const changeEndDate = date => {
    setFormData({ ...formData, endDate: date })
  }

  const handleNavigation = () => {
    setPage(false)
    onNext(true)
  }

  const handleCalculation = e => {
    e.preventDefault()
    if (endDate - startDate > 0) {
      dispatch(setInfo({ country,userType, startDate, endDate }))
    } else {
      toast.error('End date needs to be bigger than start date')
    }
  }

  return (
    <div className="flex justify-center items-center w-full px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col space-y-6 bg-darkGreen bg-opacity-70 border rounded-lg p-6 sm:p-8 w-full ">
        <form className="flex flex-col space-y-6  border-b border-opacity-50 border-gray-300 pb-6" onSubmit={handleCalculation}>
          <div className="flex flex-col space-y-4 border-b border-opacity-50 border-gray-300 pb-6">
            <div className="font-semibold text-2xl text-white border-b border-opacity-50 border-gray-300 pb-2">First, Tell us how you participate in this.</div>
            <p className="font-normal text-base text-white text-opacity-80 tracking-wider">
              Whether you're an individual looking to make personal eco-conscious choices or a business aiming to implement sustainable practices, our webpage caters to both. Explore tailored strategies for individuals, including energy-saving tips and eco-friendly habits.
            </p>
            <p className="font-normal text-base text-white text-opacity-80 tracking-wider">
              For businesses, discover scalable solutions such as green initiatives, carbon offset programs, and sustainable supply chain practices. Join us in creating a positive impact, regardless of your role as an individual or a business entity.
            </p>
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <button
                type="button"
                className={`flex-1 py-3  text-darkGreen font-semibold rounded-lg border border-gray-300 hover:bg-carbifyOrange ${isClicked==1 ? 'bg-carbifyOrange':'bg-white'}`}
                onClick={() => handleSelectUserType('individual')}
              >
                As an Individual
              </button>
              <button
                type="button"
                className={`flex-1 py-3  text-white font-semibold rounded-lg border border-gray-300 hover:bg-carbifyOrange ${isClicked==2 ? 'bg-carbifyOrange':'bg-darkGreen'}`}
                onClick={() => handleSelectUserType('company')}
              >
                As a Company
              </button>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="font-semibold text-2xl text-white">Date</div>
            <p className="font-normal text-base text-white text-opacity-80 tracking-wider">
              Carbon footprint calculations are typically based on annual emissions from the previous 12 months. Enter the period this calculation covers (optional).
            </p>
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <div className="relative flex-1">
                <div className="block mb-2 text-base font-medium text-white">From</div>
                <DatePicker
                  selected={startDate}
                  onChange={changeStartDate}
                  dateFormat="dd/MM/yyyy"
                  className="tracking-wider font-medium bg-white border border-gray-300 text-darkGreen rounded-lg focus:outline-none focus:ring-2 focus:ring-carbifyOrange block w-full p-2"
                />
                <BsCalendar className="absolute right-3 bottom-3 text-darkGreen" />
              </div>
              <div className="relative flex-1">
                <div className="block mb-2 text-base font-medium text-white">To</div>
                <DatePicker
                  selected={endDate}
                  onChange={changeEndDate}
                  dateFormat="dd/MM/yyyy"
                  className="tracking-wider font-medium bg-white border border-gray-300 text-darkGreen rounded-lg focus:outline-none focus:ring-2 focus:ring-carbifyOrange block w-full p-2"
                />
                <BsCalendar className="absolute right-3 bottom-3 text-darkGreen" />
              </div>
            </div>
          </div>
        </form>
        <div className="flex flex-col border-b border-opacity-50 border-gray-300 space-y-4 text-white text-opacity-80 tracking-wider pb-6">
          <div>
            Next, select the appropriate tab above to calculate the part of your lifestyle you are most interested in, e.g., your flights. Or, visit each of the tabs above to calculate your full carbon footprint. Following your calculation, you can offset / neutralise your emissions through our climate-friendly project.
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="flex items-center justify-center w-full md:w-1/2 h-12 bg-carbifyOrange text-white font-bold rounded-lg text-lg space-x-2 tracking-wider hover:bg-orange-500"
            onClick={handleNavigation}
          >
            <BiRightArrowCircle className="w-6 h-6" />
            <span>Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default GetStarted
