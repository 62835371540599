import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from 'components/utils/Spinner'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

function CancelDirectListing({ listingId }) {

  // Cancel Direct Listing
  const {
    data: cancelDirectListingHash,
    writeContract: cancelDirectListing,
    isPending: isCancelingDirectListing,
    isError: isCancelingDirectListingError,
  } = useWriteContract()
  console.log('cancelDirectListingHash:', cancelDirectListingHash)
  const {
    isSuccess: cancelDirectListingSuccess,
    isError: cancelDirectListingError,
    isLoading: cancelDirectListingLoading,
  } = useWaitForTransactionReceipt({ hash: cancelDirectListingHash })

  // Cancel Direct Listing
  const handleCancelDirectListing = () => {
    console.log('Listing ID: ', listingId)
    cancelDirectListing({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'cancelDirectListing',
      args: [listingId],
    })
  }

  // Cancel Direct Listing
  useEffect(() => {
    if (cancelDirectListingSuccess) {
      toast.success('You have successfully cancelled the listing!')
    } else if (cancelDirectListingError || isCancelingDirectListingError) {
      toast.error('Error cancelling the listing')
    }
  }, [cancelDirectListingSuccess, cancelDirectListingError, isCancelingDirectListingError])

  return (
    <>
      <button onClick={handleCancelDirectListing} className="bg-secondary py-1 px-3 rounded-lg mr-2.5">
        <span className="text-base font-bold text-bt">Cancel listing</span>
      </button>
      {(isCancelingDirectListing || cancelDirectListingLoading) && <Spinner />}
      {cancelDirectListingSuccess && <Navigate to={'/nfts'} />}
    </>
  )
}

export default CancelDirectListing
