import axios from "axios";
import { Config } from "../../config.js";

const authApi = Config().auth_api;

export const removeCertificateRequest = async (requestId) => {
    try {
        const response = await axios.delete(`${authApi}api/v1/user-profile/certificate-request/user/delete/${requestId}`, {
            withCredentials: true,
        });
    
        if (response.status >= 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
