import { aCO2_ABI, aCO2Pool_ABI } from "constants/BlockchainConstants";
import { ethers } from "ethers";
import { Config } from "../../config";
import { CiWallet } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { useEffect, useState } from "react";
import { getERC721Data } from "features/dashboard/getERC721Data";
import { isApprovedForAll } from "features/aco2-pools/isApprovedACO2";
import { toast } from "react-toastify";
import { getAllPoolData } from "features/aco2-pools/getAllPoolData";
import { getAllPoolLogos } from "features/aco2-pools/getAllPoolLogos";
import { getUserPoolData } from "features/aco2-pools/getUserPoolData";
import { sellACO2Rewards } from "features/aco2-pools/sellACO2";

const SellPopup = ({
  showSellAco2Popup,
  handleClosePopup,
  poolLogos,
  selectedPool,
  amount,
  setAmount,
  aco2TotalBalance,
  totalPledgedAmount,
  loading,
  isTeamMember,
  isApprovedACO2,
  setLoading,
  setErc721Data,
  setIsApprovedACO2,
  setAco2Pools,
  setPoolLogos,
  setUserPoolData,
  setShowSellAco2Popup,
  setShowSuccessPopup,
  setSuccessTxHash,
}) => {
  const { address } = useAccount();

  const [sellACO2ParamsQueue, setSellACO2ParamsQueue] = useState([]);
  const [sellACO2Params, setSellACO2Params] = useState([]);

  // Approve for all
  const {
    data: approveForAllHash,
    writeContract: approveForAllWrite,
    isPending: approveForAllLoading,
    isError: approveForAllWriteError,
  } = useWriteContract();
  const { isSuccess: isApprovingSuccess, isError: isApprovingError } =
    useWaitForTransactionReceipt({ hash: approveForAllHash });

  // Sell aCO2 to pool
  const {
    data: sellHash,
    writeContract: sellWrite,
    isPending: sellLoading,
    isError: sellWriteError,
  } = useWriteContract();
  const { isSuccess: isSellingSuccess, isError: isSellingError } =
    useWaitForTransactionReceipt({ hash: sellHash });

  const handleApproveForAll = async () => {
    try {
      setLoading(true);
      toast.success("Successfully submitted approval request");
      const args = [
        `${Config().contract_addresses.aco2_pool_address}`.toLowerCase(),
        true,
      ];
      approveForAllWrite({
        args: args,
        abi: aCO2_ABI,
        address: Config().contract_addresses.aco2_address,
        functionName: "setApprovalForAll",
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to submit approval request");
      setTimeout(() => {
        getERC721Data(address).then((data) => {
          setErc721Data(data.token_data);
        });
        isApprovedForAll(address).then((data) => {
          setIsApprovedACO2(data);
        });
        setLoading(false);
      }, 1000);
      return { error: error.message };
    }
  };

  const handleSell = async () => {
    try {
      setLoading(true);
      // create const data which is a map of the aco2TotalBalance.moreDetaildBalance with the aco2_id from the erc721Data make connection using the contract_address and token_id
      let tokenData = aco2TotalBalance.moreDetaildBalance.map((balance) => {
        return {
          token_id: balance.tokenId,
          contract_address: balance.contractAddress,
          erc_1155_token_id: balance.tokenId,
          erc_1155_amount: balance.balance,
        };
      });
      sellACO2Rewards(tokenData, amount, selectedPool.id).then((result) => {
        if (!result.error) {
          toast.success("Successfully submitted sell request");
          setSellACO2ParamsQueue(result);
        } else {
          toast.error("Transaction failed");
          setTimeout(() => {
            getAllPoolData().then((data) => {
              if (data === null) {
                console.error("Error:", data);
              } else {
                setAco2Pools(data.pool_data);
              }
            });
            getAllPoolLogos().then((data) => {
              setPoolLogos(data);
            });
            getUserPoolData(address).then((data) => {
              setUserPoolData(data.user_pool_data);
            });
            getERC721Data(address).then((data) => {
              setErc721Data(data.token_data);
            });
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Transaction failed");
      setTimeout(() => {
        getAllPoolData().then((data) => {
          if (data === null) {
            console.error("Error:", data);
          } else {
            setAco2Pools(data.pool_data);
          }
        });
        getAllPoolLogos().then((data) => {
          setPoolLogos(data);
        });
        getUserPoolData(address).then((data) => {
          setUserPoolData(data.user_pool_data);
        });
        getERC721Data(address).then((data) => {
          setErc721Data(data.token_data);
        });
        setLoading(false);
      }, 1000);
      return { error: error.message };
    }
  };

  const handleaCO2Amount = (e) => {
    const value = e.target.value;
    // Regular expression to test for a whole number
    const isWholeNumber = /^\d*$/;

    if (isWholeNumber.test(value)) {
      setAmount(value.toString());
      // Uncomment and update the next line as necessary for your application
      // setRequiredCBY((value * 5) / cbyLivePrice?.weeklyPrice);
    }
  };

  useEffect(() => {
    if (isApprovingSuccess) {
      toast.success("Approval successful");
      setTimeout(() => {
        getERC721Data(address).then((data) => {
          setErc721Data(data.token_data);
        });
        isApprovedForAll(address).then((data) => {
          setIsApprovedACO2(data);
        });
        setLoading(false);
      }, 5000);
    } else if (approveForAllWriteError || isApprovingError) {
      toast.error("Approval failed");
      setTimeout(() => {
        getERC721Data(address).then((data) => {
          setErc721Data(data.token_data);
        });
        isApprovedForAll(address).then((data) => {
          setIsApprovedACO2(data);
        });
        setLoading(false);
      }, 1000);
    }
  }, [
    isApprovingSuccess,
    isApprovingError,
    approveForAllLoading,
    approveForAllWriteError,
  ]);

  useEffect(() => {
    if (sellACO2ParamsQueue.length > 0) {
      console.log("sellACO2ParamsQueue:", sellACO2ParamsQueue);
      setSellACO2Params(sellACO2ParamsQueue[0]);
    } else if (sellACO2ParamsQueue.length === 0 && loading === true) {
      setTimeout(() => {
        setLoading(false);
        setShowSellAco2Popup(false);
        setShowSuccessPopup(true);
      }, 5000);
    }
    // Start an interval that triggers every 5 seconds
    const intervalId = setInterval(() => {
      getAllPoolData().then((data) => {
        if (data === null) {
          console.error("Error:", data);
        } else {
          setAco2Pools(data.pool_data);
        }
      });
      getAllPoolLogos().then((data) => {
        setPoolLogos(data);
      });
      getUserPoolData(address).then((data) => {
        setUserPoolData(data.user_pool_data);
      });
      getERC721Data(address).then((data) => {
        setErc721Data(data.token_data);
      });
    }, 5000); // 5000 milliseconds (5 seconds)

    // Stop the interval after 30 seconds
    setTimeout(() => {
      clearInterval(intervalId); // This stops the interval
    }, 30000); // 30000 milliseconds (30 seconds)
  }, [sellACO2ParamsQueue]);
  useEffect(() => {
    if (sellACO2Params?.args?.length > 0) {
      console.log("sellACO2Params:", sellACO2Params);
      console.log("sellACO2Params?.args:", sellACO2Params?.args);
      sellWrite({
        args: sellACO2Params?.args,
        abi: aCO2Pool_ABI,
        address: Config().contract_addresses.aco2_pool_address,
        functionName: "depositBatchaCO2",
      });
    }
  }, [sellACO2Params]);
  useEffect(() => {
    if (isSellingSuccess) {
      toast.success("Sell successful");
      setSuccessTxHash((prevHashes) => [...prevHashes, sellHash]);
      setSellACO2ParamsQueue((prev) => prev.slice(1));
    } else if (isSellingError || sellWriteError) {
      toast.error("Sell failed");
      getAllPoolData().then((data) => {
        if (data === null) {
          console.error("Error:", data);
        } else {
          setAco2Pools(data.pool_data);
        }
      });
      getAllPoolLogos().then((data) => {
        setPoolLogos(data);
      });
      getUserPoolData(address).then((data) => {
        setUserPoolData(data.user_pool_data);
      });
      getERC721Data(address).then((data) => {
        setErc721Data(data.token_data);
      });
      setLoading(false);
    }
  }, [isSellingSuccess, isSellingError, sellLoading, sellWriteError]);

  return (
    <>
      {showSellAco2Popup ? (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed flex justify-center items-center z-40 bg-black bg-opacity-80 w-full h-full p-4  overflow-x-hidden overflow-y-auto inset-0 h-modal"
        >
          <div className="relative w-full h-full max-w-md md:h-auto">
            <div className="relative bg-light_green bg-opacity-80 p-6 border rounded-lg shadow">
              <button
                onClick={() => handleClosePopup()}
                type="button"
                className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-hide="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="flex items-center justify-center pb-2">
                <div className="w-8 h-8">
                  <img
                    src={
                      poolLogos.find(
                        (logo) => logo.poolId === selectedPool.id.toString(),
                      )?.logoURL || "defaultLogoURL"
                    }
                    alt={`${selectedPool.id} Logo`}
                  />
                </div>
                <div className="ml-3 text-xl font-bold">
                  Sell $aCO2 to {selectedPool.buyer}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="mb-3">
                  <div className="relative py-2 space-y-1">
                    <label
                      for="amount"
                      className="block text-base font-semibold tracking-wide"
                    >
                      $aCO2 amount
                    </label>
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                      placeholder="amount"
                      required="true"
                      value={amount}
                      onChange={handleaCO2Amount}
                    />
                  </div>
                  <div className="flex space-x-1 text-sm font-normal">
                    <IoIosInformationCircleOutline className="w-4 h-4  mt-0.5 stroke-1" />
                    <span>
                      Exchange your $aCO2 for $
                      {selectedPool.exchangeCurrencySymbol} using this pool
                    </span>
                  </div>
                </div>

                <div className="flex flex-col justify-center font-medium text-sm tracking-wide space-y-2 bg-black bg-opacity-25 w-full h-25 rounded-lg p-3 mb-3">
                  <div className="flex justify-between">
                    <span>My total $aCO2 balance</span>
                    <span>
                      {Number(aco2TotalBalance.totalBalanceFormatted).toFixed(
                        2,
                      )}{" "}
                      $aCO2
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Amount sold / Max sellable</span>
                    <span>
                      {totalPledgedAmount === null
                        ? "0"
                        : Number(totalPledgedAmount).toFixed(0)}{" "}
                      /{" "}
                      {selectedPool.userClaimsCap <
                      selectedPool.exchangeCurrencyAmount
                        ? (Number(
                            ethers.utils.formatUnits(
                              selectedPool.userClaimsCap,
                              selectedPool.exchangeCurrencyDecimals,
                            ),
                          ) / Number(selectedPool.exchangeRate)).toFixed(0)
                        : (
                            Number(
                              ethers.utils.formatUnits(
                                selectedPool.exchangeCurrencyAmount,
                                selectedPool.exchangeCurrencyDecimals,
                              ),
                            ) / Number(selectedPool.exchangeRate)
                          ).toFixed(0)}{" "}
                      $aCO2
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>Commission</span>
                    <span>
                      {selectedPool.poolFee}%{" "}
                      {selectedPool.exchangeCurrencySymbol}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>You will receive</span>
                    <span>
                      {(
                        Number(amount) *
                        selectedPool.exchangeRate *
                        (1 - selectedPool.poolFee / 100)
                      ).toFixed(4)}{" "}
                      {selectedPool.exchangeCurrencySymbol}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() =>
                    isApprovedACO2 ? handleSell() : handleApproveForAll()
                  }
                  disabled={
                    loading ||
                    Number(amount) >
                      Number(aco2TotalBalance.totalBalanceFormatted) ||
                    (Number(amount) >
                      Number(
                        ethers.utils.formatUnits(
                          selectedPool.userClaimsCap,
                          selectedPool.exchangeCurrencyDecimals,
                        ),
                      ) /
                        Number(selectedPool.exchangeRate) &&
                      !isTeamMember) ||
                    (Number(amount) >
                      Number(
                        ethers.utils.formatUnits(
                          selectedPool.userClaimsCap,
                          selectedPool.exchangeCurrencyDecimals,
                        ),
                      ) /
                        Number(selectedPool.exchangeRate) -
                        Number(totalPledgedAmount) &&
                      !isTeamMember) ||
                    Number(amount) === 0
                  }
                  className="flex items-center justify-center w-full tracking-tighter bg-carbifyOrange text-bt rounded-lg shadow-xl shadow-black-500/50 py-2 font-bold space-x-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                  <CiWallet className="w-6 h-6 stroke-1" />
                  <span className="text-lg tracking-wider">
                    {!isApprovedACO2
                      ? "Approve $aCO2"
                      : !loading
                        ? "Sell $aCO2"
                        : "Selling $aCO2..."}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SellPopup;
