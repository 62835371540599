/* global BigInt */
import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import Spinner from 'components/utils/Spinner'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router-dom'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

function UpdateListing({
  listingId,
  quantityToList,
  reservePricePerToken,
  buyoutPricePerToken,
  currencyToAccept,
  startTime,
  secondsUntilEndTime,
}) {
  // Update Listing
  const {
    data: updateListingData,
    error,
    isError,
    isLoading,
    writeContract: updateListing,
  } = useWriteContract()
  console.log('updateListing Hash:', updateListingData)
  const {
    isSuccess: isListingUpdated,
    isLoading: isUpdateListingLoading,
  } = useWaitForTransactionReceipt({ hash: updateListingData })

  // Update Listing
  const handleUpdateListing = () => {
    updateListing({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'updateListing',
      args: [
        listingId,
        quantityToList,
        BigInt(reservePricePerToken * 10 ** 18),
        BigInt(buyoutPricePerToken * 10 ** 18),
        currencyToAccept,
        startTime,
        secondsUntilEndTime,
      ],
    })
  }
  useEffect(() => {
    if (isListingUpdated) {
      toast.success('Your listing is updated!')
    } else if (isError) {
      toast.error('Error while updating the listing!', error.cause)
    }
  }, [error, isError, isListingUpdated])

  return (
    <>
      <button
        disabled={isLoading || isUpdateListingLoading}
        onClick={() => handleUpdateListing()}
        className="w-full text-xs 2xl:text-base font-bold text-bt bg-secondary px-3 py-4 rounded-lg"
      >
        {'Update Listing'}
      </button>
      {(isLoading || isUpdateListingLoading) && <Spinner />}
      {isListingUpdated && <Navigate to={'/nfts'} />}
    </>
  )
}

export default UpdateListing
