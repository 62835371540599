import axios from "axios";

const api_url = process.env.REACT_APP_ACO2_DATA_API;

export const getGlobalClusterData = async (selectedFilters, selectedClusters) => {
    try {

        const body = {
            certificates_filter: selectedFilters,
        };

        const response = await axios.post(`${api_url}/api/nft/global-cluster-data`, body);

        if (selectedClusters && selectedClusters.length > 0) {
            // Ensure globalClusterData is populated by correctly matching cluster_id
            let globalClusterData = response.data.global_cluster_data.filter((item) => 
                selectedClusters.includes(item.cluster_id)
            );

            return { global_cluster_data: globalClusterData };
        }

        return response.data;
    } catch (error) {
        console.error("Error fetching global cluster data: ", error);
        return null;
    }
};
