import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AuthLayout from 'layouts/AuthLayout'

// Icons
import { RiLockPasswordLine } from 'react-icons/ri'

// Reducers
import { resetPasswordEmail } from 'features/authentication/authSlice'

const ForgotPassword = () => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: '',
  })

  const { email } = formData

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(resetPasswordEmail({ email: formData.email }))

    if (email) {
      setFormData({
        ...formData,
        email: '',
      })
    }
  }

  return (
    <AuthLayout title={'Carbify | Forgot Password'}>
      <div className="flex flex-col space-y-3 items-center rounded-lg p-4">
        <div>
          <RiLockPasswordLine className="w-14 h-14 fill-carbifyOrange" />
        </div>
        <div className="text-center tracking-wider">
          <p className="font-bold text-2xl">Forgot password</p>
          <p className="text-lg pt-2">
            Don't fret! Just type in your email and we will send you a link to reset your password.
          </p>
        </div>
        <div className="w-full">
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block mb-3 text-base font-medium">
                Enter your verified email address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
                placeholder="name@company.com"
                value={formData.email}
                onChange={onChange}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-carbifyOrange text-bt focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Send reset link
            </button>
            <p className="text-sm font-light text-white">
              Already got the password?{' '}
              <Link to={'/login'} className="font-medium text-carbifyOrange hover:underline">
                {' '}
                Login here
              </Link>
            </p>
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}

export default ForgotPassword
