import React from 'react'

function TrustWalletIcon() {
  return (
    <div>
      <svg width="24" height="24" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
            d="M32.3999 64C50.073 64 64.3999 49.6731 64.3999 32C64.3999 14.3269 50.073 0 32.3999 0C14.7268 0 0.399902 14.3269 0.399902 32C0.399902 49.6731 14.7268 64 32.3999 64Z"
            fill="#3375BB"
          />
          <path
            d="M32.6267 14C38.9508 19.2816 46.2029 18.9559 48.275 18.9559C47.8217 48.9937 44.3683 43.0373 32.6267 51.46C20.8851 43.0373 17.4533 48.9937 17 18.9559C19.0505 18.9559 26.3026 19.2816 32.6267 14Z"
            stroke="white"
            strokeWidth="4"
            stroke-miterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="64" height="64" fill="white" transform="translate(0.399902)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default TrustWalletIcon
