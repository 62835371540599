import React from 'react'

const CardContainer = ({ children, styles }) => {
  const defaultStyles = ' h-64 bg-green  ';

  return (
    <div className={` flex flex-col inline-flex relative p-6 items-start isolate rounded-lg w-full ${styles ? styles : defaultStyles}`}>
      {children}
    </div>
  )
}

export default CardContainer;
