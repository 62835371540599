import { Skeleton, Tooltip } from '@mui/material';
import React, { useState } from 'react';

export const TooltipComponent = ({ tooltipContent, stroke = "#FFFFFF" }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={tooltipContent}
      arrow
      open={open}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: '600px',
            fontSize: '14px',
            padding: '8px',
            backgroundColor: '#023B14B3',
            borderRadius: '8px',
            borderColor: 'white',
            borderWidth: '1px',
            textAlign: 'center'
          },
        },
        arrow: {
          sx: {
            color: 'white', // This sets the arrow color to match the tooltip background color
          },
        },
      }}
    >
      <span
        className="hover:cursor-pointer"
        onClick={handleTooltipOpen}
        onTouchStart={handleTooltipOpen}
        style={{ position: 'relative', display: 'inline-block', marginLeft: '5px' }}
      >
        <div className="flex items-center justify-center w-[16px] h-[16px]">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke={stroke}
              className="w-6 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
      </span>
    </Tooltip>
  );
};

export const SmallOverviewCard = ({ title, value, tooltipText = "Tooltip content here" }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex flex-col w-[100%] h-[7.5rem] items-start gap-2.5 px-5 py-4 relative bg-darkGreen bg-opacity-70 rounded-lg overflow-hidden border border-solid border-[#eaeaea]">
      <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-[#ffffff] text-base tracking-[0] leading-[normal] flex gap-2">
        {title === undefined ? "Title" : title}
      </div>
      <div className="absolute top-2 right-2">
        <Tooltip
          title={tooltipText}
          arrow
          open={open}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: '200px',
                fontSize: '14px',
                padding: '8px',
              },
            },
          }}
        >
          <span
            className="hover:cursor-pointer"
            onClick={handleTooltipOpen}
            onTouchStart={handleTooltipOpen}
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <div className="flex items-center justify-center w-[20px] h-[20px]">
              <img src="tooltip.svg" alt="info" />
            </div>
          </span>
        </Tooltip>
      </div>
      <div>
      </div>
      <div className="inline-flex flex-col items-start gap-0.5 relative flex-[0_0_auto]">
        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-2xl tracking-[0.12px] leading-[31.2px] whitespace-nowrap">
          {value === undefined ? <Skeleton width={80} height={40} variant="rectangular" /> : value}
        </div>
      </div>
    </div>
  );
};

export const DualOverviewCard = ({ title, value1, value2, tooltipText = "Tooltip content here" }) => {
    const [open, setOpen] = useState(false);
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
  
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    return (
      <div className="flex flex-col w-[100%] h-[136px] items-start gap-2.5 px-5 py-4 relative bg-darkGreen bg-opacity-70 rounded-lg overflow-hidden border border-solid border-[#eaeaea] justify-between">
        <div className="items-center mr-[-8.00px] inline-flex gap-2.5 relative flex-[0_0_auto]">
          <div className="relative w-10 h-10 bg-[#ff962e] rounded-[20px]">
            <img className="absolute w-[25px] h-[25px] top-2 left-2" alt="Mdi leaf" src="leaf.svg" />
          </div>
          <div className="relative w-[268px] mt-[-1.00px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-[#ffffff] text-base tracking-[0] leading-[normal]">
            {title === undefined ? "Title" : title}
          </div>
        </div>
        <div className="absolute top-2 right-2">
          <Tooltip
            title={tooltipText}
            arrow
            open={open}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '200px',
                  fontSize: '14px',
                  padding: '8px',
                },
              },
            }}
          >
            <span
              className="hover:cursor-pointer"
              onClick={handleTooltipOpen}
              onTouchStart={handleTooltipOpen}
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <div className="flex items-center justify-center w-[20px] h-[20px]">
                <img src="tooltip.svg" alt="info" />
              </div>
            </span>
          </Tooltip>
        </div>
        <div className="items-start mb-[-3.00px] inline-flex gap-2.5 relative flex-[0_0_auto]">
          <div className="inline-flex flex-col items-start relative flex-[0_0_auto]">
            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-2xl tracking-[0.12px] leading-[31.2px] whitespace-nowrap">
              {value1 === undefined ? <Skeleton width={100} height={40} variant="rectangular" /> : value1}
            </div>
            <div className="relative w-[134px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#f0f0f0] text-xs tracking-[0] leading-[normal]">
              Total
            </div>
          </div>
          <div className="inline-flex flex-col items-start relative flex-[0_0_auto]">
            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-2xl tracking-[0.12px] leading-[31.2px] whitespace-nowrap">
              {value2 === undefined ? "" : value2}
            </div>
            <div className="relative w-[134px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#f0f0f0] text-xs tracking-[0] leading-[normal]">
              {value2 === undefined ? "" : "Used"}
            </div>
          </div>
        </div>
      </div>
    );
};
