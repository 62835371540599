import React, { useEffect, useState } from 'react'

function AddressCopyToClipBoard({ address }) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }
  }, [copied])

  const copyToClipBoard = async walletAddress => {
    try {
      await navigator.clipboard.writeText(walletAddress)
      setCopied(true)
    } catch (err) {
      console.log('copy to clipboard error', err)
    }
  }

  return (
    <div>
      <button
        data-tip={copied ? 'Copied!' : 'Copy to clipboard'}
        className="relative before:z-10 before:absolute before:left-1/2 before:-bottom-3 before:w-max before:max-w-xs before:-translate-x-1/2 before:translate-y-full before:bg-copyToClipboard before:p-2.5 before:text-white before:font-medium before:text-base before:invisible before:content-[attr(data-tip)] after:z-10 after:absolute after:left-1/2 after:-bottom-3 after:h-0 after:w-0 after:-translate-x-1/2 after:border-8 after:border-b-copyToClipboard after:border-l-transparent after:border-t-transparent after:border-r-transparent after:invisible hover:before:visible hover:after:visible"
        onClick={() => {
          copyToClipBoard(address)
        }}
      >
        <div className="flex items-center">
          <span className='hidden sm:block'>{`${address?.slice(0, 6)}...${address?.slice(-4)}`}</span>
          <span className='lg:hidden'>{`${address?.slice(0, 4)}..`}</span>
        </div>
      </button>
    </div>
  )
}

export default AddressCopyToClipBoard
