import axios from "axios";

const api_url = process.env.REACT_APP_TREE_API_URL;

export const getNftreeOverview = async () => {
    try {
        const response = await axios.get(`${api_url}/api/nft/overview`);

        return response.data;
    } catch (error) {
        console.error("Error fetching Nftree overview: ", error);
        return null;
    }
};
