import { createSlice } from '@reduxjs/toolkit'

const footprintSlice = createSlice({
  name: 'footprint',
  initialState: {
    userType: 'individual', // added userType to handle individual or company selection
    country: '',
    startDate: '',
    endDate: '',
    houseFootprint: 0,
    airFootprint: 0,
    privateTransportFootprint: 0,
    publicTransportFootprint: 0,
    secondaryFootprint: 0,
    carbonFootprint: 0,
  },
  reducers: {
    setInfo: (state, action) => {
      state.country = action.payload.country
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
      state.userType = action.payload.userType
    },
    setCarbonFootprint: state => {
      state.carbonFootprint =
        state.houseFootprint +
        state.airFootprint +
        state.privateTransportFootprint +
        state.publicTransportFootprint +
        state.secondaryFootprint
    },
    setHouseFootprint: (state, action) => {
      state.houseFootprint = action.payload
    },
    setAirFootprint: (state, action) => {
      state.airFootprint = action.payload
    },
    setPrivateTransportFootprint: (state, action) => {
      state.privateTransportFootprint = action.payload
    },
    setPublicTransportFootprint: (state, action) => {
      state.publicTransportFootprint = action.payload
    },
    setSecondaryFootprint: (state, action) => {
      state.secondaryFootprint = action.payload
    },
    resetFootprint: state => {
      state.houseFootprint = 0
      state.airFootprint = 0
      state.privateTransportFootprint = 0
      state.publicTransportFootprint = 0
      state.secondaryFootprint = 0
      state.carbonFootprint = 0
    },
  },
})

export const {
  setInfo,
  setCarbonFootprint,
  setHouseFootprint,
  setAirFootprint,
  setPrivateTransportFootprint,
  setPublicTransportFootprint,
  setSecondaryFootprint,
  resetFootprint,
} = footprintSlice.actions

export default footprintSlice.reducer
