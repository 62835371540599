import React, { useEffect, useState, useRef } from 'react'

// Constants
import { USDT_ADDRESS, USDC_ADDRESS, USDCE_ADDRESS, CBY_ADDRESS } from 'constants/BlockchainConstants'

// Icons
import { AiOutlineCheck } from 'react-icons/ai'
import ChevronDownIcon from 'icons/ChevronDownIcon'
import ChevronUpIcon from 'icons/ChevronUpIcon'

const SelectCurrency = ({ disabled, currency, setCurrency, isUSDC }) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const currencies = [
    { name: 'USDCE', logo: '/assets/images/carbify-blue-dollar.png', address: USDCE_ADDRESS },
    { name: 'USDC', logo: '/assets/images/carbify-blue-dollar.png', address: USDC_ADDRESS },
    { name: 'USDT', logo: '/assets/images/tether.png', address: USDT_ADDRESS },
    // { name: 'CBY', logo: '/assets/images/carbify-logo-marketplace.png', address: CBY_ADDRESS },
    // Add more currencies with their logos here
  ]

  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies[0].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[0]
      : currencies[1].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[1]
      : currencies[2].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[2]
      : currencies[3],
  )

  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    if (!disabled) {
      setOpenDropdown(!openDropdown)
    }
  }

  const closeDropdown = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false)
    }
  }

  const handleCurrencySelection = currency => {
    setSelectedCurrency(currency)
    toggleDropdown()
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)

    return () => {
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [])

  useEffect(() => {
    setCurrency(selectedCurrency?.address)
  }, [selectedCurrency])

  return (
    <div className="relative">
      <div className={`w-full ${openDropdown ? 'z-10' : ''}`}>
        <div
          className={`w-full rounded-lg border bg-white bg-opacity-10 border-carbifyOrange px-3 py-2.5 relative ${
            disabled ? 'pointer-events-none' : 'cursor-pointer'
          }`}
          onClick={toggleDropdown}
        >
          <div className="flex items-center justify-between">
            <span className="flex items-center">
              <img src={selectedCurrency?.logo} alt={`${selectedCurrency?.name} logo`} className="w-6 h-6 mr-2" />
              <span className="font-medium text-base tracking-wider">{selectedCurrency?.name}</span>
            </span>
            <div className="flex items-center">
              {openDropdown ? <ChevronUpIcon width={16} height={16} /> : <ChevronDownIcon width={16} height={16} />}
            </div>
          </div>
        </div>
        {openDropdown && (
          <div
            ref={dropdownRef}
            className="absolute w-full mt-2 bg-[#232323] rounded-lg border border-carbifyOrange z-20"
          >
            <div className="flex flex-col space-y-1 pt-3">
              {currencies.map(currency => (
                <div
                  key={currency.name}
                  onClick={() => handleCurrencySelection(currency)}
                  className={`flex justify-between items-center text-sm font-normal ${
                    currency.name === selectedCurrency?.name ? 'text-carbifyOrange' : ''
                  }`}
                  style={{ userSelect: 'none', cursor: 'pointer', padding: '0.5rem 1rem' }}
                >
                  <span className="flex items-center">
                    <img src={currency.logo} alt={`${currency.name} logo`} className="w-6 h-6 mr-2" />
                    <span>{currency.name}</span>
                  </span>
                  {currency.name === selectedCurrency?.name && <AiOutlineCheck />}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectCurrency
