import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAccount } from 'wagmi'
import Layout from 'layouts/Layout'
import PageHeading from 'components/PageHeading'
import Spinner from 'components/utils/Spinner'
import NftreeCard from 'components/nfts/cards/NftreeCard'
import ListNftModal from 'components/nfts/modals/ListNftModal'

// Actions
import { getNftrees } from 'features/nfts/nftreesSlice'

function NftreesPage() {
  const { asset_contract } = useParams()
  const dispatch = useDispatch()
  const { address: walletAddress } = useAccount()

  const [showModal, setShowModal] = useState(false)
  const [nft, setNft] = useState({})
  const [pageKey, setPageKey] = useState(0)
  const [previousPageKeys, setPreviousPageKeys] = useState([])
  const { nftrees, nftreesLoading } = useSelector(state => state.nftrees)

  useEffect(() => {
    dispatch(getNftrees({ asset_contract, pageKey }))
  }, [walletAddress, pageKey, asset_contract])

  const handleNextPage = () => {
    setPreviousPageKeys(prevKeys => [...prevKeys, pageKey])
    setPageKey(nftrees?.pageKey)
  }

  const handlePreviousPage = () => {
    if (previousPageKeys.length > 0) {
      const previousPageKey = previousPageKeys.pop()
      setPageKey(previousPageKey)
    }
  }

  const handleListNftModal = (nft, showModal) => {
    setNft(nft)
    setShowModal(showModal)
  }

  console.log('nftrees', nftrees)

  return (
    <Layout title="Carbify | Nftrees" content="Your Nftrees">
      {nftreesLoading && <Spinner />}
      <PageHeading>NFTrees</PageHeading>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-6 gap-y-6 mb-6">
        {nftrees?.token_data?.map((nftree, index) => (
          <NftreeCard
            key={index}
            nft={nftree}
            name={nftree.metadata.name}
            image={nftree.metadata.image}
            handleListNftModal={handleListNftModal}
          />
        ))}
      </div>
      <div className="flex justify-center">
        {previousPageKeys.length > 0 && (
          <button onClick={handlePreviousPage} className="bg-secondary py-2 px-6 rounded-lg mr-4">
            Previous
          </button>
        )}
        {nftrees?.pageKey !== pageKey && (
          <button onClick={handleNextPage} className="bg-secondary py-2 px-10 rounded-lg">
            Next
          </button>
        )}
      </div>
      {nft && (
        <ListNftModal
          nft={nft}
          name={nftrees?.token_data?.[0]?.metadata?.name}
          image={nftrees?.token_data?.[0]?.metadata?.image}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </Layout>
  )
}

export default NftreesPage
