import kmeans from 'node-kmeans';

export const getClustersFromTrees = async (trees, totalClusters) => {
  if (!trees || trees.length === 0) {
    return [];
  }

  // Extract coordinates from trees
  const coordinates = trees.map(tree => {
    const [lat, lon] = tree.geo_location.split(' ').map(Number);
    return [lat, lon];
  });

  // Validate and clean coordinates
  const validCoordinates = coordinates.filter(coord => !isNaN(coord[0]) && !isNaN(coord[1]));
  if (validCoordinates.length === 0) {
    throw new Error('No valid coordinates found.');
  }

  // Using k-means to create clusters
  const clusters = await kmeansClusterize(validCoordinates, totalClusters);

  // Map clusters to desired format
  return clusters.map((cluster, index) => ({
    cluster_id: index + 1,
    cluster_center: {
      geo_location: `${cluster.centroid[0]} ${cluster.centroid[1]}`
    },
    trees: cluster.clusterInd.map(idx => ({
      id: trees[idx].id,
      geo_location: trees[idx].geo_location,
    })),
  }));
};

// Wrap kmeans.clusterize in a promise
const kmeansClusterize = (coordinates, K) => {
  return new Promise((resolve, reject) => {
    kmeans.clusterize(coordinates, { k: K }, (err, clusters) => {
      if (err) reject(err);
      else resolve(clusters);
    });
  });
};
