import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ScatterChart from './ScatterChart'

//reducers
import {
  setCarbonFootprint,
  setAirFootprint,
  setHouseFootprint,
  setPrivateTransportFootprint,
  setPublicTransportFootprint,
  setSecondaryFootprint,
} from 'features/footprint/footprintSlice'
import { resetFlightEmission } from 'features/footprint/flightSlice'

//icons
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'

const Results = ({ onBack, onNext, setPage }) => {
  const [checkboxes, setCheckboxes] = useState({
    house: false,
    air: false,
    private: false,
    public: false,
    secondary: false,
  })
  const [exit, setExit] = useState(false)

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    setCheckboxes(prevCheckboxes => ({
      ...prevCheckboxes,
      [name]: checked,
    }))
  }

  const {
    carbonFootprint,
    houseFootprint,
    airFootprint,
    privateTransportFootprint,
    publicTransportFootprint,
    secondaryFootprint,
  } = useSelector(state => state.footprint)
  const dispatch = useDispatch()

  const handleNavigation = type => {
    if (type === 'Next') {
      setExit(true)
    } else {
      setPage(false)
      onBack(true)
    }
  }

  const handleClosePopup = () => {
    setExit(false)
  }

  const handleExit = () => {
    dispatch(setHouseFootprint(0))
    dispatch(resetFlightEmission())
    dispatch(setAirFootprint(0))
    dispatch(setPublicTransportFootprint(0))
    dispatch(setPrivateTransportFootprint(0))
    dispatch(setSecondaryFootprint(0))
    setPage(false)
    setExit(false)
    onNext(true)
  }

  const handleFinalOffset = event => {
    event.preventDefault()
    if (checkboxes.house) {
      dispatch(setHouseFootprint(0))
    }
    if (checkboxes.air) {
      dispatch(resetFlightEmission())
      dispatch(setAirFootprint(0))
    }
    if (checkboxes.public) {
      dispatch(setPublicTransportFootprint(0))
    }
    if (checkboxes.private) {
      dispatch(setPrivateTransportFootprint(0))
    }
    if (checkboxes.secondary) {
      dispatch(setSecondaryFootprint(0))
    }
    setCheckboxes({
      house: false,
      air: false,
      private: false,
      public: false,
      secondary: false,
    })
    window.open('https://carbondebits.io/shop/', '_blank')
  }

  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [houseFootprint, airFootprint, privateTransportFootprint, publicTransportFootprint, secondaryFootprint])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      {exit ? (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed flex justify-center items-center bg-[#222] bg-opacity-50 z-50  w-full p-4 overflow-x-hidden overflow-y-auto xxxs:inset-0 h-modal xxxs:h-full"
        >
          <div className="relative w-full h-full max-w-md md:h-auto xxxs:max-md:items-center xxxs:max-md:justify-center xxxs:max-md:flex">
            {/* Modal content */}
            <div className="relative bg-green p-6 rounded-lg drop-shadow-2xl hover:-translate-y-0.5 ease-in-out duration-500">
              <button
                onClick={handleClosePopup}
                type="button"
                className="absolute top-2 right-2.5 text-gray-100 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="flex items-center justify-center pb-2 space-x-3 border-b border-white">
                <div className="text-xl font-semibold">Exit Carbon Caclculator</div>
              </div>
              {/* Modal Body */}
              <div className="p-3 font-semibold text-lg text-center flex flex-col space-y-3">
                <p>
                  Resetting the calculator will erase your current carbon footprint calculation. Are you sure you want
                  to proceed and exit the calculator?
                </p>
                <div>
                  <button
                    onClick={handleExit}
                    className="bg-carbifyOrange text-bt font-bold text-base text-center w-1/2 h-12 rounded-lg hover:bg-[#024728] hover:text-white tracking-wider"
                  >
                    Exit Calculator
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex flex-col xl:max-2xl:w-[800px] xl:h-[724px] 2xl:max-3xl:w-[580px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 pb-2">
          <div className="font-semibold text-xl">Your results</div>
        </div>

        <div className="py-4 tracking-wider">
          <form className="flex flex-col space-y-3 text-base font-normal text-white text-opacity-80 h-full">
            <div className="relative flex flex-col space-y-3 border-b border-copyToClipboard h-80 pb-4">
              <div className="absolute w-full h-72 left-1 pr-4">
                <ScatterChart carbon={carbonFootprint} />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold text-xl text-white">Compare</div>
              <ul className="px-5 py-2 list-disc text-base font-normal text-white text-opacity-80 tracking-wider space-y-2">
                <li>Your footprint is {carbonFootprint} metric tons CO2e per year</li>
                <li>The average footprint for people in the Netherlands is 11 metric tons</li>
                <li>The average for the European Union is about 6.8 metric tons</li>
                <li>The average worldwide carbon footprint is about 4.79 metric tons</li>
                <li>The worldwide target to combat climate change is 0 metric tons</li>
              </ul>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <div className="flex flex-col space-y-5 justify-center items-center border-b border-copyToClipboard pb-3">
            <div className="flex flex-col space-y-3">
              <div className="font-semibold text-xl">Your Total Footprint</div>
              <div className="text-center">
                <p className="text-white font-semibold text-4xl">{parseFloat(carbonFootprint).toFixed(4)}</p>
                <p className="font-medium text-base">metric tons of CO2e</p>
              </div>
            </div>
            <div className="flex flex-col space-y-3 w-full">
              <form onSubmit={handleFinalOffset} className="flex flex-col space-y-5">
                <div>
                  <div className="flex justify-between xxxs:max-md:flex-col">
                    <label className="md:pl-4 flex items-center xxxs:max-md:justify-center text-base font-semibold">
                      <input
                        type="checkbox"
                        name="house"
                        checked={checkboxes.house}
                        onChange={handleCheckboxChange}
                        className="border-2 border-black mr-2"
                      />
                      House
                    </label>
                    <p className="xxxs:max-md:text-center">
                      {parseFloat(houseFootprint).toFixed(4)} metric tons of CO2e
                    </p>
                  </div>
                  <div className="flex justify-between xxxs:max-md:flex-col">
                    <label className="md:pl-4 flex items-center xxxs:max-md:justify-center text-base font-semibold">
                      <input
                        type="checkbox"
                        name="air"
                        checked={checkboxes.air}
                        onChange={handleCheckboxChange}
                        className="border-2 border-black mr-2"
                      />
                      Air Travel
                    </label>
                    <p className="xxxs:max-md:text-center">{parseFloat(airFootprint).toFixed(4)} metric tons of CO2e</p>
                  </div>
                  <div className="flex justify-between xxxs:max-md:flex-col">
                    <label className="md:pl-4 flex items-center xxxs:max-md:justify-center text-base font-semibold">
                      <input
                        type="checkbox"
                        name="private"
                        checked={checkboxes.private}
                        onChange={handleCheckboxChange}
                        className="border-2 border-black mr-2"
                      />
                      Private Transport
                    </label>
                    <p className="xxxs:max-md:text-center">
                      {parseFloat(privateTransportFootprint).toFixed(4)} metric tons of CO2e
                    </p>
                  </div>
                  <div className="flex justify-between xxxs:max-md:flex-col">
                    <label className="md:pl-4 flex items-center xxxs:max-md:justify-center text-base font-semibold">
                      <input
                        type="checkbox"
                        name="public"
                        checked={checkboxes.public}
                        onChange={handleCheckboxChange}
                        className="border-2 border-black mr-2"
                      />
                      Public Transport
                    </label>
                    <p className="xxxs:max-md:text-center">
                      {parseFloat(publicTransportFootprint).toFixed(4)} metric tons of CO2e
                    </p>
                  </div>
                  <div className="flex justify-between xxxs:max-md:flex-col">
                    <label className="md:pl-4 flex items-center xxxs:max-md:justify-center text-base font-semibold">
                      <input
                        type="checkbox"
                        name="secondary"
                        checked={checkboxes.secondary}
                        onChange={handleCheckboxChange}
                        className="border-2 border-black mr-2"
                      />
                      Secondary
                    </label>
                    <p className="xxxs:max-md:text-center">
                      {parseFloat(secondaryFootprint).toFixed(4)} metric tons of CO2e
                    </p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    type="submit"
                    className="bg-carbifyOrange text-bt font-bold text-base text-center w-1/2 h-12 rounded-lg hover:bg-light_green hover:text-white tracking-wider"
                  >
                    Offset Now
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="py-3 text-base font-normal lg:w-[431px]">
            To offset some or all of your carbon footprint, click the sections you would like to offset in the list
            above, and click the Offset Now button.
          </div>
        </div>

        <div className="flex justify-between xxxs:mt-3">
          <div className="flex items-center justify-center">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Exit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Results
