import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import withClickOutside from 'utils/withClickOutside'
import ProfileBoxDropdown from './ProfileBoxDropdown'

const ProfileBox = forwardRef(({ open, setOpen }, ref) => {
  const { userInfo } = useSelector(state => state.authentication)

  return (
    <div className="pl-10" ref={ref}>
      <div className="flex items-center cursor-pointer" onClick={() => setOpen(!open)}>
        <div>
          <img
            className="w-12 h-12 border-2 border-white rounded-full"
            src={userInfo?.photoUrl ? userInfo.photoUrl : '/assets/images/avatar.png'}
            alt="User Avatar"
          />
        </div>
        {userInfo?.fullName && <h3 className="font-montserrat text-xl font-semibold pl-3">{userInfo.fullName}</h3>}
        <div className="relative">
          <ProfileBoxDropdown open={open} />
        </div>
      </div>
    </div>
  )
})
export default withClickOutside(ProfileBox)
