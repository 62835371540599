import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'
import { getAccount } from '@wagmi/core'
import { config } from 'BlockchainConfig'

// Constants
import { GENESIS_PLOT_ADDRESS, RARE_PLOT_ADDRESS, STANDARD_PLOT_ADDRESS } from 'constants/BlockchainConstants'

const initialState = {
  loadingGenesisPlots: false,
  loadingRarePlots: false,
  loadingStandardPlots: false,
  loadingPlots: false,
  genesisPlots: null,
  rarePlots: null,
  standardPlots: null,
  plots: null,
  errors: null,
}

export const getGenesisPlots = createAsyncThunk('plots/getGenesisPlots', async ({ pageKey, amount }, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_API_URL4
      }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${GENESIS_PLOT_ADDRESS.toLowerCase()}&pageKey=${pageKey}&amount=${amount}&listed=${0}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getRarePlots = createAsyncThunk('plots/getRarePlots', async ({ pageKey, amount }, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_API_URL4
      }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${RARE_PLOT_ADDRESS.toLowerCase()}&pageKey=${pageKey}&amount=${amount}&listed=${0}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getStandardPlots = createAsyncThunk('plots/getStandardPlots', async ({ pageKey, amount }, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_API_URL4
      }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${STANDARD_PLOT_ADDRESS.toLowerCase()}&pageKey=${pageKey}&amount=${amount}&listed=${0}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const getPlots = createAsyncThunk('plots/getPlots', async ({ asset_contract, pageKey, amount }, thunkAPI) => {
  let account = getAccount(config)
  try {
    const res = await axiosInstance.get(
      `${
        process.env.REACT_APP_API_URL4
      }/api/erc721/wallet?wallet_address=${account.address.toLowerCase()}&erc721_address=${asset_contract.toLowerCase()}&pageKey=${pageKey}&amount=${amount}&listed=${0}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const plotsSlice = createSlice({
  name: 'plots',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGenesisPlots.pending, state => {
        state.loadingGenesisPlots = true
      })
      .addCase(getGenesisPlots.fulfilled, (state, action) => {
        state.loadingGenesisPlots = false
        state.genesisPlots = action.payload
      })
      .addCase(getGenesisPlots.rejected, (state, action) => {
        state.loadingGenesisPlots = false
        state.errors = action.payload
      })

      .addCase(getRarePlots.pending, state => {
        state.loadingRarePlots = true
      })
      .addCase(getRarePlots.fulfilled, (state, action) => {
        state.loadingRarePlots = false
        state.rarePlots = action.payload
      })
      .addCase(getRarePlots.rejected, (state, action) => {
        state.loadingRarePlots = false
        state.errors = action.payload
      })

      .addCase(getStandardPlots.pending, state => {
        state.loadingStandardPlots = true
      })
      .addCase(getStandardPlots.fulfilled, (state, action) => {
        state.loadingStandardPlots = false
        state.standardPlots = action.payload
      })
      .addCase(getStandardPlots.rejected, (state, action) => {
        state.loadingStandardPlots = false
        state.errors = action.payload
      })

      .addCase(getPlots.pending, state => {
        state.loadingPlots = true
      })
      .addCase(getPlots.fulfilled, (state, action) => {
        state.loadingPlots = false
        state.plots = { token_data: action.payload.token_data, pageKey: action.payload.pageKey }
      })
      .addCase(getPlots.rejected, (state, action) => {
        state.loadingPlots = false
        state.errors = action.payload
      })
  },
})

export const {} = plotsSlice.actions
export default plotsSlice.reducer
