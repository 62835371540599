/* global BigInt */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { toast } from 'react-toastify'
import ApproveCurrency from 'components/MarketplaceV2/utils/ApproveCurrency'
import Spinner from 'components/utils/Spinner'
import { postPurchase } from 'features/marketplace/listingSlice'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function NftreeBuyNowButton({
  disabled,
  currencyAddress,
  currencyAbi,
  currencyDecimals,
  totalPrice,
  quantityToBuy,
  asset_contract,
  setShowModal,
}) {
  const dispatch = useDispatch()
  const [approvedAmount, setApprovedAmount] = useState('')
  const { address: walletAddress } = useAccount()
  const { userInfo } = useSelector(state => state.authentication)

  // const savePurchase = async () => {
  //   await dispatch(
  //     postPurchase({
  //       username: userInfo?.username,
  //       useremail: userInfo?.email,
  //       country: userInfo?.country,
  //       wallet: walletAddress,
  //     }),
  //   )
  // }

  const {
    data: allowance,
    error: allowanceError,
  } = useReadContract({
    address: currencyAddress,
    abi: currencyAbi,
    functionName: 'allowance',
    args: [walletAddress, MARKETPLACE_ADDRESS],
  })

  const {
    data: buyNftreeHash,
    writeContract: buyNftree,
    isPending: buyNftreeIsPending,
    isError: buyNftreeIsError,
    error: buyNftreeError,
  } = useWriteContract()
  const {
    isLoading: isBuyingNftreeLoading,
    isSuccess: isBuyingNftreeCompleted,
    isError: isBuyingNftreeError,
  } = useWaitForTransactionReceipt({ hash: buyNftreeHash })

  console.log('buyNftreeError: ', buyNftreeError)

  useEffect(() => {
    if (allowance) {
      setApprovedAmount(Number(allowance))
    } else if (allowanceError) {
      toast.error('Error fetching allowance:', allowanceError.cause)
    }
  }, [walletAddress, allowance, allowanceError])

  useEffect(() => {
    if (buyNftreeIsError || isBuyingNftreeError) {
      toast.error('Error buying Nft')
    } else if (isBuyingNftreeCompleted) {
      setShowModal(false)
      toast.success('You have successfully bought this Nft!')
    }
  }, [buyNftreeIsError, isBuyingNftreeError, isBuyingNftreeCompleted])

  useEffect(() => {
    const savePurchase = async () => {
      await dispatch(
        postPurchase({
          username: userInfo?.username,
          useremail: userInfo?.email,
          country: userInfo?.country,
          wallet: walletAddress,
        }),
      )
    }
    if(isBuyingNftreeCompleted && !isBuyingNftreeLoading) {
      savePurchase()
    }
  }, [isBuyingNftreeCompleted, isBuyingNftreeLoading])

  return (
    <>
      {BigInt(totalPrice * 10 ** 6) <= approvedAmount ? (
        <>
          <button
            disabled={disabled}
            onClick={() => buyNftree({
              address: MARKETPLACE_ADDRESS,
              abi: MARKETPLACE_ABI,
              functionName: 'buyFromCarbify',
              args: [asset_contract, currencyAddress, quantityToBuy],
            })}
            className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
          >
            <CoinsStacked02 color="#1C4828" />
            <span>Complete purchase</span>
          </button>
          {(buyNftreeIsPending || isBuyingNftreeLoading) && <Spinner />}
          {isBuyingNftreeCompleted && <Navigate to={'/marketplace'} />}
        </>
      ) : (
        <>
          <ApproveCurrency
            currencyAddress={currencyAddress}
            currencyAbi={currencyAbi}
            currencyDecimals={currencyDecimals}
            amount={totalPrice}
          />
        </>
      )}
    </>
  )
}

export default NftreeBuyNowButton
