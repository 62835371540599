import axios from 'axios';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getERC721Balance = async (walletAddress, erc721Address) => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/erc721/wallet/count`, {
            params: {
                wallet_address: walletAddress.toLowerCase(),
                erc721_address: erc721Address?.toLowerCase(),
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
