import React from 'react'

function Attribute({ heading, text }) {
  return (
    <div className="w-full bg-[#B3C8BF] rounded-lg">
      <div className="p-2">
        <div className="flex items-center justify-center">
          <p className="text-[#024728] text-sm font-medium uppercase tracking-wider">{heading}</p>
        </div>
        <div className="flex items-center justify-center">
          <p className="text-[#024728] text-lg font-bold capitalize">{text}</p>
        </div>
      </div>
    </div>
  )
}

export default Attribute
