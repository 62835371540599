"use client";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainButton } from "../components/TreeOverview/MainButton";
import { ChevronDown5, ChevronUp5 } from "../components/TreeOverview/icons/ChevronDown5";
import { FilterLines1 } from "../components/TreeOverview/icons/FilterLines1";
import { Skeleton } from "@mui/material";
import { CustomCheckbox } from "../components/TreeOverview/CheckBox";
import Layout from "layouts/Layout";
import PageHeading from "components/PageHeading";
import { StatCardOverview } from "components/dashboard/StatCard";
import { fetchOverviewData, fetchDetailsData, setSelectedTree, setBatchLoading } from '../features/tree-map/treeSlice';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "components/utils/Spinner";

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(Math.round(number));
};

const TreeOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const { overviewData, detailsData, selectedFilters } = useSelector((state) => state.tree);

  const [batchLoading, setBatchLoading] = useState(false);

  console.log("overviewData", overviewData);

  const totalAgroforestrySystems = overviewData == null ? undefined : formatNumber(overviewData.total_trees);
  const totalBatches = overviewData == null ? undefined : formatNumber(overviewData.total_batches);
  const areaReforested = overviewData == null ? undefined : formatNumber(overviewData.total_trees * 12.5);
  const foodProvided = overviewData == null ? undefined : formatNumber(overviewData.total_trees * 50) ;
  const workProvided = overviewData == null ? undefined : formatNumber(overviewData.total_trees / 1200) ;
  const communitiesImpacted = overviewData == null ? undefined : formatNumber(overviewData.total_trees / 2500);
  const totalCo2Absorbed = overviewData == null ? undefined : formatNumber(overviewData.total_aco2_generated);
  const co2ForCarbonOffsets = overviewData == null ? undefined : formatNumber(overviewData.total_aco2_burned_in_certificates);

  const statsData = [
    {
      title: 'ALL AGROFORESTRY SYSTEMS',
      value: totalAgroforestrySystems || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: '',
      tooltip: 'The total number of agroforestry systems planted by Carbify. This number does not include the amount of crops, fruit trees and support trees/plants.',
      loading: totalAgroforestrySystems === undefined
    },
    {
      title: 'TOTAL BATCHES',
      value: totalBatches || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: '',
      tooltip: 'The total number of trees planted. Each agroforestry system includes 4 to 7 trees alongside a hardwood tree, with an average of 5 trees used for calculation.',
      loading: totalBatches === undefined
    },
    {
      title: 'AFFORESTATION AREA',
      value: areaReforested || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'm²',
      tooltip: 'The total afforestation area established by Carbify, indicating the land converted to forests. This metric tracks Carbify\'s efforts in environmental restoration and carbon sequestration.',
      loading: areaReforested === undefined
    },
    {
      title: 'FOOD PROVIDED',
      value: foodProvided || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'KG',
      tooltip: 'The total amount of food provided by the trees planted by Carbify. This metric measures the food production resulting from Carbify\'s reforestation efforts.',
      loading: foodProvided === undefined
    },
    {
      title: 'WORK PROVIDED',
      value: workProvided || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'FTE',
      tooltip: 'The total amount of work provided by Carbify, resulting from planting, maintaining, and harvesting the trees.',
      loading: workProvided === undefined
    },
    {
      title: 'COMMUNITIES IMPACTED',
      value: communitiesImpacted || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'community',
      tooltip: 'The total number of communities impacted by Carbify, through tree planting and food provision.',
      loading: communitiesImpacted === undefined
    },
    {
      title: 'TOTAL CO2 ABSORBED',
      value: totalCo2Absorbed || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'KG',
      tooltip: 'The total amount of CO2 absorbed by the trees planted by Carbify. This metric measures the carbon sequestration and as we call them Carbon Debits.',
      loading: totalCo2Absorbed === undefined
    },
    {
      title: 'CO2 FOR OFFSETS',
      value: co2ForCarbonOffsets || <Skeleton width={94} height={36} variant="rectangular" />,
      unit: 'KG',
      tooltip: 'The total amount of CO2 used for carbon offsets. This number shows the carbon debits utilized for issuing carbon reduction certificates.',
      loading: co2ForCarbonOffsets === undefined
    }
  ];

  // Initial data fetching
  useEffect(() => {

    if (overviewData === null) {
      dispatch(fetchOverviewData());
    }
    if (detailsData === null) {
      dispatch(fetchDetailsData());
    }

  }, [overviewData,detailsData,dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setBatchLoading(true);
      try {
        if (selectedFilters.length > 0) {
          // dispatch(setSelectedCluster(null));
          dispatch(setSelectedTree(null));
          // Await the dispatch to ensure it's complete
          await dispatch(fetchDetailsData({ clusterId: null, filters: selectedFilters }));
        } else if (selectedFilters.length === 0) {
          // Await the dispatch to ensure it's complete
          await dispatch(fetchDetailsData({ clusterId: null, filters: [] }));
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      } finally {
        setBatchLoading(false);  // Only set this to false after the dispatch completes
      }
    };
  
    fetchData();
  }, [selectedFilters, dispatch]);

  const handleOpenMap = (detail) => {
    if (detail && detail.cluster_id) {
      dispatch(setSelectedTree(detail));
      navigate(`/map-tree`);
    } else {
      toast.error('Invalid detail object:', detail);
    }
  };

  return (
    <>
      { batchLoading === true && <Spinner /> }
      <Layout title="Carbify | Tree Overview" content="Tree Overview">
        <PageHeading>Tree Overview</PageHeading>
        <div className="flex flex-col items-center gap-8 pt-8 pb-12 sm:px-4 relative bg-cover bg-no-repeat bg-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 w-full max-w-[95%] mx-auto">
          {statsData.map((stat, index) => (
          <StatCardOverview
            key={index}
            title={stat.title}
            value={stat.value}
            unit={stat.unit}
            tooltip={stat.tooltip}
            loading={stat.loading}
          />
        ))}
          
          </div>
          <div className="relative w-full max-w-2xl" >
            <div className="flex items-center gap-2.5 px-4 py-2 bg-[#23232326] rounded-lg border border-solid border-orange" onClick={() => setShowFilters(!showFilters)}>
              <FilterLines1 className="!relative !w-6 !h-6" color="white" />
              <div className="relative w-fit [font-family:'Montserrat-Medium',Helvetica] font-medium text-[#ffffff] text-base tracking-[0.08px] leading-6 whitespace-nowrap">
                Filters
              </div>
              {showFilters ? <ChevronUp5 className="!relative !w-6 !h-6 ml-auto" color="white" /> : <ChevronDown5 className="!relative !w-6 !h-6 ml-auto" color="white" />}
            </div>
            {showFilters && (
              <div className="absolute top-full left-0 w-full bg-[#232323] rounded-lg shadow-lg z-10 border border-solid border-orange mt-2 select-none p-4">
                <div className="relative w-fit mt-1 mb-2 [font-family:'Montserrat',Helvetica] font-bold text-[#ffffff] text-base tracking-[0.08px] leading-6 whitespace-nowrap">
                  By batch
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                  <CustomCheckbox label="Carbify Genesis NFTree" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #2" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #3" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #4" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #5" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #6" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #7" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #8" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #9" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #10" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #11" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #12" selectedFilters={selectedFilters} />
                  <CustomCheckbox label="Carbify NFTree #13" selectedFilters={selectedFilters} />
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full flex-col lg:flex-row md:w-[80%] lg:w-[95%] h-auto items-start gap-4 p-4 relative bg-darkGreen bg-opacity-70 border rounded-lg overflow-hidden">
            <div className="flex flex-col w-full items-start gap-2 relative flex-1 grow">
              <div className="flex flex-col items-start gap-1 relative self-stretch w-full flex-[0_0_auto]">
                <div className="hidden md:flex flex-wrap items-start relative self-stretch w-full flex-[0_0_auto] border-b [border-bottom-style:solid] border-[#527766]">
                  <div className="flex w-[25%] items-start gap-2 pt-0 pb-2 px-2 sm:px-0 relative">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-white text-base tracking-[0.08px] leading-[20.8px] whitespace-nowrap">
                      Tree ID
                    </div>
                  </div>
                  <div className="flex w-[25%] items-start gap-2 pt-0 pb-2 px-2 sm:px-0 relative">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-white text-base tracking-[0.08px] leading-[20.8px] whitespace-nowrap">
                      CO2
                    </div>
                  </div>
                  <div className="flex w-[25%] items-start gap-2 pt-0 pb-2 px-2 sm:px-0 relative">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-white text-base tracking-[0.08px] leading-[20.8px] whitespace-nowrap">
                      Planting date
                    </div>
                  </div>
                  <div className="flex w-[25%] items-start gap-2 pt-0 pb-2 px-2 sm:px-0 relative">
                    <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-white text-base tracking-[0.08px] leading-[20.8px] whitespace-nowrap xl:left-24">
                      View Map
                    </div>
                  </div>
                </div>
                {detailsData !== null ? (
                  <div className="overflow-y-scroll overflow-x-hidden max-h-[75vh] w-full pb-2">
                    {detailsData.map((detail, index) => (
                      <div key={index} className="flex flex-col sm:flex-row w-full items-start sm:items-center px-0 py-1.5 relative flex-[0_0_auto] border-light_green border-b">
                        <div className="flex w-full sm:w-[25%] items-start gap-2 p-2 sm:px-0 relative">
                          <div className="relative w-full sm:w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-semibold text-white text-base tracking-[0.08px] leading-6">
                            <span className="sm:hidden">Tree ID: </span>#{detail.nftree_id}
                          </div>
                        </div>
                        <div className="flex w-full sm:w-[25%] items-start gap-2 p-2 sm:px-0 relative">
                          <div className="relative w-full sm:w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-white text-base tracking-[0.08px] leading-[20.8px]">
                            <span className="sm:hidden">CO2: </span>{detail.aco2_generated_over_lifetime.toFixed(2)}
                          </div>
                        </div>
                        <div className="flex w-full sm:w-[25%] items-start gap-2 p-2 sm:px-0 relative">
                          <div className="relative w-full sm:w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-[#6ea8ff] text-base tracking-[0.08px] leading-[20.8px]">
                            <span className="sm:hidden">Planting date: </span>{new Date(detail.start_time * 1000).toLocaleDateString()}
                          </div>
                        </div>
                        <div className="flex w-full sm:w-[25%] items-start justify-center gap-2 px-2 sm:px-0 py-1 relative">
                          <MainButton
                            className="!border-[#ffffff] !justify-center !flex-[0_0_auto] !border !border-solid !py-1.5 !bg-[unset] hover:cursor-pointer 2xl:px-4 xl:px-4 lg:px-3 px-3"
                            divClassName="!text-[#ffffff] !tracking-[0.07px] !text-sm !font-semibold !leading-[21px]"
                            property1="normal-state"
                            text="Open map"
                            visible={false}
                            onClick={() => handleOpenMap(detail)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (

                  <div className="pl-6">
                    <div className="w-20 animate-spin z-51">
                      <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
                    </div>
                  </div>

                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TreeOverview;
