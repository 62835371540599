import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'utils/axiosInstance'

export const getCbyLivePrice = createAsyncThunk('cbyLivePrice/getCbyLivePrice', async (_, thunkAPI) => {
  try {
    const res = await axiosInstance.get(`/cby/live-price/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

const initialState = {
  cbyLivePrice: null,
  loadingCbyLivePrice: false,
  errors: null,
}

export const cbyLivePriceSlice = createSlice({
  name: 'cbyLivePrice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCbyLivePrice.pending, state => {
        state.loadingCbyLivePrice = true
      })
      .addCase(getCbyLivePrice.fulfilled, (state, action) => {
        state.loadingCbyLivePrice = false
        state.cbyLivePrice = action.payload
      })
      .addCase(getCbyLivePrice.rejected, (state, action) => {
        state.loadingCbyLivePrice = false
        state.errors = action.payload
      })
  },
})

export const {} = cbyLivePriceSlice.actions

export default cbyLivePriceSlice.reducer
