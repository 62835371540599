import { ethers } from 'ethers';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';
// Staking Contract ABI and Address
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`;

export const isClaimedStakingMerkle = async (stakedMerkleProofData, walletAddress, click) => {
    try {
        let provider;

        if (click === true) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
        } else {
            provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        }

        // Initialize ethers with a provider
        const stakingContract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, provider);

        const amounts = stakedMerkleProofData.map((item) => item.amount);

        let combinedResult = [];

        const isClaimedBatch = await stakingContract.isClaimedBatch(walletAddress, amounts);

        for (let i = 0; i < stakedMerkleProofData.length; i++) {
            // Combine each token_id and contract_address with its isClaimed status
            combinedResult.push({
                isClaimed: isClaimedBatch[i],
                amount: amounts[i],
            });
        }

        return combinedResult;
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message };
    }
}
