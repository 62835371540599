import { ethers } from 'ethers';
import { CBY_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';

// CBY Contract ABI and Address
const CBY = CBY_ABI;
const CBY_ADDRESS = `${Config().contract_addresses.cby_address}`.toLowerCase();

export const getCBYBalance = async (address) => {
    try {
        // Initialize ethers with a provider
        const provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        const cbyToken = new ethers.Contract(CBY_ADDRESS, CBY, provider);
        const balance = await cbyToken.balanceOf(address);
        if (!balance) return 0;
        const balanceFormatted = ethers.utils.formatUnits(balance, 18);
        return { balanceFormatted: balanceFormatted };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message, balanceFormatted: 0 };
    }
}
