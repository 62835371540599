import axios from "axios";

const api_url = process.env.REACT_APP_TREE_API_URL;

export const getNftreeDetails = async (nftreeIds, nftreeAddresses, numberOfTrees, clusterId, selectedFilters) => {
    try {
        let body = {
            nft_ids: nftreeIds,
            nft_addresses: nftreeAddresses,
            number_of_trees: numberOfTrees || 200,
        };

        if (
            nftreeIds === null && 
            nftreeAddresses === null && 
            numberOfTrees === null && 
            clusterId &&
            selectedFilters.length > 0
        ) {
            body = {
                cluster_id: clusterId,
                batches_filter: selectedFilters,
            };
        } 
        if (
            nftreeIds === null && 
            nftreeAddresses === null && 
            numberOfTrees === null &&
            clusterId &&
            selectedFilters.length === 0
        ) {
            body = {
                cluster_id: clusterId,
            };
        }
        if (
            nftreeIds === null && 
            nftreeAddresses === null && 
            numberOfTrees === null &&
            clusterId === null &&
            selectedFilters.length > 0
        ) {
            body = {
                batches_filter: selectedFilters,
            };
        }

        const response = await axios.post(`${api_url}/api/nft/details`, body);

        return response.data;
    } catch (error) {
        console.error("Error fetching Nftree details: ", error);
        return null;
    }
};
