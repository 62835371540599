import { createSlice } from '@reduxjs/toolkit'

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    total: 0,
    totalItems: 0,
  },
  reducers: {
    addItem: (state, action) => {
      const item = action.payload
      item.base_price = item.price
      state.items.push(item)
      state.total = state.total + item.price
    },
    removeItem: (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id)
      state.total = state.total - action.payload.price
      if (index !== -1) {
        state.items.splice(index, 1)
      }
    },
    clearCart: state => {
      state.items = []
      state.total = 0
    },
    updatePrice: (state, action) => {
      const { id, quantity } = action.payload
      const item = state.items.find(item => item.id === id)
      if (item) {
        const basePrice = parseFloat(item.base_price)
        item.price = basePrice * quantity
      }
    },
    updateTotal: state => {
      const total = state.items.reduce((acc, item) => acc + item.price, 0)
      state.total = total
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload
      const itemIndex = state.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity = quantity
      }
    },
    cartLength: state => {
      const totalItems = state.items.reduce((acc, item) => acc + item.quantity, 0)
      state.totalItems = totalItems
    },
  },
})

export const { addItem, removeItem, clearCart, updatePrice, updateTotal, updateQuantity, cartLength } =
  cartSlice.actions
export default cartSlice.reducer
