import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from 'components/utils/Spinner'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

function ClaimButton({ listingId, closeFor }) {
  // Close Auction
  const {
    data: closeAuctionHash,
    writeContract: closeAuction,
    isPending: isCloseAuction,
    isError: isCloseAuctionError,
  } = useWriteContract()
  console.log('closeAuctionHash:', closeAuctionHash)
  const {
    isSuccess: listingCancelledSuccess,
    isError: listingCancelledError,
    isLoading: listingCancelledLoading,
  } = useWaitForTransactionReceipt({ hash: closeAuctionHash })

  // Close Auction
  const handleClaim = () => {
    closeAuction({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'closeAuction',
      args: [listingId, closeFor],
    })
  }

  // Close Auction
  useEffect(() => {
    if (listingCancelledSuccess) {
      toast.success('Auction has been closed!')
    } else if (listingCancelledError || isCloseAuctionError) {
      toast.error('Error while closing the auction')
    }
  }, [listingCancelledSuccess, listingCancelledError, isCloseAuctionError])

  return (
    <>
      <button
        onClick={handleClaim}
        className={`bg-secondary py-1 px-3 rounded-lg mr-2.5 ${
          isCloseAuction ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={isCloseAuction}
      >
        <span className="text-base font-bold text-bt">Claim</span>
      </button>
      {(isCloseAuction || listingCancelledLoading) && <Spinner />}
      {listingCancelledSuccess && <Navigate to="/nfts" />}
    </>
  )
}

export default ClaimButton
