/* global BigInt */
import React, { useEffect, useState } from 'react'
import { useAccount, useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import Spinner from 'components/utils/Spinner'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router-dom'

// Constants
import {
  MARKETPLACE_ADDRESS,
  MARKETPLACE_ABI,
  CarbifyNFT,
  CarbifyNFTABI,
  NFTREE_ABI,
} from 'constants/BlockchainConstants'

// Icons
import CoinsStacked02 from 'icons/CoinsStacked02'

function ListForSale({
  assetContract,
  token_id,
  startTime,
  secondsUntilEndTime,
  quantityToList,
  currencyToAccept,
  reservePricePerToken,
  buyoutPricePerToken,
  listingType,
  decimals,
}) {
  console.log('The time remaining is : ', secondsUntilEndTime)

  const [isApprovedForAllListings, setIsApprovedForAllListings] = useState(false)
  const [abi, setABI] = useState('')

  useEffect(() => {
    setABI(
      assetContract?.toLowerCase() === process.env.REACT_APP_CARBIFYNFT_ADDRESS?.toLowerCase()
        ? CarbifyNFTABI
        : NFTREE_ABI,
    )
  }, [assetContract])

  const { address: walletAddress } = useAccount()

  const {
    data: isApprovedForAllData,
    isLoading: isApprovedForAllLoading,
    error: isApprovedForAllError,
  } = useReadContract({
    address: assetContract,
    abi: abi,
    functionName: 'isApprovedForAll',
    args: [walletAddress, MARKETPLACE_ADDRESS],
  })
  useEffect(() => {
    if (isApprovedForAllData !== isApprovedForAllListings) {
      setIsApprovedForAllListings(isApprovedForAllData)
    } else if (isApprovedForAllError) {
      toast.error('Error fetching isApprovedForAll:', isApprovedForAllError.cause)
    }
  }, [isApprovedForAllData, isApprovedForAllError])

  // List to marketplace
  const {
    data: createListingData,
    isError,
    isLoading,
    writeContract: createListing,
  } = useWriteContract()
  console.log('create listing hash:', createListingData)
  const {
    isSuccess: isNftListed,
    isLoading: isNftListingLoading,
    isError: isNftListingError,
  } = useWaitForTransactionReceipt({ hash: createListingData })
  useEffect(() => {
    if (isNftListed) {
      toast.success('Your NFT has been successfully listed on the marketplace!')
    } else if (isNftListingError || isError) {
      toast.error('Error listing NFT')
    }
  }, [isNftListed, isNftListingError, isError])

  // Approve for all
  const { data: getApprovalForAllData, writeContract: writeApprovalForAll, error: getApprovalForAllError } = useWriteContract()
  console.log('approval hash:', getApprovalForAllData)
  const {
    isSuccess: isSetAppovalForAllSuccess,
    isLoading: isSetAppovalForAllLoading,
    isError: isSetAppovalForAllError,
  } = useWaitForTransactionReceipt({ hash: getApprovalForAllData })
  useEffect(() => {
    if (isSetAppovalForAllSuccess) {
      toast.success('Approved for all nfts')
      setIsApprovedForAllListings(true)
    } else if (isSetAppovalForAllError || getApprovalForAllError) {
      toast.error('Error approving for all nfts')
    }
  }, [isSetAppovalForAllSuccess, isSetAppovalForAllError, getApprovalForAllError])

  const listNft = async () => {
    if (!isApprovedForAllListings) {
      writeApprovalForAll({
        address: assetContract,
        abi: abi,
        functionName: 'setApprovalForAll',
        args: [MARKETPLACE_ADDRESS, true],
      })
    } else {
      if (buyoutPricePerToken <= 0) {
        toast.error('Price cannot be zero or less!')
      } else if (secondsUntilEndTime <= 0) {
        toast.error('Date cannot be same day!')
      } else if (quantityToList <= 0) {
        toast.error('Number of Items cannot be 0')
      } else {
        console.log(
          'YOU ABOUT TO LIST ',
          assetContract + ' ' + token_id,
          ' ' +
            startTime +
            ' ' +
            secondsUntilEndTime +
            ' ' +
            quantityToList +
            ' ' +
            currencyToAccept +
            ' ' +
            BigInt(reservePricePerToken * 10 ** decimals) +
            ' ' +
            BigInt(buyoutPricePerToken * 10 ** decimals) +
            ' ' +
            listingType,
        )

        createListing({
          address: MARKETPLACE_ADDRESS,
          abi: MARKETPLACE_ABI,
          functionName: 'createListing',
          args: [
            [
              assetContract,
              token_id,
              startTime,
              secondsUntilEndTime,
              quantityToList,
              currencyToAccept,
              BigInt(reservePricePerToken * 10 ** decimals),
              BigInt(buyoutPricePerToken * 10 ** decimals),
              listingType,
            ],
          ],
        })
      }
    }
  }
  return (
    <>
      <button
        disabled={isApprovedForAllLoading || isLoading}
        onClick={() => listNft()}
        className="flex space-x-3 items-center justify-center bg-carbifyOrange text-bt tracking-wider font-bold text-base py-3 px-9 rounded-lg w-full"
      >
        <CoinsStacked02 color="#1C4828" />
        <span>{isApprovedForAllListings ? 'List for Sale' : 'Approve'}</span>
      </button>
      {(isLoading || isNftListingLoading || isSetAppovalForAllLoading) && <Spinner />}
      {isNftListed && <Navigate to={'/marketplace'} />}
    </>
  )
}

export default ListForSale
