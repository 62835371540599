import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAccount } from 'wagmi'
import Layout from 'layouts/Layout'
import StakingCards from 'components/cards/StakingCards'
import PageHeading from 'components/PageHeading'
import StakingDataCards from 'components/cards/StakingDataCards'
import { getCbyBalance } from 'features/wallet/walletSlice'
import { getCbyLivePrice } from 'features/cby/cbyLivePriceSlice'
import { getAllTreeBalances } from 'features/dashboard/getAllTreeBalances'
import { getERC721Data } from 'features/dashboard/getERC721Data'
import { getAvailablePlotBalances } from 'features/staking/getAvailablePlotBalances'
import { getCBYBalance } from 'features/staking/getCBYBalance'
import { ALL_LANDPLOT_ADDRESSES } from 'constants/BlockchainConstants'
import { getAllTreeData } from 'features/dashboard/getAllTreeData'
import { getCBYPrice } from 'features/dashboard/getCBYPrice'
import { getStakingRewardsMultiple } from 'features/rewards/getStakingRewardsMultiple'
import { getAllLandplotBalances } from 'features/dashboard/getAllLandplotBalances'
import ClaimStakingAco2Card from 'components/cards/ClaimStakingAco2Card'

function Staking() {
  const dispatch = useDispatch()
  const { address } = useAccount()

  const [totalGenesisPlots, setTotalGenesisPlots] = useState(null);
  const [totalPremiumPlots, setTotalPremiumPlots] = useState(null);
  const [totalStandardPlots, setTotalStandardPlots] = useState(null);
  const [totalSpots, setTotalSpots] = useState(null);
  const [totalNftrees, setTotalNftrees] = useState(null);
  const [totalStakedNftrees, setTotalStakedNftrees] = useState(null);
  const [totalLockedNftrees, setTotalLockedNftrees] = useState(null);
  const [totalCBY, setTotalCBY] = useState(null);
  const [availableGenesisPlots, setAvailableGenesisPlots] = useState(null);
  const [availablePremiumPlots, setAvailablePremiumPlots] = useState(null);
  const [availableStandardPlots, setAvailableStandardPlots] = useState(null);
  const [availableSpots, setAvailableSpots] = useState(null);
  const [stakedNftreesOnGenesis, setStakedNftreesOnGenesis] = useState(null);
  const [stakedNftreesOnPremium, setStakedNftreesOnPremium] = useState(null);
  const [stakedNftreeOnStandard, setStakedNftreeOnStandard] = useState(null);
  const [yearlyEstimatedRewards, setYearlyEstimatedRewards] = useState(null);
  const [cbyLivePrice, setCbyLivePrice] = useState(null);
  const [erc721Data, setErc721Data] = useState(null);

  const enoughToStakeTrees = Math.floor(totalCBY * cbyLivePrice?.cby_price / 5).toFixed(0)

  useEffect(() => {
    dispatch(getCbyBalance())
    dispatch(getCbyLivePrice())
  }, [address])

  useEffect(() => {
    getERC721Data(address, undefined).then(data => {
      // Create a deep copy of token_data before any processing
      const originalDataCopy = JSON.parse(JSON.stringify(data.token_data));
      // Filter out tokens that do not match the specified contract addresses
      const processedData = data.token_data
        .filter(token =>
          ALL_LANDPLOT_ADDRESSES.map(a => a.toLowerCase()).includes(token.contract_address.toLowerCase())
        )
        .map(token => {
          // Remove null values
          if (token.earn_rate === null) {
            delete token.earn_rate;
          }
          if (token.last_claimed_time === null) {
            delete token.last_claimed_time;
          }
          if (token.is_staked === false) {
            delete token.is_staked;
          }
          if (token.is_locked === false) {
            delete token.is_locked;
          }
          if (token.locked_cby === "0") {
            delete token.locked_cby;
          }
          return token;
      });
      // Now setErc721Data with the filtered data
      setErc721Data(processedData);
      let stakeCount = 0;
      let lockCount = 0;
      for (let i = 0; i < originalDataCopy.length; i++) {
        if (originalDataCopy[i].is_staked === true) {
          stakeCount++;
        }
        if (originalDataCopy[i].is_locked === true && originalDataCopy[i].is_staked === false) {
          lockCount++;
        }
      }
      // Set the total count of staked nftrees
      setTotalStakedNftrees(stakeCount);
      // Set the total count of locked nftrees
      setTotalLockedNftrees(lockCount);
    });
    Promise.all([
      getAllTreeBalances(address),
      getAllTreeData(address),
    ]).then(([totalBalance, totalData]) => {
      getStakingRewardsMultiple(totalData).then(data => {
        setYearlyEstimatedRewards(data.stakingReward)
      })
      // Set the total nftree count
      setTotalNftrees(totalBalance);
    });
    getAllLandplotBalances(address).then(data => {
      setTotalSpots(data.total_plot_count)
      setTotalGenesisPlots(data.detailed_plot_count.genesis)
      setTotalPremiumPlots(data.detailed_plot_count.rare)
      setTotalStandardPlots(data.detailed_plot_count.standard)
    });
    getCBYBalance(address).then(data => {
      if (data.error) {
        setTimeout(() => {
          getCBYBalance(address).then(data => {
            if (data.error) {
              console.log("Error:", data.error);
            } else {
              setTotalCBY(Number(data.balanceFormatted))
            }
          });
        }, 3000);
      }
      setTotalCBY(Number(data.balanceFormatted))
    });
    getCBYPrice().then(data => {
      setCbyLivePrice(data)
    });
  }, [address])

  useEffect(() => {
    if (address && erc721Data) {
      getAvailablePlotBalances(erc721Data).then(result => {
        setAvailableSpots(result.totalAvailablePlots)
        setAvailableGenesisPlots(result.genesisAvailablePlots)
        setAvailablePremiumPlots(result.rareAvailablePlots)
        setAvailableStandardPlots(result.standardAvailablePlots)
      });
    }
  }, [address, erc721Data])

  useEffect(() => {
    if (
      totalGenesisPlots !== null && 
      totalPremiumPlots !== null && 
      totalStandardPlots !== null && 
      availableGenesisPlots !== null && 
      availablePremiumPlots !== null && 
      availableStandardPlots !== null
    ) {
      setStakedNftreesOnGenesis((Number(totalGenesisPlots * 50) - Number(availableGenesisPlots)))
      setStakedNftreesOnPremium((Number(totalPremiumPlots * 30) - Number(availablePremiumPlots)))
      setStakedNftreeOnStandard((Number(totalStandardPlots * 15) - Number(availableStandardPlots)))
    }
  }, [availableSpots, availableGenesisPlots, availablePremiumPlots, availableStandardPlots, totalGenesisPlots, totalPremiumPlots, totalStandardPlots])

  return (
    <Layout title="Carbify | Staking" content="Stake your NFTrees on the plots">
      <PageHeading>Staking</PageHeading>
      <StakingDataCards
        totalCBY={totalCBY}
        enoughToStakeTrees={enoughToStakeTrees !== 'NaN' ? enoughToStakeTrees : 0}
        totalNftrees={totalNftrees}
        totalStakedNftrees={totalStakedNftrees}
        totalSpots={totalSpots}
        availableSpots={availableSpots}
        yearlyEstimatedRewards={yearlyEstimatedRewards}
        cbyLivePrice={cbyLivePrice}
        totalGenesisPlots={totalGenesisPlots}
        totalPremiumPlots={totalPremiumPlots}
        totalStandardPlots={totalStandardPlots}
      />
      <StakingCards
        stakedNftreesOnGenesis={stakedNftreesOnGenesis !== null ? stakedNftreesOnGenesis : 0}
        stakedNftreesOnPremium={stakedNftreesOnPremium !== null ? stakedNftreesOnPremium : 0}
        stakedNftreeOnStandard={stakedNftreeOnStandard !== null ? stakedNftreeOnStandard : 0}
        totalGenesisPlots={totalGenesisPlots}
        totalPremiumPlots={totalPremiumPlots}
        totalStandardPlots={totalStandardPlots}
        availableGenesisPlots={availableGenesisPlots}
        availablePremiumPlots={availablePremiumPlots}
        availableStandardPlots={availableStandardPlots}
        landPlotData={erc721Data}
        setLandPlotData={setErc721Data}
        totalStakedNftrees={totalStakedNftrees}
        setTotalStakedNftrees={setTotalStakedNftrees}
        totalNftrees={totalNftrees}
        setTotalLockedNftrees={setTotalLockedNftrees}
        totalLockedNftrees={totalLockedNftrees}
        setYearlyEstimatedRewards={setYearlyEstimatedRewards}
        setTotalCBY={setTotalCBY}
      />
      <ClaimStakingAco2Card />
      <div className="mt-5 bg-darkGreen bg-opacity-70 border rounded-lg p-6 ">
        <h1 className="text-white text-2xl font-bold">Staking Info</h1>
        <ul className="text-white text-lg mt-2 list-disc list-inside ">
          <li>You need $5 worth of CBY per each tree staked to be able to stake (i.e. 50 trees = 250$ worth of CBY)</li>
          <li>If you decide to unstake the NFTrees, the $CBY will be returned minus a 7.5% fee</li>
        </ul>
      </div>
    </Layout>
  )
}

export default Staking
