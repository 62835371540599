import React, { useEffect, useState } from 'react'
import moment from 'moment'

//icons
import { GiSandsOfTime } from 'react-icons/gi'

const CountdownTimer = ({setIsExpired, time}) => {
  const [timeDisplay, setTimeDisplay] = useState('')

  useEffect(() => {
    // Convert Unix timestamp to a Moment object
    const timestampMoment = moment.unix(time)

    // Current moment
    const now = moment()

    if (now.isAfter(timestampMoment)) {
      setTimeDisplay(`Expired`)
      setIsExpired(true);
    } else {
      // Calculate the remaining time in minutes
      const duration = moment.duration(timestampMoment.diff(now))
      const remainingMinutes = duration.asMinutes()

      // Calculate remaining hours and remaining minutes
      const remainingHours = Math.floor(remainingMinutes / 60)
      const remainingMinutesInHour = Math.floor(remainingMinutes % 60)

      setTimeDisplay(`${remainingHours} hours, ${remainingMinutesInHour} minutes`)
    }
  }, [])

  return (
    <div className="flex space-x-1">
      <GiSandsOfTime />
      <div className="tracking-wider text-xs font-semibold">{timeDisplay}</div>
    </div>
  )
}

export default CountdownTimer
