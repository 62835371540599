export const transactions = [
  {
    event: 'Transfer',
    unit_price: '',
    Quantity: '250',
    From: '9A26CB',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2022-06-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
  {
    event: 'Transfer',
    unit_price: '',
    Quantity: '1',
    From: '9A26CB',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2023-02-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
  {
    event: 'Transfer',
    unit_price: '',
    Quantity: '1',
    From: '9A26CB',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2023-01-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
  {
    event: 'Transfer',
    unit_price: '',
    Quantity: '1',
    From: '9A26CB',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2023-05-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
  {
    event: 'Transfer',
    unit_price: '',
    Quantity: '1',
    From: '9A26CB',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2023-04-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
  {
    event: 'Sale',
    unit_price: '29,950 USDC',
    Quantity: '1',
    From: 'DjangolSky',
    To: '7B238F',
    Date: calculateRemainingTime(new Date('2023-06-04T10:30:00')),
    transaction_link: 'https://dashboard.carbify.io',
  },
]

function calculateRemainingTime(expirationDate) {
  const now = new Date()
  const timeDifference = now.getTime() - expirationDate.getTime()

  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    return `${days} ${days > 1 ? 'days' : 'day'} ago`
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`
  } else {
    return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
  }
}
