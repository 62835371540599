import React, { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { useDispatch, useSelector } from 'react-redux'
import OtherListings from './components/OtherListings'
import CarbifyListings from './components/CarbifyListings'
import TabularView from './components/views/TabularView'
import FilterByType from './components/filters/FilterByType'
import SearchByName from './components/filters/SearchByName'
import SortFilter from './components/filters/SortFilter'
import DisplayOptions from './components/filters/DisplayOptions'
import ActivityTable from './components/views/ActivityView'
import ShowCart from './components/popups/ShowCart'
import ItemPurchased from './components/popups/ItemPurchased'
import Spinner from 'components/utils/Spinner'

// Reducers
import { getListedNfts } from 'features/marketplace/listingSlice'
import { Navigate } from 'react-router-dom'

//icons
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { HeroCardNoTree } from 'components/dashboard/CertificateManagement '
import { getWalletActivity } from 'features/marketplace/getWalletActivity'

function MarketplaceContent({ listings = {} }) {
  const [showCart, setShowCart] = useState(false)
  const [itemPurchased, setItemPurchased] = useState(false)
  const [viewType, setViewType] = useState('grid')
  const [activityView, setActivityView] = useState(false)
  const [nftButtonClicked, setNftButtonClicked] = useState(false)
  const [sortValue, setSortValue] = useState('Active')
  const { address: walletAddress } = useAccount()

  const dispatch = useDispatch()

  const { totalItems } = useSelector(state => state?.cart)

  const { listedNfts, listedNftsLoading } = useSelector(state => state?.listings)

  const [search, setSearch] = useState('');
  const [filteredListings, setFilteredListings] = useState(listedNfts);
  const [activityData, setActivityData] = useState(null);

  useEffect(() => {
    dispatch(getListedNfts())
    getWalletActivity(walletAddress).then((data) => {
      if (data.activity_data) {
        setActivityData(data.activity_data)
      }
    })
  }, [walletAddress])

  const handleCart = () => {
    setShowCart(!showCart)
  }

  const handleActivity = () => {
    setActivityView(!activityView)
  }

  const handleItems = () => {
    setActivityView(false)
  }

  const handleButtonClicked = () => {
    setNftButtonClicked(true)
  }

  useEffect(() => {
    if (listedNfts !== null) {
      setFilteredListings(
        listedNfts?.filter((item) => {
          return item.title.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [search, listedNfts]);

  return (
    <>
      {listedNftsLoading && <Spinner />}
      <div className="relative">
        {/* <div className="absolute -top-16 xxxs:max-md:-top-6 right-0 flex space-x-3 pl-5" onClick={handleCart}>
          <AiOutlineShoppingCart className="w-8 h-8" />
          <div className="absolute left-0 -top-2 bg-red-600 w-5 h-5 flex justify-center items-center rounded-full text-sm font-medium">
            {totalItems}
          </div>
          <span className="font-semibold text-2xl flex justify-center items-center">Cart</span>
        </div>
        {showCart ? <ShowCart handlePopup={setShowCart} handlePurchasedItemPopup={setItemPurchased} /> : null}
        {itemPurchased ? <ItemPurchased handlePopup={setItemPurchased} /> : null} */}

        <div className="flex space-x-8 mb-3 text-2xl font-medium tracking-wider">
          <div className={`${activityView ? 'cursor-pointer' : 'underline underline-offset-4 text-darkGreen cursor-pointer'}`}
            onClick={handleActivity}>
            Items
          </div>
          <div className={`${!activityView ? 'cursor-pointer' : 'underline underline-offset-4 text-darkGreen cursor-pointer'}`}
            onClick={handleActivity}>
            Activity
          </div>
          {/* <div className="flex items-center justify-center">
            <button
              onClick={handleButtonClicked}
              className="absolute right-0 rounded bg-carbifyOrange pl-8 pr-8 p-2 text-bt text-lg font-bold text-center"
            >
              List your NFTs now
            </button>
          </div> */}
        </div>
        <div className="flex flex-col space-y-3">
          {/* items section */}
          {!activityView ? (

            <>
            
              <CarbifyListings />
              <HeroCardNoTree 
                topText={'Community Listings'}
                bottomText={'The Community Listings page includes all the objects available for sale on our marketplace, complete with various prices and specifications. Explore our diverse offerings and find the perfect match for you.'}
              />
              <div className="md:h-11 w-full flex md:space-x-3 xxxs:max-md:flex-col xxxs:max-md:space-y-3">
                <div className={`w-full`}>
                  <SearchByName 
                    search={search} 
                    setSearch={setSearch}
                  />
                </div>
                <div className={`z-[11]`}>
                  <SortFilter sortValue={sortValue} setSortValue={setSortValue} />
                </div>
                <div className={`z-10 flex items-center space-x-2 bg-white rounded-lg p-1 `}>
                  <button
                    className={`w-full h-full p-2 rounded-l-lg border-r border-darkGreen ${viewType === 'grid' ? 'bg-darkGreen text-white border ' : 'text-gray-500'}`}
                    onClick={() => setViewType('grid')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 3h4v4H5V3zM5 9h4v4H5V9zM5 15h4v4H5v-4zM15 3h4v4h-4V3zM15 9h4v4h-4V9zM15 15h4v4h-4v-4z"
                      />
                    </svg>
                  </button>
                  <button
                    className={`w-full h-full p-2 rounded-r-lg border-l border-darkGreen ${viewType === 'list' ? 'bg-darkGreen text-white' : 'text-gray-500'}`}
                    onClick={() => setViewType('list')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div>
                {viewType === 'grid' ? (
                  <>
                    {/* <div>
                      <div className="text-4xl font-bold relative ml-2 mt-10 mb-6">
                        <span className="text-white" style={{ textShadow: '0 0 20px rgba(255, 255, 0, 0.5)' }}>
                          CARBIFY NFT'S{' '}
                        </span>
                        {[15].map((blurRadius, index) => (
                          <span
                            key={index}
                            className="text-4xl font-bold absolute left-0 mb-6"
                            style={{ textShadow: `0 0 ${blurRadius}px rgba(255, 255, 0, ${1 - index * 0.2})` }}
                          >
                            CARBIFY NFT'S{' '}
                          </span>
                        ))}
                      </div>
                      <CarbifyListings />
                    </div> */}
                    <div>
                      {/* <div className="text-4xl font-bold relative ml-2 mt-10 mb-6">
                        <span className="text-white" style={{ textShadow: '0 0 10px rgba(255, 255, 0, 0.5)' }}>
                          COMMUNITY AUCTIONS
                        </span>
                        {[15].map((blurRadius, index) => (
                          <span
                            key={index}
                            className="text-4xl font-bold absolute left-0 mb-6"
                            style={{ textShadow: `0 0 ${blurRadius}px rgba(255, 255, 0, ${1 - index * 0.2})` }}
                          >
                            COMMUNITY AUCTIONS
                          </span>
                        ))}
                      </div> */}
                      {!listedNftsLoading && listedNfts !== null ? (
                      <OtherListings listings={filteredListings} sortValue={sortValue} />
                      ) : (
                        ''
                      )}
                    </div>
                  </>

                ) : (
                  <div className="grid grid-cols-1">
                    <TabularView  listings={listedNfts} sortValue={sortValue}/>
                  </div>
                )}
              </div>
            </>

          ):(

          // Activity section 
          <div>
            <ActivityTable 
              activityData={activityData}
            />
          </div>
          )}



          {nftButtonClicked && <Navigate to={'/nfts'} />}
        </div>
      </div>
    </>
  )

}

export default MarketplaceContent
