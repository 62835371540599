import { ethers } from 'ethers';

export const claimRegularMerkle = async (merkleProofData) => {
    console.log("merkleProofData", merkleProofData)
    merkleProofData.sort((a, b) => {
        const aAmount = ethers.BigNumber.from(a.aco2_amount.hex);
        const bAmount = ethers.BigNumber.from(b.aco2_amount.hex);
    
        if (aAmount.gt(bAmount)) {
            return -1; // a is greater than b
        } else if (aAmount.lt(bAmount)) {
            return 1; // b is greater than a
        } else {
            return 0; // a and b are equal
        }
    });

    // Remove any items from the array that have already been claimed
    console.log("merkleProofData before:", merkleProofData);

    for (let i = merkleProofData.length - 1; i >= 0; i--) {
        if (merkleProofData[i].isClaimed === true) {
            // Remove the item from the array
            merkleProofData.splice(i, 1);
        }
    }

    console.log("merkleProofData after:", merkleProofData);
    try {

        const tokenIds = merkleProofData.map((item) => item.token_id);
        const amounts = merkleProofData.map((item) => item.aco2_amount);
        const contractAddresses = merkleProofData.map((item) => item.contract_address);
        const merkleProofs = merkleProofData.map((item) => item.merkle_proofs);

        console.log("tokenIds:", tokenIds);
        console.log("amounts:", amounts);
        console.log("contractAddresses:", contractAddresses);
        console.log("merkleProofs:", merkleProofs);

        return {
            success: true,
            tokenIds: tokenIds,
            amounts: amounts,
            contractAddresses: contractAddresses,
            merkleProofs: merkleProofs
        }

    } catch (error) {
        console.error("Error:", error);
        return { error: error.message };
    }
}
