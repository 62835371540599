import React, { useEffect } from 'react'
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from 'components/utils/Spinner'

// Constants
import { MARKETPLACE_ADDRESS, MARKETPLACE_ABI } from 'constants/BlockchainConstants'

function CloseAuctionButton({ listingId, closeFor }) {
  
  // Close Auction
  const {
    data: closeAuctionHash,
    writeContract: closeAuction,
    isPending: isClosingAuction,
    isError: isClosingAuctionError,
  } = useWriteContract()
  console.log('closeAuctionHash:', closeAuctionHash)
  const {
    isSuccess: closingAuctionSuccess,
    isError: closingAuctionError,
    isLoading: closingAuctionLoading,
  } = useWaitForTransactionReceipt({ hash: closeAuctionHash })

  // Close Auction
  const handleCloseAuction = () => {
    closeAuction({
      address: MARKETPLACE_ADDRESS,
      abi: MARKETPLACE_ABI,
      functionName: 'closeAuction',
      args: [listingId, closeFor],
    })
  }

  // Close Auction
  useEffect(() => {
    if (closingAuctionSuccess) {
      toast.success('Auction has been closed!')
    } else if (closingAuctionError || isClosingAuctionError) {
      toast.error('Error while closing the auction')
    }
  }, [closingAuctionSuccess, closingAuctionError, isClosingAuctionError])

  return (
    <>
      <button
        onClick={handleCloseAuction}
        className={`bg-secondary py-1 px-3 rounded-lg mr-2.5 ${
          isClosingAuction ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        disabled={isClosingAuction}
      >
        <span className="text-base font-bold text-bt">Close Auction</span>
      </button>
      {(isClosingAuction || closingAuctionLoading) && <Spinner />}
      {closingAuctionSuccess && <Navigate to="/nfts" />}
    </>
  )
}

export default CloseAuctionButton
