import React from 'react'

function SmallLoader() {
  return (
    <div className={`w-8 animate-spin z-5`}>
      <img className="w-full" src="/assets/images/loading.png" alt="loading...." />
    </div>
  )
}

export default SmallLoader
