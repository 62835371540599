import React from 'react'

const ChevronDownIcon = ({ color = 'white', width = 20, height = 20 }) => {
  return (
    <div>
      <svg width={`${width}`} height={`${height}`} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 7L13 1" stroke={`${color}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}

export default ChevronDownIcon
