import React, { useState } from 'react'

function ListingTypeSelector({ handleListingType }) {
  const [isChecked, setIsChecked] = useState(false)

  const checkHandler = () => {
    setIsChecked(!isChecked)
    
    if (!isChecked) {
      console.log("Auction")
      handleListingType(1)
    } else {
      console.log("Direct Listing")
      handleListingType(0)
    }
  }

  return (
    <div className="flex items-center w-full space-x-3">
      <label className="relative items-center cursor-pointer">
        <input onChange={checkHandler} type="checkbox" checked={isChecked} className="sr-only peer" />
        <div className="w-16 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-carbifyOrange dark:peer-focus:ring-carbifyOrange rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5px after:left-4px after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-8 after:transition-all dark:border-gray-600 peer-checked:bg-carbifyOrange"></div>
      </label>
      <p className="text-lg font-medium">Allow bids</p>
    </div>
  )
}

export default ListingTypeSelector
