"use client"
import React from 'react';
import { DualOverviewCard } from './OverviewCards';
import MapCard from './MapCard';

export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Math.round(number));
};

const MapAndCardsContainer = ({ 
    overviewData, 
    globalClusterData, 
    selectedCluster, 
    setSelectedCluster, 
    selectedTree,
    setSelectedTree,
    detailsData,
    userCertificates,
    selectedFilters,
    setSelectedFilters,
    isSmallScreen
}) => {
    return (
        <div className="w-full">
            <div>
                <MapCard
                    globalClusterData={globalClusterData}
                    selectedCluster={selectedCluster}
                    setSelectedCluster={setSelectedCluster}
                    selectedTree={selectedTree}
                    setSelectedTree={setSelectedTree}
                    detailsData={detailsData}
                    userCertificates={userCertificates}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    isSmallScreen={isSmallScreen}
                />
            </div>
            {/* <div className="grid grid-cols-2 gap-4 mt-4">
                <DualOverviewCard 
                    title="Total CO2 absorbed"
                    value1={overviewData === undefined ? undefined : overviewData?.total_aco2_generated.toFixed(2)}
                    tooltipText="The total amount of CO2 absorbed by the trees planted by Carbify. This metric measures the carbon sequestration and as we call them Carbon Debits."
                />
                <DualOverviewCard 
                    title="CO2 for carbon offset"
                    value1={overviewData === undefined ? undefined : overviewData?.total_aco2_burned_in_certificates.toFixed(2)}
                    tooltipText="The total amount of CO2 used for carbon offsets. This number shows the carbon debits utilized for issuing carbon reduction certificates."
                />
            </div> */}
        </div>
    )
};

export default MapAndCardsContainer;
