import { getERC721Data } from './getERC721Data'
import { ALL_NFTREE_ADDRESSES } from "constants/BlockchainConstants"

export const getAllTreeData = async (walletAddress) => {
    let treeData = []
    for (const erc721Address of ALL_NFTREE_ADDRESSES) {
        if (erc721Address === undefined) continue;
        const erc721Data = await getERC721Data(walletAddress, erc721Address)
        if (erc721Data && erc721Data.token_data) {
            treeData = treeData.concat(erc721Data.token_data)
        }
    }
    return treeData
}