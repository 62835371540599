import React from 'react'
import TradeStationHeader from './TradeStationHeader'
import TradeStationMain from './TradeStationMain'

function TradeStation({ handleBuyNowModal, handleMakeOfferModal, handleUpdateListingModal }) {
  return (
    <div className="w-full bg-darkGreen bg-opacity-70 border rounded-lg">
      <div className="p-4">
        <div className="mb-3">
          <TradeStationHeader />
        </div>
        <TradeStationMain
          handleBuyNowModal={handleBuyNowModal}
          handleMakeOfferModal={handleMakeOfferModal}
          handleUpdateListingModal={handleUpdateListingModal}
        />
      </div>
    </div>
  )
}

export default TradeStation
