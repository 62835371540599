import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useConnect } from 'wagmi'
import { Buffer } from 'buffer'
import { setWalletAddress } from '../../features/wallet/walletSlice'
import TrustWalletIcon from '../../icons/TrustWalletIcon'
import MetamaskIcon from '../../icons/MetamaskIcon'
import CoinbaseIcon from '../../icons/CoinbaseIcon'
import WalletConnectIcon from '../../icons/WalletConnectIcon'
import { getUser } from 'features/authentication/authSlice'
import { useWeb3Modal } from '@web3modal/wagmi/react'

function WalletOptions() {
  if (!window.Buffer) {
    window.Buffer = Buffer
  }

  const { open } = useWeb3Modal()

  const { address } = useAccount()
  const { connect, connectors } = useConnect()
  const reversedConnectors = [...connectors].reverse()
  const userInfo = useSelector(state => state.authentication.userInfo)
  const dispatch = useDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const [selectedConnector, setSelectedConnector] = useState(null)

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  useEffect(() => {
    if (address) {
      dispatch(setWalletAddress(address))
      if (userInfo && !userInfo.linked_wallet) {
        setShowPopup(true)
      } else {
        setShowPopup(false)
      }
    }
  }, [address, userInfo, dispatch])

  const handleConnectClick = async connector => {
    setSelectedConnector(connector)
    if (connector.id === 'walletConnect') {
      open()
    }
    if (userInfo && userInfo.linked_wallet) {
      handleConfirm(connector)
    } else {
      setShowPopup(true)
    }
  }

  const handleConfirm = async (connector = selectedConnector) => {
    if (connector) {
      connect({ connector })
      setShowPopup(false)
    }
  }

  const handleCancel = () => {
    setShowPopup(false)
    setSelectedConnector(null)
  }

  return (
    <div className="space-y-3">
      {reversedConnectors.map(connector => (
        <li key={connector.id}>
          <button
            className="w-full flex items-center justify-between p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
            onClick={() => handleConnectClick(connector)}
          >
            <div className="flex items-center">
              {connector.name === 'MetaMask' && <MetamaskIcon />}
              {connector.id === 'coinbaseWalletSDK' && <CoinbaseIcon />}
              {connector.id === 'walletConnect' && <WalletConnectIcon />}
              {connector.name === 'Trust Wallet' && <TrustWalletIcon />}
              <span className="flex-1 ml-3 whitespace-nowrap">{connector.name}</span>
            </div>
            {connector.name === 'MetaMask' && (
              <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                Popular
              </span>
            )}
          </button>
        </li>
      ))}
      {showPopup && (
        <div className="fixed inset-0 flex justify-center items-center">
          <div className="bg-green p-7 rounded-lg border-4 border-carbifyOrange">
            <p className="text-white text-center text-xl font-semibold px-4 py-8 max-w-lg mx-auto">
              After proceeding, you will be required to permanently link your wallet to this account. Once linked, this
              connection cannot be altered or changed
            </p>
            <div className="flex justify-center items-center gap-3 mt-4">
              <button
                className="px-4 py-2 bg-carbifyOrange text-black text-lg font-montserrat rounded hover:bg-carbifyOrangeDarker hover:text-white font-bold"
                onClick={() => handleConfirm()}
              >
                Ok
              </button>
              <button
                className="px-4 py-2 bg-carbifyOrange text-black text-lg font-montserrat rounded hover:bg-carbifyOrangeDarker hover:text-white font-bold"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default WalletOptions
