import React, { lazy, Suspense, useState } from "react";
import { CustomCheckbox } from "./CustomCheckbox";

const Map = lazy(() => import("./Map"));

const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const formatCoordinates = (geo_location) => {
    if (!geo_location) return "-";
    const [latitude, longitude] = geo_location
        .split(" ")
        .map((coord) => parseFloat(coord).toFixed(6));
    return `${latitude} ${longitude}`;
};

const MapCard = ({
    globalClusterData,
    selectedCluster,
    setSelectedCluster,
    selectedTree,
    setSelectedTree,
    detailsData,
    userCertificates,
    selectedFilters,
    setSelectedFilters,
    isSmallScreen,
}) => {
    const [showFilters, setShowFilters] = useState(false);

    return isSmallScreen ? (
        <>
            <div className="relative w-full h-[677px] bg-darkGreen bg-opacity-70 rounded-lg overflow-hidden border border-solid border-white">
                <div className="absolute w-[91%] h-[229px] top-[430px] left-3.5 bg-basewhite rounded-xl overflow-hidden border border-solid border-white z-[1]">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Map
                            globalClusterData={globalClusterData}
                            selectedCluster={selectedCluster}
                            setSelectedCluster={setSelectedCluster}
                        />
                    </Suspense>
                </div>
                <div className="flex w-full items-start justify-center gap-[19px] absolute top-[375px]">
                    <button className="all-[unset] box-border flex w-[136px] items-center justify-center gap-1 px-3 py-2.5 relative rounded-lg overflow-hidden border-[0.5px] border-solid border-white">
                        <img
                            className="relative w-[18px] h-[18px]"
                            alt="Chevron left"
                            src="left.svg"
                        />
                        <div
                            className="relative w-fit [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap"
                            onClick={() => {
                                if (!selectedCluster.trees) {
                                    const selectedId = Number(
                                        selectedTree?.nftree_id,
                                    );
                                    const selectedBatch = Number(
                                        selectedTree?.batch_number,
                                    );

                                    const index = detailsData.findIndex(
                                        (tree) =>
                                            Number(tree.nftree_id) ===
                                                selectedId &&
                                            Number(tree.batch_number) ===
                                                selectedBatch,
                                    );

                                    if (index !== -1) {
                                        // Check if selectedTree exists in detailsData
                                        const newIndex =
                                            (index - 1 + detailsData.length) %
                                            detailsData.length; // Ensure the index wraps around correctly
                                        setSelectedTree(detailsData[newIndex]);
                                    }
                                } else if (selectedCluster?.trees) {
                                    // Adjust for trees with geo_location
                                    const selectedGeo =
                                        selectedTree.geo_location;
                                    const treeIndex =
                                        selectedCluster.trees.findIndex(
                                            (tree) =>
                                                tree.geo_location ===
                                                selectedGeo,
                                        );

                                    if (treeIndex !== -1) {
                                        const newIndex =
                                            (treeIndex -
                                                1 +
                                                selectedCluster.trees.length) %
                                            selectedCluster.trees.length;
                                        setSelectedTree(
                                            selectedCluster.trees[newIndex],
                                        );
                                    }
                                }
                            }}
                        >
                            Previous
                        </div>
                    </button>
                    <button className="all-[unset] box-border flex w-[136px] items-center justify-center gap-1 px-3 py-2.5 relative rounded-lg overflow-hidden border-[0.5px] border-solid border-white">
                        <div
                            className="relative w-fit [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap"
                            onClick={() => {
                                if (!selectedCluster.trees) {
                                    const selectedId = Number(
                                        selectedTree?.nftree_id,
                                    );
                                    const selectedBatch = Number(
                                        selectedTree.batch_number,
                                    );

                                    const index = detailsData.findIndex(
                                        (tree) =>
                                            Number(tree.nftree_id) ===
                                                selectedId &&
                                            Number(tree.batch_number) ===
                                                selectedBatch,
                                    );

                                    if (index !== -1) {
                                        // Check if selectedTree exists in detailsData
                                        const newIndex =
                                            (index + 1) % detailsData.length; // Ensure the index wraps around correctly
                                        setSelectedTree(detailsData[newIndex]);
                                    }
                                } else if (selectedCluster?.trees) {
                                    // Adjust for trees with geo_location
                                    const selectedGeo =
                                        selectedTree.geo_location;
                                    const treeIndex =
                                        selectedCluster.trees.findIndex(
                                            (tree) =>
                                                tree.geo_location ===
                                                selectedGeo,
                                        );

                                    if (treeIndex !== -1) {
                                        const newIndex =
                                            (treeIndex + 1) %
                                            selectedCluster.trees.length;
                                        setSelectedTree(
                                            selectedCluster.trees[newIndex],
                                        );
                                    }
                                }
                            }}
                        >
                            Next
                        </div>
                        <img
                            className="relative w-[18px] h-[18px]"
                            alt="Chevron left"
                            src="right.svg"
                        />
                    </button>
                </div>
                <div
                    className="flex w-[91%] items-start gap-[80%] px-3 py-1.5 absolute top-[68px] left-4 rounded-lg border-[0.5px] border-solid border-orange cursor-pointer"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <div className="inline-flex items-start gap-1.5 relative flex-[0_0_auto]">
                        <img
                            className="!relative !w-[18px] !h-[18px]"
                            alt="Filter lines"
                            src="filter.svg"
                        />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-white text-xs tracking-[0.06px] leading-[18px] whitespace-nowrap">
                            Filters
                        </div>
                    </div>
                    {showFilters && (
                        <div
                            className="absolute top-full right-0 w-full p-3 bg-[#232323] rounded-lg shadow-lg z-10 border border-solid border-orange mt-2 select-none"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-bold text-[#ffffff] text-sm tracking-[0.08px] leading-6 whitespace-nowrap">
                                By certificate
                            </div>
                            <div className="grid grid-cols-2 text-sm">
                                {userCertificates &&
                                userCertificates.length > 0 ? (
                                    userCertificates.map(
                                        (certificate, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-1.5 mt-2 mb-2"
                                            >
                                                <CustomCheckbox
                                                    label={certificate.name}
                                                    selectedFilters={
                                                        selectedFilters
                                                    }
                                                    setSelectedFilters={
                                                        setSelectedFilters
                                                    }
                                                />
                                            </div>
                                        ),
                                    )
                                ) : (
                                    <div className="relative w-fit [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#ffffff] tracking-[0.08px] leading-6 whitespace-nowrap">
                                        No certificates available
                                    </div>
                                )}
                            </div>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-bold text-[#ffffff] text-sm tracking-[0.08px] leading-6 whitespace-nowrap">
                                By tree
                            </div>
                            <div className="grid grid-cols-2 text-sm">
                                <div className="flex items-center gap-1.5 mt-2 mb-2">
                                    <CustomCheckbox
                                        label={"Owned trees"}
                                        selectedFilters={selectedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <img
                        className="!relative !w-[18px] !h-[18px] right-5"
                        alt="Chevron down"
                        src={showFilters ? "up.svg" : "down.svg"}
                    />
                </div>
                <div className="absolute top-[23px] w-full text-center [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-base tracking-[0] leading-[normal]">
                    <span>Map card</span>
                </div>
                <div className="flex flex-col w-[90%] items-start gap-3 absolute top-[111px] left-4">
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Tree ID
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.nftree_id === undefined
                                ? "-"
                                : "#" + selectedTree.nftree_id}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Planted by
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Carbify
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Audited by
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            GCS
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            CO2 claimed by the owner
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.aco2_claimed === undefined
                                ? "-"
                                : selectedTree.aco2_claimed?.toFixed(2)}
                        </div>
                    </div>
                    {selectedFilters && selectedFilters.length === 0 && (
                        <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                                CO2 used for carbon offsets
                            </div>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                                {selectedTree?.aco2_burned_in_certificates ===
                                undefined
                                    ? "-"
                                    : selectedTree.aco2_burned_in_certificates?.toFixed(
                                        2,
                                    )}
                            </div>
                        </div>
                    )}
                    {selectedFilters && selectedFilters.length > 0 && (
                        <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                                CO2 for selected cert(s)
                            </div>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                                {selectedTree?.aco2_burned_per_certificate === undefined
                                    ? "-"
                                    : selectedFilters.reduce((acc, certificate) => {
                                        const key = 
                                            certificate === 'DEMO 1' ? 'Carbon Reduction Certificate 13' :
                                            certificate === 'DEMO 2' ? 'Carbon Reduction Certificate 14' :
                                            certificate;
                                        
                                        // Check if the key exists in the object
                                        const value = selectedTree.aco2_burned_per_certificate[key];

                                        // If the key exists and has a valid number, add it to the accumulator
                                        return acc + (value !== undefined ? value : 0);
                                    }, 0).toFixed(2)
                                }
                            </div>
                        </div>
                    )}
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            CO2 available to claim
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.aco2_unclaimed === undefined
                                ? "-"
                                : selectedTree.aco2_unclaimed?.toFixed(2)}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            GEO Location
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.geo_location === undefined
                                ? "-"
                                : formatCoordinates(selectedTree.geo_location)}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Batch number
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.batch_number === undefined
                                ? "-"
                                : selectedTree.batch_number}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            Planting date
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-sm tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.start_time === undefined
                                ? "-"
                                : formatDate(selectedTree?.start_time)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="relative w-[100%] h-[392px] bg-darkGreen bg-opacity-70 rounded-lg overflow-hidden border border-solid border-white">
            <div>
                <div className="absolute top-[30px] left-[30px] [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-base tracking-[0] leading-[normal]">
                    Map card
                </div>
                <div className="absolute 2xl:w-[63%] w-[53%] h-[344px] top-6 2xl:left-[35%] left-[45%] bg-basewhite rounded-xl overflow-hidden border border-solid border-white z-[1]">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Map
                            globalClusterData={globalClusterData}
                            selectedCluster={selectedCluster}
                            setSelectedCluster={setSelectedCluster}
                        />
                    </Suspense>
                </div>
            </div>
            <div className="w-[40%]">
                <div className="flex flex-col 2xl:w-[28%] w-[35%] items-start gap-2.5 absolute top-[70px] left-[30px]">
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Tree ID
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.nftree_id === undefined
                                ? "-"
                                : "#" + selectedTree.nftree_id}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Planted by
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Carbify
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Audited by
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            GCS
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <p className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                            CO2 claimed by the owner
                        </p>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.aco2_claimed === undefined
                                ? "-"
                                : selectedTree.aco2_claimed?.toFixed(2)}
                        </div>
                    </div>
                    { selectedFilters && selectedFilters.length === 0 && (
                        <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                            <p className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                                CO2 used for carbon offsets
                            </p>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                                {selectedTree?.aco2_burned_in_certificates ===
                                undefined
                                    ? "-"
                                    : selectedTree.aco2_burned_in_certificates?.toFixed(
                                        2,
                                    )}
                            </div>
                        </div>
                    )}
                    { selectedFilters && selectedFilters.length > 0 && (
                        <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                            <p className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                                CO2 for selected cert(s)
                            </p>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                                {selectedTree?.aco2_burned_per_certificate === undefined
                                    ? "-"
                                    : selectedFilters.reduce((acc, certificate) => {
                                        const key = 
                                            certificate === 'DEMO 1' ? 'Carbon Reduction Certificate 13' :
                                            certificate === 'DEMO 2' ? 'Carbon Reduction Certificate 14' :
                                            certificate;
                                        
                                        // Check if the key exists in the object
                                        const value = selectedTree.aco2_burned_per_certificate[key];

                                        // If the key exists and has a valid number, add it to the accumulator
                                        return acc + (value !== undefined ? value : 0);
                                    }, 0).toFixed(2)
                                }
                            </div>
                        </div>
                    )}
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            CO2 available to claim
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.aco2_unclaimed === undefined
                                ? "-"
                                : selectedTree.aco2_unclaimed?.toFixed(2)}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <p className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            GEO Location
                        </p>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.geo_location === undefined
                                ? "-"
                                : formatCoordinates(selectedTree.geo_location)}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Batch number
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.batch_number === undefined
                                ? "-"
                                : selectedTree.batch_number}
                        </div>
                    </div>
                    <div className="flex items-center justify-between self-stretch w-full relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            Planting date
                        </div>
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-bold text-white text-xs tracking-[0] leading-[18px] whitespace-nowrap">
                            {selectedTree?.start_time === undefined
                                ? "-"
                                : formatDate(selectedTree?.start_time)}
                        </div>
                    </div>
                </div>
                <div className="inline-flex items-start justify-center gap-5 absolute top-[327.5px] left-[30px] 2xl:w-[28%] w-[35%]">
                    <button
                        className="all-[unset] box-border flex w-[50%] items-center justify-center gap-1 px-3 py-2.5 relative rounded-lg overflow-hidden border-[0.5px] border-solid border-white"
                        onClick={() => {
                            if (!selectedCluster.trees) {
                                const selectedId = Number(
                                    selectedTree?.nftree_id,
                                );
                                const selectedBatch = Number(
                                    selectedTree?.batch_number,
                                );

                                const index = detailsData.findIndex(
                                    (tree) =>
                                        Number(tree.nftree_id) === selectedId &&
                                        Number(tree.batch_number) ===
                                            selectedBatch,
                                );

                                if (index !== -1) {
                                    // Check if selectedTree exists in detailsData
                                    const newIndex =
                                        (index - 1 + detailsData.length) %
                                        detailsData.length; // Ensure the index wraps around correctly
                                    setSelectedTree(detailsData[newIndex]);
                                }
                            } else if (selectedCluster?.trees) {
                                // Adjust for trees with geo_location
                                const selectedGeo = selectedTree.geo_location;
                                const treeIndex =
                                    selectedCluster.trees.findIndex(
                                        (tree) =>
                                            tree.geo_location === selectedGeo,
                                    );

                                if (treeIndex !== -1) {
                                    const newIndex =
                                        (treeIndex -
                                            1 +
                                            selectedCluster.trees.length) %
                                        selectedCluster.trees.length;
                                    setSelectedTree(
                                        selectedCluster.trees[newIndex],
                                    );
                                }
                            }
                        }}
                    >
                        <img
                            className="relative w-[18px] h-[18px]"
                            alt="Chevron left"
                            src="left.svg"
                        />
                        <div className="relative w-fit [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                            Previous
                        </div>
                    </button>
                    <button
                        className="all-[unset] box-border flex w-[50%] items-center justify-center gap-1 px-3 py-2.5 relative rounded-lg overflow-hidden border-[0.5px] border-solid border-white"
                        onClick={() => {
                            if (!selectedCluster.trees) {
                                const selectedId = Number(
                                    selectedTree?.nftree_id,
                                );
                                const selectedBatch = Number(
                                    selectedTree.batch_number,
                                );

                                const index = detailsData.findIndex(
                                    (tree) =>
                                        Number(tree.nftree_id) === selectedId &&
                                        Number(tree.batch_number) ===
                                            selectedBatch,
                                );

                                if (index !== -1) {
                                    // Check if selectedTree exists in detailsData
                                    const newIndex =
                                        (index + 1) % detailsData.length; // Ensure the index wraps around correctly
                                    setSelectedTree(detailsData[newIndex]);
                                }
                            } else if (selectedCluster?.trees) {
                                // Adjust for trees with geo_location
                                const selectedGeo = selectedTree.geo_location;
                                const treeIndex =
                                    selectedCluster.trees.findIndex(
                                        (tree) =>
                                            tree.geo_location === selectedGeo,
                                    );

                                if (treeIndex !== -1) {
                                    const newIndex =
                                        (treeIndex + 1) %
                                        selectedCluster.trees.length;
                                    setSelectedTree(
                                        selectedCluster.trees[newIndex],
                                    );
                                }
                            }
                        }}
                    >
                        <div className="relative w-fit [font-family:'Poppins-SemiBold',Helvetica] font-semibold text-white text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                            Next
                        </div>
                        <img
                            className="relative w-[18px] h-[18px]"
                            alt="Chevron left"
                            src="right.svg"
                        />
                    </button>
                </div>
                <div
                    className="inline-flex items-start gap-5 px-3 py-1.5 absolute top-6 3xl:left-[17%] 2xl:left-[15%] left-[22%] rounded-lg border-[0.5px] border-solid border-orange cursor-pointer"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <div className="inline-flex items-start gap-1.5 relative">
                        <img
                            className="!relative !w-[18px] !h-[18px]"
                            alt="Filter lines"
                            src="filter.svg"
                        />
                        <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Medium',Helvetica] font-medium text-white text-xs tracking-[0.06px] leading-[18px] whitespace-nowrap">
                            Filters
                        </div>
                    </div>
                    {showFilters && (
                        <div
                            className="absolute top-full right-0 w-[210%] p-3 bg-[#232323] rounded-lg shadow-lg z-10 border border-solid border-orange mt-2 select-none max-h-[250px] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-bold text-[#ffffff] text-sm tracking-[0.08px] leading-6 whitespace-nowrap">
                                By certificate
                            </div>
                            <div className="grid grid-cols-2 text-sm">
                                {userCertificates &&
                                userCertificates.length > 0 ? (
                                    userCertificates.map(
                                        (certificate, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-1 mt-2 mb-2"
                                            >
                                                <CustomCheckbox
                                                    label={certificate.name}
                                                    selectedFilters={
                                                        selectedFilters
                                                    }
                                                    setSelectedFilters={
                                                        setSelectedFilters
                                                    }
                                                />
                                            </div>
                                        ),
                                    )
                                ) : (
                                    <div className="relative w-fit [font-family:'Montserrat-Regular',Helvetica] font-normal text-[#ffffff] tracking-[0.08px] leading-6 whitespace-nowrap">
                                        No certificates available
                                    </div>
                                )}
                            </div>
                            <div className="relative w-fit mt-[-1.00px] [font-family:'Montserrat-Medium',Helvetica] font-bold text-[#ffffff] text-sm tracking-[0.08px] leading-6 whitespace-nowrap">
                                By tree
                            </div>
                            <div className="grid grid-cols-2 text-sm">
                                <div className="flex items-center gap-1 mt-2 mb-2">
                                    <CustomCheckbox
                                        label={'Owned trees'} 
                                        selectedFilters={selectedFilters} 
                                        setSelectedFilters={setSelectedFilters}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <img
                        className="!relative !w-[18px] !h-[18px]"
                        alt="Chevron down"
                        src={showFilters ? "up.svg" : "down.svg"}
                    />
                </div>
            </div>
        </div>
    );
};

export default MapCard;
