import axios from "axios";
import { Config } from "../../config.js";

const authApi = Config().auth_api;

export const submitCertificateRequest = async (requestData) => {

    console.log('requestData:', requestData)

    try {
        const response = await axios.post(`${authApi}api/v1/user-profile/certificate-request`, requestData, {
            withCredentials: true,
        });
    
        if (response.status >= 200) {
            return response.data;
        } else {
            console.error("Response Error:", response.status);
            return null;
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
