import { http, createConfig } from 'wagmi'
import { polygon, mainnet, polygonAmoy } from 'wagmi/chains'
import { walletConnect, coinbaseWallet } from 'wagmi/connectors'
import { createWeb3Modal } from '@web3modal/wagmi/react'

export const projectId = 'e1144b18d1be4c886d0dc69043e21bc6'

export const metadata = {
  name: 'Carbify',
  description: 'Carbify - Carbon Footprint Calculator',
  icon: 'https://carbify.vercel.app/logo.png',
  site: 'https://carbify.vercel.app',
}

export const chains = [polygon, mainnet, polygonAmoy]

export const config = createConfig({
  chains,
  connectors: [
    coinbaseWallet({ appName: 'carbify' }),
    walletConnect({ projectId, showQrModal: false }),
  ],
  transports: {
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
  },
})

createWeb3Modal({
  wagmiConfig: config,
  // theme: {
  //   background: 'white',
  //   main: 'black',
  //   secondary: 'gray',
  //   border: 'black',
  //   hover: 'gray',
  // },
  projectId: projectId,
  enableAnalytics: true,
  enableOnramp: true,
})
