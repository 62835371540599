import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const GENESIS_ADDRESS = `${Config().contract_addresses.genesis_address}`.toLowerCase();
const RARE_ADDRESS = `${Config().contract_addresses.rare_address}`.toLowerCase();
const STANDARD_ADDRESS = `${Config().contract_addresses.standard_address}`.toLowerCase();

const LANDPLOT_ABI =[
    {
        "inputs": [
        {
            "internalType": "uint256",
            "name": "plotId",
            "type": "uint256"
        }
        ],
        "name": "getAvailablePlotCapacity",
        "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

const plotTitleToPlotAddress = {
    "Genesis": process.env.REACT_APP_GENESIS_PLOT_ADDRESS.toLowerCase(),
    "Rare": process.env.REACT_APP_RARE_PLOT_ADDRESS.toLowerCase(),
    "Standard": process.env.REACT_APP_STANDARD_PLOT_ADDRESS.toLowerCase(),
};

export const stakeTrees = async (amount, treeData, plotData, plotTitle) => {
    let contractAddress = null;
    if (plotTitle === "Genesis") {
        contractAddress = GENESIS_ADDRESS;
    } else if (plotTitle === "Rare") {
        contractAddress = RARE_ADDRESS;
    } else if (plotTitle === "Standard") {
        contractAddress = STANDARD_ADDRESS;
    }

    try {
        const web3Provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(web3Provider)
        const multicall = new Multicall({
            multicallAddress : Config().contract_addresses.multi_call,
            provider: web3Provider
        });
        const web3LandplotContract = new web3.eth.Contract(LANDPLOT_ABI, contractAddress);

        // Filter trees that are not staked
        let lockedAndNotStakedTrees = treeData.filter(tree => !tree.is_staked);

        // Sort trees by locked status, with locked trees first
        lockedAndNotStakedTrees.sort((a, b) => {
            return (b.is_locked === true ? 1 : 0) - (a.is_locked === true ? 1 : 0);
        });

        if (amount > lockedAndNotStakedTrees.length) {
            return { error: "Not enough trees!" };
        }

        // Limit the number of trees to the requested amount
        const amountOfTrees = Math.min(amount, lockedAndNotStakedTrees.length);
        const selectedTrees = lockedAndNotStakedTrees.slice(0, amountOfTrees);

        const selectedTreeIds = selectedTrees.map(tree => Number(tree.token_id));
        const selectedTreeAddresses = selectedTrees.map(tree => tree.contract_address);

        // Filter plots based on plotTitle and contract address
        const filteredPlots = plotData.filter(plot => plot.contract_address === plotTitleToPlotAddress[plotTitle]);

        const result = await multicall.aggregate(
            filteredPlots.map(plot => web3LandplotContract.methods.getAvailablePlotCapacity(plot.token_id)) 
        );
        const plotsWithAvailableCapacity = filteredPlots.map((plot, index) => {
            return { ...plot, availablePlotCapacity: Number(result[index]) };
        });

        plotsWithAvailableCapacity.sort((a, b) => b.availablePlotCapacity - a.availablePlotCapacity); // Sort plots by available capacity from highest to lowest

        let selectedPlotIds = [];
        let selectedPlotAddresses = [];
        let amountProxy = amount;

        for (let i = 0; i < plotsWithAvailableCapacity.length; i++) {
            const plot = plotsWithAvailableCapacity[i];
            if (plot.availablePlotCapacity >= amountProxy) {
                selectedPlotIds = selectedPlotIds.concat(Array(amountProxy).fill(Number(plot.token_id)));
                selectedPlotAddresses = selectedPlotAddresses.concat(Array(amountProxy).fill(plot.contract_address));
                break;
            } else {
                amountProxy -= plot.availablePlotCapacity;
                selectedPlotIds = selectedPlotIds.concat(Array(plot.availablePlotCapacity).fill(Number(plot.token_id)));
                selectedPlotAddresses = selectedPlotAddresses.concat(Array(plot.availablePlotCapacity).fill(plot.contract_address));
            }
        }

        if (selectedPlotIds.length < amount) {
            return { error: "Not enough available plot capacity" };
        }

        let MAX_TREES_PER_TX = Number(Config().max_trees_per_tx)
        let query = []

        if (amountOfTrees <= MAX_TREES_PER_TX) {
          MAX_TREES_PER_TX = amountOfTrees
        }

        for (let i = 0; i < amountOfTrees; i += MAX_TREES_PER_TX) {
            const treeIdsChunk = selectedTreeIds.slice(i, i + MAX_TREES_PER_TX)
            const treeAddressesChunk = selectedTreeAddresses.slice(i, i + MAX_TREES_PER_TX)

            // Assuming that selectedPlotIds and selectedPlotAddresses are prepared beforehand and are the same length as the tree arrays.
            const plotIdsChunk = selectedPlotIds.slice(i, i + MAX_TREES_PER_TX)
            const plotAddressesChunk = selectedPlotAddresses.slice(i, i + MAX_TREES_PER_TX)

            if (
                treeIdsChunk.length === 0 ||
                treeAddressesChunk.length === 0 ||
                plotIdsChunk.length === 0 ||
                plotAddressesChunk.length === 0
            ) {
                break
            }

            const args = [treeIdsChunk, treeAddressesChunk, plotIdsChunk, plotAddressesChunk]
            query.push({args: args})
        }

        return { 
            success: true, 
            stakedTreeIds: selectedTreeIds, 
            selectedTreeAddresses: selectedTreeAddresses, 
            stakedPlotIds: selectedPlotIds, 
            selectedPlotAddresses: selectedPlotAddresses, 
            stakedTreesCount: amountOfTrees,
            args: query
        };
    } catch (error) {
        console.error("Error:", error);
        return { error: error.message };
    }
};
