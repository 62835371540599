import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ModalsWrapper from 'components/utils/ModalsWrapper'
import QuantityField from 'components/nfts/inputFields/QuantityField'
import NftreeBuyNowButton from '../buttons/NftreeBuyNowButton'
import SelectCurrency from './SelectCurrency'

// Constants
import {
  CBY_ADDRESS,
  USDC_ADDRESS,
  USDCE_ADDRESS,
  USDT_ADDRESS,
  CBY_ABI,
  USDC_ABI,
  USDT_ABI,
  CBY_DECIMALS,
  USDC_DECIMALS,
  USDT_DECIMALS,
} from 'constants/BlockchainConstants'

// Icons
import { BsInfoCircle } from 'react-icons/bs'

function NftreeBuyNowModal({
  showModal,
  setShowModal,
  title,
  image,
  pricePerToken,
  asset_contract,
  currency,
  setCurrency,
}) {
  const [currencyAddress, setCurrencyAddress] = useState('')
  const [currencyAbi, setCurrencyAbi] = useState('')
  const [currencyDecimals, setCurrencyDecimals] = useState(0)
  const [quantityToBuy, setQuantityToBuy] = useState(1)

  const currencies = [
    { name: 'USDCE', logo: '/assets/images/carbify-blue-dollar.png', address: USDCE_ADDRESS },
    { name: 'USDC', logo: '/assets/images/carbify-blue-dollar.png', address: USDC_ADDRESS },
    { name: 'USDT', logo: '/assets/images/tether.png', address: USDT_ADDRESS },
    { name: 'CBY', logo: '/assets/images/carbify-logo-marketplace.png', address: CBY_ADDRESS },
    // Add more currencies with their logos here
  ]

  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies[0].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[0]
      : currencies[1].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[1]
      : currencies[2].address.toString().toLowerCase() === currency.toLowerCase()
      ? currencies[2]
      : currencies[3],
  )

  useEffect(() => {
    if (currency.toLowerCase() === CBY_ADDRESS.toLowerCase()) {
      setCurrencyAddress(CBY_ADDRESS.toLowerCase())
      setCurrencyAbi(CBY_ABI)
      setCurrencyDecimals(CBY_DECIMALS)
      setSelectedCurrency(currencies[3])
    } else if (currency.toLowerCase() === USDC_ADDRESS.toLowerCase()) {
      setCurrencyAddress(USDC_ADDRESS)
      setCurrencyAbi(USDC_ABI)
      setCurrencyDecimals(USDC_DECIMALS)
      setSelectedCurrency(currencies[1])
    } else if (currency.toLowerCase() === USDCE_ADDRESS.toLowerCase()) {
      setCurrencyAddress(USDCE_ADDRESS)
      setCurrencyAbi(USDC_ABI)
      setCurrencyDecimals(USDC_DECIMALS)
      setSelectedCurrency(currencies[0])
    } else {
      setCurrencyAddress(USDT_ADDRESS)
      setCurrencyAbi(USDT_ABI)
      setCurrencyDecimals(USDT_DECIMALS)
      setSelectedCurrency(currencies[2])
    }
  }, [currency])

  useEffect(() => {
    if (quantityToBuy > 1000) {
      toast.error("Can't buy more than 1000 in a single transaction")
    }
  }, [quantityToBuy])

  return (
    <ModalsWrapper title={`Buy Now ${title}`} showModal={showModal} setShowModal={setShowModal}>
      <div className="overflow-y-auto max-h-[80vh]">
        {/* Nft image & title */}
        <div className="flex w-full space-x-6 pb-4 mb-3 border-b">
          <div className="w-16 h-24">
            <img className="rounded-[5px]" src={image} />
          </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-xl font-bold">{title}</h3>
            <p className="text-base font-semibold">Carbify NFTrees</p>
          </div>
        </div>
        {/*  */}
        <div className="mb-2.5">
          <QuantityField label={'Number of items'} max={1000} quantity={quantityToBuy} setQuantity={setQuantityToBuy} />
        </div>
        {/*  */}
        <div className="grid grid-cols-1 mb-2.5">
          <div className="flex items-center space-x-2">
            <BsInfoCircle />
            <p className="text-sm font-normal">You can buy Max 1000 in a single transaction</p>
          </div>
        </div>
        <div className="mb-2.5">
          <div className="col-span-2">
            <SelectCurrency disabled={false} currency={currency} setCurrency={setCurrency} />
          </div>
        </div>
        {/*  */}
        <div className="w-full flex justify-between mb-2.5">
          <p className="text-[22px] font-semibold">Total price</p>
          <p className="text-[22px] font-semibold">
            {pricePerToken * quantityToBuy} {selectedCurrency.name}
          </p>
        </div>
        {/*  */}
        <div>
          <NftreeBuyNowButton
            disabled={quantityToBuy > 1000}
            currencyAddress={currencyAddress}
            currencyAbi={currencyAbi}
            currencyDecimals={currencyDecimals}
            totalPrice={pricePerToken * quantityToBuy}
            quantityToBuy={quantityToBuy}
            asset_contract={asset_contract}
            setShowModal={setShowModal}
          />
        </div>
      </div>
    </ModalsWrapper>
  )
}

export default NftreeBuyNowModal
