"use client";

import React from "react";
import { useReducer } from "react";
import { Wallet02 } from "./icons/Wallet02";

export const MainButton = ({
  link = true,
  property1,
  className,
  icon = <Wallet02 className="!relative !w-6 !h-6" color="#024728" />,
  text = "Connect wallet",
  visible = true,
  divClassName,
  onClick,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "normal-state",
  });

  return (
    <div
      className={`inline-flex items-center gap-2.5 px-9 py-3 overflow-hidden rounded-lg relative ${
        state.property1 === "active" ? "bg-green" : "bg-orange"
      } ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      // onClick={() => {
      //   dispatch("click");
      // }}
      onClick={onClick}
    >
      <div
        className={`w-[284px] -left-7 h-16 absolute ${
          state.property1 === "hover" ? "top-[-9px]" : state.property1 === "active" ? "-top-1" : "top-[53px]"
        } ${state.property1 === "active" ? "bg-[#024728]" : "bg-green"}`}
      />
      {visible && <>{icon}</>}

      <div
        className={`[font-family:'Montserrat',Helvetica] w-fit mt-[-1.00px] tracking-[0.08px] text-base relative font-bold whitespace-nowrap leading-6 ${
          state.property1 === "normal-state" ? "text-green" : "text-white"
        } ${divClassName}`}
      >
        {text}
      </div>
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "normal-state") {
    switch (action) {
      case "mouse_enter":
        return {
          property1: "hover",
        };
    }
  }

  if (state.property1 === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property1: "normal-state",
        };

      case "click":
        return {
          property1: "active",
        };
    }
  }

  return state;
}
