import CardContainer from "components/containers/CardContainer";

const TotalTreesCard = ({ totalTrees, stakingRewards }) => {
    return (
        <>
        <CardContainer styles={'h-full border rounded-lg shadow-lg bg-darkGreen bg-opacity-70'}>
            <div className="flex w-full justify-between">
                <span className="text-3xl font-extrabold">NFTrees owned</span>
                <div className="absolute -top-0 rounded-bl-lg right-0 px-2 z-10 bg-white text-bt text-lg font-bold text-center rounded-tr-[7px]">{totalTrees === null ? "0" : totalTrees}</div>
            </div>
            <div className="flex flex-col w-full h-full py-2 mt-[92px]">
                <p className="text-base font-semibold">
                {' '}
                Base rewards: <br />≈{(totalTrees === null ? "0" : totalTrees) * 175} $aCO2{' '}
                </p>
                <p className="text-base font-semibold">
                {' '}
                Staking rewards: <br />≈{(stakingRewards === null ? "0" : stakingRewards)} $aCO2 <br />
                </p>
            </div>
            <img className="absolute w-52 h-52 right-0 mt-14" src="/assets/images/dashboard_nftree_card1.png" alt="nftree"></img>
        </CardContainer>
        </>
    );
};

export default TotalTreesCard;
