import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarbonUnitInput from 'components/utils/CarbonUnitInput'
import Decimal from 'decimal.js'
import TotalFootprint from './TotalFootprint'

//reducers
import { setCarbonFootprint, setPublicTransportFootprint } from 'features/footprint/footprintSlice'

//icons
import { BiRightArrowCircle } from 'react-icons/bi'
import { BiLeftArrowCircle } from 'react-icons/bi'

const PublicTransport = ({ onBack, onNext, setPage }) => {
  const CO2_EMISSION_FACTORS = {
    bus: 0.1, // 1 km bus
    coach: 0.1, // 1 km coach
    localTrain: 0.04, // 1 km local Train
    distantTrain: 0.04, // 1 km distant train
    tram: 0.03, // 1 km tram
    subway: 0.04, // 1 km subway
    taxi: 0.15, // 1 km taxi
  }

  const milesValue = 1.609
  const units = ['km', 'miles']

  const [formData, setFormData] = useState({
    bus: 0,
    coach: 0,
    localTrain: 0,
    distantTrain: 0,
    tram: 0,
    subway: 0,
    taxi: 0,
  })

  const { bus, coach, localTrain, distantTrain, tram, subway, taxi } = formData
  const [busUnit, setBusUnit] = useState(units[0])
  const [coachUnit, setCoachUnit] = useState(units[0])
  const [localTrainUnit, setLocalTrainUnit] = useState(units[0])
  const [distantTrainUnit, setDistantTrainUnit] = useState(units[0])
  const [tramUnit, setTramUnit] = useState(units[0])
  const [subwayUnit, setSubwayUnit] = useState(units[0])
  const [taxiUnit, setTaxiUnit] = useState(units[0])

  const [busCalculation, setBusCalculation] = useState(0)
  const [coachCalculation, setCoachCalculation] = useState(0)
  const [localTrainCalculation, setLocalTrainCalculation] = useState(0)
  const [distantTrainCalculation, setDistantTrainCalculation] = useState(0)
  const [tramCalculation, setTramCalculation] = useState(0)
  const [subwayCalculation, setSubwayCalculation] = useState(0)
  const [taxiCalculation, setTaxiCalculation] = useState(0)
  const [sum, setSum] = useState(0)
  const [isFirstRendering, setIsFirstRendering] = useState(true)

  const dispatch = useDispatch()
  const { carbonFootprint, publicTransportFootprint } = useSelector(state => state.footprint)
  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleNavigation = type => {
    setPage(false)
    if (type === 'Next') {
      onNext(true)
    } else {
      onBack(true)
    }
  }

  const handleCalculation = e => {
    e.preventDefault()
    const busCarbon = new Decimal(bus)
      .times(busUnit === units[0] ? CO2_EMISSION_FACTORS.bus : new Decimal(CO2_EMISSION_FACTORS.bus).times(milesValue))
      .valueOf()
    setBusCalculation(busCarbon)
    const coachCarbon = new Decimal(coach)
      .times(
        coachUnit === units[0] ? CO2_EMISSION_FACTORS.coach : new Decimal(CO2_EMISSION_FACTORS.coach).times(milesValue),
      )
      .valueOf()
    setCoachCalculation(coachCarbon)
    const localTrainCarbon = new Decimal(localTrain)
      .times(
        localTrainUnit === units[0]
          ? CO2_EMISSION_FACTORS.localTrain
          : new Decimal(CO2_EMISSION_FACTORS.localTrain).times(milesValue),
      )
      .valueOf()
    setLocalTrainCalculation(localTrainCarbon)
    const distantTrainCarbon = new Decimal(distantTrain)
      .times(
        distantTrainUnit === units[0]
          ? CO2_EMISSION_FACTORS.distantTrain
          : new Decimal(CO2_EMISSION_FACTORS.distantTrain).times(milesValue),
      )
      .valueOf()
    setDistantTrainCalculation(distantTrainCarbon)
    const tramCarbon = new Decimal(tram)
      .times(
        tramUnit === units[0] ? CO2_EMISSION_FACTORS.tram : new Decimal(CO2_EMISSION_FACTORS.tram).times(milesValue),
      )
      .valueOf()
    setTramCalculation(tramCarbon)
    const taxiCarbon = new Decimal(taxi)
      .times(
        taxiUnit === units[0] ? CO2_EMISSION_FACTORS.taxi : new Decimal(CO2_EMISSION_FACTORS.taxi).times(milesValue),
      )
      .valueOf()
    setTaxiCalculation(taxiCarbon)
    const subwayCarbon = new Decimal(subway)
      .times(
        subwayUnit === units[0]
          ? CO2_EMISSION_FACTORS.subway
          : new Decimal(CO2_EMISSION_FACTORS.subway).times(milesValue),
      )
      .valueOf()
    setSubwayCalculation(subwayCarbon)
  }

  const offsetPublicTransportFootprint = () => {
    dispatch(setPublicTransportFootprint(0))
    setFormData({
      bus: 0,
      coach: 0,
      localTrain: 0,
      distantTrain: 0,
      tram: 0,
      subway: 0,
      taxi: 0,
    })
    window.open('https://carbondebits.io/shop/', '_blank')
  }
  const handleCancelElement = element => {
    let result = new Decimal(publicTransportFootprint).times(1000).valueOf()
    if (element === 'bus') {
      result = new Decimal(result).minus(busCalculation).valueOf()
      setFormData({ ...formData, bus: 0 })
      setBusCalculation(0)
    } else if (element === 'coach') {
      result = new Decimal(result).minus(coachCalculation).valueOf()
      setFormData({ ...formData, coach: 0 })
      setCoachCalculation(0)
    } else if (element === 'local train') {
      result = new Decimal(result).minus(localTrainCalculation).valueOf()
      setFormData({ ...formData, localTrain: 0 })
      setLocalTrainCalculation(0)
    } else if (element === 'distant train') {
      result = new Decimal(result).minus(distantTrainCalculation).valueOf()
      setFormData({ ...formData, distantTrain: 0 })
      setDistantTrainCalculation(0)
    } else if (element === 'tram') {
      result = new Decimal(result).minus(tramCalculation).valueOf()
      setFormData({ ...formData, tram: 0 })
      setTramCalculation(0)
    } else if (element === 'subway') {
      result = new Decimal(result).minus(subwayCalculation).valueOf()
      setFormData({ ...formData, subway: 0 })
      setSubwayCalculation(0)
    } else if (element === 'taxi') {
      result = new Decimal(result).minus(taxiCalculation).valueOf()
      setFormData({ ...formData, taxi: 0 })
      setTaxiCalculation(0)
    }
    const finalResult = new Decimal(result).dividedBy(1000).valueOf()
    dispatch(setPublicTransportFootprint(parseFloat(finalResult)))
  }

  useEffect(() => {
    if (!isFirstRendering) {
      const newSum = Decimal.sum(
        busCalculation,
        coachCalculation,
        localTrainCalculation,
        distantTrainCalculation,
        tramCalculation,
        subwayCalculation,
        taxiCalculation,
      ).valueOf()
      const carbon = new Decimal(newSum).dividedBy(1000).valueOf()
      setSum(carbon)
    } else {
      setIsFirstRendering(false)
    }
  }, [
    busCalculation,
    coachCalculation,
    localTrainCalculation,
    distantTrainCalculation,
    tramCalculation,
    subwayCalculation,
    taxiCalculation,
  ])

  useEffect(() => {
    if (!isFirstRendering) {
      dispatch(setPublicTransportFootprint(parseFloat(sum)))
    } else {
      setIsFirstRendering(false)
    }
  }, [sum])

  useEffect(() => {
    dispatch(setCarbonFootprint())
  }, [publicTransportFootprint])

  return (
    <div className="flex xl:space-x-6 xxxs:max-xl:flex-col xxxs:max-xl:space-y-8 justify-between md:max-xl:w-[680px] xs:max-md:w-[355px] xxs:max-xs:w-[315px] xxxs:max-xs:w-[260px]">
      <div className="flex flex-col xl:w-[800px] 2xl:max-3xl:w-[650px] md:h-[724px] bg-darkGreen bg-opacity-70 border rounded-lg p-6">
        <div className="flex flex-col space-y-3 border-b border-copyToClipboard pb-4">
          <div className="font-semibold text-xl">How do you get around?</div>
          <p className="text-base font-normal text-white text-opacity-80 tracking-wider">
            Enter mileage for each type of public transport, and press the Calculate button.
          </p>
        </div>
  
        <div className="flex flex-col justify-between h-full py-4 tracking-wider">
          <form className="flex flex-col space-y-3" onSubmit={handleCalculation}>
            <div className="flex flex-col space-y-3 text-base font-normal text-white text-opacity-80 border-b border-copyToClipboard pb-12 md:h-[525px]">
              <div className="flex flex-col space-y-3  border-copyToClipboard pb-4">
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label="Bus"
                    name="bus"
                    value={bus}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setBusUnit}
                  />
                  <CarbonUnitInput
                    label="Coach"
                    name="coach"
                    value={coach}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setCoachUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label="Local or Commuter Train"
                    name="localTrain"
                    value={localTrain}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setLocalTrainUnit}
                  />
                  <CarbonUnitInput
                    label="Long Distance Train"
                    name="distantTrain"
                    value={distantTrain}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setDistantTrainUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label="Tram"
                    name="tram"
                    value={tram}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setTramUnit}
                  />
                  <CarbonUnitInput
                    label="Subway"
                    name="subway"
                    value={subway}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setSubwayUnit}
                  />
                </div>
                <div className="xxxs:max-md:flex-col xxxs:max-md:space-y-3 flex md:space-x-8">
                  <CarbonUnitInput
                    label="Taxi"
                    name="taxi"
                    value={taxi}
                    dropDownOptions={units}
                    onChange={onChange}
                    onUnitChange={setTaxiUnit}
                  />
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <button
                type="submit"
                className="bg-carbifyOrange text-bt font-bold text-base text-center w-full h-12 rounded-lg hover:bg-[#024728] hover:text-white"
              >
                Calculate & Add To Footprint
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col grow md:h-[724px] justify-between">
        <div className="flex flex-col w-full md:h-[653px] text-white text-opacity-80 border rounded-lg bg-darkGreen bg-opacity-70 p-6">
          <TotalFootprint
            title="Public Transport"
            individualFootprint={publicTransportFootprint}
            totalFootprint={carbonFootprint}
            offsetFootprint={offsetPublicTransportFootprint}
          />
          <div className="flex flex-col p-3">
            {bus > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{bus} {busUnit}</b> travelled by bus
                </p>
                <button
                  onClick={() => handleCancelElement('bus')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {coach > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{coach} {coachUnit}</b> travelled by coach
                </p>
                <button
                  onClick={() => handleCancelElement('coach')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {localTrain > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{localTrain} {localTrainUnit}</b> travelled by local train
                </p>
                <button
                  onClick={() => handleCancelElement('local train')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {distantTrain > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{distantTrain} {distantTrainUnit}</b> travelled by distant train
                </p>
                <button
                  onClick={() => handleCancelElement('distant train')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {tram > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{tram} {tramUnit}</b> travelled by tram
                </p>
                <button
                  onClick={() => handleCancelElement('tram')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {subway > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{subway} {subwayUnit}</b> travelled by subway
                </p>
                <button
                  onClick={() => handleCancelElement('subway')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
            {taxi > 0 && (
              <div className="flex justify-between">
                <p className="text-base" style={{ wordBreak: 'break-word' }}>
                  <b>{taxi} {taxiUnit}</b> travelled by taxi
                </p>
                <button
                  onClick={() => handleCancelElement('taxi')}
                  type="button"
                  className=""
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            )}
          </div>
        </div>
  
        <div className="flex justify-between xxxs:max-md:mt-3 mt-4">
          <div className="flex items-center justify-center pr-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-copyToClipboard text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Back')}
            >
              <BiLeftArrowCircle className="w-6 h-6 stroke-1" />
              <span>Back</span>
            </button>
          </div>
          <div className="flex items-center justify-center pl-2">
            <button
              className="flex items-center justify-center md:max-xl:w-64 xl:max-3xl:w-48 3xl:w-56 xxxs:max-md:w-28 h-12 bg-carbifyOrange text-bt font-bold rounded-lg text-base space-x-2 tracking-wider hover:bg-light_green hover:text-white"
              onClick={() => handleNavigation('Next')}
            >
              <BiRightArrowCircle className="w-6 h-6 stroke-1" />
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default PublicTransport
