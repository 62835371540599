import React from "react";
// import BurnIcon from 'icons/BurnIcon';  // Replace this with the actual path to your burn icon
import Certificate from 'icons/Certificate';
import { IoMdInformationCircleOutline } from "react-icons/io";
// import InfoIcon from 'icons/InfoIcon';  // Replace this with the actual path to your info icon

const GenerateCertficateCard = ({
    handleOpenPopupBurn,
    handleOpenPopupCreate,
    balance,
    showPopupBurn,
    showPopupCreate
}) => {
    return (
        <div className="items-stretch h-full bg-darkGreen bg-opacity-70 rounded-lg border p-4 lg:p-6 space-y-6 mb-6">
            <div className="border-b-2 pb-3 border-green w-full mb-4">
                <p className="font-semibold text-xl lg:text-2xl text-white text-center">Generate a certificate request</p>
            </div>
            <div className="space-y-6">
    
                <div className="flex flex-col h-full items-center w-full space-y-4">
                    <p className="text-white text-sm lg:text-base text-center">
                        If you want to create a certificate, you need to follow these steps:
                        <br />
                        <ul className="list-disc list-inside">
                            <li>STEP 1: Burn $aCO2 tokens. You can only create certificates after burning the amount of tokens you want to use for the certificate(s).</li>
                            <li>STEP 2: Generate the certificate request. Fill in the details and submit the form. The Carbify team will review the request and potentially approve it. Once your request is approved, you can proceed to create the certificate! You can check all the data relevant to your carbon reduction certificates using the map view!</li>
                        </ul>
                    </p>
                    <button
                        disabled={ showPopupBurn || balance?.totalBalanceFormatted === 0}
                        onClick={() => handleOpenPopupBurn()}
                        className="flex items-center justify-center w-full bg-transparent text-white border border-white rounded-lg py-2 lg:py-3 font-bold disabled:cursor-not-allowed hover:bg-carbifyOrange hover:border-carbifyOrange hover:text-white disabled:hover:text-green disabled:hover:bg-gray-400"
                    >
                        {/* <BurnIcon className="mr-2" /> */}
                        <span className="tracking-wider text-sm lg:text-base">Burn $aCO2</span>
                    </button>
                </div>
                <div className="flex flex-col space-y-2">
                    <button
                        disabled={ showPopupCreate || balance?.totalBalanceFormatted === 0}
                        onClick={() => handleOpenPopupCreate()}
                        className="bg-white w-full text-green font-bold text-sm lg:text-base font-montserrat rounded-lg py-2 lg:py-3 items-center justify-center flex flex-row hover:bg-carbifyOrange hover:text-white disabled:cursor-not-allowed"
                    >
                        <Certificate className="mr-2" />
                        <span className="tracking-wider">Generate Certificate Requeste</span>
                    </button>
                    <div className="justify-center mt-2 text-white font-light text-center inline-flex items-center">
                      <span>You will request a certificate, your request will be visible in the "Requested Certificates" table. Once approved, you will be able to mint your certificate.</span>
                      <IoMdInformationCircleOutline className="ml-1" />
                    </div>
                </div>
    
            </div>
        </div>
    );
    
    
};

export default GenerateCertficateCard;
