import React from 'react'

const TotalFootprint = ({ title, individualFootprint, totalFootprint, offsetFootprint }) => {
  return (
    <div className="flex flex-col space-y-5 justify-center items-center border-b border-copyToClipboard pb-3">
      <div className="flex flex-col space-y-3 text-center">
        <div className="font-semibold text-xl">Your {title} Footprint</div>
        <div className="text-center">
          <p className="text-white font-semibold text-4xl">{parseFloat(individualFootprint).toFixed(4)}</p>
          <p className="font-medium text-base">metric tons of CO2e</p>
        </div>
      </div>
      <div className="flex flex-col space-y-3 text-center">
        <div className="font-semibold text-xl">Your Total Footprint</div>
        <div className="text-center">
          <p className="text-white font-semibold text-4xl">{parseFloat(totalFootprint).toFixed(4)}</p>
          <p className="font-medium text-base">metric tons of CO2e</p>
        </div>
      </div>
      <button
        onClick={offsetFootprint}
        className="bg-carbifyOrange text-bt font-bold text-base text-center w-1/2 h-12 rounded-lg hover:bg-light_green hover:text-white tracking-wider"
      >
        Offset Now
      </button>
    </div>
  )
}

export default TotalFootprint
