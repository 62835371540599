import axios from 'axios';
import { ethers } from 'ethers';
import { Config } from '../../config.js';

const aco2DataApi = Config().aco2_data_api;

export const getACO2TotalBurnedAmount = async (walletAddress) => {
    try {
        const response = await axios.get(`${aco2DataApi}/api/erc1155/burned/wallet?wallet_address=${walletAddress}`);
        if (response.data.burned_tokens.length === 0) {
            return { totalBurnedAmount: "0", moreDetaildBurnBalance: [] };
        } else {
            const data = response.data.burned_tokens;

            let totalBurnedAmount = 0;
    
            for (let i = 0; i < data.length; i++) {
                totalBurnedAmount += Number(ethers.utils.formatUnits(data[i].amount.toString(), 18))
            }
    
            return {
                totalBurnedAmount: totalBurnedAmount.toString(),
                moreDetaildBurnBalance: data,
            }
        }
    } catch (err) {
        console.error("Error:", err.message);
        return { totalBurnedAmount: "0", moreDetaildBurnBalance: [], error: err.message };
    }
};

