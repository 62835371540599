import Layout from 'layouts/Layout'
import PageHeader from '../components/PageHeading'
import CertificateContent from 'components/certificates/CertificateContent'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCertificateData } from 'features/dashboard/getCertificateData';
import { toast } from 'react-toastify';

function Certificates({ userCertificates }) {
  return (
    <>
     {/* <Layout title="Carbify | Certificates" content="PoCC certificates"> */}
      { userCertificates === null || userCertificates?.length === 0 ? null : 
        <>
        <PageHeader styles={'text-grey !text-3xl'}>Generated Certificates</PageHeader>
        <div>{<CertificateContent userCertificateData={userCertificates} />}</div>
        </>
      }
     {/* </Layout> */}
    </>
  )
}
export default Certificates
