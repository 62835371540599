import React, { useState } from 'react'
import WalletDropdown from '../WalletDropdown'
import { useWeb3Modal } from '@web3modal/wagmi/react'

const ConnectWalletButton = ({ children, text, isSidebar }) => {
  const [walletPopup, setWalletPopup] = useState(false)
  const { open } = useWeb3Modal()
  const handleClick = () => {
    open()
    // setWalletPopup(true)
  }
  const handleClosePopup = () => {
    setWalletPopup(false)
  }
  return (
    <>
      <button
        onClick={handleClick}
        type="button"
        data-modal-target="crypto-modal"
        data-modal-toggle="crypto-modal"
        className="btn relative inline-flex items-center justify-start overflow-hidden px-5 h-12 transition-all bg-carbifyOrange rounded-lg hover:bg-carbifyOrange shadow-xl shadow-black-500/50 font-montserrat font-bold group"
      >
        <span className="w-full h-0 rounded bg-green absolute bottom-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
        <span className="flex items-center justify-center w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10">
          {children}
          <span className="origin-left p-4 text-base">{text}</span>
        </span>
      </button>
      <WalletDropdown visibility={walletPopup} handleClosePopup={handleClosePopup} />
    </>
  )
}

export default ConnectWalletButton
