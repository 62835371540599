import React, { useEffect } from 'react'

// Constants
import { CBY_ADDRESS, USDC_ADDRESS, USDT_ADDRESS } from 'constants/BlockchainConstants'

function CurrencySymbol({ selectedCurrency }) {
  useEffect(() => {
    console.log('Selected Currency', selectedCurrency)
    console.log('USDT', USDT_ADDRESS)
  })
  return (
    <span>
      {selectedCurrency.toLowerCase() === CBY_ADDRESS.toLowerCase()
        ? 'CBY'
        : selectedCurrency.toLowerCase() === USDT_ADDRESS.toLowerCase()
        ? 'USDT'
        : 'USDC'}
    </span>
  )
}

export default CurrencySymbol
