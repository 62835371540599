import { ethers } from 'ethers';
import { CBY_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';

// CBY Contract ABI and Address
const CBY = CBY_ABI;
const CBY_ADDRESS = `${Config().contract_addresses.cby_address}`.toLowerCase();
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();

export const getCBYStakingAllowance = async (address) => {
    try {
        // Initialize ethers with a provider
        const provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        const cbyToken = new ethers.Contract(CBY_ADDRESS, CBY, provider);
        const allowance = await cbyToken.allowance(address, STAKING_ADDRESS);
        // const allowanceFormatted = ethers.utils.formatUnits(allowance, 18);
        if (!allowance) return 0;
        return { allowance: allowance };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message, allowance: 0 };
    }
}
