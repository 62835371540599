import React from 'react'
import Layout from 'layouts/Layout'
import PageHeader from '../components/PageHeading'
import MarketplaceContent from 'components/MarketplaceV2/MarketplaceContent'

function Nfts() {

  return (
    <Layout title="Carbify | Market Place" content="Buy and sell NFTs">
      <PageHeader>Marketplace</PageHeader>
      <MarketplaceContent />
    </Layout>
  )
}
export default Nfts
