import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { STAKING_ABI } from 'constants/BlockchainConstants';
import { Config } from '../../config.js';

// Staking Contract ABI and Address
const STAKING = STAKING_ABI;
const STAKING_ADDRESS = `${Config().contract_addresses.staking_address}`.toLowerCase();
const GENESIS_ADDRESS = `${Config().contract_addresses.genesis_address}`.toLowerCase();
const RARE_ADDRESS = `${Config().contract_addresses.rare_address}`.toLowerCase();
const STANDARD_ADDRESS = `${Config().contract_addresses.standard_address}`.toLowerCase();

export const unstakeTrees = async (amount, treeData, plotTitle) => {
    try {
        const web3Provider = new Web3.providers.HttpProvider(Config().web3_provider);
        const web3 = new Web3(web3Provider)
        const multicall = new Multicall({
            multicallAddress : Config().contract_addresses.multi_call,
            provider: web3Provider
        });
        const web3StakingContract = new web3.eth.Contract(STAKING, STAKING_ADDRESS);

        let landplotAddress = null;
        switch (plotTitle) {
            case "Genesis":
                landplotAddress = GENESIS_ADDRESS;
                break;
            case "Rare":
                landplotAddress = RARE_ADDRESS;
                break;
            case "Standard":
                landplotAddress = STANDARD_ADDRESS;
                break;
        }

        let lockedAndStakedTrees = treeData.filter(tree => tree.is_locked && tree.is_staked);

        // Define the batch size limit
        const batchSize = Config().max_calls_per_multicall_batch; // Adjust this value based on testing to stay under the return limit

        console.log("Batch Size:", batchSize);

        // Function to process batches
        const processBatch = async (batch) => {
            const result = await multicall.aggregate(
                batch.map(token => web3StakingContract.methods.getStakeInfo(token.token_id, (token.contract_address).toLowerCase()))
            );
            return result.map((treeStakeData, index) => ({
                ...batch[index],
                stakedOnPlot: treeStakeData[10].toLowerCase() === landplotAddress
            }));
        };

        // Process all batches
        let results = [];
        for (let i = 0; i < lockedAndStakedTrees.length; i += batchSize) {
            const batch = lockedAndStakedTrees.slice(i, i + batchSize);
            const batchResult = await processBatch(batch);
            results = results.concat(batchResult);
        }

        // Filter trees staked on the correct plot
        lockedAndStakedTrees = results.filter(tree => tree.stakedOnPlot);

        if (amount > lockedAndStakedTrees.length) {
            return { error: `Not enough staked trees found on plot ${plotTitle}. Only found ${lockedAndStakedTrees.length} staked tree${lockedAndStakedTrees.length === 1 ? '' : 's'} on your ${plotTitle.toLowerCase()} plots, while you are trying to unstake ${amount}.` };
        }

        const amountOfTrees = Math.min(amount, lockedAndStakedTrees.length);
        console.log("Amount of Trees:", amountOfTrees);

        return { success: true, unstakedTreesCount: amountOfTrees, lockedAndStakedTrees: lockedAndStakedTrees };
    } catch (err) {
        console.error("Error:", err);
        return { error: err.message };
    }
};
