import React from 'react'

function SmallAco2Loader() {
  return (
    <div className={`w-8 animate-spin z-5`}>
      <img className="w-full" src="/assets/images/aco2_logo.png" alt="loading...." />
    </div>
  )
}

export default SmallAco2Loader
