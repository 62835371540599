import axios from 'axios';
import { Config } from '../../config.js';

const aco2DataAPI = Config().aco2_data_api

export const getCertificateData = async (user_id) => {
    try {
        if (aco2DataAPI === undefined) {
            return null;
        }
        const response = await axios.get(`${aco2DataAPI}/api/certificates/id/?company_id=${user_id}`);

        return response.data; 
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
};
