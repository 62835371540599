import React from 'react'

function ShoppingCartIcon({ width = 24, height = 24, active }) {
  return (
    <div>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          active ? 'stroke-carbifyOrange' : 'stroke-white group-hover:stroke-carbifyOrange ease-in-out duration-500'
        } `}
      >
        <path
          d="M6.25017 17.5H22.6698C23.9359 17.5 24.5689 17.5 25.0728 17.264C25.5167 17.056 25.8897 16.7222 26.1454 16.3039C26.4356 15.8292 26.5056 15.2 26.6454 13.9417L27.3766 7.36043C27.4193 6.9761 27.4407 6.78394 27.3789 6.6352C27.3246 6.50457 27.2276 6.39621 27.1038 6.32785C26.9628 6.25 26.7694 6.25 26.3827 6.25H5.62517M2.5 2.5H4.06055C4.39133 2.5 4.55672 2.5 4.68611 2.56291C4.80002 2.61828 4.89442 2.70697 4.9568 2.8172C5.02765 2.94242 5.03797 3.10749 5.0586 3.43762L6.1914 21.5624C6.21203 21.8925 6.22235 22.0576 6.2932 22.1828C6.35558 22.293 6.44998 22.3817 6.56389 22.4371C6.69328 22.5 6.85867 22.5 7.18945 22.5H23.75M9.375 26.875H9.3875M20.625 26.875H20.6375M10 26.875C10 27.2202 9.72018 27.5 9.375 27.5C9.02982 27.5 8.75 27.2202 8.75 26.875C8.75 26.5298 9.02982 26.25 9.375 26.25C9.72018 26.25 10 26.5298 10 26.875ZM21.25 26.875C21.25 27.2202 20.9702 27.5 20.625 27.5C20.2798 27.5 20 27.2202 20 26.875C20 26.5298 20.2798 26.25 20.625 26.25C20.9702 26.25 21.25 26.5298 21.25 26.875Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default ShoppingCartIcon
