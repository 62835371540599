import React from 'react';

const CertificateManagement = () => {
  return (
    <div className="bg-darkGreen bg-opacity-70 p-6 border rounded-lg mb-6 flex items-center relative">
      <div className="w-2/3">
        <h2 className="text-3xl font-bold text-white mb-4">Carbon Reduction Certificate Management</h2>
        <p className="text-white ">
          On this page, you can create carbon reduction certificates. You can do this for yourself, your family or for others as a gift or present. It is possible to display a name on the certificate as well as a 'reason' for the certificate, which is perfect for adding a personal touch. These certificates are NFTs and officially recognized carbon reduction certificates based on the UNFCCC guidelines.
        </p>
      </div>
      <div className="w-1/3 -mt-40 mr-8 flex justify-end hidden md:block">
        <img src='/assets/images/dashboard_nftree_card1.png' alt="Tree" className=" h-auto" />
      </div>
    </div>
  );
};

export const DashboardHero = () => {
  return (
    <div className="bg-darkGreen bg-opacity-70 p-6 border rounded-lg mb-6 flex items-center relative">
      <div className="w-2/3">
        <h2 className="text-3xl font-bold text-white mb-4">Welcome to Carbidash!</h2>
        <p className="text-white ">
          On this portal, you can manage a variety of things that have a significant positive impact on the climate and our planet! This includes purchasing trees, managing them, staking trees, creating carbon reduction certificates, and viewing the trees responsible for your offsets!
        </p>
      </div>
      <div className="w-1/3 -mt-40 mr-8 flex justify-end hidden md:block">
        <img src='/assets/images/dashboard_nftree_card1.png' alt="Tree" className=" h-auto" />
      </div>
    </div>
  );
};

export const HeroCard = ({ topText, bottomText }) => {
  return (
    <div className="bg-darkGreen bg-opacity-70 p-6 border rounded-lg flex items-center relative">
      <div className="w-2/3">
        <h2 className="text-3xl font-bold text-white mb-4">{topText}</h2>
        <p className="text-white ">
          {bottomText}
        </p>
      </div>
      <div className="w-1/3 -mt-40 mr-8 flex justify-end hidden md:block">
        <img src='/assets/images/dashboard_nftree_card1.png' alt="Tree" className=" h-auto" />
      </div>
    </div>
  );
};

export const HeroCardNoTree = ({ topText, bottomText }) => {
  return (
    <div className="bg-darkGreen bg-opacity-70 p-6 border rounded-lg flex items-center relative">
      <div>
        <h2 className="text-3xl font-bold text-white mb-4">{topText}</h2>
        <p className="text-white ">
          {bottomText}
        </p>
      </div>
    </div>
  );
};

export default CertificateManagement;
