import { ethers } from 'ethers';
import { Config } from '../../config.js';
import { aCO2_ABI } from '../../constants/BlockchainConstants.js'
// ERC1155 Contract ABI and Address
const ERC1155_ABI = aCO2_ABI
const ERC1155_ADDRESS = `${Config().contract_addresses.aco2_address}`

export const isApprovedForAll = async (address) => {
    try {
        const provider = new ethers.providers.JsonRpcProvider(Config().web3_provider);
        const aCO2Token = new ethers.Contract(ERC1155_ADDRESS, ERC1155_ABI, provider);
        const approved = await aCO2Token.isApprovedForAll(address, Config().contract_addresses.aco2_pool_address);
        return approved;
    } catch (err) {
        console.error("Error:", err.message);
        return { error: err.message };
    }
}
