import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PasswordField from 'components/utils/PasswordField'
import AuthLayout from 'layouts/AuthLayout'
import useDisplayError from 'hooks/useDisplayError'

// Reducers
import { resetRegistered, login, resetPasswordUpdated } from 'features/authentication/authSlice'
import { toast } from 'react-toastify'

function LoginPage() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const dispatch = useDispatch()
    
  const navigate = useNavigate()

  const { isAuthenticated, registered, passwordUpdated, errors, userInfo } = useSelector(state => state.authentication)
  const is2faEnabled = useSelector(state => state.authentication.userInfo?.isTwoFactorEnabled)

  useDisplayError(errors)

  useEffect(() => {
    if (registered) dispatch(resetRegistered())
  }, [dispatch, registered])

  useEffect(() => {
    if (passwordUpdated) dispatch(resetPasswordUpdated())
  }, [dispatch, passwordUpdated])

  const { email, password } = formData

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      
      // Dispatch the login action and wait for the result
      dispatch(login({ email, password }));
    } catch (error) {
      console.error("Login failed", error);
    }
  }

  console.log('userInfo', userInfo)

  useEffect(() => {
    if (userInfo !== null) {
      if (userInfo.twoFactorAuth === true) {
        console.log('Navigating to 2fa');
        toast.success('Login successful. Please check your email for the 2FA code.');
        navigate('/two-factor-authentication');
      } else if (userInfo.twoFactorAuth === false) {
        console.log('Navigating to connect-wallet');
        toast.success('Login successful.');
        navigate('/connect-wallet');
      }
    }
  }, [userInfo])

  // if (isAuthenticated && is2faEnabled) {
  //   return <Navigate to="/two-factor-authentication" />
  // } else if (isAuthenticated && !is2faEnabled) {
  //   return <Navigate to="/connect-wallet" />
  // }

  return (
    <AuthLayout title={'Carbify | Login'}>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight  md:text-2xl">Login</h1>
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block mb-2 text-base font-medium">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="text-white-600 bg-white border border-white border-opacity-50 bg-opacity-10 rounded-lg w-full focus:outline-none focus:border-carbifyOrange p-2.5"
              placeholder="email"
              required={true}
              value={email}
              onChange={onChange}
            />
          </div>
          <PasswordField onChange={onChange} value={password} label={'Password'} name={'password'} />
          <button
            type="submit"
            className="w-full bg-carbifyOrange text-bt focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Login
          </button>
          <p className="text-base">
            <Link
              to={'/forgot-password'}
              className="font-medium text-carbifyOrange hover:underline hover:underline-offset-4"
            >
              Forgot password?
            </Link>
          </p>
          <p className="text-sm font-light text-white">
            Don't have an account?{' '}
            <Link to={'/register'} className="font-medium text-carbifyOrange hover:underline">
              Create one here
            </Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  )
}

export default LoginPage
