import React from 'react'
import Sidebar from 'components/Sidebar'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div>
      <div className="flex font-montserrat bg-black">
        <Sidebar />
        <main className="flex-1 ml-72">
          <div className="flex flex-col justify-center items-center space-y-5 w-full h-screen bg-pageNotFoundBackground bg-cover overflow-auto text-white pl-8 pr-6 py-6">
            <div className="font-base text-4xl">brrrrr..</div>
            <div className="font-bold leading-[200px] text-[200px]">404</div>
            <div className="flex flex-col font-bold text-4xl space-y-3">
              <span> It's scary in here, let me take</span>
              <span>you back to a greener view</span>
            </div>
            <div>
              <Link
                to={'/'}
                type="button"
                className="btn relative inline-flex items-center justify-start overflow-hidden px-5 h-12 transition-all bg-carbifyOrange rounded-lg hover:bg-carbifyOrange shadow-xl shadow-black-500/50 font-semibold group"
              >
                <span className="w-full h-0 rounded bg-[#ffcb97] absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
                <span className="flex items-center justify-center w-full text-xl text-black z-10 px-7 py-2">
                  Back Home
                </span>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default PageNotFound
